import { AIRCALL, PHONE, VISIBLE } from './constants';

export const callAircall = (state) => async (dispatch) => {
	dispatch({
		type: PHONE,
		payload: state
	});

	dispatch({
		type: VISIBLE,
		payload: state
	});
};

export const showAircall = (state) => async (dispatch) => {
	dispatch({
		type: VISIBLE,
		payload: state
	});
};

export const setAircallClient = (state) => async (dispatch) => {
	dispatch({
		type: AIRCALL,
		payload: state
	});
};

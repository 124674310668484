import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Col,
	Row,
	Input,
	Modal,
	Form,
	Select,
	Spin,
	notification,
	DatePicker
} from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { InputNumber, ProvinceSelector } from 'afrodita';
import TextArea from 'antd/lib/input/TextArea';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const { Text } = Typography;
const { Option, OptGroup } = Select;

const TableBankinter = ({ mortgageId, mortgage, type }) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [visibleCancel, setVisibleCancel] = useState(false);
	const [otherDatasAll, setOtherDatasAll] = useState(undefined);
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const bankRequestId = location?.state !== undefined ? location.state.bankRequestId : undefined;
	const [emptyFields, setEmptyFields] = useState([]);
	const [selectValues, setSelectValues] = useState(undefined);
	const [guaranties, setGuaranties] = useState({});

	const labelStyles = {
		fontSize: 10,
		color: '#2F4858',
		fontWeight: 400
	};

	useEffect(() => {
		getSelectValues();
		getOtherData();
		const initialValues = generateInitialValues();
		form.setFieldsValue(initialValues);
	}, []);
	useEffect(() => {
		if (mortgage && otherDatasAll && selectValues) {
			handleFormChange();
		}
	}, [form, mortgage, otherDatasAll, selectValues]);

	const getOtherData = () => {
		axios.get(`${env.api.url}/banks/evo/data/all`).then((response) => {
			setOtherDatasAll(response.data.data);
		});
	};
	const getSelectValues = () => {
		axios
			.get(`${env.api.url}/banks/bankinter/data/json`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setSelectValues(response.data.data);
			});
	};
	const handleFormChange = () => {
		const values = form.getFieldsValue();
		const undefinedFields = Object.entries(values)
			.filter(
				([fieldName, value]) => value === undefined || value === '' || value === 'Fecha inválida'
			)
			.map(([fieldName]) => fieldName);
		setEmptyFields(undefinedFields);
	};
	const handleGuarantiesChange = (value, prefix) => {
		setGuaranties((prevState) => ({
			...prevState,
			[prefix]: value
		}));
	};
	const renderFields = (prefix) => (
		<Row gutter={[32, 0]} style={{ marginTop: 24 }}>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.streetName`}
					label={<Text style={labelStyles}>{'Nombre de la calle'}</Text>}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.unitFloor`}
					label={<Text style={labelStyles}>{'En que planta del edificio está la vivienda'}</Text>}>
					<Input />
				</Form.Item>
			</Col>

			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.stair`}
					label={<Text style={labelStyles}>{'Escalera de la vivienda'}</Text>}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.numberOfDependingPersons`}
					label={
						<Text style={labelStyles}>{'Número de personas dependientes que no sean hijos'}</Text>
					}>
					<InputNumber style={{ height: 40 }} />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.relationWithMainOwner`}
					label={
						<Text style={labelStyles}>
							{'Si no es el titular principal, que relación tiene con este'}
						</Text>
					}>
					<Input />
				</Form.Item>
			</Col>

			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.streetType`}
					label={<Text style={labelStyles}>{'Tipo de calle'}</Text>}
					className={emptyFields.includes(`${prefix}.streetType`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Select placeholder="Seleccione">
						{selectValues.streetType.map((data) => (
							<Option key={data.code} value={data.code}>
								{data.name}
							</Option>
						))}
					</Select>
				</Form.Item>
			</Col>

			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.streetNumber`}
					label={<Text style={labelStyles}>{'Numero de la calle'}</Text>}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.provincia`}
					label={<Text style={labelStyles}>{'Provincia'}</Text>}
					className={emptyFields.includes(`${prefix}.provincia`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<ProvinceSelector style={{ width: '100%' }} />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.payRollDirectDepositBank`}
					label={<Text style={labelStyles}>{'Banco de la nómina'}</Text>}
					className={emptyFields.includes(`${prefix}.payRollDirectDepositBank`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.payRollAmountBeforeTax`}
					label={<Text style={labelStyles}>{'Importe de la nómina antes de impuestos'}</Text>}
					className={emptyFields.includes(`${prefix}.payRollAmountBeforeTax`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<InputNumber style={{ height: 40 }} />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.payRollAmountAfterTax`}
					label={<Text style={labelStyles}>{'Importe de la nómina despues de impuestos'}</Text>}
					className={emptyFields.includes(`payRollAmountAfterTax`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<InputNumber style={{ height: 40 }} />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.professionalCategory`}
					label={<Text style={labelStyles}>{'Categoria profesional'}</Text>}
					className={emptyFields.includes(`${prefix}.professionalCategory`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
						{selectValues.professionalCategory.map((data) => (
							<Option key={data.code} value={data.code}>
								{data.name}
							</Option>
						))}
					</Select>
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.CNAECode`}
					label={<Text style={labelStyles}>{'CNAE'}</Text>}
					className={emptyFields.includes(`${prefix}.CNAECode`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
						{otherDatasAll &&
							otherDatasAll.cnaes.map((cnae) => (
								<OptGroup key={cnae.name} label={cnae.name}>
									{cnae.cnaes.map((item) => (
										<Option key={item.code} value={item.code}>
											{item.name}
										</Option>
									))}
								</OptGroup>
							))}
					</Select>
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.tempContractEndDate`}
					label={<Text style={labelStyles}>{'Fecha vencimiento del contrato temporal'}</Text>}>
					<DatePicker style={{ width: '100%', height: 40 }} format={'DD/MM/YYYY'} />
				</Form.Item>
			</Col>

			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.companyName`}
					label={<Text style={labelStyles}>{'Nombre de la compañía'}</Text>}
					className={emptyFields.includes(`${prefix}.companyName`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.companyPhone`}
					label={<Text style={labelStyles}>{'Tlf. de la compañía'}</Text>}
					className={emptyFields.includes(`${prefix}.companyPhone`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.otherJustifiedIncomes`}
					label={<Text style={labelStyles}>{'Otros Ingresos Justificados'}</Text>}>
					<InputNumber style={{ height: 40 }} />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.otherNonJustifiedIncomes`}
					label={<Text style={labelStyles}>{'Otros Ingresos NO Justificados'}</Text>}>
					<InputNumber style={{ height: 40 }} />
				</Form.Item>
			</Col>

			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					name={`${prefix}.guaranties`}
					label={<Text style={labelStyles}>{'Propiedades libres de carga'}</Text>}
					className={emptyFields.includes(`${prefix}.guaranties`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Select
						defaultValue={
							mortgage[`${prefix}`]?.incomesWealth?.ownedHouses?.length > 0
								? true
								: false || undefined
						}
						onChange={(value) => handleGuarantiesChange(value, prefix)}
						placeholder="Seleccione">
						<Option key={true} value={true}>
							{'Si'}
						</Option>
						<Option key={false} value={false}>
							{'No'}
						</Option>
					</Select>
				</Form.Item>
			</Col>
			{guaranties[prefix] && (
				<>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.id`}
							label={<Text style={labelStyles}>{'Identificador de la garantía'}</Text>}>
							<Input />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.uso`}
							label={<Text style={labelStyles}>{'Uso'}</Text>}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
							className={emptyFields.includes(`${prefix}.uso`) ? 'shadowed' : ''}>
							<Select placeholder="Seleccione">
								{selectValues.uso.map((data) => (
									<Option key={data.code} value={data.code}>
										{data.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.tipo`}
							label={<Text style={labelStyles}>{'Tipo de Garantía'}</Text>}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
							className={emptyFields.includes(`${prefix}.tipo`) ? 'shadowed' : ''}>
							<Select placeholder="Seleccione">
								{selectValues.tipoGarantia.map((data) => (
									<Option key={data.code} value={data.code}>
										{data.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.titularidad`}
							label={<Text style={labelStyles}>{'Titularidad'}</Text>}>
							<Input />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.porcentajeTitularidad`}
							label={<Text style={labelStyles}>{'Porcentaje de titularidad - 0 a 100'}</Text>}>
							<InputNumber style={{ height: 40 }} />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.fechaCompra`}
							label={<Text style={labelStyles}>{'Fecha de compra'}</Text>}>
							<DatePicker style={{ width: '100%', height: 40 }} format={'DD/MM/YYYY'} />
						</Form.Item>
					</Col>

					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.tipoVia`}
							label={<Text style={labelStyles}>{'Tipo de vía'}</Text>}>
							<Select placeholder="Seleccione">
								{selectValues.streetType.map((data) => (
									<Option key={data.code} value={data.code}>
										{data.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.nombreVia`}
							label={<Text style={labelStyles}>{'Nombre de la vía'}</Text>}>
							<Input />
						</Form.Item>
					</Col>

					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.numero`}
							label={<Text style={labelStyles}>{'Número'}</Text>}>
							<Input />
						</Form.Item>
					</Col>

					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.escalera`}
							label={<Text style={labelStyles}>{'Escalera'}</Text>}>
							<Input />
						</Form.Item>
					</Col>

					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.planta`}
							label={<Text style={labelStyles}>{'Planta'}</Text>}>
							<Input />
						</Form.Item>
					</Col>

					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item name={`${prefix}.letra`} label={<Text style={labelStyles}>{'Letra'}</Text>}>
							<Input />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.localidad`}
							label={<Text style={labelStyles}>{'Localidad'}</Text>}>
							<Input />
						</Form.Item>
					</Col>

					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.codigoPostal`}
							label={<Text style={labelStyles}>{'Código Postal'}</Text>}>
							<Input />
						</Form.Item>
					</Col>

					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.provincia`}
							label={<Text style={labelStyles}>{'Provincia'}</Text>}
							className={emptyFields.includes(`${prefix}.provincia`) ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<ProvinceSelector style={{ width: '100%' }} />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item name={`${prefix}.pais`} label={<Text style={labelStyles}>{'País'}</Text>}>
							<Input />
						</Form.Item>
					</Col>

					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.numeroHabitantes`}
							label={<Text style={labelStyles}>{'Número de habitantes'}</Text>}>
							<InputNumber style={{ height: 40 }} />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.tipoValoracion`}
							label={<Text style={labelStyles}>{'Tipo de valoración'}</Text>}>
							<Input />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.fechaTasacion`}
							label={<Text style={labelStyles}>{'Fecha de la tasación'}</Text>}>
							<DatePicker style={{ width: '100%', height: 40 }} format={'DD/MM/YYYY'} />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.viviendaProtegida`}
							label={<Text style={labelStyles}>{'Tipo de vivienda protegida'}</Text>}>
							<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
								<Option key={'VPLIB'} value={'VPLIB'}>
									{'Libre'}
								</Option>
								<Option key={'VPVPO'} value={'VPVPO'}>
									{'VPO'}
								</Option>
								<Option key={'VPVPP'} value={'VPVPP'}>
									{'VPP'}
								</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.superficie`}
							label={<Text style={labelStyles}>{'Superficie'}</Text>}>
							<InputNumber style={{ height: 40 }} />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.estadoVivienda`}
							label={<Text style={labelStyles}>{'Estado de la vivienda'}</Text>}>
							<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
								{selectValues.estadoVivienda.map((data) => (
									<Option key={data.code} value={data.code}>
										{data.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.ingresoAlquiler`}
							label={<Text style={labelStyles}>{'Ingreso Alquiler'}</Text>}>
							<InputNumber style={{ height: 40 }} />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.saldoHipoteca`}
							label={<Text style={labelStyles}>{'Saldo Hipoteca'}</Text>}>
							<InputNumber style={{ height: 40 }} />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.tasadora`}
							label={<Text style={labelStyles}>{'Tasadora'}</Text>}>
							<Input />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.finca`}
							label={<Text style={labelStyles}>{'Finca'}</Text>}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
							className={emptyFields.includes(`${prefix}.finca`) ? 'shadowed' : ''}>
							<Input />
						</Form.Item>
					</Col>

					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.referenciaCatastral`}
							label={<Text style={labelStyles}>{'Referencia Catastral'}</Text>}>
							<Input />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.idufirCru`}
							label={<Text style={labelStyles}>{'IDUFIR/CRU'}</Text>}>
							<Input />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.importeTasacion`}
							label={
								<Text style={labelStyles}>{'Valor real de tasación aportado por el cliente'}</Text>
							}>
							<InputNumber style={{ height: 40 }} />
						</Form.Item>
					</Col>

					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.divisaTasacion`}
							label={<Text style={labelStyles}>{'Divisa tasación'}</Text>}>
							<Input />
						</Form.Item>
					</Col>

					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.ClaseInmueble`}
							label={<Text style={labelStyles}>{'Clase de inmueble'}</Text>}>
							<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
								{selectValues.claseInmueble.map((data) => (
									<Option key={data.code} value={data.code}>
										{data.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.tipoInmueble`}
							label={<Text style={labelStyles}>{'Tipo de inmueble'}</Text>}>
							<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
								{selectValues.tipoInmueble.map((data) => (
									<Option key={data.code} value={data.code}>
										{data.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.tipoVivienda`}
							label={<Text style={labelStyles}>{'Tipo de vivienda'}</Text>}>
							<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
								{selectValues.tipoVivienda.map((data) => (
									<Option key={data.code} value={data.code}>
										{data.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>

					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.valorEstimado`}
							label={<Text style={labelStyles}>{'Valor estimado de la garantía'}</Text>}>
							<InputNumber style={{ height: 40 }} />
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.calificacionEnergetica`}
							label={<Text style={labelStyles}>{'Calificación energética'}</Text>}>
							<Select>
								<Option value="SI">Si</Option>
								<Option value="NO">No</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
						<Form.Item
							name={`${prefix}.fechaCalificacionEnergetica`}
							label={<Text style={labelStyles}>{'Fecha calificación energética'}</Text>}>
							<DatePicker style={{ width: '100%', height: 40 }} format={'DD/MM/YYYY'} />
						</Form.Item>
					</Col>
				</>
			)}
		</Row>
	);
	const renderOwnersForm = () => {
		const owners = mortgage.operation.owners || 1;
		const guarantors = mortgage.operation.guarantors || 0;
		const arrayForm = [];

		for (let i = 1; i <= owners; i++) {
			arrayForm.push(
				<React.Fragment key={`owner-${i}`}>
					<Row>
						<Text
							style={{
								fontSize: 14,
								fontWeight: 600,
								color: '#2F4858',
								marginTop: i === 1 ? 32 : 24
							}}>
							{`Titular ${i}`}
						</Text>
					</Row>
					{renderFields(`owner${i}`)}
				</React.Fragment>
			);
		}

		for (let j = 1; j <= guarantors; j++) {
			arrayForm.push(
				<React.Fragment key={`guarantor-${j}`}>
					<Row>
						<Text
							style={{
								fontSize: 14,
								fontWeight: 600,
								color: '#2F4858',
								marginTop: j === 1 ? 32 : 24
							}}>
							{`Avalista ${j}`}
						</Text>
					</Row>
					{renderFields(`guarantor${j}`)}
				</React.Fragment>
			);
		}

		return arrayForm;
	};
	const generateInitialValues = () => {
		const owners = mortgage.operation.owners || 1;
		const guarantors = mortgage.operation.guarantors || 0;
		const initialValues = {
			notes: mortgage.otherDataAdditional?.summary || undefined
		};
		const newGuaranties = {};

		for (let i = 1; i <= owners; i++) {
			initialValues[`owner${i}.streetName`] = mortgage[`owner${i}`]?.address || undefined;
			initialValues[`owner${i}.companyName`] = mortgage[`owner${i}`]?.companyName || undefined;
			initialValues[`owner${i}.payRollAmountAfterTax`] =
				mortgage[`owner${i}`]?.netIncomes || undefined;
			initialValues[`owner${i}.guaranties`] =
				mortgage[`owner${i}`]?.incomesWealth?.ownedHouses?.length > 0 ? true : false || undefined;
			newGuaranties[`owner${i}`] =
				mortgage[`owner${i}`]?.incomesWealth?.ownedHouses?.length > 0 ? true : false || undefined;
		}

		for (let j = 1; j <= guarantors; j++) {
			initialValues[`guarantor${j}.streetName`] = mortgage[`guarantor${j}`]?.address || undefined;
			initialValues[`guarantor${j}.companyName`] =
				mortgage[`guarantor${j}`]?.companyName || undefined;
			initialValues[`guarantor${j}.payRollAmountAfterTax`] =
				mortgage[`guarantor${j}`]?.netIncomes || undefined;
			initialValues[`guarantor${j}.payRollAmountAfterTax`] =
				mortgage[`guarantor${j}`]?.incomesWealth?.ownedHouses?.length > 0
					? true
					: false || undefined;
			newGuaranties[`guarantor${j}`] =
				mortgage[`guarantor${j}`]?.incomesWealth?.ownedHouses?.length > 0
					? true
					: false || undefined;
		}
		setGuaranties(newGuaranties);
		return initialValues;
	};
	const createParticipant = (index, prefix, values) => {
		const participant = {
			ownerType: `${prefix}${index}`,
			numberOfDependingPersons: values[`${prefix}${index}.numberOfDependingPersons`],
			relationWithMainOwner: values[`${prefix}${index}.relationWithMainOwner`],
			addressDetails: {
				stair: values[`${prefix}${index}.stair`],
				unitLetter: values[`${prefix}${index}.unitLetter`],
				township: values[`${prefix}${index}.township`],
				streetName: values[`${prefix}${index}.streetName`],
				streetNumber: values[`${prefix}${index}.streetNumber`],
				unitFloor: values[`${prefix}${index}.unitFloor`],
				streetType: values[`${prefix}${index}.streetType`],
				province: values[`${prefix}${index}.provincia`]
			},
			economicDetails: {
				payRollDirectDepositBank: values[`${prefix}${index}.payRollDirectDepositBank`],
				payRollAmountBeforeTax: values[`${prefix}${index}.payRollAmountBeforeTax`],
				payRollAmountAfterTax: values[`${prefix}${index}.payRollAmountAfterTax`],
				professionalCategory: values[`${prefix}${index}.professionalCategory`],
				CNAECode: values[`${prefix}${index}.CNAECode`],
				companyName: values[`${prefix}${index}.companyName`],
				companyPhone: values[`${prefix}${index}.companyPhone`],
				tempContractEndDate: dayjs(values[`${prefix}${index}.tempContractEndDate`]).format(
					'YYYYMMDD'
				),
				otherJustifiedIncomes: values[`${prefix}${index}.otherJustifiedIncomes`],
				otherNonJustifiedIncomes: values[`${prefix}${index}.otherNonJustifiedIncomes`]
			}
		};
		if (values[`${prefix}${index}.guaranties`]) {
			participant.guaranties = [
				{
					id: values[`${prefix}${index}.id`],
					uso: values[`${prefix}${index}.uso`],
					tipo: values[`${prefix}${index}.tipo`],
					titularidad: values[`${prefix}${index}.titularidad`],
					porcentajeTitularidad: values[`${prefix}${index}.porcentajeTitularidad`],
					fechaCompra: dayjs(values[`${prefix}${index}.fechaCompra`]).format('YYYYMMDD'),
					direccion: {
						tipoVia: values[`${prefix}${index}.tipoVia`],
						nombreVia: values[`${prefix}${index}.nombreVia`],
						numero: values[`${prefix}${index}.numero`],
						escalera: values[`${prefix}${index}.escalera`],
						planta: values[`${prefix}${index}.planta`],
						letra: values[`${prefix}${index}.letra`],
						localidad: values[`${prefix}${index}.localidad`],
						codigoPostal: values[`${prefix}${index}.codigoPostal`],
						provincia: values[`${prefix}${index}.provincia`],
						pais: values[`${prefix}${index}.pais`]
					},
					numeroHabitantes: values[`${prefix}${index}.numeroHabitantes`],
					tipoValoracion: values[`${prefix}${index}.tipoValoracion`],
					fechaTasacion: dayjs(values[`${prefix}${index}.fechaTasacion`]).format('YYYYMMDD'),
					viviendaProtegida: values[`${prefix}${index}.viviendaProtegida`],
					superficie: values[`${prefix}${index}.superficie`],
					estadoVivienda: values[`${prefix}${index}.estadoVivienda`],
					ingresoAlquiler: values[`${prefix}${index}.ingresoAlquiler`],
					saldoHipoteca: values[`${prefix}${index}.saldoHipoteca`],
					tasadora: values[`${prefix}${index}.tasadora`],
					finca: values[`${prefix}${index}.finca`],
					referenciaCatastral: values[`${prefix}${index}.referenciaCatastral`],
					idufirCru: values[`${prefix}${index}.idufirCru`],
					importeTasacion: values[`${prefix}${index}.importeTasacion`],
					divisaTasacion: values[`${prefix}${index}.divisaTasacion`],
					claseInmueble: values[`${prefix}${index}.claseInmueble`],
					tipoInmueble: values[`${prefix}${index}.tipoInmueble`],
					tipoVivienda: values[`${prefix}${index}.tipoVivienda`],
					valorEstimado: values[`${prefix}${index}.valorEstimado`],
					calificacionEnergetica: values[`${prefix}${index}.calificacionEnergetica`],
					fechaCalificacionEnergetica: dayjs(
						values[`${prefix}${index}.fechaCalificacionEnergetica`]
					).format('YYYYMMDD')
				}
			];
		}

		return participant;
	};
	const cleanObject = (obj) => {
		if (typeof obj !== 'object' || obj === null) {
			return obj;
		}

		const newObj = Array.isArray(obj) ? [] : {};
		for (const key in obj) {
			const cleanedValue = cleanObject(obj[key]);
			if (
				cleanedValue !== undefined &&
				!(typeof cleanedValue === 'object' && Object.keys(cleanedValue).length === 0)
			) {
				newObj[key] = cleanedValue;
			}
		}
		return newObj;
	};
	const onFinish = (values) => {
		setLoading(true);

		const obj = {
			bankRequestId: bankRequestId,
			operationId: mortgageId,
			earnestMoneySigned: values.earnestMoneySigned,
			fundsPurpose: values.fundsPurpose,
			fundsOrigin: values.fundsOrigin,
			nonFinancedFundsOrigin: values.nonFinancedFundsOrigin,
			notes: values.notes,
			ownerData: [],
			newConstructionData: {
				projectName: values.projectName,
				sponsoringBank: values.sponsoringBank,
				deliveryDate: values.deliveryDate,
				type: values.type,
				projectUrl: values.projectUrl,
				developer: values.developer,
				address: {
					stair: values.stair,
					unitLetter: values.unitLetter,
					township: values.township,
					streetName: values.streetName,
					streetNumber: values.streetNumber,
					streetType: values.streetType,
					postalCode: values.postalCode,
					contry: values.country,
					province: values.province
				}
			},
			subrogationData: {
				currentDifferential: values.currentDifferential,
				currentMortgageType: values.currentMortgageType
			},
			mortgageGuaranty: {
				id: values.id,
				uso: values.uso,
				tipo: values.tipo,
				titularidad: values.titularidad,
				porcentajeTitularidad: values.porcentajeTitularidad,
				fechaCompra: dayjs(values.fechaCompra).format('YYYYMMDD'),
				direccion: {
					tipoVia: values.tipoVia,
					nombreVia: values.nombreVia,
					numero: values.numero,
					escalera: values.escalera,
					planta: values.planta,
					letra: values.letra,
					localidad: values.localidad,
					codigoPostal: values.codigoPostal,
					provincia: values.provincia,
					pais: values.pais
				},
				numeroHabitantes: values.numeroHabitantes,
				tipoValoracion: values.tipoValoracion,
				fechaTasacion: dayjs(values.fechaTasacion).format('YYYYMMDD'),
				viviendaProtegida: values.viviendaProtegida,
				superficie: values.superficie,
				estadoVivienda: values.estadoVivienda,
				ingresoAlquiler: values.ingresoAlquiler,
				saldoHipoteca: values.saldoHipoteca,
				tasadora: values.tasadora,
				finca: values.finca,
				referenciaCatastral: values.referenciaCatastral,
				idufirCru: values.idufirCru,
				importeTasacion: values.importeTasacion,
				divisaTasacion: values.divisaTasacion,
				claseInmueble: values.claseInmueble,
				tipoInmueble: values.tipoInmueble,
				tipoVivienda: values.tipoVivienda,
				valorEstimado: values.valorEstimado,
				calificacionEnergetica: values.calificacionEnergetica,
				fechaCalificacionEnergetica: dayjs(values.fechaCalificacionEnergetica).format('YYYYMMDD')
			}
		};

		if (values.deliveryDate) {
			obj.newConstructionData.deliveryDate = values.deliveryDate.format('YYYYMMDD');
		}

		const owners = mortgage.operation.owners;
		for (let i = 1; i <= owners; i++) {
			const ownerData = createParticipant(i, 'owner', values);
			obj.ownerData.push(ownerData);
		}

		const guarantors = mortgage.operation.guarantors;
		for (let i = 1; i <= guarantors; i++) {
			const guarantorData = createParticipant(i, 'guarantor', values);
			obj.ownerData.push(guarantorData);
		}

		const cleanedObjet = cleanObject(obj);

		axios
			.post(
				`${env.api.url}/banks/bankinter/create-request`,
				{
					...cleanedObjet
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.data.resultado === 'OK') {
					setLoading(false);
					notification.success({
						message: 'Se ha generado correctamente el bank Resquest.',
						description: 'La bank Resquest se genero correctamente'
					});
					navigate(`/operations/mortgages/${mortgageId}/?tab=5`);
				} else {
					setLoading(false);
					notification.error({
						message: 'Error',
						description: 'Algo fue mal'
					});
				}
			});
	};
	const handleCancel = () => {
		setVisibleCancel(!visibleCancel);
	};
	const handleOk = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/form-send-bank`);
	};

	if (otherDatasAll && loading === false && selectValues && mortgage) {
		console.log('objeto: ', mortgage);
		return (
			<>
				<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
					Datos de la oferta solicitada
				</Text>
				<Form
					key={'bankinterForm'}
					form={form}
					layout="vertical"
					name="sendMessageForm"
					className="gb-form-offers-new"
					style={{ width: '100%' }}
					onChange={handleFormChange}
					onFinish={onFinish}>
					<Row gutter={[32, 4]} style={{ marginTop: 24, marginBottom: 24 }}>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={'earnestMoneySigned'}
								label={<Text style={labelStyles}>{'Dinero en arras firmado'}</Text>}
								className={emptyFields.includes('earnestMoneySigned') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select placeholder="Seleccione">
									<Option value={true}>Sí</Option>
									<Option value={false}>No</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={'fundsPurpose'}
								label={<Text style={labelStyles}>{'Fondos Propósito'}</Text>}
								className={emptyFields.includes('fundsPurpose') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select placeholder="Seleccione">
									{selectValues.fundsPurpose.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={'fundsOrigin'}
								label={<Text style={labelStyles}>{'Origen de los fondos'}</Text>}
								className={emptyFields.includes('fundsOrigin') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select placeholder="Seleccione">
									{selectValues.fundsOrigin.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={'nonFinancedFundsOrigin'}
								label={<Text style={labelStyles}>{'Origen de los fondos no financiados'}</Text>}
								className={emptyFields.includes('nonFinancedFundsOrigin') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select placeholder="Seleccione">
									{selectValues.fundsOrigin.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
						Datos de Inmueble a Hipotecar
					</Text>
					<Row gutter={[32, 4]} style={{ marginTop: 24, marginBottom: 24 }}>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name="id"
								label={<Text style={labelStyles}>{'Identificador de la garantía'}</Text>}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name="uso"
								label={<Text style={labelStyles}>{'Uso'}</Text>}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
								className={emptyFields.includes(`uso`) ? 'shadowed' : ''}>
								<Select placeholder="Seleccione">
									{selectValues.uso.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`tipo`}
								label={<Text style={labelStyles}>{'Tipo de Garantía'}</Text>}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
								className={emptyFields.includes(`tipo`) ? 'shadowed' : ''}>
								<Select placeholder="Seleccione">
									{selectValues.tipoGarantia.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`titularidad`}
								label={<Text style={labelStyles}>{'Titularidad'}</Text>}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`porcentajeTitularidad`}
								label={<Text style={labelStyles}>{'Porcentaje de titularidad - 0 a 100'}</Text>}>
								<InputNumber style={{ height: 40 }} />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`fechaCompra`}
								label={<Text style={labelStyles}>{'Fecha de compra'}</Text>}>
								<DatePicker style={{ width: '100%', height: 40 }} format={'DD/MM/YYYY'} />
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item name={`tipoVia`} label={<Text style={labelStyles}>{'Tipo de vía'}</Text>}>
								<Select placeholder="Seleccione">
									{selectValues.streetType.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`nombreVia`}
								label={<Text style={labelStyles}>{'Nombre de la vía'}</Text>}>
								<Input />
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item name={`numero`} label={<Text style={labelStyles}>{'Número'}</Text>}>
								<Input />
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item name={`escalera`} label={<Text style={labelStyles}>{'Escalera'}</Text>}>
								<Input />
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item name={`planta`} label={<Text style={labelStyles}>{'Planta'}</Text>}>
								<Input />
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item name={`letra`} label={<Text style={labelStyles}>{'Letra'}</Text>}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item name={`localidad`} label={<Text style={labelStyles}>{'Localidad'}</Text>}>
								<Input />
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`codigoPostal`}
								label={<Text style={labelStyles}>{'Código Postal'}</Text>}>
								<Input />
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`provincia`}
								label={<Text style={labelStyles}>{'Provincia'}</Text>}
								className={emptyFields.includes(`provincia`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<ProvinceSelector style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item name={`pais`} label={<Text style={labelStyles}>{'País'}</Text>}>
								<Input />
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`numeroHabitantes`}
								label={<Text style={labelStyles}>{'Número de habitantes'}</Text>}>
								<InputNumber style={{ height: 40 }} />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`tipoValoracion`}
								label={<Text style={labelStyles}>{'Tipo de valoración'}</Text>}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`fechaTasacion`}
								label={<Text style={labelStyles}>{'Fecha de la tasación'}</Text>}>
								<DatePicker style={{ width: '100%', height: 40 }} format={'DD/MM/YYYY'} />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`viviendaProtegida`}
								label={<Text style={labelStyles}>{'Tipo de vivienda protegida'}</Text>}>
								<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
									<Option key={'VPLIB'} value={'VPLIB'}>
										{'Libre'}
									</Option>
									<Option key={'VPVPO'} value={'VPVPO'}>
										{'VPO'}
									</Option>
									<Option key={'VPVPP'} value={'VPVPP'}>
										{'VPP'}
									</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`superficie`}
								label={<Text style={labelStyles}>{'Superficie'}</Text>}>
								<InputNumber style={{ height: 40 }} />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`estadoVivienda`}
								label={<Text style={labelStyles}>{'Estado de la vivienda'}</Text>}>
								<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
									{selectValues.estadoVivienda.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`ingresoAlquiler`}
								label={<Text style={labelStyles}>{'Ingreso Alquiler'}</Text>}>
								<InputNumber style={{ height: 40 }} />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`saldoHipoteca`}
								label={<Text style={labelStyles}>{'Saldo Hipoteca'}</Text>}>
								<InputNumber style={{ height: 40 }} />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item name={`tasadora`} label={<Text style={labelStyles}>{'Tasadora'}</Text>}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`finca`}
								label={<Text style={labelStyles}>{'Finca'}</Text>}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
								className={emptyFields.includes(`finca`) ? 'shadowed' : ''}>
								<Input />
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`referenciaCatastral`}
								label={<Text style={labelStyles}>{'Referencia Catastral'}</Text>}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item name={`idufirCru`} label={<Text style={labelStyles}>{'IDUFIR/CRU'}</Text>}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`importeTasacion`}
								label={
									<Text style={labelStyles}>
										{'Valor real de tasación aportado por el cliente'}
									</Text>
								}>
								<InputNumber style={{ height: 40 }} />
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`divisaTasacion`}
								label={<Text style={labelStyles}>{'Divisa tasación'}</Text>}>
								<Input />
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`ClaseInmueble`}
								label={<Text style={labelStyles}>{'Clase de inmueble'}</Text>}>
								<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
									{selectValues.claseInmueble.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`tipoInmueble`}
								label={<Text style={labelStyles}>{'Tipo de inmueble'}</Text>}>
								<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
									{selectValues.tipoInmueble.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`tipoVivienda`}
								label={<Text style={labelStyles}>{'Tipo de vivienda'}</Text>}>
								<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
									{selectValues.tipoVivienda.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`valorEstimado`}
								label={<Text style={labelStyles}>{'Valor estimado de la garantía'}</Text>}>
								<InputNumber style={{ height: 40 }} />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`calificacionEnergetica`}
								label={<Text style={labelStyles}>{'Calificación energética'}</Text>}>
								<Select>
									<Option value="SI">Si</Option>
									<Option value="NO">No</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`fechaCalificacionEnergetica`}
								label={<Text style={labelStyles}>{'Fecha calificación energética'}</Text>}>
								<DatePicker style={{ width: '100%', height: 40 }} format={'DD/MM/YYYY'} />
							</Form.Item>
						</Col>
					</Row>

					{mortgage.operation.subtype === 'subrogation' && (
						<>
							<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
								Hipoteca Actual
							</Text>
							<Row gutter={[32, 4]} style={{ marginTop: 24 }}>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="currentDifferential"
										label={<Text style={labelStyles}>{'Diferencial actual'}</Text>}
										className={emptyFields.includes('currentDifferential') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<InputNumber style={{ height: 40 }} />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="currentMortgageType"
										label={<Text style={labelStyles}>{'Tipo de préstamo'}</Text>}
										className={emptyFields.includes('currentMortgageType') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<Select placeholder="Seleccione">
											{selectValues.tipoPrestamo.map((data) => (
												<Option key={data.code} value={data.code}>
													{data.name}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
						</>
					)}
					{mortgage.mortgage.propertyType === 'newConstruction' && (
						<>
							<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
								Datos de Obra Nueva
							</Text>
							<Row gutter={[32, 4]} style={{ marginTop: 24 }}>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="projectName"
										label={<Text style={labelStyles}>{'Nombre del proyecto de obra nueva'}</Text>}>
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="sponsoringBank"
										label={<Text style={labelStyles}>{'Banco promotor'}</Text>}>
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="deliveryDate"
										label={<Text style={labelStyles}>{'Fecha de entrega'}</Text>}>
										<DatePicker style={{ width: '100%', height: 40 }} format={'YYYY/MM/DD'} />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="tipoObraNueva"
										label={<Text style={labelStyles}>{'Tipo obra nueva'}</Text>}>
										<Select placeholder="Seleccione">
											{selectValues.tipoObraNueva.map((data) => (
												<Option key={data.code} value={data.code}>
													{data.name}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="projectUrl"
										label={<Text style={labelStyles}>{'Enlace a la web del proyecto'}</Text>}>
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item name="developer" label={<Text style={labelStyles}>{'Promotor'}</Text>}>
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="stair"
										label={<Text style={labelStyles}>{'Escalera de la vivienda'}</Text>}>
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="unitLetter"
										label={<Text style={labelStyles}>{'Letra de la vivienda'}</Text>}>
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="township"
										label={<Text style={labelStyles}>{'Localidad de la vivienda'}</Text>}>
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="province"
										label={<Text style={labelStyles}>{'Provincia de la vivienda'}</Text>}
										className={emptyFields.includes('province') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<ProvinceSelector style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item name="streetName" label={<Text style={labelStyles}>{'Calle'}</Text>}>
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="streetNumber"
										label={<Text style={labelStyles}>{'Número'}</Text>}>
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item name="unitFloor" label={<Text style={labelStyles}>{'Planta'}</Text>}>
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="streetType"
										label={<Text style={labelStyles}>{'Tipo de calle'}</Text>}
										className={emptyFields.includes('province') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<Select placeholder="Seleccione">
											{selectValues.streetType.map((data) => (
												<Option key={data.code} value={data.code}>
													{data.name}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item
										name="postalCode"
										label={<Text style={labelStyles}>{'Código postal'}</Text>}>
										<Input />
									</Form.Item>
								</Col>
								<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
									<Form.Item name="country" label={<Text style={labelStyles}>{'País'}</Text>}>
										<Input />
									</Form.Item>
								</Col>
							</Row>
						</>
					)}
					{renderOwnersForm()}
					<Row>
						<Col xs={24} md={24} sm={24} lg={24} xl={24} xxl={24}>
							<Form.Item name={'notes'} label={<Text style={labelStyles}>{'Notas'}</Text>}>
								<TextArea
									style={{
										borderColor: '#c0daee',
										borderRadius: '4px',
										width: '100%',
										height: '84px'
									}}
									placeholder={'Notas'}></TextArea>
							</Form.Item>
						</Col>
					</Row>
					<Row style={{ justifyContent: 'center', marginTop: 20 }}>
						<Button
							style={{ height: 32, width: 96, fontSize: 14 }}
							className="button-secundary-gibobs"
							onClick={handleCancel}
							type="dashed">
							Atras
						</Button>

						<Button
							style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
							type="primary"
							htmlType="submit"
							className="button-primari-gibobs">
							Aceptar
						</Button>
					</Row>
				</Form>
				<Modal
					className="gb-modal-style-common"
					cancelButtonProps={{ className: 'button-secundary-gibobs', style: { width: 96 } }}
					okButtonProps={{ className: 'button-primari-gibobs', style: { width: 96 } }}
					open={visibleCancel}
					title={
						<Text style={{ fontSize: '14px', fontWeight: '600px', color: '#2F4858' }}>
							{'Cancelar envío'}
						</Text>
					}
					okText={'Aceptar'}
					cancelText={'Cancelar'}
					width={404}
					onCancel={handleCancel}
					onOk={handleOk}>
					<Text style={{ fontSize: '12px', fontWeight: '400px', color: '#2F4858' }}>
						{'¿Aceptas la cancelación del envio a Bankinter?'}
					</Text>
				</Modal>
			</>
		);
	} else {
		return (
			<>
				<div style={{ textAlign: 'center', height: '40vh' }}>
					<Spin style={{ position: 'absolute', top: '30%' }} size="large" />
				</div>
				<div style={{ textAlign: 'center', height: '80vh' }}>
					<Text style={{ marginTop: 34, fontSize: 14, fontWeight: 500 }}>
						Por favor, no cierre ni recargue la pagina, puede tardar hasta 3 minutos...
					</Text>
				</div>
			</>
		);
	}
};
export default TableBankinter;

import { createStore, combineReducers, applyMiddleware } from 'redux';
import authReducer from './auth/reducer';
import mortgagesReducer from './mortgages/reducer';
import formalizationTasacion from './FormalizationTasacion/reducer';
import aircall from './aircall/reducer';
import taskData from './task/reducer';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'; // this is for debugging with React-Native-Debugger, you may leave it out
import documents from './documents/reducer';
import banks from './banks/reducer';
import promoter from './promoter/reducer';

const reducers = combineReducers({
	auth: authReducer,
	mortgages: mortgagesReducer,
	formaTasaci: formalizationTasacion,
	documentation: documents,
	task: taskData,
	banks: banks,
	aircall: aircall,
	promoter: promoter
});

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunkMiddleware)));

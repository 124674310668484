export const PROFILE = 'PROFILE';
export const TOKEN = 'TOKEN';
export const LOADING = 'LOADING';
export const EXPIRES_AT = 'EXPIRES_AT';
export const ERROR = 'ERROR';
export const AUTHENTICATED = 'AUTHENTICATED';
export const PROFILERESET = 'PROFILERESET';
export const ROLES = 'ROLES';
export const LOGINFAILE = 'LOGINFAILE';
export const DATETABLE = 'DATETABLE';
export const DATACLIENT = 'DATACLIENT';


import React, { useEffect, useState } from 'react';
import { Card, Typography, Button } from 'antd';
import axios from 'axios';
import authClient from '../../../../../../Auth/Auth';
import env from '../../../../../../environment';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const BlockedBanksComponent = ({ mortgageId }) => {
	let navigate = useNavigate();
	const [dataBankBlock, setDataBankBlock] = useState(undefined);

	useEffect(() => {
		getBankBloked();
	}, [mortgageId]);

	const getBankBloked = (bank) => {
		axios
			.get(`${env.api.url}/banks/operation-blocked-bank/by-operation/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				if (!bank) {
					setDataBankBlock(response.data.data);
				}
			});
	};

	const hasBanks = dataBankBlock ? dataBankBlock.length > 0 : false;

	return (
		<>
			<Card
				style={{
					border: '1px solid #ff4d4f',
					borderRadius: '4px',
					width: 'auto',
					minHeight: 72
				}}>
				<div
					style={{
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'space-between'
					}}>
					<Text
						style={{
							fontSize: '12px',
							fontWeight: '600',
							color: '#2F4858',
							minWidth: '140px'
						}}>
						Bancos bloqueados
					</Text>

					<div style={{ width: '100%' }}>
						{dataBankBlock &&
							dataBankBlock.map((bank) => (
								<img
									key={bank?.bank?.bankCode}
									src={
										'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
										`${bank?.bank?.bankCode}` +
										'.png'
									}
									alt={bank?.bank?.largeName}
									style={{ height: '20px', width: 'auto', margin: '0 10%' }}
								/>
							))}
					</div>
					<Button
						type="dashed"
						className="button-secundary-gibobs"
						onClick={() =>
							hasBanks
								? navigate(`/operations/mortgages/${mortgageId}/add-block-bank/view-banks`)
								: navigate(`/operations/mortgages/${mortgageId}/editData`)
						}
						style={{ fontSize: 12, width: 136, height: 24, marginLeft: 24 }}>
						{hasBanks ? 'Ir a detalle' : 'Añadir banco'}
					</Button>
				</div>
			</Card>
		</>
	);
};

export default BlockedBanksComponent;

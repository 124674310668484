import React, { useEffect, useState, useLayoutEffect } from 'react';
import axios from 'axios';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import {
	Button,
	Col,
	DatePicker,
	Layout,
	message,
	Row,
	Popover,
	Card,
	Modal,
	Form,
	Input,
	Typography,
	Avatar,
	Image,
	Progress,
	Collapse
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Icon } from '@ant-design/compatible';
import MortgageNoteCreate from '../../../../../Components/ModalsAndAlerts/MortgageNoteCreate';
import { useDispatch, useSelector } from 'react-redux';
import { showModalRedux, updateStateMortgages } from '../../../../../store/mortgages/actions';
import moment from 'moment';
import SnoozeModalNew from '../../../../../Components/ModalsAndAlerts/SnoozeModalNew';
import { LoadingOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import {
	WhatsAppOutlined,
	PlusCircleOutlined,
	MinusCircleOutlined,
	MailOutlined,
	PlusOutlined,
	MinusOutlined,
	ShoppingCartOutlined
} from '@ant-design/icons';
import ModalCreateTasks from '../../../../../Components/ModalsAndAlerts/ModalCreateTasks';
import ModalFinishTask from '../../../../../Components/ModalsAndAlerts/ModalFinishTask';
import ModalReScheduleTask from '../../../../../Components/ModalsAndAlerts/ModalReScheduleTask';
import { showAircall } from '../../../../../store/aircall/actions';
import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const { Text } = Typography;
const { Panel } = Collapse;

const { Content } = Layout;

const ContentHorizontal = {
	height: 'auto',
	display: 'inline-flex',
	float: 'left',
	width: 'auto',
	overflowX: 'scroll',
	img: {
		borderRadius: '10px'
	}
};

const statusSteps = [
	'make_call',
	'pending_declarative',
	'pending_documentation',
	'risk_review',
	'peding_bank_offers',
	'selecting_bank_offer',
	'property_search',
	'estimated_appraisal',
	'appraisal',
	'earnest_money_contract',
	'mortgage_sign',
	'finish'
];

function MortgageDetailStateComponentMobile(props) {
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);
	const dispatch = useDispatch();
	const mortgageUpda = useSelector((state) => state.mortgages.updateMortgages);
	const showModal = useSelector((state) => state.mortgages.showModal);
	const [showMortgageNoteModal, setShowMortgageNoteModal] = useState(false);
	const [profile, setProfile] = useState({});
	const [dataClient, setDataClient] = useState(false);
	const [dataOperation, setDataOperation] = useState(false);
	const [dataChannel, setDataChannel] = useState(false);
	const [participants, setParticipants] = useState([]);
	const [loading, setLoading] = useState(true);
	const { mortgageId } = props;
	const mortgage = props.mortgage || {};
	const [riskSuccessForecast, setRiskSuccessForecast] = useState(mortgage.riskSuccessForecast);
	const [riskDescription, setRiskDescription] = useState(mortgage.riskDescription);
	const [riskReasons, setRiskReasons] = useState(mortgage.riskReasons);
	const [riskActions, setRiskActions] = useState(mortgage.riskActions);
	// const [snooze, setSnooze] = useState(null)
	const [issuccess, setIssuccess] = useState(false);
	const [fecha, setFecha] = useState();
	const [showMortgageCloseModal, setShowMortgageCloseModal] = useState(false);
	const [showModalActionFin, setShowModalActionFin] = useState(false);
	const [guarantor, setGuarantor] = useState({});
	const [owner1, setOwner1] = useState({});
	const [owner2, setOwner2] = useState({});
	const [mortageDataNew, setMortageDataNew] = useState({});
	const [fechaFom, setFechaFom] = useState(null);
	const [agent, setAgent] = useState(undefined);
	const [form] = Form.useForm();
	const [showModalCreate, setShowModalCreate] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [taskDetails, setTaskDetails] = useState();
	const [agentsRisk, setAgentsRisk] = useState([]);
	let idUser = undefined;
	let operationIdModal = undefined;
	const aircall = useSelector((state) => state.aircall.aircall);

	const [infoProfile, setInfoProfile] = useState([]);
	const [taskOperation, setTaskOperation] = useState([]);
	const [showModalActionDelete, setShowModalActionDelete] = useState(false);
	const [showModalActionAsing, setShowModalActionAsing] = useState(false);
	const [clientPreRegister, setClientPreRegister] = useState();
	const [percent1, setPercent1] = useState(
		mortgage && mortgage && mortgage.riskSuccessForecast
			? mortgage && Number(mortgage.riskSuccessForecast)
			: 0
	);
	const [noteArray, setNoteArray] = useState();
	const [formEdit] = Form.useForm();
	const [dataMortgage, setDataMortgage] = useState([]);
	const [refreshNps, setRefreshNps] = useState(false);
	const [formFinish] = Form.useForm();
	const [formDelete] = Form.useForm();
	const [formReagendar] = Form.useForm();

	const initDelete2 = {
		date: undefined,
		taskIdDete: undefined,
		operation: undefined,
		type: undefined,
		name: undefined,
		description: undefined,
		assigned: undefined,
		agentId: undefined
	};
	const allTask = useSelector((state) => state.task.setDataTask);

	useEffect(() => {
		const { mortgageId } = props;
		const profile = authClient.getProfile();
		setProfile(profile);

		axios
			.get(`${env.api.url}/api/mortgages/participants/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setParticipants(response.data);
				setLoading(false);
			});
		getAgent();
		// handleSnoozeApi();
		handleApiDataNew();
		getSalesAgents();
	}, [mortgageId, issuccess, refreshNps]);

	useEffect(() => {
		// handleSnoozeApi();
		getSalesAgents();
		getInfoProfile();
		setRiskSuccessForecast(mortgage && mortgage.riskSuccessForecast);
		setPercent1(mortgage ? Number(mortgage.riskSuccessForecast) : 0);
	}, [mortgage]);

	useEffect(() => {
		getDataNotes();
	}, [showMortgageNoteModal]);

	useEffect(() => {
		updateRiskText('success-forecast', percent1);
	}, [percent1]);

	const getInfoProfile = () => {
		axios
			.get(`${env.api.url}/v1/hadmin/user/profile/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setInfoProfile(response.data.data.profile);
			});
	};
	useEffect(() => {
		getInfoProfile();
	}, []);
	const getTask = () => {
		axios
			.get(`${env.api.url}/v1/hadmin/tasks/operation/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setTaskOperation(response.data.task);
			});
	};

	useEffect(() => {
		getTask();
		getClientePreRegister();
		getDataNotes();
	}, [mortgageId]);

	const getDataNotes = () => {
		axios
			.get(`${env.api.url}/v1/operations/notes/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				if (response.data.data.notes !== null && response.data.data.notes !== undefined) {
					setNoteArray(response.data.data.notes.reverse());
				}
			});
	};

	const getSalesAgents = () => {
		axios
			.get(`${env.api.url}/v1/hadmin/sales-agents`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setAgentsRisk(response.data.data);
			});
	};

	const getAgent = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/agents/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setAgent(response.data.data);
			});
	};
	
	const onFinish = async (values) => {};

	const handleApiDataNew = async () => {
		let gevaluesData = await axios
			.get(`${env.api.url}/v1/mortgages/data/get/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setDataMortgage(response.data.data);
				return response.data.data;
			});

		const { guarantor, owner1, owner2 } = gevaluesData;
		setGuarantor(guarantor);
		setOwner1(owner1);
		setOwner2(owner2);
		setMortageDataNew(mortgage);
		setFechaFom(gevaluesData.mortgage ? gevaluesData.mortgage.closeDateEstimate : undefined);
	};

	// const handleSnoozeApi = async () => {
	//   await axios
	//     .get(`${env.api.url}/api/hadmin/get-mortgage-reminder/${mortgageId}`, {
	//       headers: { Authorization: `Bearer ${authClient.getToken()}` },
	//     })
	//     .then((response) => {
	//       setSnooze(response.data[0]);
	//     });
	// };
	const getClientePreRegister = () => {
		axios
			.get(
				`${env.api.url}/v1/operations/get-type/${mortgageId}
      `,
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setClientPreRegister(response.data.data);
			});
	};

	let agentTypeName;
	if (clientPreRegister && clientPreRegister.operationType === 'web') {
		agentTypeName = 'Cliente web';
	} else if (clientPreRegister && clientPreRegister.operationType === 'friend') {
		agentTypeName = 'Cliente web';
	} else if (clientPreRegister && clientPreRegister.operationType === 'realestate') {
		agentTypeName = 'Inmobiliaria';
	} else if (clientPreRegister && clientPreRegister.operationType === 'property_developer') {
		agentTypeName = 'Promotora';
	}

	const updateRiskText = (type, value) => {
		if (type === 'description') {
			setRiskDescription(value);
		} else if (type === 'reasons') {
			setRiskReasons(value);
		} else if (type === 'actions') {
			setRiskActions(value);
		} else if (type === 'success-forecast') {
			setRiskSuccessForecast(value);
		}

		axios.post(
			`${env.api.url}/api/hadmin/risk-descriptions`,
			{
				mortgageId,
				type,
				value
			},
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
	};

	const chooseType = (type) => {
		if (type === 'doc') {
			return 'Pedir documentación';
		} else if (type === 'call') {
			return 'Llamar';
		} else if (type === 'other') {
			return 'Otros';
		}
	};

	const handleUpdateDate = (e) => {
		let key = 'closeDateEstimate';
		let participant = 'mortgage';
		let value = e;

		axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${mortgageId}`,
				{ key, participant, value },
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				message.success('Se actualizo Exitosamente');
				dispatch(updateStateMortgages(!mortgageUpda));
			});
	};
	const updateSnooze = () => {
		const { mortgageId } = props;

		axios
			.get(`${env.api.url}/api/hadmin/reminder/wakeup/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((res) => {
				setIssuccess(true);
				message.success('Su snooze se elimino correctamente.');
				dispatch(updateStateMortgages(!mortgageUpda));
			});
	};

	const handleDate = (date, dateString) => {
		let dateFormte = moment(date).format('YYYY-MM-DD');

		handleUpdateDate(dateFormte);
		setFecha(dateFormte);
	};

	const handleButtonDelete = (type, taskId) => {
		setShowModalActionDelete(!showModalActionDelete);
	};

	if (showModal) {
		return <SnoozeModalNew mortgageId={mortgageId} />;
	}

	// const despertarRender = () => {
	//   return (
	//     <Row style={{ marginTop: "30px" }} gutter={10}>
	//       <Col span={5}>
	//         <Button
	//           style={{ backgroundColor: "#AB3C24", color: "white" }}
	//           onClick={() => updateSnooze()}
	//         >
	//           {snooze.type === "reminder" ? "DESCARTAR" : "DESPERTAR AHORA"}
	//         </Button>
	//       </Col>
	//       <Col span={18}>
	//         Configurado recordatorio el {moment(snooze.date).format("DD/MM/YYYY")}{" "}
	//         por {snooze.agent} <br />
	//         <strong>Motivo</strong>: {snooze.reason} <br />
	//         Observaciones: {snooze.body}
	//       </Col>
	//     </Row>
	//   );
	// };

	const snoozeBotonRender = () => {
		return (
			<Row style={{ marginTop: '30px' }} gutter={10}>
				<Col span={12}>
					<Button
						disabled={statusJob === 'finish' ? true : false}
						onClick={() => dispatch(showModalRedux())}
						type={'primary'}
					>
						SNOOZE
					</Button>
				</Col>
			</Row>
		);
	};

	let finishButton;

	if (statusJob !== 'finish') {
		finishButton = (
			<Button
				type="primary"
				onClick={() => {
					setShowMortgageCloseModal(true);
				}}
			>
				<Icon type="delete" /> Finalizar operación
			</Button>
		);
	}

	const customDot = (dot, { status, index }) => <>{dot}</>;

	const stepProps = {
		style: { width: '11%' }
	};

	const stages = [
		'basic',
		'full',
		'documents',
		'analysis',
		'banks',
		'oferts',
		'appraisal',
		'formalization'
	];

	const stagesIndex = stages.indexOf(mortgage.stage);

	const getStepState = (index) => {
		if (stagesIndex === index) {
			return 'process';
		} else if (stagesIndex > index) {
			return 'finish';
		} else {
			return 'wait';
		}
	};

	const stagesList = [
		{
			title: 'Básicos',
			milestone: 'Completar preguntas LITE'
		},
		{
			title: 'Perfil',
			milestone: 'Completar perfil completo'
		},
		{
			title: 'Documentación',
			milestone: 'Tagear todos los documentos obligatorios'
		},
		{
			title: 'Análisis',
			milestone: 'Enviar la operación al menos a un banco'
		},
		{
			title: 'Bancos',
			milestone: 'Disponer de al menos una oferta disponible'
		},
		{
			title: 'Selección de oferta',
			milestone: 'Oferta elegida por el cliente'
		},
		{
			title: 'Tasación',
			milestone: 'Tasación OK y aprobada por el banco'
		},
		{
			title: 'Formalización',
			milestone: 'Hipoteca firmada'
		},
		{
			title: 'Finalización'
		}
	];

	const stagesButtons = [];
	stagesList.forEach((stage, index) => {
		let buttonType;
		let icon = false;

		if (stagesIndex > index) {
			buttonType = 'primary';
		} else if (stagesIndex === index) {
			buttonType = 'dashed';
			icon = <LoadingOutlined />;
		}

		stagesButtons.push(
			<Popover
				key={`stage-buttons-${index}`}
				content={<p>{stage.milestone}</p>}
				title="Hito a cumplir"
				trigger="hover"
			>
				<Button disabled={statusJob === 'finish' ? true : false} type={buttonType} icon={icon}>
					{stage.title}
				</Button>
			</Popover>
		);
	});

	let channel = 'Inmobiliaria';

	if (
		mortgage.accountType === 'property_developer' ||
		mortgage.accountType === 'realestate_property_developer'
	) {
		channel = 'Promotora';
	}

	if (
		mortgage.accountEmail === 'clientes@hipotecaprimero.com' ||
		mortgage.accountEmail === 'web@hipotecaprimero.com' ||
		mortgage.accountEmail === 'web@gibobs.com' ||
		mortgage.accountEmail === 'clientes@gibobs.com'
	) {
		channel = 'Web';
	}

	const getTaskDetails = async (taskId) => {
		const response = await axios.get(`${env.api.url}/v1/hadmin/tasks/${taskId}`, {
			headers: { Authorization: `Bearer ${authClient.getToken()}` }
		});

		setTaskDetails(response.data.task);
		initDelete2.taskIdDete = response.data.task.id;
	};

	const handleCancelFin = (type, taskId) => {
		formFinish.setFieldsValue(initDelete);
		setShowModalActionFin(!showModalActionFin);
	};

	const actions = (type, taskId, note, date = undefined, hour) => {
		axios
			.post(
				`${env.api.url}/v1/hadmin/tasks/action/${type}/${taskId}`,
				{
					note: note,
					dueDate:
						date !== undefined
							? moment(
									`${date.format('YYYY-MM-DD')} ${hour.format('HH:mm')}`,
									'YYYY-MM-DD HH:mm'
							  ).toDate()
							: undefined
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.errorCode === 'FORBIDDEN') {
					message.error('Comprueba que tu eres el que ha creado la tarea a eliminar');
				} else {
					if (type === 'complete') {
						message.success('Su tarea se ha finalizado exitosamente');
					} else if (type === 'delete') {
						message.success('Su tarea se ha eliminado exitosamente');
					} else if (type === 'postpone') {
						message.success('Su tarea se ha pospuesto exitosamente');
					}
					getTask();
					getDataNotes();
				}
			})
			.catch((error) => {
				console.log(error);
				message.error('Compruebe que la fecha es mayor que hoy');
			});
	};
	const initDelete = {
		note: undefined,
		taskIdDete: undefined,
		hour: moment('21:00', 'HH:mm')
	};

	const handleCancelDelete = (type, taskId) => {
		formFinish.setFieldsValue(initDelete);
		setShowModalActionDelete(!showModalActionDelete);
	};
	const handleCancelAgendar = (type, taskId) => {
		formReagendar.setFieldsValue(initDelete);
		setShowModalActionAsing(!showModalActionAsing);
	};
	const handleSendAgendar = (type, taskId) => {
		setShowModalActionAsing(!showModalActionAsing);
	};
	const showModalEditFuction = async (taskId) => {
		getTaskDetails(taskId).then(() => {
			setShowModalEdit(!showModalEdit);
		});
	};

	const changeContact = (a) => {
		if (a === 'morning') {
			return 'Mañanas';
		} else if (a === 'afternoon') {
			return 'Mediodias';
		} else if (a === 'evening') {
			return 'Tardes';
		}
	};
	const changeLang = (a) => {
		if (a === 'es' || a == 'es_ES') {
			return 'Español';
		} else if (a === 'en' || a === 'en_EN') {
			return 'Inglés';
		} else if (a === 'cat' || a == 'ca_ES') {
			return 'Catalan';
		}
	};

	const increase = () => {
		let percent = percent1 + 10;
		if (percent > 100) {
			percent = 100;
		}
		setPercent1(percent);
	};

	const decline = () => {
		let percent = percent1 - 10;
		if (percent > 100) {
			percent = 100;
		}
		setPercent1(percent);
	};

	let whatsappPhone = owner1.phone;
	if (whatsappPhone && whatsappPhone.indexOf('+') === -1) {
		whatsappPhone = `+34${whatsappPhone}`;
	}
	let whatsappPhoneInmo = mortgage.accountPhone;
	if (whatsappPhoneInmo && whatsappPhoneInmo.indexOf('+34') === -1) {
		whatsappPhoneInmo = `+34${whatsappPhoneInmo}`;
	}
	if (whatsappPhone) {
		whatsappPhone = whatsappPhone.replace('+', '%2B');
	}
	if (whatsappPhoneInmo) {
		whatsappPhoneInmo = whatsappPhoneInmo.replace('+', '%2B');
	}
	const callClient = (phoneNumber) => {
		aircall.send('dial_number', { phone_number: phoneNumber }, (success, data) => {
			console.log('success of dial:', success, data, aircall);
		});

		dispatch(showAircall(true));
	};

	const handleNPS = () => {
		axios
			.get(`${env.api.url}/v1/nps/first-call/send/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then(() => {
				message.success('Se envio el mail correctamente');
				setRefreshNps(!refreshNps);
			});
	};
	const genExtra = () => (
		<PlusOutlined
			style={{ fontSize: '14px', color: '#02C3CD' }}
			onClick={(event) => {
				console.log(event);
			}}
		/>
	);

	const newContent = (
		<Content>
			<Row gutter={24}>
				<Col
					span={24}
					style={{
						marginBottom: '16px'
					}}
				>
					{(mortgage.operationStatus === 'postpone' || mortgage.operationStatus === 'reminder') &&
					props.snooze != null ? (
						<Card className="CardSnoozeComponent" style={{ marginTop: 0 }}>
							<Row span={24}>
								<Col lg={16}>
									<div>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#2F4858',
												marginRight: '4px'
											}}
										>
											Motivo:
										</Text>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#FF4D4F'
											}}
										>
											{props.snooze && props.snooze.reason}
										</Text>
									</div>
									<div>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#2F4858',
												marginRight: '4px'
											}}
										>
											Observaciones:
										</Text>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#FF4D4F'
											}}
										>
											{props.snooze && props.snooze.body}
										</Text>
									</div>
								</Col>
								<Col lg={8}>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: 'normal',
											color: '#FF4D4F'
										}}
									>
										Configurado recordatorio el {moment(props.snooze.date).format('DD/MM/YYYY')} por{' '}
										{props.snooze.agent}
									</Text>
								</Col>
							</Row>
						</Card>
					) : null}
					{mortgage && mortgage.closeDate !== null && mortgage.status === 'finish' ? (
						<Card className="CardFinishComponent" style={{ marginTop: '0' }}>
							<Row span={24}>
								<Col lg={16} xs={24}>
									<div
										style={{
											marginBottom: '4px',
											lineHeight: '15px'
										}}
									>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#2F4858',
												marginRight: '4px'
											}}
										>
											Causa de cierre:
										</Text>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#1890FF'
											}}
										>
											{mortgage.closeReason}
										</Text>
									</div>
									<div
										style={{
											marginBottom: '4px',
											lineHeight: '17px'
										}}
									>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#2F4858',
												marginRight: '4px'
											}}
										>
											Subcausa de cierre:
										</Text>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#1890FF'
											}}
										>
											{mortgage.closeSubreason ? mortgage.closeSubreason : '-'}
										</Text>
									</div>
								</Col>
								<Col lg={8} xs={24}>
									<div
										style={{
											marginBottom: '4px',
											lineHeight: '17px'
										}}
									>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#2F4858',
												marginRight: '4px'
											}}
										>
											Fecha de cierre:
										</Text>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#1890FF'
											}}
										>
											{moment(mortgage.closeDate).format('DD/MM/YYYY')}
										</Text>
									</div>
								</Col>
								<Col lg={24}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column'
										}}
									>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#2F4858',
												width: '130px'
											}}
										>
											Descripción de cierre:
										</Text>
										{mortgage && (
											<p
												className="descriptionClosedReason"
												style={{ width: 'auto' }}
												dangerouslySetInnerHTML={{
													__html: mortgage.closeReasonDescription
												}}
											></p>
										)}
									</div>
								</Col>
							</Row>
						</Card>
					) : null}
				</Col>
				<Col
					span={24}
					style={{
						marginBottom: '8px'
					}}
				>
					<Card className="gb-resumen-mobile">
						<Collapse
							// defaultActiveKey={["3"]}
							ghost
							onChange={() => setDataClient(!dataClient)}
						>
							<Panel
								header={
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											width: '100%'
										}}
									>
										<div style={{ fontWeight: '600', fontSize: '14px' }}>Datos del cliente</div>
										{dataClient ? (
											<MinusOutlined style={{ fontSize: '14px', color: '#02C3CD' }} />
										) : (
											<PlusOutlined style={{ fontSize: '14px', color: '#02C3CD' }} />
										)}
									</div>
								}
								key="1"
								expandIcon={() => <PlusOutlined style={{ fontSize: '14px', color: '#02C3CD' }} />}
								showArrow={false}
							>
								<div style={{ display: 'flex', flexWrap: 'wrap', height: 'auto' }}>
									<div style={{ width: '80%' }}>
										<div style={{ marginBottom: '12px' }}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '600',
													color: '#2F4858'
												}}
											>
												{owner1.name}
											</Text>
										</div>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												marginBottom: '24px'
											}}
										>
											{dataMortgage &&
												dataMortgage.nps === undefined &&
												mortgage.stage === 'analysis' && (
													<div
														onClick={() => handleNPS()}
														style={{
															cursor: 'pointer',
															display: 'flex',
															alignItems: 'center',
															backgroundColor: '#02C3CD',
															width: '49px',
															height: '32px',
															marginRight: '8px',
															borderRadius: '4px',
															paddingRight: 4
														}}
													>
														<MailOutlined
															style={{
																color: 'white',
																fontSize: '12px',
																margin: '4px'
															}}
														/>
														<Text
															style={{
																fontSize: '12px',
																fontWeight: '500',
																color: '#FFFFFF'
															}}
														>
															NPS
														</Text>
													</div>
												)}

											<div
												style={{
													cursor: 'pointer',
													backgroundColor: '#02C3CD',
													width: '32px',
													height: '32px',
													marginRight: '8px',
													borderRadius: '4px'
												}}
											>
												<a
													href={
														'https://api.whatsapp.com/send?phone=' + whatsappPhone
														// "&text=Estimado cliente, soy tu comercial de gibobs. Por favor haz click en el siguiente enlace para confirmar tu registro " + " y así podamos empezar a estudiar tu financiación."
													}
													target="_blank"
												>
													<WhatsAppOutlined
														style={{
															color: 'white',
															fontSize: '20px',
															margin: '6px'
														}}
													/>
												</a>
											</div>

											<div>
												<div>
													<a
														style={{
															fontSize: '12px',
															fontWeight: '600',
															color: '#02C3CD'
														}}
														onClick={() => callClient(owner1.phone)}
													>
														{owner1.phone}
													</a>
												</div>
												<div>
													<Text
														style={{
															fontSize: '12px',
															fontWeight: 'normal',
															color: '#748EA0'
														}}
													>
														{owner1.email}
													</Text>
												</div>
											</div>
										</div>
									</div>
									<div style={{ width: '20%' }}>
										{/* <Upload> */}
										<Avatar
											size={48}
											icon={<UserOutlined />}
											src={
												infoProfile && infoProfile.avatar && infoProfile.avatar.data !== null ? (
													<Image
														preview={false}
														// style={{ cursor: "pointer" }}
														src={`data:image/png;base64,${infoProfile.avatar.data}`}
													/>
												) : (
													<Image />
												)
											}
										/>
										{/* </Upload> */}
									</div>
								</div>
								<div>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: '500',
											color: '#2F4858',
											marginRight: '4px'
										}}
									>
										Preferencia:
									</Text>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: '500',
											color: '#02C3CD'
										}}
									>
										{infoProfile && changeContact(infoProfile.contactTime)}
									</Text>
								</div>
								<div>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: '500',
											color: '#2F4858',
											marginRight: '4px'
										}}
									>
										Idioma:
									</Text>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: '500',
											color: '#02C3CD'
										}}
									>
										{owner1 && changeLang(owner1.lang)}
									</Text>
								</div>
							</Panel>
						</Collapse>
					</Card>
				</Col>
				<Col
					span={24}
					style={{
						marginBottom: '8px'
					}}
				>
					<Card className="gb-resumen-mobile">
						<Collapse
							defaultActiveKey={['3']}
							ghost
							onChange={() => setDataOperation(!dataOperation)}
						>
							<Panel
								showArrow={false}
								header={
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											width: '100%'
										}}
									>
										<div style={{ fontWeight: '600', fontSize: '14px' }}>Datos de la operación</div>
										{dataOperation ? (
											<MinusOutlined style={{ fontSize: '14px', color: '#02C3CD' }} />
										) : (
											<PlusOutlined style={{ fontSize: '14px', color: '#02C3CD' }} />
										)}
									</div>
								}
								key="2"
							>
								<div style={{ display: 'flex', flexWrap: 'wrap', height: 'auto' }}>
									<div style={{ width: '80%' }}>
										<div>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}
											>
												Fecha de creación:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#02C3CD'
												}}
											>
												{moment(mortgage.createAt).format('DD-MM-YYYY')}
											</Text>
										</div>
										<div>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}
											>
												Fecha de actualización:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#02C3CD'
												}}
											>
												{moment(mortgage.updateAt).format('DD-MM-YYYY')}
											</Text>
										</div>

										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												marginTop: '16px',
												marginBottom: '24px'
											}}
										>
											<div>
												<div>
													<Text
														style={{
															fontSize: '12px',
															fontWeight: 'normal',
															color: '#2F4858'
														}}
													>
														Previsión de éxito
													</Text>
												</div>
												<div>
													<Progress
														style={{ width: '220px', marginRight: '10px' }}
														width={220}
														percent={percent1}
														strokeColor={'#02C3CD'}
													/>
													<MinusCircleOutlined
														style={{
															fontSize: '14px',
															color: '#02C3CD',
															marginRight: '6px'
														}}
														onClick={() => decline()}
													/>
													<PlusCircleOutlined
														style={{ fontSize: '14px', color: '#02C3CD' }}
														onClick={() => increase()}
													/>
												</div>
											</div>
										</div>
									</div>
									<div style={{ width: '20%' }}>
										{/* <Upload> */}
										<Avatar
											size={48}
											icon={<UserOutlined />}
											src={
												agent && agent.analyst && agent.analyst.avatar !== null ? (
													<Image
														preview={false}
														// style={{ cursor: "pointer" }}
														src={`${agent.analyst.avatar.link}`}
													/>
												) : (
													<Image src="https://gibobs-assets.s3.eu-central-1.amazonaws.com/assets-dev/6008ddb40164df16002f94c6b1_608a71a603b0c458502cd74a93?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIS4QMZRS5JUUI7VA%2F20210719%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20210719T064357Z&X-Amz-Expires=300&X-Amz-Signature=7cca0a6445382b1fb12bf1176fb7a3b3e205a9b852e61288ec043e42e51f25ef&X-Amz-SignedHeaders=host" />
												)
											}
										/>
										{/* </Upload> */}
										<div>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858'
												}}
											>
												Analista
											</Text>
										</div>
										<div style={{ width: '45px', textAlign: 'center' }}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: 'normal',
													color: '#02C3CD'
												}}
											>
												{agent && agent.analyst && agent.analyst.name ? agent.analyst.name : null}
											</Text>
										</div>
									</div>
								</div>
								<div>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: 'normal',
											color: '#2F4858',
											marginRight: '4px'
										}}
									>
										Fecha prevista de cierre:
									</Text>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: '500',
											color: '#02C3CD'
										}}
									>
										<DatePicker
											className="pickerStyle"
											onChange={(date, dateString) => handleDate(date, dateString)}
											value={
												fechaFom && fechaFom !== 'Fecha invalida' ? moment(fechaFom) : undefined
											}
											style={{
												height: '14px',
												border: 'none',
												fontSize: '12px'
											}}
										/>
									</Text>
								</div>
							</Panel>
						</Collapse>
					</Card>
				</Col>
				<Col lg={8} md={12} xs={24}>
					{/*} <Card style={{ border: "1px solid #02C3CD", borderRadius: "4px", height: 28, background: "#02C3CD", borderBottom: "none" }}>
            <div style={{width: "100%", position: "relative", top: -10}}>
              <ShoppingCartOutlined style={{ marginLeft: 12, color: "#FFFFFF", fontSize: 20 }} />  <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#FFFFFF",
                }}
              >
                Personal Shopper
              </Text>
            </div>


              </Card>*/}

					<Card
						className="gb-resumen-mobile cardPersonalShopper"
						style={{
							border: '1px solid #C0DAEE',
							borderRadius: '4px',
							height: '100%'
						}}
						title={
							mortgage.accountEmail === owner1.email ? (
								<div
									style={{
										display: 'flex',
										width: '100%',
										placeContent: 'center'
									}}
								>
									<ShoppingCartOutlined
										style={{ marginRight: 15, color: '#FFFFFF', fontSize: 20 }}
									/>{' '}
									<Text
										style={{
											fontSize: '12px',
											fontWeight: '600',
											color: '#FFFFFF'
										}}
									>
										Personal Shopper
									</Text>
								</div>
							) : undefined
						}
					>
						<Collapse defaultActiveKey={['2']} ghost onChange={() => setDataChannel(!dataChannel)}>
							<Panel
								showArrow={false}
								header={
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											width: '100%'
										}}
									>
										<div style={{ fontWeight: '600', fontSize: '14px' }}>Datos del canal</div>
										{dataChannel ? (
											<MinusOutlined style={{ fontSize: '14px', color: '#02C3CD' }} />
										) : (
											<PlusOutlined style={{ fontSize: '14px', color: '#02C3CD' }} />
										)}
									</div>
								}
								key="3"
							>
								{clientPreRegister && clientPreRegister.operationType !== 'web' ? (
									<div style={{ display: 'flex', height: 'auto' }}>
										<div style={{ width: '76%' }}>
											<div>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: '500',
														color: '#02C3CD'
													}}
												>
													{agentTypeName}
												</Text>
											</div>
											<div style={{ marginBottom: '24px' }}>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: '500',
														color:
															clientPreRegister && clientPreRegister.operationType === 'web'
																? '#ffff'
																: '#2F4858',
														marginRight: '4px'
													}}
												>
													Agencia:
												</Text>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: '500',
														color:
															clientPreRegister && clientPreRegister.operationType === 'web'
																? '#ffff'
																: '#02C3CD'
													}}
												>
													{mortgage.agencyName}
												</Text>
											</div>
											<div style={{ marginBottom: '16px' }}>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: '600',
														color:
															clientPreRegister && clientPreRegister.operationType === 'web'
																? '#ffff'
																: '#2F4858'
													}}
												>
													{mortgage.accountName}
												</Text>
											</div>
										</div>
										{clientPreRegister && clientPreRegister.operationType !== 'web' ? (
											<div style={{ width: '24%', textAlign: 'center' }}>
												{/* <Upload> */}
												<Avatar
													size={48}
													icon={<UserOutlined />}
													src={
														agent && agent.agent && agent.agent.avatar !== null ? (
															<Image
																preview={false}
																// style={{ cursor: "pointer" }}
																src={`${agent.agent.avatar.link}`}
															/>
														) : (
															<Image />
														)
													}
												/>
												{/* </Upload> */}
												<div>
													<Text
														style={{
															fontSize: '12px',
															fontWeight: '500',
															color: '#2F4858'
														}}
													>
														Comercial
													</Text>
												</div>
												<div style={{ textAlign: 'center' }}>
													<Text
														style={{
															fontSize: '12px',
															fontWeight: 'normal',
															color: '#02C3CD'
														}}
													>
														{agent && agent.agent && agent.agent.name}
													</Text>
												</div>
											</div>
										) : null}
									</div>
								) : (
									<div style={{ width: '76%' }}>
										<div>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#02C3CD'
												}}
											>
												{agentTypeName}
											</Text>
										</div>
									</div>
								)}
								{clientPreRegister && clientPreRegister.operationType !== 'web' ? (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div
											style={{
												backgroundColor:
													mortgage && Math.trunc(mortgage.accountPhone / 100000000) === 9
														? '#02C3CD'
														: '#E5E5E5',
												width: '32px',
												height: '32px',
												marginRight: '8px',
												borderRadius: '4px'
											}}
										>
											{mortgage && Math.trunc(mortgage.accountPhone / 100000000) === 9 ? (
												<a
													href={
														'https://api.whatsapp.com/send?phone=' + whatsappPhoneInmo
														// "&text=Estimado cliente, soy tu comercial de gibobs. Por favor haz click en el siguiente enlace para confirmar tu registro " + " y así podamos empezar a estudiar tu financiación."
													}
													target="_blank"
												></a>
											) : null}
											<WhatsAppOutlined
												style={{
													color: 'white',
													fontSize: '20px',
													margin: '6px'
												}}
											/>
										</div>
										<div>
											<div>
												<a
													style={{
														fontSize: '12px',
														fontWeight: '600',
														color:
															clientPreRegister && clientPreRegister.operationType === 'web'
																? '#ffff'
																: '#02C3CD'
													}}
													onClick={() => callClient(mortgage.accountPhone)}
												>
													{mortgage.accountPhone}
												</a>
											</div>
											<div>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: 'normal',
														color:
															clientPreRegister && clientPreRegister.operationType === 'web'
																? '#ffff'
																: '#748EA0'
													}}
												>
													{mortgage.accountEmail}
												</Text>
											</div>
										</div>
									</div>
								) : null}
							</Panel>
						</Collapse>
					</Card>
				</Col>
			</Row>

			<Row gutter={24}>
				<Col lg={16} xs={24} style={{ marginTop: '27px' }}>
					<Row gutter={24}>
						<Col lg={14} xs={14}>
							<div>
								<Text
									style={{
										fontSize: '14px',
										fontWeight: '600',
										color: '#2F4858'
									}}
								>
									Notas
								</Text>
							</div>
						</Col>
						<Col lg={10} xs={10} style={{ textAlign: 'right' }}>
							<Button
								type="dashed"
								className="button-secundary-gibobs"
								style={{ padding: '0 10px' }}
								onClick={() => {
									setShowMortgageNoteModal(true);
									dispatch(updateStateMortgages(!mortgageUpda));
								}}
							>
								Añadir nota
							</Button>
						</Col>
					</Row>
					<Card
						style={{
							border: '1px solid #C0DAEE',
							borderRadius: '4px',
							marginTop: '19px'
						}}
					>
						{noteArray
							? noteArray.map((data) => {
									return (
										<div>
											<div>
												<Text
													style={{
														fontSize: '14px',
														fontWeight: '600',
														color: '#02C3CD'
													}}
												>
													{data.userFullname}
												</Text>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: 'normal',
														color: '#2F4858',
														marginLeft: '8px'
													}}
												>
													{moment(data.date).format('L')} - {moment(data.date).format('LT')}
												</Text>
											</div>
											<div>
												<div
													style={{
														fontSize: '12px',
														fontWeight: 'normal',
														color: '#748EA0',
														marginRight: '8px'
													}}
													dangerouslySetInnerHTML={{ __html: data.body }}
												></div>
											</div>
										</div>
									);
							  })
							: null}
					</Card>
				</Col>
			</Row>

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{ className: 'button-secundary-gibobs' }}
				okButtonProps={{ className: 'button-primari-gibobs' }}
				title="Eliminar tarea"
				open={showModalActionDelete}
				onOk={() => {
					formDelete
						.validateFields()
						.then((values) => {
							if (values.taskIdDete === undefined) {
								values.taskIdDete = taskDetails.id;
							}
							handleButtonDelete(values);
							actions('delete', values.taskIdDete, values.note);
							formDelete.setFieldsValue(initDelete);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}
				okText={'Eliminar'}
				cancelText={'Cancelar'}
				onCancel={handleCancelDelete}
				closable={false}
			>
				<Form
					form={formDelete}
					layout="horizonatl"
					name="basic"
					onFinish={onFinish}
					// style={{ display: "flex" }}
					initialValues={{
						taskIdDete: taskDetails && taskDetails.id
					}}
				>
					<Row gutter={24}>
						<Col lg={24}>
							<Form.Item
								label="Nota:"
								name="note"
								rules={[{ required: true, message: 'Introduzca una nota' }]}
								// style={{ float: "left", marginRight: 50 }}
							>
								<TextArea style={{ border: '1px solid #C0DAEE' }} />
							</Form.Item>
						</Col>
						<Form.Item
							hidden={true}
							name="taskIdDete"
							style={{ float: 'left', marginRight: 200, width: 150 }}
						>
							<Input hidden={true}></Input>
						</Form.Item>
					</Row>
				</Form>
			</Modal>

			<Row style={{ marginTop: '10px' }} gutter={10}>
				<Col span={24}>
					<MortgageNoteCreate
						mortgageId={mortgage.id}
						visible={showMortgageNoteModal}
						getDataNotes={() => getDataNotes()}
						onSuccess={() => {
							setShowMortgageNoteModal(false);
							dispatch(updateStateMortgages(!mortgageUpda));
							// getDataNotes();
						}}
						onCancel={() => {
							dispatch(updateStateMortgages(!mortgageUpda));
							setShowMortgageNoteModal(false);
						}}
					/>
				</Col>
			</Row>
			{/* Modal para crear tareas */}
			<ModalCreateTasks
				// key={showModalCreate}
				visible={showModalCreate}
				width={800}
				allTask={allTask}
				operationIdModal={operationIdModal}
				profile={profile}
				agentsRisk={agentsRisk}
				getTask={() => {
					getTask();
					getDataNotes();
				}}
				setShowModalEdit={(e) => setShowModalCreate(e)}
				initialValuesStatus={{
					operation: mortgage !== undefined ? mortgage.name : '',
					operationId: mortgage !== undefined ? mortgage.id : '',
					assigned: mortgage !== undefined ? mortgage.risk : '',
					hour: moment('21:00', 'HH:mm'),
					priority: 20,
					radiogroup: 1
				}}
			/>
			{/* Modal para editar tareas */}
			<ModalCreateTasks
				// key={showModalEdit}
				visible={showModalEdit}
				width={800}
				allTask={allTask}
				operationIdModal={operationIdModal}
				profile={profile}
				agentsRisk={agentsRisk}
				getTask={() => {
					getTask();
					getDataNotes();
				}}
				setShowModalEdit={(e) => setShowModalEdit(e)}
				initialValuesStatus={{
					...taskDetails,
					date: moment(taskDetails && taskDetails.dueDate),
					radiogroup: 2,
					operation: taskDetails && taskDetails.operationName,
					assigned: taskDetails && taskDetails.assigneeName
				}}
				edit={true}
			/>
			{/* Modal para reagendar tareas */}
			<ModalReScheduleTask
				key={showModalActionAsing}
				visible={showModalActionAsing}
				setShowModalActionAsing={(e) => setShowModalActionAsing(e)}
				initialValuesStatus={{
					...taskDetails,
					taskIdDete: taskDetails && taskDetails.id,
					hour: dayjs('21:00', 'HH:mm'),
					radiogroup: taskDetails && taskDetails.notified && taskDetails.notified === true ? 2 : 1
				}}
				getTask={() => {
					getTask();
					getDataNotes();
				}}
			/>
			{/* Modal para finalizar tareas */}
			<ModalFinishTask
				key={showModalActionFin}
				visible={showModalActionFin}
				setShowModalActionFin={(e) => setShowModalActionFin(e)}
				initialValuesStatus={{ taskIdDete: taskDetails && taskDetails.id }}
				getTask={() => {
					getTask();
					getDataNotes();
				}}
			/>
		</Content>
	);

	return newContent;
}

export default MortgageDetailStateComponentMobile;

// eslint-disable-next-line no-undef
export const env = process.env.GIBOBS_ENV || process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;

// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
const environment = require(`./Environments/env.${env.trim()}.json`);
//const environment = require('./Environments/env.prod.json');
//import environment from './Environments/env.prod.json';
environment.env = env;

export default environment;

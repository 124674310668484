import React, { useEffect, useState } from 'react';
import { Content } from 'antd/es/layout/layout';
import { Col, message, Row, Input, Modal, Checkbox, InputNumber } from 'antd';
import authClient from '../../../../../Auth/Auth';
import axios from 'axios';
import env from '../../../../../environment';
import Loading from '../../../../../Components/ModalsAndAlerts/Loading';
import { useSelector } from 'react-redux';
import numeral from 'numeral';

export default (props) => {
	const { mortgageId, mortgage, mortgageDataGet } = props;
	const [mortgageData, setMortgageData] = useState();
	const [ownerData, setOwnerData] = useState();
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);
	const [billing, setBilling] = useState({});
	const [visible, setVisible] = useState(false);
	const [checkedPay1, setCheckedPay1] = useState(false);
	const [checkedPay2, setCheckedPay2] = useState(false);

	const [friends, setFriends] = useState({});
	const [visible2, setVisible2] = useState(false);

	const [accoutBank, setAccountBank] = useState(undefined);

	useEffect(() => {
		getMortgageData();
		getAccountBank();
	}, [mortgageId]);

	useEffect(() => {
		getMortgageData();
		getAccountBank();
	}, []);

	const getAccountBank = () => {
		axios
			.get(`${env.api.url}/v1/friends/get-bank-accounts/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setAccountBank(response.data.data || undefined);
			});
	};

	const getMortgageData = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setBilling(response.data.data.billing || {});
				setFriends(response.data.data.friends || {});
				if (response.data.data.friends !== undefined) {
					setCheckedPay1(response.data.data.friends.payClient || false);
					setCheckedPay2(response.data.data.friends.payFriend || false);
				}
				setOwnerData(response.data.data.owner1);
				setMortgageData(response.data.data.mortgage);
			});
	};

	if (!mortgageData) {
		return <Loading />;
	}

	const saveDataAccountBank = (value, type) => {
		setTimeout(() => {
			message.success('Se actualizo con exito');
		}, 2000);

		axios
			.post(
				`${env.api.url}/v1/user/hadmin-set-bank-account`,
				{ bankAccount: value, clientOrAgent: type, operationId: mortgageId },
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				getAccountBank();
			});
	};

	const saveData = (participant, key, value) => {
		setTimeout(() => {
			message.success('Se actualizo con exito');
		}, 2000);

		axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${mortgageId}`,
				{ key, participant, value },
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				getMortgageData();
			});
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const onOK = () => {
		setCheckedPay1(true);
		saveData('friends', 'payClient', true);
		setVisible(false);
	};

	const handleSetVisible = () => {
		setVisible(true);
	};

	const handleCancel2 = () => {
		setVisible2(false);
	};

	const onOK2 = () => {
		setCheckedPay2(true);
		saveData('friends', 'payFriend', true);
		setVisible2(false);
	};

	const handleSetVisible2 = () => {
		setVisible2(true);
	};

	return (
        (<Content style={{ paddingLeft: 10 }}>
            {mortgageDataGet && mortgageDataGet?.mortgage?.ringTheBell === undefined && 
			<div style={{background: "#F65307", height: 36}}>La operacion no tiene ring de bell, no esta bien cerrada, debe tocar la campana para cerrarla</div>
			}
            <label>
				<strong>CLIENTE</strong>
			</label>
            <Row style={{ paddingBottom: 25, paddingTop: 25 }}>
				<Col span={12}>
					<div style={{ marginTop: 20 }}>Nombre del cliente</div>
					<Input
						readOnly={true}
						defaultValue={ownerData.name}
						onBlur={(e) => saveData('friends', 'nameClient', e.target.value)}
						type="text"
						placeholder="Nombre"
						style={{ width: 200, marginBottom: 20, top: 5 }}
					/>
					<div style={{ marginTop: 20 }}>Nº de cuenta</div>
					{accoutBank && (
						<Input
							defaultValue={accoutBank && accoutBank.clientBankAccount}
							onBlur={(e) => saveDataAccountBank(e.target.value, 'client')}
							type="text"
							placeholder="ESXX XXXX XXXX XXXX"
							style={{ width: 200, marginBottom: 20, top: 5 }}
						/>
					)}
					<div style={{ marginTop: 20 }}>Importe de recompensa</div>
					<InputNumber
						readOnly={true}
						defaultValue={friends.amountClient}
						onBlur={(e) => saveData('friends', 'amountClient', e.target.value)}
						type="text"
						placeholder="50€"
						formatter={(value) => numeral(value).format('0,0 $')}
						style={{ width: 200, marginBottom: 20, top: 5 }}
					/>
					<Checkbox
						name="pagado"
						checked={friends.payClient ? true : false}
						style={{ marginLeft: 80, top: -90, position: 'relative' }}
						onChange={(e) =>
							checkedPay1
								? saveData('friends', 'payClient', e.target.checked)
								: handleSetVisible(e.target.checked)
						}
					>
						<strong>Pagado</strong>
					</Checkbox>
				</Col>

				<Col style={{ paddingBottom: 50, marginTop: -45 }} span={12}>
					<label>
						<strong>AMIGO QUE LE RECOMENDÓ</strong>
					</label>
					<div style={{ marginTop: 45 }}>Nombre del amigo</div>
					<Input
						readOnly={true}
						defaultValue={friends && friends.nameFriend ? friends.nameFriend : mortgage.accountName}
						onBlur={(e) => saveData('friends', 'nameFriend', e.target.value)}
						type="text"
						placeholder="Nombre"
						style={{ width: 200, marginBottom: 20, top: 5 }}
					/>
					<div style={{ marginTop: 20 }}>Nº de cuenta</div>
					{accoutBank && (
						<Input
							defaultValue={accoutBank && accoutBank.agentBankAccount}
							onBlur={(e) => saveDataAccountBank(e.target.value, 'agent')}
							type="text"
							placeholder="ESXX XXXX XXXX XXXX"
							style={{ width: 200, marginBottom: 20, top: 5 }}
						/>
					)}
					<div style={{ marginTop: 20 }}>Importe de recompensa</div>
					<InputNumber
						readOnly={true}
						defaultValue={friends.amountFriend}
						onBlur={(e) => saveData('friends', 'amountFriend', e.target.value)}
						type="text"
						placeholder="50€"
						formatter={(value) => numeral(value).format('0,0 $')}
						style={{ width: 200, marginBottom: 20, top: 5 }}
					/>
					<Checkbox
						name="pagado"
						checked={friends.payFriend ? true : false}
						style={{ marginLeft: 80, top: -90, position: 'relative' }}
						onChange={(e) =>
							checkedPay2
								? saveData('friends', 'payFriend', e.target.checked)
								: handleSetVisible2(e.target.checked)
						}
					>
						<strong>Pagado</strong>
					</Checkbox>
				</Col>
			</Row>
            <Modal
				open={visible}
				title="Importatne"
				okText="Aceptar"
				onCancel={() => handleCancel()}
				onOk={() => onOK()}
			>
				<a>¿Estás seguro que este cliente ha sido recompensado por esta operación?</a>
			</Modal>
            <Modal
				open={visible2}
				title="Importatne"
				okText="Aceptar"
				onCancel={() => handleCancel2()}
				onOk={() => onOK2()}
			>
				<a>¿Estás seguro que este Amigo ha sido recompensado por esta operación?</a>
			</Modal>
        </Content>)
    );
};

import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import Loading from './Components/ModalsAndAlerts/Loading';
import Navigation from './Components/coreNav/Navigation';
import { useNavigate } from "react-router-dom";

const history = createBrowserHistory();

function App(props) {
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	

	return <div style={{ height: '100%' }}><Navigation></Navigation></div>;
}

export default App;

import React, { useState } from 'react';
import axios from 'axios';
import { Card, Layout, Row, Col, Input, Button, Table, Progress } from 'antd';
import env from '../../../environment';
import _ from 'lodash';
import authClient from '../../../Auth/Auth';
import moment from 'moment';

const { Content } = Layout;
const { TextArea } = Input;

export default (props) => {
	const [excelString, setExcelString] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [stepsProgress, setStepsProgress] = useState(0);
	const [excel, setExcel] = useState([]);

	const excelColums = [
		'operation',
		'agent',
		'date',
		'name',
		'surname',
		'email',
		'phone',
		'serviceType',
		'hasHouse',
		'whenBuy',
		'maxBudget',
		'savings',
		'incomes',
		'hasLoan',
		'owner1Activity',
		'owner2Activity',
		'hasGuarantor',
		'ltv',
		'dti',
		'result'
	];

	const activities = {
		ASALARIADO: 'EMPLOYEE_FIXED',
		AUTONOMO: 'FREELANCER',
		FUNCIONARIO: 'FUNCTIONARY',
		'PENSIONISTA ': 'PENSIONER'
	};

	const columns = excelColums.map((col) => ({
		title: col,
		key: col,
		dataIndex: col
	}));

	const updateData = async (operationId, data) => {
		await axios.post(
			`${env.api.url}/v1/mortgages/data/multiple/update/${operationId}`,
			{
				values: data.map((line) => ({
					key: line[0],
					subkey: line[1],
					value: line[2]
				}))
			},
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
	};

	const convertExcel = () => {
		const rows = excelString.split('\n');
		const data = rows.map((row) => {
			const cols = row.split('\t');
			const dataObj = {};

			cols.forEach((value, index) => {
				dataObj[excelColums[index]] = value;
			});

			return dataObj;
		});

		setExcel(data);
	};

	const createTask = async (operationId, operationName, clientName, clientSurname) => {
		const { riskId } = await getOperationId(operationName);

		await axios.post(
			`${env.api.url}/v1/hadmin/tasks/create`,
			{
				date: moment().toDate(),
				operation: operationId,
				type: 'firstcall',
				name: `NUEVO CLIENTE - Llamar a ${clientName} ${clientSurname} después de su paso por el CC`,
				description:
					'Llama lo antes posible al cliente; el call center lo ha cualificado y ha considerado que es viable. Ten en cuenta que si no le llamas en los próximos minutos, el algoritmo puede reducir tu entrada de leads automáticamente.',
				assigned: riskId,
				notification: true,
				priority: 30
			},
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
	};

	const overrideRisk = async (operationId) => {
		await axios.post(
			`${env.api.url}/v1/hadmin/clusters/override-operation-salesagent`,
			{
				operationId
			},
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
	};

	const closeOperation = async (operationId) => {
		await axios.post(
			`${env.api.url}/api/hadmin/mortgage-close`,
			{
				mortgageId: operationId,
				applicant: 'h1',
				closeReason: 'Pre-scoring',
				subCloseReason: 'Valoración call center',
				body: 'El call center considera la operación NO VIABLE',
				closeAllTask: true
			},
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
	};

	const getOperationId = async (operationName) => {
		const response = await axios.get(`${env.api.url}/api/hadmin/multisearch/${operationName}`, {
			headers: { Authorization: `Bearer ${authClient.getToken()}` }
		});

		return { operationId: response.data[0].id, riskId: response.data[0].salesAgent };
	};

	const updateOperations = async () => {
		setIsLoading(true);
		let counter = 0;
		let count = excel.length * 4;
		let step = 100 / count;

		for (let i = 0; i < excel.length; i++) {
			const operation = excel[i];
			const { operationId, riskId } = await getOperationId(operation.operation);

			setStepsProgress(step * ++counter);

			const data = [];

			data.push(['owner1', 'netIncomes', Number(operation.incomes)]);
			data.push(['owner1', 'name', `${operation.name} ${operation.surname}`]);
			data.push(['owner1', 'phone', operation.phone]);

			if (activities[operation.owner1Activity]) {
				data.push(['owner1', 'activity', activities[operation.owner1Activity]]);
			}

			if (operation.hasLoan) {
				data.push(['owner1', 'loanPayment', Number(operation.hasLoan)]);
				data.push(['owner1', 'loanPaymentBoolean', true]);
			}

			data.push(['mortgage', 'houseState', 'have_decide']);
			data.push(['mortgage', 'maxBudget', Number(operation.maxBudget)]);
			data.push(['mortgage', 'savings', Number(operation.savings)]);

			if (operation.result === 'VIABLE') {
				data.push(['operation', 'ccViable', moment().format('YYYY-MM-DD')]);
			}

			await updateData(operationId, data);
			setStepsProgress(step * ++counter);

			if (operation.result === 'VIABLE') {
				await overrideRisk(operationId);
				setStepsProgress(step * ++counter);
				await createTask(operationId, operation.operation, operation.name, operation.surname);
				setStepsProgress(step * ++counter);
			} else {
				await closeOperation(operationId);
				setStepsProgress(step * ++counter);
				setStepsProgress(step * ++counter);
			}

			await new Promise((resolve) => setTimeout(resolve, 10000));
		}

		setIsLoading(false);
	};

	const progressBar = isLoading ? <Progress percent={stepsProgress} status="active" /> : undefined;

	return (
		<Layout>
			<Content>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card style={{ width: '100%' }} title="Introducir datos excel">
							<TextArea
								style={{ borderRadius: 0}}
								rows={4}
								onChange={(e) => {
									setExcelString(e.target.value);
								}}
							/>
							<Button type="primary" onClick={convertExcel} style={{ borderRadius: 0}}>
								Convertir
							</Button>
						</Card>
					</Col>
					<Col span={24}>
						<Card style={{ width: '100%' }} title="Datos">
							<Table columns={columns} dataSource={excel} scroll={{ x: 2000 }}></Table>
							<Button loading={isLoading} type="primary" onClick={updateOperations} style={{ borderRadius: 0}}>
								Actualizar operaciones
							</Button>
							{progressBar}
						</Card>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};

import { AIRCALL, PHONE, VISIBLE } from './constants';

export const INITIAL_STATE = {
	phone: '',
	aircall: undefined,
	visible: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PHONE:
			return { ...state, phone: action.payload };
		case VISIBLE:
			return { ...state, visible: action.payload };
		case AIRCALL:
			return { ...state, aircall: action.payload };
		default:
			return state;
	}
};

import { SENDTOBANK } from './Constants';

export const INITIAL_STATE = {
	sendToBank: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SENDTOBANK:
			return { ...state, sendToBank: action.payload };

		default:
			return state;
	}
};

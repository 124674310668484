import React, { useState, useEffect } from 'react';

const LoaderLogo = () => {
	return (
		<>
			<div class="infinity">
				<img
					src={'/images/spinner.gif'}
					alt="asdf"
					style={{ position: 'absolute', top: '50%', left: '47%' }}
					width="100px"
					height="100px"
				/>
			</div>
		</>
	);
};
export default LoaderLogo;

/* eslint-disable indent */
import { LOADING, NEEDED, COMPLETED, DOCUMENTS, GROUPS, GROUPSCATEGORIES } from './constants';

export const INITIAL_STATE = {
	loading: true,
	needed: 0,
	completed: 0,
	documents: [],
	groups: [],
	groupsCategories: {}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LOADING:
			return { ...state, loading: action.payload };
		case NEEDED:
			return { ...state, needed: action.payload };
		case COMPLETED:
			return { ...state, completed: action.payload };
		case DOCUMENTS:
			return { ...state, documents: action.payload };
		case GROUPS:
			return { ...state, groups: action.payload };
		case GROUPSCATEGORIES:
			return { ...state, groupsCategories: action.payload };
		default:
			return state;
	}
};

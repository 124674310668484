import React, { useEffect, useState } from 'react';
import { Avatar, Dropdown, Layout, Menu, Spin, Button, message } from 'antd';
import {
	FlagOutlined,
	SmileOutlined,
	MenuOutlined,
	MailOutlined,
	ScheduleOutlined
} from '@ant-design/icons';
import { Icon } from '@ant-design/compatible';
import { useNavigate } from 'react-router-dom';
import authClient from '../../Auth/Auth';
import '../../App.less';
import { SidebarItemsHadmin, SidebarItemsLoginHadmmin } from '../coreNav/SidebarItems';
import { useSelector, useDispatch } from 'react-redux';
import useGoogleAnalytics from '../AnalyticsGoogle/useGoogleAnalytics';
import { resetProfileRedux } from '../../store/auth/actions';
import env from '../../environment';
import axios from 'axios';
import ModalHelp from '../ModalsAndAlerts/ModalHelp';
import Login from '../../Pages/auth/Login';
import auth from '../../Auth/Auth';
import moment from 'moment';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

function LayoutMobile(props) {
	const [profile, setProfile] = useState({});
	const [collapse, setCollapse] = useState(false);
	const [collapsed, setCollapsed] = useState(false);
	const roles = useSelector((state) => state.auth.roles);
	const [loading, setLoading] = useState(true);
	const [logeado, setLogeado] = useState(auth.getToken().length > 0);
	const [showModalHelp, setShowModalHelp] = useState(false);
	let dispatch = useDispatch();
	let navigate = useNavigate();
	useGoogleAnalytics();

	useEffect(() => {
		const profile = authClient.getProfile();
		setProfile(profile);
		setLoading(false);
	}, []);

	const onCollapse = () => {
		setCollapse(!collapsed);
	};

	const enviarNuevoMensaje = (values, operationNameModal, saveFileDataField) => {
		const bodyMessage = {
			summary: values.summary,
			description:
				(operationNameModal !== undefined &&
					values.typeApp !== undefined &&
					`${values.typeApp}-${values.contactReason}-${operationNameModal} ${values.description}`) ||
				(operationNameModal === undefined &&
					values.typeApp !== undefined &&
					`${values.typeApp}-${values.contactReason} ${values.description}`) ||
				(operationNameModal === undefined &&
					values.typeApp === undefined &&
					`${values.contactReason} ${values.description}`) ||
				(operationNameModal !== undefined &&
					values.typeApp === undefined &&
					`${values.contactReason}-${operationNameModal} ${values.description}`),
			attachments: []
		};

		axios
			.post(`${env.api.url}/v1/jira/create-ticket`, bodyMessage, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then(() => {
				message.success('Tu mensaje fue enviado');
			});

		setShowModalHelp(false);
	};

	const hideAddTaskModal = () => {
		setShowModalHelp(false);
	};

	const logOut = async () => {
		await dispatch(resetProfileRedux());
		logeado && navigate('/login');
	};

	const renderer = ({ hours, minutes, seconds, completed }) => {
		return (
			<span>
				{hours}:{minutes}:{seconds}
			</span>
		);
	};

	
	const menu = (
		<Menu>
			<Menu.Item key="12">
				<ScheduleOutlined />{' '}
				<a target="_blank" href="https://gibobs.freshdesk.com/support/home">
					<span> Portal reclamaciones</span>
				</a>
			</Menu.Item>
			<Menu.Item key="13">
				<SmileOutlined />
				<a target="_blank" href="https://app.factorialhr.com/">
					<span> Factorial HR</span>
				</a>
			</Menu.Item>
			<Menu.Item key="14" style={{ color: 'red' }}>
				<Icon type="logout" />
				<span onClick={() => logOut()}> Cerrar sesión</span>
			</Menu.Item>
		</Menu>
	);

	let prof = auth.getProfile();
	const agent = logeado ? prof.name : 'H1 Administrador';

	if (loading) {
		return (
			<div style={{ textAlign: 'center', height: '100vh' }}>
				<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
			</div>
		);
	}

	if (auth.getToken().length > 0) {
		return (
			<>
				<Header
					className="ant-layout-header g-header"
					style={{
						zIndex: 1,
						backgroundColor: '#FFFFFF',
						boxShadow: '0 0 2px #666',
						display: 'flex',
						justifyContent: 'space-between',
						padding: '0 20px'
					}}>
					<div style={{ width: 'auto' }}>
						<Button
							style={{ color: '#2F4858', border: 'none' }}
							icon={<MenuOutlined color="#2F4858" style={{ fontSize: '24px' }} />}
							onClick={() => navigate('/')}
						/>
					</div>

					<div style={{ position: 'relative', marginLeft: '5%' }}>
						<img
							src={'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/logos/logo-gmail.png'}
							style={{
								height: '50px',
								alignSelf: 'center'
							}}
							alt="Gibobs"
						/>
					</div>

					<div style={{ width: 'auto' }}>
						<Button
							shape="square"
							style={{
								borderRadius: '4px',
								background: '#2F4858',
								marginRight: '12px',
								width: '24px',
								height: '24px'
							}}
							icon={<MailOutlined style={{ color: '#ffffff', fontSize: '12px' }} />}
							onClick={() => setShowModalHelp(true)}></Button>
						<Dropdown overlay={menu} trigger={['click']}>
							<a>
								<Avatar icon={<FlagOutlined />} className="lang-avatar" size="medium" />
							</a>
						</Dropdown>
					</div>
				</Header>
				<Layout>
					{/* <Sider
            trigger={null}
            width={270}
            collapsedWidth={64}
            collapsible
            collapsed={false}
            style={{
              backgroundColor: "#ffffff",
              position: "absolute",
              zIndex: "1",
              minHeight: "100%",
              display: collapse ? undefined : "none",
            }}
          >
            <div className="logo">
              <img
                src={
                  "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/logos/logo-gmail.png"
                }
                alt="logo"
              />
            </div>

            {/* <Menu
              inlineCollapsed={collapse}
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
            >
              {render_list_hadmin} */}
					{/* {render_list_hadmin} */}
					{/* {render_list_customer} */}
					{/* </Menu> */}
					{/* </Sider> */}
					<ModalHelp
						key={showModalHelp}
						visible={showModalHelp}
						onCreate={enviarNuevoMensaje}
						onCancel={hideAddTaskModal}
						width={800}
						dataAgent={prof}
					/>
					<Content
						style={{
							marginTop: '5px',
							height: '100vh',
							overflowY: 'scroll',
							position: 'relative'
						}}>
						{/* <div
              className="site-layout-background"
              style={{ padding: 0, minHeight: 360, background: "#fff" }}
            > */}
						{props.children}
						<Footer
							style={{
								marginTop: '5px',
								// marginBottom: "70px",
								overflowY: 'scroll',
								position: 'relative',
								textAlign: 'center'
							}}>
							{'gibobs allbanks © ' + moment().format('YYYY')}
						</Footer>
					</Content>
					{/* </div> */}
				</Layout>
			</>
			// {/* </Layout> */}
		);
	} else {
		return <Login />;
	}
}

export default LayoutMobile;

import React from 'react';
import { Select, Tag } from 'antd';

const { Option } = Select;

const InputEmailAddresses = ({ contacts = [], ...props }) => (
	<Select
		{...props}
		mode="tags"
		showSearch
		filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
		filterSort={(optionA, optionB) =>
			optionA.value.toLowerCase().localeCompare(optionB?.value?.toLowerCase())
		}
		style={{ width: '100%' }}
		placeholder="Seleccione"
		tagRender={(props) => {
			const { label, value, closable, onClose } = props;
			const isDB = contacts.find((item) => item.email === value);

			return (
				<Tag
					color={isDB ? '#4682B4' : '#FFA343'}
					closable={closable}
					onClose={onClose}
					style={{ marginRight: 3 }}>
					{label}
				</Tag>
			);
		}}>
		{contacts &&
			contacts.map((contact) => (
				<Option key={contact.id} value={contact.email}>
					{contact.email}
				</Option>
			))}
	</Select>
);

export default InputEmailAddresses;

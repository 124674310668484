/* eslint-disable indent */
import {
	CONFIRMATIONAPROVAL,
	FORMALIZATIONOBJ,
	MOTIVOCIERRE,
	STATUSJOB,
	TASACIONOBJ
} from './Constants';

export const INITIAL_STATE = {
	confirmationAproval: true,
	statusJob: '',
	formalizacion: {
		email: '',
		linkContrato: '',
		user2: '',
		user1: '',
		avalista: '',
		hpSign: false,
		fileSign: false,
		bank: '',
		datesign: '',
		dateDcumentSign: '',
		importe: '',
		file: []
	},
	tasacion: {
		file: []
	},
	file: [],
	motivoCierre: {
		requestReason: '',
		reasonsFinish: '',
		errorDetails: '',
		bank: ['banco1', 'banco2'],
		optionClient: '',
		optionHp: '',
		optionBank: ''
	}
};

const formalizationTasacion = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CONFIRMATIONAPROVAL:
			return { ...state, confirmationAproval: action.payload };
		case STATUSJOB:
			return { ...state, statusJob: action.payload };
		case FORMALIZATIONOBJ:
			return {
				...state,
				formalizacion: { ...state.formalizacion, ...action.payload }
			};
		case TASACIONOBJ:
			return { ...state, tasacion: { ...state.tasacion, ...action.payload } };
		case MOTIVOCIERRE:
			return {
				...state,
				motivoCierre: { ...state.motivoCierre, ...action.payload }
			};

		default:
			return state;
	}
};

export default formalizationTasacion;

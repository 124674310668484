import ReactGA from 'react-ga';
import env from '../../environment';
import auth from '../../Auth/Auth';

function init() {
	ReactGA.initialize(env.analytics.trackId, { debug: env.analytics.debug });
}

function sendEvent(payload) {
	ReactGA.event(payload);
}

function sendPageview(path) {
	const profile = auth.getProfile();

	ReactGA.set({ page: path, user: profile.name, email: profile.email });
	ReactGA.pageview(path);
}

export default {
	init,
	sendEvent,
	sendPageview
};

import React, { useState } from 'react';
import axios from 'axios';
import env from '../../environment';
import authClient from '../../Auth/Auth';
import { Select, Card, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HomeOutlined, DoubleRightOutlined, LockOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Text } = Typography;

function SuperSearchMobile({ modal, operation, onOperationChange, header }) {
	console.log(modal, operation, onOperationChange, header);
	let timeout;
	let currentValue;
	let navigate = useNavigate();

	const [data, setData] = useState([]);
	const [value, setValue] = useState(undefined);

	const search = (value) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		currentValue = value;

		const paramsSearch = {
			page: 1,
			limit: 10,
			fields: {
				guarantors: false,
				otherOwners: false,
				owner1: true,
				type: ['mortgage'],
				ownerName: undefined,
				nif: undefined,
				phone: undefined,
				email: undefined,
				operationId: undefined
			},
			text: currentValue || ''
		};

		const searchData = () => {
			axios
				.post(`${env.api.url}/operations/metasearch/list`, paramsSearch, {
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				})
				.then((response) => {
					setData(response.data.data.metasearchs);
				});
		};
		timeout = setTimeout(searchData, 300);
	};

	const handleSearch = (value) => {
		if (value) {
			search(value);
		} else {
			setData([]);
		}
	};

	const handleChange = (value) => {
		if (!modal) {
			navigate(`/operations/mortgages/${value}`);
			setValue('');
			if (header) {
				setData([]);
			}
		} else {
			if (onOperationChange) {
				setValue(value);
				onOperationChange(value);
			}
		}
	};

	const options = data.map((d) => (
		<>
			{!modal && (
				<Option key={d.id} clearTimeout={1}>
					<strong>{d.name}</strong> {d.client} | {d.realestate}
				</Option>
			)}

			{modal && (
				<Option key={d.id}>
					<span
						style={{
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							width: 300
						}}>
						<strong>{d.name}</strong> {d.client} | {d.realestate}
					</span>
				</Option>
			)}
		</>
	));
	//  <ExclamationOutlined style={{ color: "#FAAD14", fontSize: 20 }} />

	//             <DoubleRightOutlined style={{ color: "#52C41A", fontSize: 20 }} />

	//             <LockOutlined style={{ color: "#1890FF", fontSize: 20 }} />

	const nameStages = {
		basic: 'Básico',
		full: 'Perfil de titulares',
		documents: 'Documentación',
		banks: 'Bancos',
		analysis: 'Análisis',
		appraisal: 'Tasación',
		oferts: 'Selección de oferta',
		formalization: 'Formalización'
	};

	return (
		<div>
			<Select
				autoClearSearchValue={true}
				showSearch
				//value={value !== '' ? value : ''}
				style={{
					// minWidth: modal ? 300 : "500px",
					width: '100%',
					marginBottom: '16px'
				}}
				placeholder="Escribe para buscar..."
				defaultActiveFirstOption={false}
				showArrow={false}
				filterOption={false}
				value={operation}
				onSearch={(value) => {
					handleSearch(value);
				}}
				onChange={(value) => {
					handleChange(value);
				}}
				notFoundContent={null}></Select>
			{data &&
				data.map((data) => {
					return (
						<Card
							key={data.id}
							style={{
								borderRadius: '4px',
								boxShadow: '0px 0px 8px rgba(47, 72, 88, 0.1)',
								marginBottom: '8px'
							}}
							onClick={() => {
								if (data.type == 'php') {
									navigate(`/promoter-loan/operations/${data.operationId}`);
								} else {
									navigate(`/operations/mortgages/${data.operationId}`);
								}
							}}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center'
								}}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<HomeOutlined
										style={{
											fontSize: 20,
											color: '#02C3CD',
											marginRight: '8px'
										}}
									/>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '400',
												color: '#748EA0',
												lineHeight: '16px'
											}}>
											{data.subtype === 'mortgage'
												? 'Hipoteca'
												: data.type === 'php'
												? 'Promotor'
												: 'Subrogación'}
										</Text>
										<Text
											style={{
												fontSize: '16px',
												fontWeight: '600',
												color: '#02C3CD',
												lineHeight: '24px'
											}}>
											{data.type == 'php' ? data.subname : data.name}
										</Text>
									</div>
								</div>
								{data.status === 'finish' ? (
									<LockOutlined style={{ color: '#1890FF', fontSize: 20 }} />
								) : (
									<DoubleRightOutlined style={{ color: '#52C41A', fontSize: 16 }} />
								)}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginTop: '6px'
								}}>
								<Text
									style={{
										fontSize: '12px',
										fontWeight: '400',
										color: '#748EA0',
										lineHeight: '16px'
									}}>
									Titular principal
								</Text>
								<Text
									style={{
										fontSize: '16px',
										fontWeight: '600',
										color: '#2F4858',
										lineHeight: '24px'
									}}>
									{data.owner1Name}
								</Text>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginTop: '6px'
								}}>
								<Text
									style={{
										fontSize: '12px',
										fontWeight: '400',
										color: '#748EA0',
										lineHeight: '16px'
									}}>
									Etapa
								</Text>
								<Text
									style={{
										fontSize: '16px',
										fontWeight: '600',
										color: '#2F4858',
										lineHeight: '24px'
									}}>
									{nameStages[data.stage]}
								</Text>
							</div>
						</Card>
					);
				})}
		</div>
	);
}

export default SuperSearchMobile;

import React from 'react';
import { CyclopsForbidden } from './CyclopsForbidden';
import _ from 'lodash';

/**
 * Tipo de routa para hadmin
 */
export type CyclopsRoute = {
	/**
	 * Ruta relativa
	 */
	path: string;
	/**
	 * Elemento de React que va a renderizar la página
	 */
	element: React.JSX.Element;
};

/**
 * Tipo de sección de routas  para hadmin
 */
export type CyclopsRoutes = {
	/**
	 * Ruta base
	 */
	path: string;
	/**
	 * Flag que indica si la ruta es en entorno seguro o no
	 */
	isSecure: boolean;
	/**
	 * Sistema inicial de permisos usado en el Forbidden
	 */
	users?: string[];
	/**
	 * Listado de rutas anidadas
	 */
	routes: CyclopsRoute[];
};

/**
 * Tipo de plugin Cyclops para hadmin
 */
export type CyclopsPlugin = {
	/**
	 * Listado de rutas usadas por el plugin
	 */
	routes: CyclopsRoutes[];
};

export type CyclopsAuth = {
	id: string;
	email: string;
	name: string;
	surname: string;
	phone: string;
	roles: string[];
	hadminQuestions: boolean;
	hadminDocuments: boolean;
	hadminDownload: boolean;
	hadminBanks: boolean;
	hadminStatus: boolean;
	type: string;
	token: string;
};

export type CyclopsEnv = {
	auth0: {
		callback: string;
		domain: string;
		clientID: string;
	};
	api: {
		url: string;
		urlOdd: string;
		viewer: string;
	};
	analytics: {
		trackId: string;
		debug: boolean;
	};
	env: string;
};

/**
 * Clase singleton para el control de los plugins Cyclops
 */
class Cyclops {
	/**
	 * Plugins cargados
	 */
	protected plugins: CyclopsPlugin[] = [];
	/**
	 * Rutas procesadas
	 */
	routes: CyclopsRoutes[] = [];

	protected auth: any;

	protected env: any;

	/**
	 * Permite cargar un plugin de Cyclops
	 * @param plugin Plugin a cargar
	 */
	public addPlugin(plugin: CyclopsPlugin) {
		this.plugins.push(plugin);
		plugin.routes.forEach((route) => this.routes.push(route));
	}

	/**
	 * Devuelve un listado de las rutas base cargadas en el sistema
	 * @param isSecure Indica si las rutas son seguras o no
	 * @returns Listado de rutas vase
	 */
	public getRootRoutes(isSecure = true) {
		const routes = this.routes
			.filter((route) => route.isSecure === isSecure)
			.map((route) => route.path);

		return _.uniq(routes);
	}

	/**
	 * Devuelve un listado de rutas cargadas en el sistema que coincidan con el path y nivel de seguridad
	 * @param path ruta relativa
	 * @param isSecure busca la ruta en entorno seguro o inseguro. Por defecto en seguro
	 * @returns Listado de rutas encontradas
	 */
	public getRoutes(path: string, isSecure = true) {
		const routes = this.routes.filter(
			(route) => route.path === path && route.isSecure === isSecure
		);

		return routes;
	}

	/**
	 * Devuelve un listado de rutas cargadas en el sistema que coincidan con el path y nivel de seguridad en formato de React Router
	 * @param path ruta relativa
	 * @param isSecure busca la ruta en entorno seguro o inseguro. Por defecto en seguro
	 * @returns Listado de rutas encontradas
	 */
	public getFrontRoutes(path: string, isSecure = true) {
		const routes = this.getRoutes(path, isSecure);

		const frontRoutes = routes.map((route) => {
			const securedRoutes = route.routes.map((internalRoute) => {
				let SecurityElement;

				if (route.users && route.users.length > 0) {
					SecurityElement = () => (
						<CyclopsForbidden
							validEmails={route.users}
							profile={{ email: 'juan.benavides@gibobs.com', type: 'admin' }}>
							{internalRoute.element}
						</CyclopsForbidden>
					);
				} else {
					SecurityElement = () => internalRoute.element;
				}

				//console.log('cyclops', 'route', internalRoute.path, SecurityElement);
				return { path: internalRoute.path, element: <SecurityElement /> };
			});

			return securedRoutes;
		});

		return frontRoutes.flat();
	}

	public getAuth() {
		return this.auth;
	}

	public setAuth(auth: any) {
		this.auth = auth;
	}

	public getEnv() {
		return this.env;
	}

	public setEnv(env: any) {
		this.env = env;
	}
}

const cyclops = new Cyclops();

export { cyclops };

import { SHOWMODAL, UPDATEMORTGAGES, TABSPAGE, ANALYST } from './Constants';

export const showModalRedux = () => async (dispatch) => {
	dispatch({
		type: SHOWMODAL,
		payload: true
	});
};

export const hidewModalRedux = () => async (dispatch) => {
	await dispatch({
		type: SHOWMODAL,
		payload: false
	});
};

export const updateStateMortgages = (state) => async (dispatch) => {
	dispatch({
		type: UPDATEMORTGAGES,
		payload: state
	});
};

export const tabspage = (state) => async (dispatch) => {
	await dispatch({
		type: TABSPAGE,
		payload: state
	});
};

export const allAnalyst = (state) => async (dispatch) => {
	await dispatch({
		type: ANALYST,
		payload: state
	});
};


import React, { useEffect, useState } from 'react';
import { Card, Typography, Modal } from 'antd';
import moment from 'moment';
import WeeksCalendar from './WeeksCalendar';
import { useDispatch } from 'react-redux';
import { fetchTaskPerWeeks, updateDays } from '../../../store/task/actions';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const { Text } = Typography;

const CalendarComponent = ({ allTask, currentDate, setCurrentDate, allTaskWeek }) => {
	const dispatch = useDispatch();
	const days = useSelector((state) => state.task.setDays);
	const [weeks, setWeeks] = useState(0);
	const [isModalVisible, setIsModalVisible] = useState(false);

	function sumarDias(fecha, dias) {
		fecha.setDate(fecha.getDate() + dias);
		return fecha;
	}
	const calculateDays = () => {
		var d = new Date();

		const week = [];
		const weekFormat = [];
		let current = new Date(sumarDias(d, weeks));

		if (current.getDay() == 0) {
			current.setDate(current.getDate() - 7 + 1);
		} else {
			current.setDate(current.getDate() - current.getDay() + 1);
		}

		week.push(new Date(current));
		current.setDate(current.getDate() + 7);
		week.push(new Date(current));
		week.forEach((w) => {
			weekFormat.push(moment(w).format());
			dispatch(updateDays(weekFormat));
		});
	};

	useEffect(() => {
		calculateDays();
	}, [weeks]);

	useEffect(() => {
		if (days.length !== 0) {
			dispatch(fetchTaskPerWeeks(days));
		}
	}, [days]);

	useEffect(() => {
		setWeeks(0);
	}, []);

	const countTask = (allTask) => {
		const today = new Date();
		const yestarday = new Date();

		yestarday.setDate(today.getDate() - 1);

		const num = allTask.filter((task) => {
			const dueDate = dayjs(task.duedDate);

			return (
				(dueDate.isSame(today, 'day') || dueDate.isSame(yestarday, 'day')) &&
				task.status === 'active'
			);
		});

		return num.length;
	};

	let textTask = '';
	if (allTask && allTask?.length === 0) {
		textTask = (
			<div style={{ display: 'flex' }}>
				<Text
					style={{
						color: '#52C41A',
						fontSize: '16px',
						fontWeight: '600',
						marginRight: '10px'
					}}>
					!
				</Text>
				<div>
					<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '400' }}>
						¡Enhorabuena!
					</Text>
					{moment(currentDate).format('YYYY/MM/DD') === moment().format('YYYY/MM/DD') ? (
						<>
							<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '400' }}>Para </Text>
							<Text style={{ color: '#02C3CD', fontSize: '12px', fontWeight: '600' }}>HOY </Text>
							<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '400' }}>tienes </Text>
						</>
					) : (
						<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '400' }}>Tienes </Text>
					)}
					<Text style={{ color: '#52C41A', fontSize: '14px', fontWeight: '600' }}>
						{allTask.length}
						tareas pendientes.
					</Text>
					<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '600' }}> Good job!</Text>
				</div>
			</div>
		);
	} else if (allTask && allTask?.length < 15) {
		textTask = (
			<div style={{ display: 'flex' }}>
				<Text
					style={{
						color: '#FAAD14',
						fontSize: '16px',
						fontWeight: '600',
						marginRight: '10px'
					}}>
					!
				</Text>
				<div>
					{moment(currentDate).format('YYYY/MM/DD') === moment().format('YYYY/MM/DD') ? (
						<>
							<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '400' }}>Para </Text>
							<Text style={{ color: '#02C3CD', fontSize: '12px', fontWeight: '600' }}>HOY </Text>
							<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '400' }}>tienes </Text>
						</>
					) : (
						<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '400' }}>Tienes </Text>
					)}
					<Text style={{ color: '#FAAD14', fontSize: '14px', fontWeight: '600' }}>
						{countTask(allTaskWeek)} tareas pendientes.
					</Text>
					<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '400' }}>
						{' '}
						Mucho ánimo; con un poco de esfuerzo más puedes ponerte al día.
					</Text>
					<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '600' }}> Go for it!</Text>
				</div>
			</div>
		);
	} else if (allTaskWeek && allTask?.length >= 15) {
		textTask = (
			<div style={{ display: 'flex' }}>
				<Text
					style={{
						color: '#FF4D4F',
						fontSize: '16px',
						fontWeight: '600',
						marginRight: '10px'
					}}>
					!
				</Text>
				<div>
					{moment(currentDate).format('YYYY/MM/DD') === moment().format('YYYY/MM/DD') ? (
						<>
							<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '400' }}>Para </Text>
							<Text style={{ color: '#02C3CD', fontSize: '12px', fontWeight: '600' }}>HOY </Text>
							<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '400' }}>tienes </Text>
						</>
					) : (
						<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '400' }}>Tienes </Text>
					)}
					<Text style={{ color: '#FF4D4F', fontSize: '14px', fontWeight: '600' }}>
						{allTask.length} tareas pendientes.
					</Text>
					<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '400' }}>
						{' '}
						Ánimo; tienes muchas tareas pendientes pero no es imposible.
					</Text>
					<Text style={{ color: '#2F4858', fontSize: '12px', fontWeight: '600' }}>
						{' '}
						Come on, you can do it!
					</Text>
				</div>
			</div>
		);
	}

	const taskDays = [
		{ key: 'A' },
		{ key: 'B' },
		{ key: 'C' },
		{ key: 'D' },
		{ key: 'E' },
		{ key: 'F' },
		{ key: 'G' },
		{ key: 'H' },
		{ key: 'I' },
		{ key: 'J' },
		{ key: 'K' },
		{ key: 'L' },
		{ key: 'M' },
		{ key: 'N' }
	];
	const month = moment(days[0]).format('M') - 1;

	return (
		<div style={{ height: '100%' }}>
			<Card className="card-styles">
				<div className="text-title" style={{ marginBottom: 23, textAlign: 'center' }}>
					Calendario de tareas - {moment().month(month).format('MMMM')} de{' '}
					{moment().year(moment(days[0]).format('YYYY')).format('YYYY')}
				</div>
				<div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 22 }}>
					<div style={{ margin: 0 }}>
						<div className="card-task">{textTask}</div>
					</div>
					<div style={{ display: 'flex' }}>
						<div>
							<div
								style={{ marginBottom: 24, textAlign: 'center', cursor: 'pointer' }}
								onClick={() => {
									setWeeks(weeks - 6);
								}}>
								<img src={'/images/arrowIcon.svg'} alt="arrowIcon" />
							</div>

							<WeeksCalendar
								taskDays={taskDays && taskDays}
								days={days && days}
								index={0}
								allTask={allTaskWeek && allTaskWeek}
								currentDate={currentDate}
								setCurrentDate={(e) => setCurrentDate(e)}
							/>

							<div
								style={{ marginTop: 24, textAlign: 'center', cursor: 'pointer' }}
								onClick={() => {
									setWeeks(weeks + 6);
								}}>
								<img
									src={'/images/arrowIcon.svg'}
									alt="arrowIcon"
									style={{ transform: 'rotate(180deg)' }}
								/>
							</div>
						</div>
						<img
							onClick={() => setIsModalVisible(true)}
							src={'/images/infoIcon.svg'}
							alt="infoIcon"
							style={{ display: 'flex', alignSelf: 'flex-start' }}
						/>
					</div>
				</div>
			</Card>

			<Modal
				title="Info iconos"
				className="gb-modal-style-common gb-modal-style-preferences ModalInfoIcons"
				open={isModalVisible}
				width={320}
				onOk={() => setIsModalVisible(!isModalVisible)}
				onCancel={() => setIsModalVisible(!isModalVisible)}>
				{/* <div style={{ display: 'flex' }}> */}
				<div style={{ display: 'flex', marginBottom: 12, alignItems: 'center' }}>
					<img src={'/images/callIcon.svg'} alt="callIcon" style={{ height: 20, marginRight: 8 }} />
					<div className="task-title" style={{ fontSize: 12 }}>
						Tipo llamar
					</div>
				</div>
				<div style={{ display: 'flex', marginBottom: 12, alignItems: 'center' }}>
					<img
						src={'/images/claimIcon.svg'}
						alt="claimIcon"
						style={{ height: 20, marginRight: 8 }}
					/>
					<div className="task-title" style={{ fontSize: 12 }}>
						Tipo reclamar (banco, tasación...)
					</div>
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<img
						src={'/images/documentIcon.svg'}
						alt="documentIcon"
						style={{ height: 20, marginRight: 8 }}
					/>
					<div className="task-title" style={{ fontSize: 12 }}>
						Tipo Otro
					</div>
				</div>
				{/* </div> */}
			</Modal>
		</div>
	);
};

export default CalendarComponent;

import React, { useState, useEffect } from 'react';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import { Select, Modal } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { WhatsAppOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

const { Option } = Select;

const LinkContact = ({
	loadDocumentsData,
	mortgageData,
	contactPreferences,
	owner,
	name,
	mortgageId,
	shopper,
	nameMortgage,
	dataTraceClient,
	getTraces,
	infoProfile
}) => {
	const token = authClient.getToken();
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);
	const [isModalVisiblePreferences, setIsModalVisiblePreferences] = useState(false);
	const [isModalVisibleTraces, setIsModalVisibleTraces] = useState(false);
	const [arrayDocuments, setArrayDocuments] = useState([]);
	const [translations, setTranslations] = useState([]);
	const documents = useSelector((state) => state.documentation.documents);
	const documentsNeed = useSelector((state) => state.documentation.needed);
	const documentsCompleted = useSelector((state) => state.documentation.completed);
	const profileAnalist = authClient.getProfile();
	const [isSabadell, setIsSabadell] = useState(undefined);


	useEffect(() => {
		getManagementId()
	},[])

	const arrayOptionsPage = [];
	for (let i = 1; i < 51; i++) {
		arrayOptionsPage.push(
			<Option key={i} value={`${i}`}>
				{i}
			</Option>
		);
	}

	let ownerList = [];
	for (let i = 1; i <= Number(_.get(mortgageData, 'mortgage.participants', 0)); i++) {
		ownerList.push(`owner${i}`);
	}
	for (let i = 0; i < Number(_.get(mortgageData, 'mortgage.guarantors', 0)); i++) {
		ownerList.push(`guarantor${i + 1}`);
	}

	useEffect(() => {
		axios
			.get(`${env.api.url}/v1/languages/app`, {})
			.then((res) => setTranslations(res.data.data[formatLanguage(infoProfile.profile?.language)]));
	}, [translations && translations?.length === 0]);



	const getManagementId = () => {
		axios
			.post(
				`${env.api.url}/management/management/by-operation`,
				{ operationId: mortgageId },
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			)
			.then((response) => {
				if(response.data.data.code === 'sabadell-es') {
					setIsSabadell(true);
				}else {
					setIsSabadell(false);
				}
			});
	};

	const formatLanguage = (lang) => {
		switch (lang) {
			case 'es':
			case 'es_ES':
			case 'ES':
				return 'es';
			case 'en':
			case 'en_EN':
			case 'EN':
				return 'en';
			case 'cat':
			case 'ca_ES':
			case 'CAT':
				return 'cat';
			default:
				return 'es';
		}
	};

	useEffect(() => {
		if (translations && translations.length !== 0) {
			ownerList.map((data) => {
				documents.map((infoOwner) => {
					let newArray = arrayDocuments;
					let nameOwner = translations && translations.form && translations.form[data];
					if (infoOwner.completed[data] === false && infoOwner.participants[data] === 'required') {
						if (!arrayDocuments.includes(`${nameOwner}:`)) {
							newArray.push(`${nameOwner}:`);
							setArrayDocuments(newArray);
						}
						newArray.push(
							`- ${
								translations &&
								translations.documentTypes &&
								translations.documentTypes[infoOwner.name]
							}`
						);
						setArrayDocuments(newArray);
					}
				});
			});
		}
	}, [
		ownerList &&
			ownerList.length ===
				Number(mortgageData && mortgageData.mortgage && mortgageData.mortgage.guarantors) +
					Number(mortgageData && mortgageData.mortgage && mortgageData.mortgage.participants),
		translations && translations?.length === 0
	]);

	let whatsappPhone = mortgageData && mortgageData.owner1.phone;
	if (whatsappPhone && whatsappPhone.indexOf('+') === -1) {
		whatsappPhone = `+34${whatsappPhone}`;
	}

	const createTrace = () => {
		axios
			.post(
				`${env.api.url}/v1/hadmin/trays/create`,
				{ operationId: mortgageId, type: 'whatsapp', client: whatsappPhone },
				{
					headers: { Authorization: `Bearer ${token}` }
				}
			)
			.then((response) => {
				getTraces();
			});
	};

	const contactName = _.get(contactPreferences, 'profile.name', 'sin nombre');
	const contactLanguage = _.get(contactPreferences, 'profile.language');
	const clientName = _.get(mortgageData, 'owner1.name', '');
	const documentsArrayMessage = arrayDocuments.toString().replace(/,/g, '%0a');

	let textClient;
	let textPersonalShopper;

	if (isSabadell) {
		if (contactLanguage === 'es' || contactLanguage === 'es_ES') {
			// eslint-disable-next-line max-len
			textClient = `Hola ${clientName},%0aSoy ${profileAnalist.name}, tu gestor de hipotecas de Banco Sabadell. Estoy revisando tu expediente para poder darte la mejor oferta y veo que todavía nos falta la siguiente documentación. Puedes subirla desde aquí: https://simuladordehipotecas.bancsabadell.com/.%0aUn saludo!%0a%0a${documentsArrayMessage}`;
		} else if (contactLanguage === 'cat' || contactLanguage === 'ca_ES') {
			// eslint-disable-next-line max-len
			textClient = `Hola ${clientName},%0aSóc ${profileAnalist.name}, el teu gestor d'hipoteques de Banc Sabadell. Estic revisant el teu expedient per poder donar-te la millor oferta i veig que encara ens manca la documentació següent. Pots pujar-la des d'aquí: https://simuladordehipotecas.bancsabadell.com/.%0aUna salutació!%0a%0a${documentsArrayMessage}`;
		} else if (contactLanguage === 'en' || contactLanguage === 'en_EN') {
			// eslint-disable-next-line max-len
			textClient = `Hi ${clientName},%0aI am ${profileAnalist.name}, your Banco Sabadell mortgage manager. I am reviewing your file to be able to give you the best offer and I see that we are still missing the following documentation. You can upload it from here: https://simulatordehipotecas.bancsabadell.com/.%0a Greetings!%0a%0a${documentsArrayMessage}`;
		} else {
			// eslint-disable-next-line max-len
			textClient = `Hola ${clientName},%0aSoy ${profileAnalist.name}, tu gestor de hipotecas de Banco Sabadell. Estoy revisando tu expediente para poder darte la mejor oferta y veo que todavía nos falta la siguiente documentación. Puedes subirla desde aquí: https://simuladordehipotecas.bancsabadell.com/.%0aUn saludo!%0a%0a${documentsArrayMessage}`;
		}
	} else {
		if (isSabadell === false) {
			if (contactLanguage === 'es' || contactLanguage === 'es_ES') {
				// eslint-disable-next-line max-len
				textClient = `Hola ${clientName},%0aSoy ${profileAnalist.name}, de gibobs. Estoy revisando tu expediente para poder conseguirte la mejor hipoteca y veo que todavía nos falta la siguiente documentación. Puedes subirla desde aquí www.gibobs.com/login.%0aUn saludo!%0a%0a${documentsArrayMessage}`;
				// eslint-disable-next-line max-len
				textPersonalShopper = `Hola ${contactName},%0aSoy ${profileAnalist.name}, de gibobs. Estoy revisando el expediente ${nameMortgage} de tu cliente ${clientName} para poder conseguirle la mejor hipoteca y veo que todavía nos falta la siguiente documentación. Recuerda que puedes subirla desde tu interfaz www.gibobs.com/login.%0aSaludos.%0a${documentsArrayMessage}`;
			} else if (contactLanguage === 'cat' || contactLanguage === 'ca_ES') {
				// eslint-disable-next-line max-len
				textClient = `Hola ${clientName},%0aSóc ${profileAnalist.name}, de gibobs. Estic revisant el teu expedient per poder aconseguir-te la millor hipoteca i veig que encara ens falta la documentació següent. Podeu pujar-la des d'aquí www.gibobs.com/login.%0aUna salutació!%0a${documentsArrayMessage}`;
				// eslint-disable-next-line max-len
				textPersonalShopper = `Hola ${contactName},%0aSóc ${profileAnalist.name}, de gibobs. Estic revisant l'expedient ${nameMortgage} del teu client ${clientName} per poder aconseguir-li la millor hipoteca i veig que encara ens falta la documentació següent. Recorda que la pots pujar des de la teva interfíciewww.gibobs.com/login.%0aUna salutació!%0a${documentsArrayMessage}`;
			} else if (contactLanguage === 'en' || contactLanguage === 'en_EN') {
				// eslint-disable-next-line max-len
				textClient = `Hi ${clientName},%0aI am ${profileAnalist.name}, from gibobs. I am reviewing your dossier in order to get you the best mortgage and I see that we are still missing the following documentation. You can upload it from here www.gibobs.com/login.%0aRegards!%0a${documentsArrayMessage}`;
				// eslint-disable-next-line max-len
				textPersonalShopper = `”Hello ${contactName},%0aI am ${profileAnalist.name} from gibobs. I am reviewing the dossier ${nameMortgage} of your client ${clientName} in order to get the best mortgage and I see that we are still missing the following documentation. Remember that you can upload it from your interface www.gibobs.com/login.%0aRegards!%0a${documentsArrayMessage}`;
			} else {
				// eslint-disable-next-line max-len
				textClient = `Hola ${clientName},%0aSoy ${profileAnalist.name}, de gibobs. Estoy revisando tu expediente para poder conseguirte la mejor hipoteca y veo que todavía nos falta la siguiente documentación. Puedes subirla desde aquí www.gibobs.com/login.%0aUn saludo!${documentsArrayMessage}`;
				// eslint-disable-next-line max-len
				textPersonalShopper = `Hola ${contactName},%0aSoy ${profileAnalist.name}, de gibobs. Estoy revisando el expediente ${nameMortgage} de tu cliente ${clientName} para poder conseguirle la mejor hipoteca y veo que todavía nos falta la siguiente documentación. Recuerda que puedes subirla desde tu interfaz www.gibobs.com/login.%0aSaludos.%0a${documentsArrayMessage}`;
			}
		}
	}

	const textPreferences = {
		phone: 'Télefono',
		email: 'Correo',
		whatsapp: 'Whatsapp',
		morning: 'Mañanas',
		afternoon: 'Mediodías',
		evening: 'Tardes'
	};

	const capitalize = (word) => {
		return word[0].toUpperCase() + word.slice(1);
	};

	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: 12,
						justifyContent: 'right'
					}}>
					<div
						style={{
							fontSize: 500,
							fontSize: 12,
							color: '#02C3CD',
							marginRight: 12,
							cursor: 'pointer'
						}}
						onClick={() => setIsModalVisiblePreferences(!isModalVisiblePreferences)}>
						Preferencia de contacto
					</div>
					<div
						style={{
							cursor: documentsNeed - documentsCompleted !== 0 ? 'pointer' : 'default',
							backgroundColor: documentsNeed - documentsCompleted !== 0 ? '#02C3CD' : '#C0DAEE',
							width: '32px',
							height: '32px',
							borderRadius: '4px'
						}}>
						{documentsNeed - documentsCompleted !== 0 && arrayDocuments.length > 0 ? (
							<a
								href={
									`https://web.whatsapp.com/send?phone=` +
									whatsappPhone +
									'&text=' +
									(shopper ? textPersonalShopper : textClient)
								}
								target="_blank">
								<WhatsAppOutlined
									style={{
										color: 'white',
										fontSize: '20px',
										margin: '6px',
										cursor: documentsNeed - documentsCompleted !== 0 ? 'pointer' : 'default'
									}}
									onClick={() => createTrace()}
								/>
							</a>
						) : (
							<div></div>
						)}
					</div>
				</div>
				<div>
					<div
						style={{ fontSize: 12, fontWeight: 400, color: '#2F4858', textAlign: 'right' }}
						onClick={() => setIsModalVisibleTraces(!isModalVisibleTraces)}>
						Recordatorios enviados
					</div>
					{dataTraceClient.length > 0 && (
						<div style={{ display: 'flex' }}>
							<div style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}>
								{dataTraceClient &&
									dataTraceClient[dataTraceClient.length - 1] &&
									capitalize(dataTraceClient[dataTraceClient.length - 1].type)}{' '}
								{dataTraceClient &&
									dataTraceClient[dataTraceClient.length - 1] &&
									moment(dataTraceClient[dataTraceClient.length - 1].createdAt).format(
										'DD/MM/YYYY'
									)}{' '}
								-{' '}
								{dataTraceClient &&
									dataTraceClient[dataTraceClient.length - 1] &&
									moment(dataTraceClient[dataTraceClient.length - 1].createdAt).format('HH:mm')}
							</div>
							<PlusOutlined
								style={{ color: '#02C3CD', fontSize: 16, marginLeft: 4 }}
								onClick={() => setIsModalVisibleTraces(!isModalVisibleTraces)}
							/>
						</div>
					)}
					{dataTraceClient.length === 0 && (
						<div style={{ width: '100%', textAlign: 'right' }}>
							<PlusOutlined
								style={{ color: '#02C3CD', fontSize: 16, marginLeft: 4 }}
								onClick={() => setIsModalVisibleTraces(!isModalVisibleTraces)}
							/>
						</div>
					)}
				</div>
			</div>
			<Modal
				title="Preferencias de contacto"
				className="gb-modal-style-common gb-modal-style-preferences"
				open={isModalVisiblePreferences}
				width={290}
				onOk={() => setIsModalVisiblePreferences(!isModalVisiblePreferences)}
				onCancel={() => setIsModalVisiblePreferences(!isModalVisiblePreferences)}>
				<div style={{ display: 'flex' }}>
					<div style={{ fontSize: 12, fontWeight: 400, color: '#02C3CD', marginRight: 4 }}>
						{contactPreferences &&
							contactPreferences.profile &&
							contactPreferences.profile.contactChannel &&
							textPreferences[contactPreferences.profile.contactChannel]}
					</div>
					<div style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}>
						{contactPreferences &&
							contactPreferences.profile &&
							contactPreferences.profile.contactTime &&
							textPreferences[contactPreferences.profile.contactTime]}
					</div>
				</div>
			</Modal>
			<Modal
				title="Recordatorios enviados"
				className="gb-modal-style-common gb-modal-style-preferences"
				open={isModalVisibleTraces}
				width={462}
				onOk={() => setIsModalVisibleTraces(!isModalVisibleTraces)}
				onCancel={() => setIsModalVisibleTraces(!isModalVisibleTraces)}>
				<div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
					<div>
						<div style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD' }}>Whatsapp</div>
						{dataTraceClient &&
							dataTraceClient.map((data, i) => {
								if (data.type === 'whatsapp') {
									return (
										<div className="details-traces-style" key={i}>
											{moment(data.createdAt).format('HH:mm')} -{' '}
											{moment(data.date).format('DD/MM/YYYY')}
										</div>
									);
								}
							})}
					</div>
					<div>
						<div style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD' }}>SMS</div>
						{dataTraceClient &&
							dataTraceClient.map((data, i) => {
								if (data.type === 'sms') {
									return (
										<div className="details-traces-style" key={i}>
											{moment(data.createdAt).format('HH:mm')} -{' '}
											{moment(data.date).format('DD/MM/YYYY')}
										</div>
									);
								}
							})}
					</div>
					<div>
						<div style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD' }}>E-mail</div>
						{dataTraceClient &&
							dataTraceClient.map((data, i) => {
								if (data.type === 'email') {
									return (
										<div className="details-traces-style" key={i}>
											{moment(data.createdAt).format('HH:mm')} -{' '}
											{moment(data.date).format('DD/MM/YYYY')}
										</div>
									);
								}
							})}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default LinkContact;

import { LOADING, NEEDED, COMPLETED, GROUPS, DOCUMENTS, GROUPSCATEGORIES } from './constants';
import axios from 'axios';
import env from '../../environment';

export const getOperationDocuments = (userToken, mortgageId) => async (dispatch) => {
	axios
		.get(`${env.api.url}/v1/documents/request-operation-documentation/${mortgageId}`, {
			headers: { Authorization: `Bearer ${userToken}` }
		})
		.then((response) => {
			if (response.data.success) {
				const data = response.data.data;

				const apiDocuments = data.documents.sort((a, b) => a.title.localeCompare(b.title));
				const apiGroupsUnmapped = data.groups;

				const apiGroups = Object.keys(apiGroupsUnmapped).map((key) => {
					return {
						name: key,
						title: apiGroupsUnmapped[key].title,
						hasDocumentTypes: apiGroupsUnmapped[key].hasDocumentTypes
					};
				});

				dispatch({
					type: LOADING,
					payload: false
				});

				dispatch({
					type: NEEDED,
					payload: data.documentsNeeded
				});

				dispatch({
					type: COMPLETED,
					payload: data.documentsCompleted
				});

				dispatch({
					type: DOCUMENTS,
					payload: apiDocuments
				});

				dispatch({
					type: GROUPS,
					payload: apiGroups
				});

				dispatch({
					type: GROUPSCATEGORIES,
					payload: data.groups
				});
			} else {
				console.error('fallo la carga de documentos', response.data);
			}
		});
};

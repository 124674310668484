import React, { useState } from 'react';
import { Form, Modal, Input, Col, Row, Select, Button, Upload } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import SuperSearch from '../Search/SuperSearch';

const { TextArea } = Input;
const { Option } = Select;

const ModalHelp = ({ visible, onCancel, onCreate, dataPersonal, dataAgent }) => {
	const [form] = Form.useForm();
	const [fileName, setFileName] = useState([]);
	const [saveFileDataField, setSaveFileDataField] = useState([]);
	const [operationNameModal, setOperationNameModal] = useState(undefined);
	const newArray = [...fileName];

	const uploadProps = {
		multiple: true,
		beforeUpload: (file) => {
			newArray.push(file.name);
			setFileName(newArray);

			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					saveFileData(base64File, file.name);
				};
			});
		}
	};

	const saveFileData = (file, name) => {
		const documentFile = saveFileDataField;
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
	};

	const deleteFile = (index) => {
		const newArrayFile = [...fileName];
		newArrayFile.splice(index, 1);
		setFileName([...newArrayFile]);
	};

	return (
        (<Modal
			className="gb-modal-style-common ModalCommonSendMessage"
			cancelButtonProps={{ className: 'button-secundary-gibobs' }}
			okButtonProps={{ className: 'button-primari-gibobs' }}
			open={visible}
			title="Contacta con nosotros"
			okText="Enviar"
			cancelText="Cancelar"
			width={1000}
			onCancel={() => {
				onCancel();
				form.resetFields();
			}}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						onCreate(values, operationNameModal, saveFileDataField);
						form.resetFields();
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
            <Form
				form={form}
				layout="vertical"
				name="Contacto"
				initialValues={{
					name: dataAgent ? dataAgent.name : '',
					surname: dataAgent ? dataAgent.surname : '',
					email: dataAgent ? dataAgent.email : '',
					phone: dataPersonal ? dataPersonal.phone : '',
					companyName: dataPersonal ? dataPersonal.companyName : '',
					comments: ''
				}}
				style={{
					marginRight: 'auto',
					marginLeft: 'auto'
				}}
			>
				<Row gutter={24}>
					<Col lg={8} xs={24}>
						<Form.Item
							name="contactReason"
							label="Tipo"
							rules={[
								{
									required: true
								}
							]}
						>
							<Select placeholder="Seleccione" style={{ width: '100%' }}>
								<Option value="incidencia">Incidencia</Option>
								<Option value="consulta">Consulta</Option>
								<Option value="peticion">Petición de producto</Option>
								<Option value="otros">Otros</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col lg={8} xs={24}>
						<Form.Item label="Operación">
							<SuperSearch
								className="selectorProperty"
								style={{
									border: '1px solid #C0DAEE',
									width: '100%',
									minWidth: '100%'
								}}
								modal={true}
								operationName={true}
								onOperationChange={(operationName) => {
									setOperationNameModal(operationName);
								}}
							/>
						</Form.Item>
					</Col>

					<Col lg={8} xs={24}>
						<Form.Item name="typeApp" label="Aplicación">
							<Select placeholder="Seleccione" style={{ width: '100%' }}>
								<Option value="Hadmin">Hadmin</Option>
								<Option value="App">App</Option>
								<Option value="Android">Android</Option>
								<Option value="Ios">Ios</Option>
								<Option value="Web">Web</Option>
								<Option value="Informes">Informes</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item
					name="summary"
					label="Asunto"
					rules={[
						{
							required: true
						}
					]}
				>
					<Input style={{ width: '100%' }} />
					{/* <TextArea
            style={{
              height: "200px",
              borderRadius: "4px",
              borderColor: "#C0DAEE",
              fontSize: "12px",
              width: "100%",
            }}
            type="text"
            placeholder="Breve descripción del problema"
          /> */}
				</Form.Item>
				<Form.Item
					name="description"
					label="Descripción"
					rules={[
						{
							required: true
						}
					]}
				>
					<TextArea
						style={{
							height: '200px',
							borderRadius: '4px',
							borderColor: '#C0DAEE',
							fontSize: '12px',
							width: '100%'
						}}
						type="text"
						placeholder="Breve descripción del problema"
					/>
				</Form.Item>
				{/* {fileName &&
          fileName.map((data, i) => {
            console.log(fileName);
            return (
              <div
                style={{
                  color: "#02C3CD",
                  fontSize: "12px",
                  marginBottom: "8px",
                  display: "flex",
                }}
              >
                {data}
                <div style={{ marginLeft: "8px" }}>
                  <DeleteOutlined onClick={() => deleteFile(i)} />
                </div>
              </div>
            );
          })}
        <Upload multiple={true} {...uploadProps}>
          <Button type="primary" style={{ margin: "10px" }}>
            Subir archivo
          </Button>
        </Upload> */}
			</Form>
        </Modal>)
    );
};

export default ModalHelp;

import React from 'react';
import { Row, Modal, Col, Form, Input, message } from 'antd';
import authClient from '../../Auth/Auth';
import env from '../../environment';
import axios from 'axios';

const { TextArea } = Input;

function ModalFinishTask({ visible, setShowModalActionFin, initialValuesStatus, getTask }) {
	const [form] = Form.useForm();

	const handleOk = (values, type) => {
		setShowModalActionFin(!visible);

		axios
			.post(
				`${env.api.url}/v1/hadmin/tasks/action/${type}/${initialValuesStatus.taskIdDete}`,
				{
					note: values.note,
					dueDate: undefined
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.errorCode === 'FORBIDDEN') {
					message.error('Comprueba que tu eres el que ha creado la tarea a eliminar');
				} else {
					message.success('Se ha eliminado con éxito');
				}
				getTask();
			})
			.catch((error) => {
				console.log(error);
				message.error('Compruebe que la fecha es mayor que hoy');
			});
	};

	const handleCancel = () => {
		setShowModalActionFin(!visible);
	};
	const initDelete = {
		note: undefined,
		taskIdDete: undefined
		
	};
	return (
        (<Modal
			className="gb-modal-style-common modal-task-button-style"
			cancelButtonProps={{ className: 'button-secundary-gibobs', style: { width: '96px' } }}
			okButtonProps={{ className: 'button-primari-gibobs', style: { width: '96px' } }}
			title={'Finalizar tarea'}
			open={visible}
			width={497}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						form.resetFields();
						form.setFieldsValue(initDelete);

						handleOk(values, 'complete');
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
			okText={'Finalizar'}
			cancelText={'Cancelar'}
			onCancel={handleCancel}
			closable={false}
		>
            <Form
				form={form}
				layout="horizontal"
				name="basic"
				className="gb-form-task"
				initialValues={
					initialValuesStatus !== undefined
						? initialValuesStatus
						: {
								note: ''
						  }
				}
			>
				<Row gutter={24}>
					<Col span={24}>
						<Form.Item
							label="Nota:"
							name="note"
							rules={[{ required: true, message: 'Introduzca una nota' }]}
						>
							<TextArea
								style={{
									width: '100%'
								}}
							/>
						</Form.Item>
					</Col>
					<Form.Item
						hidden={true}
						name="taskIdDete"
						style={{ float: 'left', marginRight: 200, width: 150 }}
					>
						<Input hidden={true} value={initialValuesStatus && initialValuesStatus.taskIdDete}></Input>
					</Form.Item>
				</Row>
			</Form>
        </Modal>)
    );
}

export default ModalFinishTask;

import React from 'react';

import { NavLink } from 'react-router-dom';
import { Breadcrumb, Typography, Layout } from 'antd';
// import { isMobile } from "react-device-detect";

const { Text } = Typography;

const MyBreadcrumb = ({ data }) => {
	return (
		<>
			<Breadcrumb className="breadCrumb">
				{data &&
					data.map((item, index) => (
						<Breadcrumb.Item key={item.link} to={item.link}>
							{item.link !== undefined ? (
								<NavLink to={item.link}>
									<Text
										style={{
											color: data.length === index + 1 ? '#02C3CD' : '#748EA0',
											fontSize: '12px',
											fontWeight: data.length === index + 1 ? '600' : 'normal',
											display: 'flex'
										}}>
										{item.name}
									</Text>
								</NavLink>
							) : (
								<Text
									style={{
										display: 'flex',
										color: data.length === index + 1 ? '#02C3CD' : '#748EA0',
										fontSize: '12px',
										fontWeight: data.length === index + 1 ? '600' : 'normal',
										display: 'flex'
									}}>
									{item.name}
								</Text>
							)}
						</Breadcrumb.Item>
					))}
			</Breadcrumb>
		</>
	);
};

export default MyBreadcrumb;

import React, { useState, useEffect } from 'react';
import { Button, Typography, Col, Row, Modal, notification, Form, Input } from 'antd';
import BlockedBanksComponent from './blocked-banks/BlockedBanksComponent';
import { useSelector } from 'react-redux';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import axios from 'axios';
import OfertsTableNewStyle from './OffersTableNewStyle';
import BankOffers from './SendNewBank/BankOffers';
import InfoBankOffers from './SendNewBank/InfoBankOffers';
import _ from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import FormPageSendBank from './pageEmailbank/FormPageSendBank';
import ViewDocumentList from './pageEmailbank/ViewDocumentList';
import TableCaixaBank from './pageEmailbank/TableCaixaBank';
import TableLiberBank from './pageEmailbank/TableLiberBank';
import TableSantander from './pageEmailbank/TableSantander';
import InfoBankOffersClaim from './SendNewBank/InfoBankOffersClaim';
import LoaderLogo from '../../../../../Components/Layout/LoaderLogo';
import ControllerApiEmailCreate from './pageEmailbank/ControllerApiEmailCreate';
import TableEvo from './pageEmailbank/TableEvo';
import EmailPageSendBank from './pageEmailbank/EmailPageSendBank';
import TableCaixaBankApi from './pageEmailbank/TableCaixaBankApi';

const { Text } = Typography;

const PageBank = ({ mortgageId, mortgageName, mortgage, loadData, type, setType }) => {
	const [valueSelect, setValueSelect] = useState(null);
	const [refreshFlag, setRefreshFlag] = useState(false);
	const takeSinVinculaciones = (data) => setValueSelect(data);
	const [taskToEdit, setTaskToEdit] = useState(null);
	const [dataDocuments, setDataDocuments] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [changeStado, setChangeStado] = useState(false);
	const [dataBankRequest, setDataBankRequest] = useState();
	const [banksOffers, setBanksOffers] = useState();
	let navigate = useNavigate();
	const location = useLocation();
	const locationReal = location.pathname + location.search;
	const [bankId, setBankId] = useState(undefined);
	const [loading, setLoading] = useState(false);
	const [requestExtra, setRequestExtra] = useState(undefined);
	const [showDetailsNotFound, setShowDetailsNotFound] = useState(false);
	const [detailsNotFound, setDetailsNotFound] = useState(undefined);
	const [bankOffer, setBankOffer] = useState(undefined);
	const [readerText, setReaderText] = useState(false);
	const [goBankHp, setGoBankHp] = useState(undefined);
	const [bankRequestNow, setBankRequestNow] = useState(undefined);
	const [bankRequestData, setBankRequestData] = useState(undefined);
	const [valueRadio, setValueRadio] = useState(undefined);

	const getAccounts = async () => {
		const response = await axios.get(
			`${env.api.url}/v1/documents/get-operation-documents/${mortgageId}`,
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
		await setDataDocuments(response.data.data);
	};

	useEffect(() => {
		getAccounts();
		getRequests();
		getGoBankHp();
		axios
			.get(`${env.api.url}/v1/banks/get-operation-bank-offers/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setBanksOffers(response.data.data);
			});
		getRequestExtra();
	}, [mortgageId, refreshFlag]);

	useEffect(() => {
		if (locationReal === `/operations/mortgages/${mortgageId}/send-to-bank`) {
			setBankRequestNow(location?.state?.bankRequestId);
			setBankRequestData(location?.state?.bankRequest);
		}
	}, [locationReal]);

	const getGoBankHp = () => {
		axios
			.post(
				`${env.api.url}/management/management/by-operation`,
				{ operationId: mortgageId },
				{
					headers: {
						Authorization: `Bearer ${authClient.getToken()}`
					}
				}
			)
			.then((response) => {
				setGoBankHp(response.data.data.code);
			});
	};

	const getRequestExtra = () => {
		axios
			.get(`${env.api.url}/banks/bank-request-extra/by-operation/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				response.data.data = _.orderBy(response.data.data, [(d) => d.bankRequest], ['desc']);
				setRequestExtra(response.data.data);
			});
	};

	const getRequests = () => {
		axios
			.get(`${env.api.url}/v1/banks/get-operation-bank-requests/${mortgageId}?hadmin=${true}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				response.data.data = _.orderBy(response.data.data, [(d) => d.id], ['desc']);
				setDataBankRequest(response.data.data);
			});
	};

	const statusJob = useSelector((state) => state.formaTasaci.statusJob);

	const showSendMessagesModal = (e) => {
		setTaskToEdit(e);
		setShowModal(true);
	};
	const showSendMessagesModalNew = (e) => {
		axios
			.get(`${env.api.url}/v1/banks/data-validator/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				if (Object.entries(response.data.data).length === 0) {
					navigate(`/operations/mortgages/${mortgageId}/form-send-bank`);
				} else {
					setDetailsNotFound(response.data.data);
					setShowDetailsNotFound(!showDetailsNotFound);
				}
			});
	};

	const showSendMessagesHTML = (e) => {
		setShowModal(!showModal);
	};

	const registerOffer = (bankRequest) => {
		setBankId(bankRequest.bankId);

		navigate(`/operations/mortgages/${mortgageId}/answerLog`, {
			state: {
				readerText: readerText,
				bankOffer: bankOffer,
				bankId: bankRequest.bankId,
				bankRequest: bankRequest,
				bankName: bankRequest.bankName,
				bankCode: bankRequest.bankCode,
				bankOfferType: valueRadio === 3 ? true : false,
				dataBanks: {
					data: undefined,
					bankName: bankRequest.bankName,
					bankCode: bankRequest.bankCode,
					reader: true
				}
			}
		});
	};

	const renderTranductions = (data) => {
		if (data === 'netIncomes') {
			return 'Ingresos';
		} else if (data === 'numberOfPayments') {
			return 'Numero de pagas';
		} else if (data === 'amount') {
			return 'Importe';
		} else if (data === 'bonus') {
			return 'Bonus';
		} else if (data === 'rentIncomes') {
			return 'Rentas';
		} else if (data === 'otherIncomes') {
			return 'Otros ingresos';
		} else if (data === 'mortgagePayment') {
			return 'Pagos de hipotecas';
		} else if (data === 'loanPayment') {
			return 'Préstamos';
		} else if (data === 'pensionPayment') {
			return 'Pension';
		} else if (data === 'savings') {
			return 'Ahorros';
		} else if (data === 'cityzenship') {
			return 'Ciudad donde vive';
		} else if (data === 'activity') {
			return 'Profesion';
		} else if (data === 'age') {
			return 'Años';
		} else if (data === 'maxBudget') {
			return 'Importe de compra';
		} else if (data === 'province') {
			return 'Provincia de compra';
		} else if (data === 'propertyType') {
			return 'Tipo de propiedad';
		} else if (data === 'houseType') {
			return 'Tipo de vivienda';
		} else if (data === 'seniority') {
			return 'Antiguedad';
		} else if (data === 'nationality') {
			return 'Nacionalidad';
		} else if (data === 'nif') {
			return 'DNI/NIF';
		} else {
			return data;
		}
	};

	const renderDatadata = (data, index) => {
		let tmpOwners = [];

		if (index === 0) {
			tmpOwners.push(
				<Text style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD', marginTop: 16 }}>
					{'Datos de la operación'}
				</Text>
			);
			tmpOwners.push(
				<>
					{data.map((data) => {
						tmpOwners.push(
							<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 8 }}>
								{renderTranductions(data)}
							</Text>
						);
					})}
				</>
			);
		} else {
			tmpOwners.push(
				<Text style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD', marginTop: 16 }}>
					{'Datos de titular ' + index}
				</Text>
			);

			tmpOwners.push(
				<>
					{data.map((data) => {
						tmpOwners.push(
							<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 8 }}>
								{renderTranductions(data)}
							</Text>
						);
					})}
				</>
			);
		}

		return tmpOwners;
	};

	const renderDataData2 = (data, index) => {
		let tmpOwners = [];
		tmpOwners.push(
			<Text style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD', marginTop: 16 }}>
				{'Datos de avalista ' + index}
			</Text>
		);

		tmpOwners.push(
			<>
				{data.map((data) => {
					tmpOwners.push(
						<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 8 }}>
							{renderTranductions(data)}
						</Text>
					);
				})}
			</>
		);

		return tmpOwners;
	};

	const renderDataOwner = () => {
		const owners = parseInt(mortgage.mortgage.participants);

		let tmp = undefined;
		Array(detailsNotFound).map((array) => {
			for (let index = 1; index <= owners; index++) {
				if (array['owner' + index] !== undefined) {
					tmp = renderDatadata(array['owner' + index], index);
				}
			}
		});

		return tmp;
	};

	const renderDataGuarantor = () => {
		const guarantors = parseInt(mortgage.mortgage.guarantors);

		let tmp = undefined;

		Array(detailsNotFound).map((array) => {
			for (let index = 1; index <= guarantors; index++) {
				if (array['guarantor' + index] !== undefined) {
					tmp = renderDataData2(array['guarantor' + index], index);
				}
			}
		});

		return tmp;
	};

	const hideAddTaskModal = () => {
		navigate(`/operations/mortgages/${mortgageId}/?tab=5`);
	};

	const renderDataMortgage = () => {
		let tmp = undefined;

		Array(detailsNotFound).map((array) => {
			if (array['mortgage'] !== undefined) {
				tmp = renderDatadata(array['mortgage'], 0);
			}
		});

		return tmp;
	};
	const renderDataSubrogation = () => {
		let tmp = undefined;

		Array(detailsNotFound).map((array) => {
			if (array['subrogation'] !== undefined) {
				tmp = renderDatadata(array['subrogation'], 0);
			}
		});

		return tmp;
	};

	const handleSubmitForm = (values, id) => {
		const objAsigment = {
			operationId: mortgageId,
			bankRequestId: id,
			name: values.name === '' ? undefined : values.name,
			email: values.email,
			phone: values.phone === '' ? undefined : values.phone,
			office: values.office === '' ? undefined : values.office
		};
		axios
			.post(`${env.api.url}/v1/banks/assign-bank-request`, objAsigment, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				loadData();
			});
		setTaskToEdit(null);
	};

	const renderEmail = (bankCode, values) => {
		if (bankCode === 'sabadell') {
			if (values.message.target) {
				return values.message.target.value;
			} else {
				return values.message;
			}
		} else {
			return values.message.toHTML();
		}
	};

	const sendBankEmail = (values, files, bankcode) => {
		values.to = values.to.filter((email) => email !== null && email !== ' ');

		const requestEmail = {
			bankRequestId: values.bankRequestId,
			operationId: mortgageId,
			to: values.to,
			from: values.from,
			cc: values.CC,
			cco: values.CCO,
			subject: values.subject,
			message: renderEmail(bankcode, values),
			attachments: files.map((file) => ({
				name: file.fileName,
				content: file.file
			}))
		};
		if (requestEmail) {
			console.log(requestEmail.attachments);
		}

		axios
			.post(`${env.api.url}/banks/email-composer/bank-request/send-to-bank`, requestEmail, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				notification.success({
					message: (
						<Text style={{ color: '#3E9314', fontSize: 16, fontWeight: 600 }}>
							{'Enviado correctamente'}
						</Text>
					),
					description: (
						<Text
							style={{
								color: '#2F4858',
								fontSize: 14,
								fontWeight: 400
							}}>
							{
								'Se esta procesando la solicitud de envio, recarga la página en unos minutos para ver el estado de tu solicitud'
							}
						</Text>
					),
					duration: 20,
					style: {
						background: '#DFF4D4',
						borderColor: '#52C41A'
					}
				});

				navigate(`/operations/mortgages/${mortgageId}/?tab=5`);
				getRequests();
			})
			.catch((error) => {
				notification.error({
					message: (
						<Text style={{ color: '#BF3A3B', fontSize: 16, fontWeight: 600 }}>
							{'Se ha producido un error'}
						</Text>
					),
					description:
						error.response !== undefined ? (
							<Text
								style={{
									color: '#2F4858',
									fontSize: 14,
									fontWeight: 400
								}}>{`Ha habido un error en el envío. Por favor, abre un ticket con este ID: ${error.response.data.debugId}`}</Text>
						) : (
							<Text
								style={{
									color: '#2F4858',
									fontSize: 14,
									fontWeight: 400
								}}>{`Ha habido un error en el envío. Por favor, rellena de nuevo el mail.`}</Text>
						),
					duration: 0,
					style: {
						background: '#FFDEDE',
						borderColor: '#FF4D4F'
					}
				});
			});
	};

	if (loading) {
		return (
			<div
				style={{
					textAlign: 'center',
					height: '80vh',
					backgroundColor: 'white'
				}}>
				<LoaderLogo />
				<div style={{ display: 'flex', flexDirection: 'column', position: 'relative', top: '56%' }}>
					<Text style={{ fontWeight: 400, fontSize: 14, color: '#2F4858' }}>
						{'Estamos obteniendo los datos de la oferta. Este proceso puede durar unos minutos.'}
					</Text>
					<Text style={{ fontWeight: 600, fontSize: 14, color: '#2F4858' }}>
						{'Por favor, no cierres esta ventana.'}
					</Text>
				</div>
			</div>
		);
	}

	return (
		<div>
			{locationReal !== `/operations/mortgages/${mortgageId}/answerLog` &&
				locationReal !== `/operations/mortgages/${mortgageId}/form-send-bank` &&
				locationReal !== `/operations/mortgages/${mortgageId}/view-document-list` &&
				locationReal !== `/operations/mortgages/${mortgageId}/table-caixaBank` &&
				locationReal !== `/operations/mortgages/${mortgageId}/table-liberBank` &&
				locationReal !== `/operations/mortgages/${mortgageId}/table-santander` &&
				locationReal !== `/operations/mortgages/${mortgageId}/table-evo` &&
				locationReal !== `/operations/mortgages/${mortgageId}/send-to-bank` &&
				locationReal !== `/operations/mortgages/${mortgageId}/email-send-evo` &&
				locationReal !== `/operations/mortgages/${mortgageId}/table-caixa-api` && (
					<>
						<div style={{ marginBottom: 32 }}>
							<Row gutter={24} style={{ margin: 0, alignItems: 'center' }}>
								<Col span={20} style={{ paddingLeft: 0, marginBottom: '24px' }}>
									<BlockedBanksComponent mortgageId={mortgageId} getData={takeSinVinculaciones} />
								</Col>
								<Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}>
									<Button
										disabled={statusJob === 'finish' ? true : false}
										type="primary"
										className="button-primari-gibobs"
										style={{ marginBottom: '20px', padding: '0 10px', width: 'auto' }}
										onClick={() => showSendMessagesModalNew()}>
										Enviar a nuevo banco
									</Button>
								</Col>
							</Row>

							{dataBankRequest &&
								dataBankRequest.map((request, index) => {
									const bankIndex = _.findIndex(dataBankRequest, function (bank) {
										return bank.id === request.id;
									});

									const indexRequest = _.findIndex(requestExtra, function (o) {
										return o.bankRequest == request.id;
									});

									return (
										<Row key={index} gutter={[12, 12]}>
											<Col
												lg={12}
												md={12}
												sm={12}
												xs={12}
												style={{ marginTop: 24, height: 'auto' }}>
												<BankOffers
													bankOffer={request}
													mortgageId={mortgageId}
													mortgageName={mortgageName}
													dataBankRequest={dataBankRequest[bankIndex]}
													changeStadoData={changeStado}
													showSendMessagesModal={showSendMessagesModal}
													registerOffer={(value) => registerOffer(value)}
													loadData={() => getRequests()}
													setBankOffer={setBankOffer}
													setReaderText={setReaderText}
													setLoading={setLoading}
													loading={loading}
													valueRadio={valueRadio}
													setValueRadio={setValueRadio}
												/>
											</Col>
											<Col lg={6} md={6} sm={6} xs={6} style={{ marginTop: 24, height: 'auto' }}>
												<InfoBankOffers
													dataBankRequest={dataBankRequest[bankIndex]}
													mortgageId={mortgageId}
													banksOffer={request}
													requestExtra={_.get(requestExtra, indexRequest)}
													numberRequest={requestExtra !== undefined ? requestExtra.length : 0}
													showModalEmail={() => showSendMessagesModal()}
													onRefresh={() => {
														setRefreshFlag(!refreshFlag);
													}}
												/>
											</Col>
											<Col lg={6} md={6} sm={6} xs={6} style={{ marginTop: 24 }}>
												<InfoBankOffersClaim
													dataBankRequest={dataBankRequest[bankIndex]}
													request={request}
													mortgageId={mortgageId}
													mortgageName={mortgageName}
													loadData={() => {
														setRefreshFlag(!refreshFlag);
													}}
												/>
											</Col>
										</Row>
									);
								})}
						</div>

						{dataBankRequest && (
							<OfertsTableNewStyle
								userMortgageId={mortgageId}
								mortgage={mortgage}
								goBankHp={goBankHp}
								loadData={() => loadData()}
								mortgageInformation={type}
								setMortgageInformation={setType}
							/>
						)}
					</>
				)}
			{locationReal === `/operations/mortgages/${mortgageId}/send-to-bank` && (
				<>
					<ControllerApiEmailCreate
						onCreate={sendBankEmail}
						onCancel={hideAddTaskModal}
						mortgageName={mortgageName}
						mortgage={mortgage}
						channel={type}
						mortgageId={mortgageId}
						bankRequestId={bankRequestNow}
						Conto
						setBankRequestData={setBankRequestData}
						bankRequest={bankRequestData}
						mortgageData={type}></ControllerApiEmailCreate>
				</>
			)}
			{locationReal === `/operations/mortgages/${mortgageId}/form-send-bank` && (
				<>
					<FormPageSendBank
						onCreate={sendBankEmail}
						onCancel={hideAddTaskModal}
						mortgageName={mortgageName}
						mortgage={mortgage}
						showSendMessagesHTML={showSendMessagesHTML}
						type={type && type.subtype}
						setBankRequestNow={setBankRequestNow}
						setBankRequestData={setBankRequestData}></FormPageSendBank>
				</>
			)}
			{locationReal === `/operations/mortgages/${mortgageId}/view-document-list` && (
				<>
					<ViewDocumentList
						showModalEmail={showSendMessagesModal}
						mortgage={mortgage}
						mortgageId={mortgageId}></ViewDocumentList>
				</>
			)}
			{/*locationReal === `/operations/mortgages/${mortgageId}/table-caixaBank` && (
				<>
					<TableCaixaBank
						mortgageId={mortgageId}
						type={type}
						showModalEmail={showSendMessagesModal}
						mortgage={mortgage}></TableCaixaBank>
				</>
			)*/}
			{locationReal === `/operations/mortgages/${mortgageId}/table-caixa-api` && (
				<>
					<TableCaixaBankApi
						mortgageId={mortgageId}
						type={type}
						showModalEmail={showSendMessagesModal}
						mortgage={mortgage}></TableCaixaBankApi>
				</>
			)}
			{locationReal === `/operations/mortgages/${mortgageId}/table-liberBank` && (
				<>
					<TableLiberBank
						mortgageId={mortgageId}
						showModalEmail={showSendMessagesModal}
						mortgage={mortgage}></TableLiberBank>
				</>
			)}
			{locationReal === `/operations/mortgages/${mortgageId}/table-santander` && (
				<>
					<TableSantander
						mortgageId={mortgageId}
						showModalEmail={showSendMessagesModal}
						type={type}
						mortgage={mortgage}></TableSantander>
				</>
			)}
			{locationReal === `/operations/mortgages/${mortgageId}/table-evo` && (
				<>
					<TableEvo
						mortgageId={mortgageId}
						showModalEmail={showSendMessagesModal}
						mortgage={mortgage}></TableEvo>
				</>
			)}
			{locationReal === `/operations/mortgages/${mortgageId}/email-send-evo` && (
				<EmailPageSendBank
					onCreate={sendBankEmail}
					onCancel={hideAddTaskModal}
					mortgageName={mortgageName}
					mortgage={mortgage}
					channel={type}
					mortgageId={mortgageId}></EmailPageSendBank>
			)}
			{detailsNotFound && (
				<Modal
					className="gb-modal-style-common modal-task-button-style"
					cancelButtonProps={{ className: 'button-secundary-gibobs', style: { display: 'none' } }}
					okButtonProps={{ className: 'button-primari-gibobs', style: { width: '96px' } }}
					title={'Datos incompletos'}
					open={showDetailsNotFound}
					width={591}
					onOk={() => {
						setShowDetailsNotFound(!showDetailsNotFound);
					}}
					okText={'Aceptar'}
					closable={false}>
					<div>
						<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
							Todavía no puedes enviar la operación al banco porque tiene datos pendientes de
							completar:
						</Text>
					</div>
					<Row>
						{detailsNotFound.mortgage !== undefined && (
							<Col span={7}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{renderDataMortgage()}
								</div>
							</Col>
						)}
						{detailsNotFound.subrogation !== undefined && (
							<Col span={7}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{renderDataSubrogation()}
								</div>
							</Col>
						)}
						<Col span={6}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>{renderDataOwner()}</div>
						</Col>
						<Col span={6}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{renderDataGuarantor()}
							</div>
						</Col>
					</Row>
				</Modal>
			)}
		</div>
	);
};
export default PageBank;

import React, { useEffect } from 'react';
import { Typography, Col, Row, notification } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';

const { Text } = Typography;

const CriteriaBanks = ({ mortgage, mortgageAmount, ltv, deb, type, value, setTextDescription, setBankName, years }) => {
	useEffect(() => {
		//evo
		if (value === 'a021t00000KGFCuAAP') {
			criteriaBankEvo();
		}
		//ibercaja
		if (value === 'a021t000009Oj00AAC') {
			criteriaBankIbercaja();
		}
        if (value !== 'a021t000009Oj00AAC' && value === 'a021t00000KGFCuAAP') {
            setTextDescription([])
        }
        setBankName(undefined)
	}, []);

	const criteriaBankIbercaja = () => {
		let textDescription = [];
		let incomes = 0;
		let incomesTotals = 0;
		let incomesTotalsBy5 = 0;
		let incomesMonth = 0;
		let today = moment(new Date());

		
		if (mortgageAmount < 70000 && type === 'mortgage') {
			textDescription.push({
				text: (
					<Text>
						{'- Importe solicitado inferior a 70.000 €'}
						<br />
					</Text>
				)
			});
		}

		if ( mortgageAmount < 80000 && type === 'mortgage' && years > 20) {
			textDescription.push({
				text: (
					<Text>
						{'- Para importes menores de 80.000 €, el plazo máximo en Ibercaja es de 20 años'}
						<br />
					</Text>
				)
			});
		}


		if (mortgage?.subrogation?.amount < 70000 && type === 'subrogation') {
			textDescription.push({
				text: (
					<Text>
						{'- Importe solicitado inferior a 70.000 €'}
						<br />
					</Text>
				)
			});
		}

		if ( mortgage?.subrogation?.amount < 80000 && type === 'subrogation' && years > 20) {
			textDescription.push({
				text: (
					<Text>
						{'- Para importes menores de 80.000 €, el plazo máximo en Ibercaja es de 20 años'}
						<br />
					</Text>
				)
			});
		}

		for (let index = 1; index <= mortgage.mortgage.participants; index++) {
			incomes =
				mortgage[`owner${index}`].netIncomes * mortgage[`owner${index}`].numberOfPayments + incomes;
			incomesMonth = mortgage[`owner${index}`].netIncomes + incomesMonth;
		}
		incomesTotals = incomes;
		incomes = incomes * 5.25;
		incomesTotalsBy5 = incomesTotals * 5;

		if (type === 'mortgage') {
			if (mortgage.owner1.cityzenship !== 'ES') {
				if (parseFloat(ltv) > 50) {
					textDescription.push({
						text: (
							<Text>
								{'- Hipoteca no residente mayor al 50% de LTV'}
								<br />
							</Text>
						)
					});
				}
				if (parseFloat(deb) > 25) {
					textDescription.push({
						text: (
							<Text>
								{'- Hipoteca no residente mayor al 25% de RdE'}
								<br />
							</Text>
						)
					});
				}
				if (mortgageAmount < 90000) {
					textDescription.push({
						text: (
							<Text>
								{'- Hipoteca no residente inferior a 90.000 €. Plazo máximo 15 años'}
								<br />
							</Text>
						)
					});
				}
				if (mortgageAmount >= 90000) {
					textDescription.push({
						text: (
							<Text>
								{'- Hipoteca no residente superior a 90.000 €. Plazo máximo 20 años'}
								<br />
							</Text>
						)
					});
				}
			} else if (mortgage.owner1.cityzenship === 'ES') {
				let ages = [];
				let tmpAge = undefined;
				for (let index = 1; index <= mortgage.mortgage.participants; index++) {
					ages.push({ age: mortgage[`owner${index}`].age });
				}

				tmpAge = _.find(ages, function (a) {
					return a.age <= 35;
				});

				for (let index = 1; index <= mortgage.mortgage.participants; index++) {
					if (tmpAge !== undefined && mortgageAmount < 500000) {
						if (parseFloat(ltv) > 95) {
							textDescription.push({
								text: (
									<Text>
										{'- Hipoteca joven mayor al 95% de LTV'}
										<br />
									</Text>
								)
							});
						}
						if (mortgage.mortgage.participants === 1 && parseFloat(deb) > 25) {
							textDescription.push({
								text: (
									<Text>
										{'- Hipoteca joven unipersonal mayor al 25% de RdE'}
										<br />
									</Text>
								)
							});
						}
						if (mortgage.mortgage.participants === 1 && mortgage[`owner${index}`].netIncomes < 1700) {
							textDescription.push({
								text: (
									<Text>
										{'- Hipoteca joven unipersonal con ingresos mensuales inferiores a 1700 €'}
										<br />
									</Text>
								)
							});
						}
						if (mortgage.mortgage.participants > 1 && parseFloat(deb) < 30) {
							textDescription.push({
								text: (
									<Text>
										{'- Hipoteca joven varios titulares mayor al 30% de RdE'}
										<br />
									</Text>
								)
							});
						}
						if (mortgage.mortgage.participants > 1 && mortgage[`owner${index}`].netIncomes < 2500) {
							textDescription.push({
								text: (
									<Text>
										{'- Hipoteca joven varios titulares con ingresos mensuales inferiores a 2500€'}
										<br />
									</Text>
								)
							});
						}
					} else if (mortgage[`owner${index}`].age > 35 || mortgageAmount >= 500000) {
						if (mortgage.mortgage.houseType === 'main') {
							if (parseFloat(ltv) > 90) {
								textDescription.push({
									text: (
										<Text>
											{'- Hipoteca de primera vivienda por encima del 90% de LTV'}
											<br />
										</Text>
									)
								});
							}

							if (parseFloat(ltv) >= 80 && parseFloat(ltv) <= 90 && parseFloat(deb) > 25) {
								textDescription.push({
									text: (
										<Text>
											{
												'- Hipoteca de primera vivienda por encima del 80% de LTV y de un 25% de RdE'
											}
											<br />
										</Text>
									)
								});
							}

							if (parseFloat(ltv) >= 80 && parseFloat(ltv) <= 90 && parseFloat(deb) > 25) {
								textDescription.push({
									text: (
										<Text>
											{
												'- Hipoteca de primera vivienda por encima del 80% de LTV y de un 25% de RdE'
											}
											<br />
										</Text>
									)
								});
							}

							if (incomesTotals <= 30000 && parseFloat(deb) > 30) {
								textDescription.push({
									text: (
										<Text>
											{'- Hipoteca con RdE>30% con ingresos inferiores a 30.000 € anuales'}
											<br />
										</Text>
									)
								});
							}
							if (incomesTotals <= 30000 && parseFloat(deb) > 35) {
								textDescription.push({
									text: (
										<Text>
											{'- Hipoteca con RdE>35%'}
											<br />
										</Text>
									)
								});
							}
						} else if (mortgage.mortgage.houseType === 'second') {
							if (parseFloat(ltv) > 70) {
								textDescription.push({
									text: (
										<Text>
											{
												'- Hipoteca de segunda residencia por encima del 70% de LTV. Comprobar si tienen propiedad libre o amortizada al 50%'
											}
											<br />
										</Text>
									)
								});
							}
							if (parseFloat(deb) > 30) {
								textDescription.push({
									text: (
										<Text>
											{
												'- Hipoteca de segunda residencia por encima del 30% de RdE. . Comprobar si tienen propiedad libre o amortizada al 50%'
											}
											<br />
										</Text>
									)
								});
							}
						} else {
							if (parseFloat(ltv) > 50) {
								textDescription.push({
									text: (
										<Text>
											{'- Hipoteca para inversión por encima del 50% de LTV'}
											<br />
										</Text>
									)
								});
							}
							if (parseFloat(deb) > 30) {
								textDescription.push({
									text: (
										<Text>
											{'- Hipoteca para inversión por encima del 30% de RdE'}
											<br />
										</Text>
									)
								});
							}
						}
					}
				}

				if (mortgage.mortgage.participants === 1 && mortgage.owner1.netIncomes < 1200) {
					textDescription.push({
						text: (
							<Text>
								{'- Hipoteca con un titular y renta mensual inferior a 1200€'}
								<br />
							</Text>
						)
					});
				}
				if (mortgage.mortgage.participants >= 2 && incomesMonth < 1800) {
					textDescription.push({
						text: (
							<Text>
								{'- Hipoteca con varios titulares y renta mensual inferior a 1800€'}
								<br />
							</Text>
						)
					});
				}
			} else {
				if (
					mortgageAmount >= 300000 ||
					(mortgage.mortgage.amount >= 350000 && incomesTotals >= 60000)
				) {
					if (mortgage.mortgage.houseType === 'main' && parseFloat(ltv) > 90) {
						textDescription.push({
							text: (
								<Text>
									{'- Hipoteca premium de primera vivienda por encima del 90% de LTV'}
									<br />
								</Text>
							)
						});
					}
					if (mortgage.mortgage.houseType !== 'main' && parseFloat(ltv) > 70) {
						textDescription.push({
							text: (
								<Text>
									{'- Hipoteca premium de segunda residencia u otros por encima del 70% de LTV'}
									<br />
								</Text>
							)
						});
					}
				}
			}
		}

		if (type === 'subrogation') {
			if (mortgage.owner1.cityzenship !== 'ES') {
				textDescription.push({
					text: (
						<Text>
							{'- Subrogación de no residente'}
							<br />
						</Text>
					)
				});
			}
			if (mortgage.subrogation.amount >= 0.8 * mortgage.subrogation.maxBudget) {
				textDescription.push({
					text: (
						<Text>
							{'-Subrogación por encima del 80% de valor de CV'}
							<br />
						</Text>
					)
				});
			}
			if (parseFloat(deb) > 30) {
				textDescription.push({
					text: (
						<Text>
							{'- Subrogación por encima del 30% de RdE'}
							<br />
						</Text>
					)
				});
			}
			if (today.diff(mortgage.subrogation.dateSign, 'years') < 3) {
				textDescription.push({
					text: (
						<Text>
							{'-  Subrogación con fecha de CV inicial inferior a 3 años'}
							<br />
						</Text>
					)
				});
			}
		}

        setTextDescription(textDescription)
		//ibercaja
		if (textDescription.length > 0) {
			notification.open({
				message: (
					<Row>
						<Col span={1}>
							<WarningOutlined style={{ color: '#FAAD14', fontSize: 20 }} />
						</Col>
						<Col span={20} style={{ marginLeft: 20 }}>
							<Text style={{ color: '#BC820F', fontSize: 14, fontWeight: 600 }}>
								{'Parece que la operación no cumple los siguientes criterios de riesgos:'}
							</Text>
						</Col>
					</Row>
				),
				description: (
					<Row>
						<Col span={2}></Col>
						<Col span={22}>
							<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
								{textDescription.map((item) => {
									return item.text;
								})}
							</Text>
						</Col>
					</Row>
				),
				className: 'gb-notification-warning',
				style: {
					width: 260,
					background: '#fef0d3',
					padding: 16
				},
				duration: 0
			});
		}
        if (textDescription.length === 0) {
            notification.open({
				message: (
					<Row>
						<Col span={1}>
							<img src={'/images/handUp.png'} alt='handUp' style={{ width: 20, height:20 }} />
						</Col>
						<Col span={20} style={{ marginLeft: 20 }}>
							<Text style={{ color: '#3E9314', fontSize: 14, fontWeight: 600 }}>
								{'La operación cumple con todos los criterios de riesgos'}
							</Text>
						</Col>
					</Row>
				),
				
				className: 'gb-notification-warning',
				style: {
					width: 248,
					background: '#DFF4D4',
                    borderColor: "1px solid  #52C41A",
					padding: 16,
                    borderRadius: 4

				},
				duration: 0
			});
        }
	};

	const criteriaBankEvo = () => {
		let textDescription = [];
		let incomes = 0;
		let incomesTotals = 0;
		let incomesTotalsBy5 = 0;
		let incomesMonth = 0;
		if (mortgage?.subrogation?.province === 'Ceuta' || mortgage?.subrogation?.province === 'Melilla') {
			textDescription.push({
				text: (
					<Text>
						{'- Vivienda situada en Ceuta o Melilla'}
						<br />
					</Text>
				)
			});
		}

		if (mortgage?.mortgage?.province === 'Ceuta' || mortgage?.mortgage?.province === 'Melilla') {
			textDescription.push({
				text: (
					<Text>
						{'- Vivienda situada en Ceuta o Melilla'}
						<br />
					</Text>
				)
			});
		}
		if (mortgage.owner1.cityzenship !== 'ES') {
			textDescription.push({
				text: (
					<Text>
						{'- T1 no residente en España'}
						<br />
					</Text>
				)
			});
		}

		for (let index = 1; index <= mortgage.mortgage.participants; index++) {
			incomes =
				mortgage[`owner${index}`].netIncomes * mortgage[`owner${index}`].numberOfPayments + incomes;
			incomesMonth = mortgage[`owner${index}`].netIncomes + incomesMonth;
		}
		incomesTotals = incomes;
		incomes = incomes * 5.25;
		incomesTotalsBy5 = incomesTotals * 5;

		if (mortgageAmount > incomes) {
			textDescription.push({
				text: (
					<Text>
						{
							'- Operación de financiación superior a 5,25 veces el ingreso anual de la unidad familiar'
						}
						<br />
					</Text>
				)
			});
		}

		if (mortgage.mortgage?.selfPromoter) {
			textDescription.push({
				text: (
					<Text>
						{'- EVO no trabaja con autopromociones'}
						<br />
					</Text>
				)
			});
		}

		if (type === 'mortgage') {
			if (mortgageAmount <= 50000 || mortgage.mortgage.amount <= 80000 ||  mortgage.mortgage.maxBudget <= 80000) {
				if (mortgage.owner1.netIncomes < 1500) {
					textDescription.push({
						text: (
							<Text>
								{'- Hipoteca de bajo importe con ingresos inferiores a 1500 €'}
								<br />
							</Text>
						)
					});
				}
				if (parseFloat(deb) > 35) {
					textDescription.push({
						text: (
							<Text>
								{'- Hipoteca de bajo importe con ratio de endeudamiento mayor a 35%'}
								<br />
							</Text>
						)
					});
				}
				if (mortgageAmount > incomesTotalsBy5) {
					textDescription.push({
						text: (
							<Text>
								{
									'- Hipoteca de bajo importe con financiación mayor a 5 veces el ingreso anual familiar'
								}
								<br />
							</Text>
						)
					});
				}
			} else if (
				mortgageAmount < 300000 &&
				(mortgage.mortgage.amount < 350000 || mortgage.mortgage.maxBudget < 350000) &&
				incomesTotals < 60000
			) {
				if (mortgage.mortgage.houseType === 'main' && parseFloat(ltv) > 80) {
					textDescription.push({
						text: (
							<Text>
								{'- Hipoteca de primera vivienda por encima del 80% de LTV'}
								<br />
							</Text>
						)
					});
				}
				if (mortgage.mortgage.houseType !== 'main' && parseFloat(ltv) > 60) {
					textDescription.push({
						text: (
							<Text>
								{'- Hipoteca de segunda residencia u otros por encima del 60% de LTV'}
								<br />
							</Text>
						)
					});
				}
				if (mortgage.mortgage.participants === 1 && mortgage.owner1.netIncomes < 1200) {
					textDescription.push({
						text: (
							<Text>
								{'- Hipoteca con un titular y renta mensual inferior a 1200€'}
								<br />
							</Text>
						)
					});
				}
				if (mortgage.mortgage.participants >= 2 && incomesMonth < 1800) {
					textDescription.push({
						text: (
							<Text>
								{'- Hipoteca con varios titulares y renta mensual inferior a 1800€'}
								<br />
							</Text>
						)
					});
				}
			} else {
				if (
					mortgageAmount >= 300000 ||
					(mortgage.mortgage.amount >= 350000 && incomesTotals >= 60000)
				) {
					if (mortgage.mortgage.houseType === 'main' && parseFloat(ltv) > 90) {
						textDescription.push({
							text: (
								<Text>
									{'- Hipoteca premium de primera vivienda por encima del 90% de LTV'}
									<br />
								</Text>
							)
						});
					}
					if (mortgage.mortgage.houseType !== 'main' && parseFloat(ltv) > 70) {
						textDescription.push({
							text: (
								<Text>
									{'- Hipoteca premium de segunda residencia u otros por encima del 70% de LTV'}
									<br />
								</Text>
							)
						});
					}
				}
			}
		}

		if (type === 'subrogation') {
			if (mortgage.subrogation.mortgageCurrentBank === 'a021t00000Jm4QOAAZ') {
				textDescription.push({
					text: (
						<Text>
							{'- Hipoteca original en Bankinter: no puede subrogar en EVO Banco'}
							<br />
						</Text>
					)
				});
			} else if (mortgage.subrogation.amount <= 50000) {
				if (incomesMonth < 1500) {
					textDescription.push({
						text: (
							<Text>
								{'- Subrogación de bajo importe con ingresos inferiores a 1500 €'}
								<br />
							</Text>
						)
					});
				}

				if (mortgage.subrogation.amount > incomesTotalsBy5) {
					textDescription.push({
						text: (
							<Text>
								{
									'- Subrogación de bajo importe con con financiación mayor a 5 veces el ingreso anual familiar'
								}
								<br />
							</Text>
						)
					});
				}
			} else if (mortgage.subrogation.amount < 300000 && incomesTotals < 60000) {
				if (mortgage.subrogation.amount >= 0.8 * mortgage.subrogation.maxBudget) {
					textDescription.push({
						text: (
							<Text>
								{
									'- Subrogación con un capital pendiente por encima del 80% del valor de compra-venta'
								}
								<br />
							</Text>
						)
					});
				}
				if (mortgage.mortgage.participants === 1 && mortgage.owner1.netIncomes < 1200) {
					textDescription.push({
						text: (
							<Text>
								{'- Subrogación con un titular y renta mensual inferior a 1200€'}
								<br />
							</Text>
						)
					});
				}
				if (mortgage.mortgage.participants >= 2 && incomesMonth < 1800) {
					textDescription.push({
						text: (
							<Text>
								{'- Subrogación con varios titulares y renta mensual inferior a 1800€'}
								<br />
							</Text>
						)
					});
				}
			} else {
				if (mortgage.subrogation.amount >= 300000 || incomesTotals >= 60000) {
					if (mortgage.subrogation.amount >= 0.8 * mortgage.subrogation.maxBudget) {
						textDescription.push({
							text: (
								<Text>
									{
										'- Subrogación con un capital pendiente por encima del 80% del valor de compra-venta'
									}
									<br />
								</Text>
							)
						});
					}
				}
			}
		}
        setTextDescription(textDescription)

		//Evo
		if (textDescription.length > 0) {
			notification.open({
				message: (
					<Row>
						<Col span={1}>
							<WarningOutlined style={{ color: '#FAAD14', fontSize: 20 }} />
						</Col>
						<Col span={20} style={{ marginLeft: 20 }}>
							<Text style={{ color: '#BC820F', fontSize: 14, fontWeight: 600 }}>
								{'Parece que la operación no cumple los siguientes criterios de riesgos:'}
							</Text>
						</Col>
					</Row>
				),
				description: (
					<Row>
						<Col span={2}></Col>
						<Col span={22}>
							<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
								{textDescription.map((item) => {
									return item.text;
								})}
							</Text>
						</Col>
					</Row>
				),
				className: 'gb-notification-warning',
				style: {
					width: 260,
					background: '#fef0d3',
					padding: 16
				},
				duration: 0
			});
		}
		if (textDescription.length === 0) {
			notification.open({
				message: (
					<Row>
						<Col span={1}>
							<img src={'/images/handUp.png'} alt="handUp" style={{ width: 20, height: 20 }} />
						</Col>
						<Col span={20} style={{ marginLeft: 20 }}>
							<Text style={{ color: '#3E9314', fontSize: 14, fontWeight: 600 }}>
								{'La operación cumple con todos los criterios de riesgos'}
							</Text>
						</Col>
					</Row>
				),

				className: 'gb-notification-warning',
				style: {
					width: 248,
					background: '#DFF4D4',
					borderColor: '1px solid  #52C41A',
					padding: 16,
					borderRadius: 4
				},
				duration: 0
			});
		}
	};

	return <></>;
};
export default CriteriaBanks;

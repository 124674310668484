import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { useNavigate, NavLink } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import slugify from 'slugify';

function SearchComponent() {
	const navigate = useNavigate();
	const [timeoutReference, setTimeoutReference] = useState(undefined);

	const onChange = (e) => {
		if (timeoutReference) {
			clearTimeout(timeoutReference);
		}

		setTimeoutReference(
			setTimeout(() => {
				const searchText = slugify(e.target.value);
				navigate(`/search/${searchText}`);
			}, 750)
		);
	};

	return (
		<div style={{ display: 'flex', marginLeft: -10 }}>
			<div>
				<Input
					style={{
						minWidth: '500px',
						width: '100%',
						height: 40,
						border: '1px solid #C0DAEE',
						borderRadius: 4
					}}
					onChange={onChange}
					suffix={<SearchOutlined style={{ fontSize: 16, color: '#748EA0' }} />}
					placeholder="Buscar..."
				/>
			</div>

			<NavLink to="/search">
				<Button
					className="button-primari-gibobs"
					type="primary"
					style={{ marginLeft: 24, fontSize: 14, height: 40, marginTop: 16 }}>
					Búsqueda avanzada
				</Button>
			</NavLink>
		</div>
	);
}

export default SearchComponent;

import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Col,
	Row,
	Card,
	Steps,
	Form,
	Modal,
	Select,
	Upload,
	Input,
	Radio,
	Space,
	Tooltip,
	Spin,
	Popover,
	Checkbox,
	message,
	Divider
} from 'antd';
import numeral from 'numeral';
import axios from 'axios';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import {
	UserOutlined,
	PlusOutlined,
	UploadOutlined,
	EyeOutlined,
	CloseOutlined
} from '@ant-design/icons';
import moment from 'moment';
import RegisterAssigment from '../registerAssignment/registerAssigment';
import { useNavigate } from 'react-router-dom';
import BraftEditor from 'braft-editor';
import _ from 'lodash';
import EditorMonaco from '@monaco-editor/react';
import JoditEditor from 'jodit-react';

const { Text } = Typography;
const { Step } = Steps;
const { Option } = Select;
const { TextArea } = Input;

const BankOffers = ({
	mortgageId,
	loadData,
	bankOffer,
	dataBankRequest,
	registerOffer,
	setBankOffer,
	setReaderText,
	setLoading,
	loading,
	valueRadio,
	setValueRadio
}) => {
	const [taskToEdit, setTaskToEdit] = useState(null);
	const [form] = Form.useForm();
	const [registerWithoutOffer, setRegisterWithoutOffer] = useState(null);
	const [fileName, setFileName] = useState('');
	const [fileData, setFileData] = useState();
	const [offerDeniedValue, setOfferDeniedValue] = useState(undefined);
	const [statusOffert, setStatusOffert] = useState(undefined);
	const navigate = useNavigate();
	const [viewResponse, setViewResponse] = useState(undefined);
	const [bankRequestEmail, setBankRequestEmail] = useState(undefined);
	const [editorState, setEditorState] = useState(undefined);
	const [reason, setReason] = useState(undefined);
	const [offerEmpty, setOfferEmpty] = useState(undefined);
	const [cancelSend, setCancelSend] = useState(false);
	const [bankRequestIDSend, setBankRequestIDSend] = useState(undefined);
	const [openPop, setOpenPop] = useState(false);
	const [zoomRequest, setZoomRequest] = useState(undefined);
	const [zoomLoading, setZoomLoading] = useState(false);
	const [zoomLoadingId, setZoomLoadingId] = useState('');
	const [showReloadButtonSantander, setShowReloadButtonSantander] = useState(false);
	const [modalSantander, setModalSantander] = useState(false);
	const [bankRequestSantnader, setBankRequestSantnader] = useState(undefined);
	const [showModalToFile, setShowModalToFile] = useState(false);
	const [bankOffersType, setBankOffersType] = useState(undefined);
	const config = {
		readonly: false, // todas las opciones de https://xdsoft.net/jodit/doc/
		uploader: {
			insertImageAsBase64URI: true // insertar imágenes como URI base64
		},
		toolbarSticky: false,
		spellcheck: true
	};
	useEffect(() => {
		if (dataBankRequest && dataBankRequest.status === 'approved') {
			setStatusOffert(3);
		} else if (dataBankRequest && dataBankRequest.status === 'deny') {
			setStatusOffert(3);
		} else if (dataBankRequest && dataBankRequest.status === 'assigned') {
			setStatusOffert(1);
		} else {
			setStatusOffert(0);
		}
		getOffers();
	}, []);

	useEffect(() => {
		if (offerDeniedValue) {
			getBanksOffersType();
		}
	}, [offerDeniedValue]);

	const getOffers = () => {
		axios
			.get(`${env.api.url}/banks/bank-offer/find-by-operation/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				const offersSantaderFav = _.filter(response.data.data, function (c) {
					return (
						c.bankCode === 'santander' && c.bankRequest?.type === 'API' && c.isFavorite === true
					);
				});
				if (offersSantaderFav.length === 0) {
					setShowReloadButtonSantander(true);
				}
			});
	};

	const getBanksOffersType = () => {
		axios
			.get(`${env.api.url}/banks/bank-offer-type/get-by-bank/${bankOffer.bankId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				if (response.data.data.length > 0) {
					setBankOffersType(response.data.data);
				}
			});
	};

	const onChangeRadio = (e) => {
		setValueRadio(e.target.value);
	};

	const modalGerente = (name, index) => {
		setTaskToEdit({ ...name, bankRequestId: index, operationId: mortgageId });
	};

	const hideAddTaskModal = () => {
		setTaskToEdit(null);
	};

	const viewResponseModal = (bankRequest) => {
		if (bankRequest.customRequest.emailId !== undefined) {
			axios
				.get(`${env.api.url}/v1/hadmin/emails/detail/${bankRequest.customRequest.emailId}`, {
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				})
				.then((response) => {
					setBankRequestEmail(response.data.data);
					if (
						response.data.data.email.managementCode === 'sabadell-es' ||
						bankRequest.bankCode === 'sabadell'
					) {
						setEditorState(response.data.data.email.body);
					} else {
						setEditorState(BraftEditor.createEditorState(response.data.data.email.body));
					}

					setViewResponse(!viewResponse);
				});
		} else {
			setBankRequestEmail(bankRequest.customRequest);

			setViewResponse(!viewResponse);
		}
	};

	const handleSubmitForm = (values, id) => {
		const objAsigment = {
			bankRequestId: id,
			bankAccountData: {
				name: values.name === undefined ? '' : values.name,
				email: values.email,
				phone: values.phone === undefined ? '' : values.phone,
				office: values.office === undefined ? '' : values.office,
				reference: values.reference === undefined ? '' : values.reference
			}
		};
		axios
			.post(`${env.api.url}/banks/bank-request/assign-to-account-executive`, objAsigment, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				loadData();
			});
		setTaskToEdit(null);
	};

	const getStatusTextract = (id) => {
		axios
			.get(
				`${env.api.url}/docs/analyzer/get-status-textract-offer/${id}`,

				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.data === 'ko') {
					setTimeout(() => {
						getStatusTextract(id);
					}, 5000);
				}
				if (response.data.data === 'ok') {
					setLoading(false);
					setRegisterWithoutOffer(false);
					navigate(`/operations/mortgages/${mortgageId}/answerLog`, {
						state: {
							bankOffer: offerEmpty,
							bankId: dataBankRequest.bankId,
							bankRequest: dataBankRequest,
							bankName: dataBankRequest.bankName,
							bankCode: dataBankRequest.bankCode,
							dataBanks: {
								data: undefined,
								bankName: dataBankRequest.bankName,
								bankCode: dataBankRequest.bankCode,
								reader: true
							}
						}
					});
				}
			});
	};

	const sendToDoc = (file, name) => {
		setLoading(true);

		axios
			.post(
				`${env.api.url}/banks/bank-textract-reader/textract-reader`,
				{
					bankRequest: dataBankRequest,
					bankOfferId: offerEmpty.id,
					files: {
						fileName: name,
						file: file
					}
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.data.textractId) {
					getStatusTextract(response.data.data.textractId);
				}
			});
	};

	const uploadProps = {
		beforeUpload: (file) => {
			setFileName(file.name);
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					setFileData(base64File, file.name);
					sendToDoc(base64File, file.name);
				};
			});
		}
	};

	const offerDenied = (value) => {
		setOfferDeniedValue(value);
	};

	const renderStateAsigned = (dataBankRequest) => {
		const arrayState = ['pending', 'send', 'error'];

		if (arrayState.includes(dataBankRequest.status)) {
			return 'send-banks-wait';
		}
		return 'send-banks';
	};

	const deniedOffer = (dataBankRequest, values) => {
		axios
			.post(
				`${env.api.url}/banks/bank-request/deny-offer`,
				{
					bankRequestId: dataBankRequest.id,
					reason: reason,
					comments: values.request
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setRegisterWithoutOffer(false);
				loadData();
			});
	};

	const registerCopyPasteOffer = (values) => {
		axios
			.post(
				`${env.api.url}/banks/bank-regex-reader/regex-reader`,
				{
					bankOfferId: offerEmpty.id,
					text: values.note
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setReaderText(true);
				registerOffer(dataBankRequest);
			});
	};

	const registerDefaultOffer = (values) => {
		axios
			.post(
				`${env.api.url}/banks/bank-offer-type/create`,
				{
					bankOfferId: offerEmpty.id,
					bankOfferTypeId: values.bankOfferTypeId
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				registerOffer(dataBankRequest);
			});
	};

	const handleOkReader = (offerDeniedValue, values) => {
		if (offerDeniedValue) {
			setRegisterWithoutOffer(false);
			if (valueRadio === 2) {
				registerCopyPasteOffer(values);
			} else if (valueRadio === 3) {
				registerDefaultOffer(values);
			} else {
				registerOffer(dataBankRequest);
			}
		} else {
			deniedOffer(dataBankRequest, values);
		}
	};

	const createOfferEmpty = () => {
		axios
			.post(
				`${env.api.url}/banks/bank-offer/create-empty`,
				{
					operationId: mortgageId,
					bankRequestId: dataBankRequest.id
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setBankOffer(response.data.data);
				setOfferEmpty(response.data.data);
				setRegisterWithoutOffer(true);
			});
	};

	const cancelBankRequest = (dataBankRequest) => {
		setCancelSend(true);
		setBankRequestIDSend(dataBankRequest);
	};

	const cancelSendBank = () => {
		axios
			.post(
				`${env.api.url}/banks/bank-request/cancel/${bankRequestIDSend.id}`,
				{},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setCancelSend(false);
				window.location.reload();
			});
	};

	const handleChecks = (e) => {
		setZoomLoading(true);
		setZoomLoadingId(e.target.value);
		const customRequest = _.get(bankOffer, 'customRequest', {});
		const zoom = _.get(bankOffer, 'customRequest.zoom', {});

		_.set(zoom, e.target.value, e.target.checked);
		_.set(zoom, `${e.target.value}Date`, moment().format('YYYY-MM-DD'));

		customRequest.zoom = zoom;

		setTimeout(() => {
			axios
				.post(
					`${env.api.url}/banks/bank-request/update-custom-request/${zoomRequest.id}`,
					{
						...customRequest
					},
					{
						headers: { Authorization: `Bearer ${authClient.getToken()}` }
					}
				)
				.then((response) => {
					setZoomLoading(false);
					if (response.data.data) {
						message.success('Datos guardados correctamente');
					} else {
						message.error('Se ha producido un error al guardar los datos');
					}
				})
				.catch(() => {
					setZoomLoading(false);
					message.error('Se ha producido un error al guardar los datos');
				});
		}, 500);
	};

	const handleOpenChange = (newOpen) => {
		setOpenPop(newOpen);
		setZoomRequest(dataBankRequest);
	};

	const toFileOffers = () => {
		form
			.validateFields()
			.then((values) => {
				handleOkReader(offerDeniedValue, values);
				form.resetFields();
				setShowModalToFile(false);
				window.location.reload();
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	const content = (
		<div>
			<div>
				<Text style={{ color: '#748EA0', fontSize: 10, fontWeight: 500 }}>
					Progreso de la operación{' '}
				</Text>
			</div>
			{zoomRequest !== undefined && (
				<div
					style={{
						width: 550
					}}>
					<Row>
						<Col span={8}>
							<Checkbox
								value="interested"
								onChange={handleChecks}
								disabled={zoomLoading}
								checked={zoomRequest?.customRequest?.zoom?.interested}>
								<Space>
									<Text
										style={{
											fontSize: 10,
											fontWeight: 400,
											color: zoomRequest?.customRequest?.zoom?.interested ? '#2F4858' : '#748EA0'
										}}>
										Interés del cliente
									</Text>
									{zoomLoading && zoomLoadingId === 'interested' && <Spin size="small" />}
								</Space>
							</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox
								value="activationStarted"
								onChange={handleChecks}
								checked={zoomRequest?.customRequest?.zoom?.activationStarted}
								disabled={zoomLoading}>
								<Space>
									<Text
										style={{
											fontSize: 10,
											fontWeight: 500,
											color: zoomRequest?.customRequest?.zoom?.activationStarted
												? '#2F4858'
												: '#748EA0'
										}}>
										Activación iniciada
									</Text>
									{zoomLoading && zoomLoadingId === 'activationStarted' && <Spin size="small" />}
								</Space>
							</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox
								value="activationCompleted"
								onChange={handleChecks}
								checked={zoomRequest?.customRequest?.zoom?.activationCompleted}
								disabled={zoomLoading}>
								<Space>
									<Text
										style={{
											fontSize: 10,
											fontWeight: 500,
											color: zoomRequest?.customRequest?.zoom?.activationCompleted
												? '#2F4858'
												: '#748EA0'
										}}>
										Activación completa
									</Text>
									{zoomLoading && zoomLoadingId === 'activationCompleted' && <Spin size="small" />}
								</Space>
							</Checkbox>
						</Col>
						<Divider style={{ marginBottom: 0, marginTop: 0 }}></Divider>
						<Col span={8}>
							<Checkbox
								value="onRisk"
								onChange={handleChecks}
								checked={zoomRequest?.customRequest?.zoom?.onRisk}
								disabled={zoomLoading}>
								<Space>
									<Text
										style={{
											fontSize: 10,
											fontWeight: 500,
											color: zoomRequest?.customRequest?.zoom?.onRisk ? '#2F4858' : '#748EA0'
										}}>
										Oferta elevada a RIESGOS
									</Text>
									{zoomLoading && zoomLoadingId === 'onRisk' && <Spin size="small" />}
								</Space>
							</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox
								value="riskApproval"
								onChange={handleChecks}
								checked={zoomRequest?.customRequest?.zoom?.riskApproval}
								disabled={zoomLoading}>
								<Space>
									<Text
										style={{
											fontSize: 10,
											fontWeight: 500,
											color: zoomRequest?.customRequest?.zoom?.riskApproval ? '#2F4858' : '#748EA0'
										}}>
										Oferta aprobada por RIESGOS
									</Text>
									{zoomLoading && zoomLoadingId === 'riskApproval' && <Spin size="small" />}
								</Space>
							</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox
								value="appraisalApproval"
								onChange={handleChecks}
								checked={zoomRequest?.customRequest?.zoom?.appraisalApproval}
								disabled={zoomLoading}>
								<Space>
									<Text
										style={{
											fontSize: 10,
											fontWeight: 500,
											color: zoomRequest?.customRequest?.zoom?.appraisalApproval
												? '#2F4858'
												: '#748EA0'
										}}>
										Tasación aprobada
									</Text>
									{zoomLoading && zoomLoadingId === 'appraisalApproval' && <Spin size="small" />}
								</Space>
							</Checkbox>
						</Col>
						<Divider style={{ marginBottom: 0, marginTop: 0 }}></Divider>

						<Col span={8}>
							<Checkbox
								value="feinRequested"
								onChange={handleChecks}
								checked={zoomRequest?.customRequest?.zoom?.feinRequested}
								disabled={zoomLoading}>
								<Space>
									<Text
										style={{
											fontSize: 10,
											fontWeight: 500,
											color: zoomRequest?.customRequest?.zoom?.feinRequested ? '#2F4858' : '#748EA0'
										}}>
										FEIN solicitada
									</Text>
									{zoomLoading && zoomLoadingId === 'feinRequested' && <Spin size="small" />}
								</Space>
							</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox
								value="feinEmitted"
								onChange={handleChecks}
								checked={zoomRequest?.customRequest?.zoom?.feinEmitted}
								disabled={zoomLoading}>
								<Space>
									<Text
										style={{
											fontSize: 10,
											fontWeight: 500,
											color: zoomRequest?.customRequest?.zoom?.feinEmitted ? '#2F4858' : '#748EA0'
										}}>
										FEIN emitida
									</Text>
									{zoomLoading && zoomLoadingId === 'feinEmitted' && <Spin size="small" />}
								</Space>
							</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox
								value="feinSigned"
								onChange={handleChecks}
								checked={zoomRequest?.customRequest?.zoom?.feinSigned}
								disabled={zoomLoading}>
								<Space>
									<Text
										style={{
											fontSize: 10,
											fontWeight: 500,
											color: zoomRequest?.customRequest?.zoom?.feinSigned ? '#2F4858' : '#748EA0'
										}}>
										FEIN firmada
									</Text>
									{zoomLoading && zoomLoadingId === 'feinSigned' && <Spin size="small" />}
								</Space>
							</Checkbox>
						</Col>
					</Row>
				</div>
			)}
		</div>
	);

	const renderButtonReload = (date, dataBankRequest) => {
		let today = moment(new Date());
		const diference = today.diff(moment(date), 'days');

		if (diference >= 30 && showReloadButtonSantander) {
			return (
				<div style={{ marginTop: 10 }}>
					<Tooltip title={'Renovar ofertas'}>
						<img
							src={'/images/reload.png'}
							alt="reload"
							style={{ width: 16, height: 16, cursor: 'pointer' }}
							onClick={() => {
								setModalSantander(true);
								setBankRequestSantnader(dataBankRequest);
							}}></img>
					</Tooltip>
				</div>
			);
		}
	};

	const sendtoReload = () => {
		setModalSantander(false);
		axios
			.post(
				`${env.api.url}/banks/santander/assesment/renovate-offers`,
				{
					bankRequestId: bankRequestSantnader.id
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				loadData();
			});
	};

	const renderNumer = (zoom) => {
		const values = _.toArray(zoom);
		const valuesTrues = _.filter(values, function (o) {
			return o === true;
		});
		return valuesTrues.length;
	};

	if (loading) {
		return (
			<div style={{ textAlign: 'center', height: '100vh' }}>
				<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
			</div>
		);
	}
	return (
		<>
			<Card
				style={{ border: '1px solid #C0DAEE', borderRadius: 4, minHeight: 175, height: '100%' }}>
				<Row>
					<Col span={9} style={{ textAlign: 'center' }}>
						<img
							src={
								'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
								`${bankOffer.bankCode}` +
								'.png'
							}
							alt={bankOffer.bankName}
							style={{ height: '24px', width: 'auto' }}
						/>
					</Col>
					<Col span={9}>
						<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>Solicitado:</Text>
						&nbsp;
						<Text style={{ color: '#02C3CD', fontSize: 12, fontWeight: 500 }}>
							{numeral(bankOffer.amount).format('0,0 $')}
						</Text>
						&nbsp;
						<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
							{'a ' + bankOffer.months / 12 + ' años'}
						</Text>
					</Col>
					<Col span={3}>
						{dataBankRequest.hasGuarantor && (
							<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>{'Avalista'}</Text>
						)}
					</Col>
					{dataBankRequest.status !== 'canceled' && (
						<Col span={3} style={{ justifyContent: 'right', display: 'flex' }}>
							<div onClick={() => viewResponseModal(bankOffer)} style={{ cursor: 'pointer' }}>
								<EyeOutlined style={{ color: '#02C3CD' }} />
							</div>
							{dataBankRequest && dataBankRequest.status !== 'error' && (
								<div
									onClick={() => modalGerente(bankOffer.bankAccountData, bankOffer.id)}
									style={{ cursor: 'pointer', marginLeft: 8 }}>
									<UserOutlined style={{ color: '#02C3CD' }} />
								</div>
							)}
							{/*
                    
                    
                    */}
							{dataBankRequest && dataBankRequest.status !== 'error' && (
								<div
									onClick={() => createOfferEmpty()}
									style={{ marginLeft: 10, cursor: 'pointer' }}>
									<PlusOutlined style={{ color: '#02C3CD' }} />
								</div>
							)}
						</Col>
					)}
				</Row>
				<Row style={{ marginTop: 30 }}>
					<Col lg={12} md={12} sm={18} xs={24}>
						<div>
							<Steps
								size="small"
								className="gb-steps-styles-common-email-banks gb-banks"
								current={statusOffert}
								style={{ marginLeft: 20 }}>
								{dataBankRequest.status === 'error' && (
									<Step
										style={{ marginLeft: -20 }}
										title=""
										description={
											<div style={{ marginLeft: -80, marginTop: -7 }}>
												{'ERROR'}
												<div className="step-date">
													{dataBankRequest.assignDate !== null
														? moment(dataBankRequest.assignDate).format('DD-MM-YYYY')
														: undefined}
												</div>
											</div>
										}
										className={'send-banks-error'}
									/>
								)}

								{dataBankRequest.status !== 'error' && (
									<Step
										style={{ marginLeft: -20 }}
										title=""
										description={
											<div style={{ marginLeft: -80, marginTop: -7 }}>
												{dataBankRequest.status === 'pending' ? 'Enviando...' : 'Enviada'}
												<div className="step-date">
													{dataBankRequest.createdAt !== null
														? moment(dataBankRequest.createdAt).format('DD-MM-YYYY')
														: undefined}
												</div>
											</div>
										}
										className={
											dataBankRequest.status === 'pending' ? 'send-banks-pending' : 'send-banks'
										}
									/>
								)}
								<Step
									style={{ marginLeft: -20 }}
									title=""
									description={
										<div style={{ marginLeft: -80, marginTop: -7 }}>
											Asignada
											<div className="step-date">
												{dataBankRequest.assignDate !== null
													? moment(dataBankRequest.assignDate).format('DD-MM-YYYY')
													: undefined}
											</div>
										</div>
									}
									className={renderStateAsigned(dataBankRequest)}
								/>

								<Step
									title=""
									description={
										<div style={{ marginLeft: -80, marginTop: -7 }}>
											{dataBankRequest.status === 'deny' ? (
												<Tooltip
													placement="right"
													title={
														<div style={{ display: 'flex', flexDirection: 'column' }}>
															<span>{dataBankRequest.reason}</span>
															<span>{dataBankRequest.comments}</span>
														</div>
													}
													style={{ marginTop: -5, marginRight: -5, minWidth: 10 }}>
													{'Denegada'}
												</Tooltip>
											) : (
												'Contestada'
											)}
											<div className="step-date">
												{dataBankRequest.responseDate !== null
													? moment(dataBankRequest.responseDate).format('DD-MM-YYYY')
													: undefined}
											</div>
											{bankOffer.bankCode === 'santander' &&
												renderButtonReload(dataBankRequest.responseDate, dataBankRequest)}
										</div>
									}
									className={
										dataBankRequest.status === 'deny'
											? 'send-banks-denied'
											: dataBankRequest.status === 'approved'
											? 'send-banks-answered'
											: 'send-banks-wait'
									}
								/>
							</Steps>
						</div>
						{dataBankRequest.status === 'error' && (
							<Text style={{ fontSize: 10, color: '#ff4d4f', fontWeight: 400 }}>
								{'Se ha producido un error en el envio del email, por favor contacte con tecnología con el código: ' +
									dataBankRequest.id}
							</Text>
						)}
					</Col>

					<Col lg={12} md={12} sm={18} xs={24} style={{ marginTop: 10 }}>
						<div style={{ textAlign: 'right' }}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
									{dataBankRequest.bankAccountData.name ? dataBankRequest.bankAccountData.name : ''}
								</Text>
								<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 500, marginLeft: 8 }}>
									{dataBankRequest.bankAccountData.email
										? dataBankRequest.bankAccountData.email
										: ''}
								</Text>
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
									{dataBankRequest.bankAccountData.office
										? dataBankRequest.bankAccountData.office
										: ''}
								</Text>
							</div>
						</div>
						{dataBankRequest.status !== 'canceled' && (
							<div
								onClick={(e) => cancelBankRequest(dataBankRequest)}
								style={{ textAlign: 'right', cursor: 'pointer', marginTop: 5 }}>
								<CloseOutlined style={{ color: 'red', fontSize: 16 }} />
							</div>
						)}
						{dataBankRequest.status === 'canceled' && (
							<div style={{ textAlign: 'right', marginTop: 5 }}>
								<Text style={{ background: '#FFC1B4', color: 'red', padding: 4 }}>Cancelada</Text>
							</div>
						)}
					</Col>
				</Row>
				<Row>
					<Tooltip title={'Registros de progreso'}>
						<Popover
							open={openPop}
							onOpenChange={handleOpenChange}
							content={content}
							trigger="click">
							<div
								style={{
									color: '#FF7A7B',
									background: '#FF7A7B',
									width: 40,
									height: 20,
									borderTopLeftRadius: '4px',
									borderBottomRightRadius: '4px',
									display: 'flex',
									placeContent: 'center',
									position: 'absolute',
									bottom: 0,
									right: 0,
									opacity: '70%'
								}}>
								{openPop === false && (
									<>
										<Text style={{ color: 'white', fontSize: 12, fontWeight: 500 }}>
											{bankOffer?.customRequest?.zoom !== undefined
												? renderNumer(bankOffer?.customRequest?.zoom)
												: 0}
										</Text>
										<img
											src={'/images/star.png'}
											alt="start"
											style={{ width: 14, height: 14, marginTop: 2, marginLeft: 4 }}></img>
									</>
								)}
								{openPop && (
									<>
										<CloseOutlined style={{ color: 'white', fontSize: 20 }}></CloseOutlined>
									</>
								)}
							</div>
						</Popover>
					</Tooltip>
				</Row>
			</Card>
			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96, marginLeft: 20 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				title={
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						{'Registro de respuesta'}
					</Text>
				}
				closable={true}
				open={registerWithoutOffer}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							if (valueRadio !== 3) {
								delete values.bankOfferTypeId;
							}
							if (values.request && bankOffer.status === 'approved') {
								setShowModalToFile(true);
							} else {
								handleOkReader(offerDeniedValue, values);
								form.resetFields();
							}
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}
				centered
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				onCancel={() => {
					form.resetFields();
					setRegisterWithoutOffer(false);
				}}
				width={offerDeniedValue ? 1036 : 500}>
				<Form
					form={form}
					layout="horizontal"
					name="basic"
					//onFinish={onFinish}
				>
					<Row gutter={24} style={{ marginBottom: -10 }}>
						<Col lg={24}>
							<Form.Item
								style={{ display: 'table-caption', flexDirection: 'column' }}
								label={''}
								name="request">
								<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
									{'Presenta oferta'}
								</Text>
								<Select
									onChange={(value) => offerDenied(value)}
									placeholder="Seleccione"
									style={{
										width: offerDeniedValue ? 241 : 420,
										height: 32,
										borderColor: '#C0DAEE'
									}}>
									<Option value={true}>Si</Option>
									<Option value={false}>No</Option>
								</Select>
							</Form.Item>

							{!offerDeniedValue && (
								<div style={{ marginTop: -16 }}>
									<Form.Item
										style={{ display: 'table-caption', flexDirection: 'column' }}
										label={''}
										name="reason">
										<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
											{'Motivo de la denegación'}
										</Text>
										<Select
											placeholder="Seleccione"
											onChange={(values) => setReason(values)}
											style={{
												width: offerDeniedValue ? 241 : 420,
												height: 32,
												borderColor: '#C0DAEE'
											}}>
											<Option value="Ahorros insuficientes">1. Ahorros insuficientes</Option>
											<Option value="Antigüedad laboral">2. Antigüedad laboral</Option>
											<Option value="Edad">3. Edad</Option>
											<Option value="Incidencias en ASNEF">4. Incidencias en ASNEF</Option>
											<Option value="Inviable en bancos">
												5. Inviable en bancos (Tipo de vivienda)
											</Option>
											<Option value="Nacionalidad">6. Nacionalidad</Option>
											<Option value="Operación ya presentada por el cliente">
												7. Operación ya presentada por el cliente
											</Option>
											<Option value="Operación ya presentada por otro prescriptor">
												8. Operación ya presentada por otro prescriptor
											</Option>
											<Option value="Ratio de endeudamiento">9. Ratio de endeudamiento</Option>
											<Option value="Riesgo de divisa">10. Riesgo de divisa</Option>
											<Option value="Riesgo reputacional">11. Riesgo reputacional</Option>
											<Option value="Subrogación - Poca antigüedad de hipoteca">
												12. Subrogación - Poca antigüedad de hipoteca
											</Option>
											<Option value="Subrogación - Poco capital amortizado">
												13. Subrogación - Poco capital amortizado
											</Option>
											<Option value="Tipo de contrato">14. Tipo de contrato</Option>

											<Option value="Ingresos demasiado bajos">15. Ingresos demasiado bajos</Option>
											<Option value="Importe de hipoteca demasiado bajo">
												16. Importe de hipoteca demasiado bajo
											</Option>
											<Option value="Política bancaria">
												17. Política bancaria (no nos dan detalles)
											</Option>
										</Select>
									</Form.Item>

									<Form.Item
										style={{ display: 'table-caption', flexDirection: 'column', marginTop: -10 }}
										label={''}
										rules={[{ required: true, message: 'Por favor, proporciona una razón válida' }]}
										name="request">
										<TextArea
											placeholder="Razón de la denegación"
											style={{
												width: '100%',
												height: 94,
												borderColor: '#C0DAEE',
												marginTop: 10
											}}></TextArea>
									</Form.Item>
								</div>
							)}

							{offerDeniedValue && (
								<>
									<div>
										<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
											Elige la opción que desees
										</Text>
									</div>
									<Radio.Group onChange={onChangeRadio} value={valueRadio}>
										<Space direction="vertical">
											{bankOffersType && (
												<div style={{ display: 'flex', marginTop: 12, alignItems: 'center' }}>
													<Radio value={3}>
														<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
															Precargar oferta estandar
														</Text>
													</Radio>
													<Form.Item
														name="bankOfferTypeId"
														label={''}
														rules={[
															{
																required: valueRadio === 3 && true,
																message:
																	'Debes elegir la oferta que quieres pre-cargar antes de continuar'
															}
														]}
														style={{ marginBottom: 0 }}>
														<Select
															onChange={() => {
																setValueRadio(3);
															}}
															placeholder="Seleccione"
															style={{ width: 300 }}>
															{bankOffersType?.map((offer) => {
																return <Option value={offer.id}>{offer.name}</Option>;
															})}
														</Select>
													</Form.Item>
												</div>
											)}
											<div style={{ display: 'flex', marginTop: 12 }}>
												<Radio value={1}>
													<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
														Adjuntar documento de oferta
													</Text>
												</Radio>
												{valueRadio === 1 && (
													<div style={{ cursor: 'pointer' }}>
														<Upload {...uploadProps}>
															{fileName === '' ? (
																<Button
																	style={{ width: 24, height: 24, borderRadius: 4 }}
																	type="primary"
																	icon={<UploadOutlined />}></Button>
															) : (
																<Text style={{ fontSize: 12, fontWeight: 500, color: '#02C3CD' }}>
																	{fileName}
																</Text>
															)}
														</Upload>
													</div>
												)}
											</div>
											<Radio value={2} style={{ marginTop: 12 }}>
												<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
													Copiar y pegar datos de oferta
												</Text>
											</Radio>
										</Space>
									</Radio.Group>
									<Form.Item name="note" style={{ marginTop: 24 }}>
										<TextArea
											style={{ height: 212 }}
											placeholder="Pega aquí el texto con los datos de la oferta"
										/>
									</Form.Item>
								</>
							)}
						</Col>
					</Row>
				</Form>
			</Modal>
			{taskToEdit && (
				<RegisterAssigment
					key={taskToEdit}
					visible={taskToEdit}
					onCreate={handleSubmitForm}
					onCancel={hideAddTaskModal}
					width={800}
					bankAccountData={taskToEdit}
				/>
			)}

			{bankRequestEmail && bankOffer && (
				<Modal
					className="gb-modal-style-common"
					cancelButtonProps={{
						className: 'button-secundary-gibobs',
						style: { height: 32, width: 96, marginLeft: 20 }
					}}
					okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
					title={
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
							{'Datos enviados al banco'}
						</Text>
					}
					closable={true}
					destroyOnClose={true}
					open={viewResponse}
					okText={'Aceptar'}
					cancelText={'Cancelar'}
					onCancel={() => {
						setViewResponse(false), setBankRequestEmail(undefined);
					}}
					onOk={() => {
						setViewResponse(false), setBankRequestEmail(undefined);
					}}
					width={1400}>
					{bankRequestEmail &&
						bankRequestEmail?.email?.managementCode !== 'sabadell-es' &&
						editorState &&
						bankOffer?.bankCode !== 'sabadell' && (
							<BraftEditor language="en" defaultValue={editorState} />
						)}

					{bankRequestEmail &&
						bankRequestEmail?.email?.managementCode === 'sabadell-es' &&
						editorState && (
							<>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text> Enviado por: {bankRequestEmail.email.from} </Text>
									<Text> Para: {bankRequestEmail.email.to.join(' ')} </Text>
								</div>
								<JoditEditor
									value={editorState ?? ''}
									config={config}
									//onBlur={(newContent) => handleEditorChange2(newContent)} // preferred to use only this option to update the content for performance reasons
									//onChange={handleEditorChange}
								/>
							</>
						)}

					{bankRequestEmail &&
						bankRequestEmail?.email?.managementCode === 'gibobs' &&
						editorState &&
						bankOffer?.bankCode === 'sabadell' && (
							<>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Text> Enviado por: {bankRequestEmail.email.from} </Text>
									<Text> Para: {bankRequestEmail.email.to.join(' ')} </Text>
								</div>
								<JoditEditor
									value={editorState ?? ''}
									config={config}
									//onBlur={(newContent) => handleEditorChange2(newContent)} // preferred to use only this option to update the content for performance reasons
									//onChange={handleEditorChange}
								/>
							</>
						)}

					{bankRequestEmail && bankRequestEmail?.email === undefined && (
						<EditorMonaco
							height="70vh"
							defaultLanguage={'javascript'}
							theme="vs-dark"
							defaultValue={JSON.stringify(bankRequestEmail, null, '\t')}
						/>
					)}
				</Modal>
			)}
			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96, marginLeft: 20 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				title={
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						{'Cancelar este envío al banco'}
					</Text>
				}
				closable={true}
				destroyOnClose={true}
				open={cancelSend}
				onOk={() => cancelSendBank()}
				onCancel={() => {
					setCancelSend(false);
				}}
				okText={bankOffer.status !== 'approved' ? 'Si' : 'Aceptar'}
				cancelText={bankOffer.status !== 'approved' ? 'No' : 'Cancelar'}
				width={400}>
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
					{bankOffer.status !== 'approved'
						? 'Estás seguro de que quieres cancelar este envío a banco'
						: 'Esta solicitud bancaria tiene ofertas asociadas. ¿Estás seguro de que deseas cancelar este envío y archivar todas las ofertas asociadas?'}
				</Text>
			</Modal>
			<Modal
				className="ModalToFileOffer"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: '45%' }
				}}
				okButtonProps={{
					style: {
						height: 32,
						width: '45%',
						backgroundColor: '#FF4D4F',
						borderRadius: 4,
						border: 'none'
					}
				}}
				title={null}
				centered
				closable={true}
				destroyOnClose={true}
				visible={showModalToFile}
				onOk={() => toFileOffers()}
				onCancel={() => {
					setShowModalToFile(false);
				}}
				okText={'Confirmar'}
				cancelText={'Cancelar'}
				width={400}>
				<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
					Vas a marcar que {bankOffer.bankName.toUpperCase()} ha denegado la operación y se
					archivarán todas las ofertas que el cliente tenía disponible para este banco. ¿Estás
					seguro?
				</Text>
			</Modal>

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96, marginLeft: 20 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				title={
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						{'Santander información'}
					</Text>
				}
				closable={true}
				destroyOnClose={true}
				open={modalSantander}
				onOk={() => sendtoReload()}
				onCancel={() => {
					setModalSantander(false);
				}}
				okText={'Continuar'}
				cancelText={'Salir'}
				width={400}>
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
					{
						'Las ofertas de Santander han caducado sin haber sido activadas. Vamos a borrarlas y solicitar al API ofertas nuevas con los mismos datos que enviaste inicialmente'
					}
				</Text>
			</Modal>
		</>
	);
};
export default BankOffers;

import React from 'react';
import { Layout, Typography } from 'antd';
import { StopOutlined } from '@ant-design/icons';
import _ from 'lodash';

const { Text } = Typography;

export default () => {
	return (
		<Layout style={{ background: 'white', height: '100%' }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					textAlign: 'center',
					height: '80vh',
					justifyContent: 'center'
				}}
			>
				<StopOutlined style={{ fontSize: 48, color: '#02C3CD' }} />
				<Text style={{ color: '#02C3CD', fontSize: 20, fontWeight: 400, marginTop: 32 }}>
					Lo sentimos,
				</Text>
				<Text style={{ color: '#02C3CD', fontSize: 20, fontWeight: 700 }}>
					no está disponible la versión mobile de Financiación promotor,
				</Text>
				<Text style={{ color: '#02C3CD', fontSize: 20, fontWeight: 700 }}>
					accede desde tu ordenador para ver más detalles.
				</Text>
			</div>
		</Layout>
	);
};

import {
	DATAFORMPROMOTER,
	DATASCENARIOS,
	DATANEWPROJECT,
	LOADING,
	REDIRECT,
	DATALISTMYPROYECTS,
	OPERATIONDATAHADMIN,
	OPERATIONDATACLIENT
} from './Constants';

export const INITIAL_STATE = {
	dataFormPromoter: {},
	dataListMyProyects: [],
	dataScenarios: [],
	operationDataHadmin: [],
	operationDataClient: [],
	dataNewProject: {},
	loading: false,
	redirect: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DATAFORMPROMOTER:
			return { ...state, dataFormPromoter: action.payload };
		case DATASCENARIOS:
			return { ...state, dataScenarios: action.payload };
		case DATANEWPROJECT:
			return { ...state, dataNewProject: action.payload };
		case LOADING:
			return { ...state, loading: action.payload };
		case REDIRECT:
			return { ...state, redirect: action.payload };
		case DATALISTMYPROYECTS:
			return { ...state, dataListMyProyects: action.payload };
		case OPERATIONDATAHADMIN:
			return { ...state, operationDataHadmin: action.payload };
		case OPERATIONDATACLIENT:
			return { ...state, operationDataClient: action.payload };
		default:
			return state;
	}
};

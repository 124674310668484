import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import { Row, Spin, Col, Button, Select, Modal, notification, Typography, message } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import TableAndColumnsFile from './TableAndColumnsFile';
import ModalTag from './ModalTag';
import { ReloadOutlined } from '@ant-design/icons';
const { Option, OptGroup } = Select;
const { Text } = Typography;

const defaultCheckedList = [];

export default (props) => {
	const { mortgageId, getAccounts, mortgageData } = props;
	let [download, setDownload] = useState(false);
	const [customTags, setCustomTags] = useState([]);
	const [arrayOwner, setArrayOwner] = useState([]);
	const [bolOwner, setBolOwner] = useState(undefined);
	const [arrayType, setArrayType] = useState([]);
	const [bolType, setBolType] = useState(undefined);
	const [participants, setParticipants] = useState(1);
	const [checkAllComplete, setCheckAllComplete] = useState(false);
	const [checkAllIncomplete, setCheckAllIncomplete] = useState(false);
	const [checkedListComplete, setCheckedListComplete] = useState(defaultCheckedList);
	const [checkedListIncomplete, setCheckedListIncomplete] = useState(defaultCheckedList);
	const [confirmLoading, setConfirmLoading] = useState(true);
	const [fileErrorId, setFileErrorId] = useState();
	const [files, setFiles] = useState([]);
	const [filesIncomplete, setFilesIncomplete] = useState([]);
	const [filesComplete, setFilesComplete] = useState([]);
	const [filesCompleteVerified, setFilesCompleteVerified] = useState([]);

	const [guarantors, setGuarantors] = useState(0);
	const [indeterminateComplete, setIndeterminateComplete] = useState(true);
	const [indeterminateIncomplete, setIndeterminateIncomplete] = useState(true);
	const [loading, setLoading] = useState(true);
	const [modalDocViewer, setModalDocViewer] = useState();
	const [modalTag, setModalTag] = useState(false);
	const [paginaData, setPaginaData] = useState();
	const [profile, setProfile] = useState({});
	const [selectDocument, setSelectDocument] = useState([]);

	const token = authClient.getToken();
	const documents = useSelector((state) => state.documentation.documents);
	const groups = useSelector((state) => state.documentation.groupsCategories);
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);

	useEffect(() => {
		setLoading(false);
		setProfile(authClient.getProfile());
	}, [mortgageId, documents, groups]);

	useEffect(() => {
		setParticipants(mortgageData?.mortgage?.participants);
		if (mortgageData?.mortgage?.guarantors) {
			setGuarantors(mortgageData.mortgage.guarantors);
		}
	}, []);

	useEffect(() => {
		if (!_.isEmpty(groups)) {
			loadDocumentsData();
		}
	}, [_.isEmpty(groups)]);

	useEffect(() => {
		const newElementArrayOwner = [];
		const newElementArrayType = [];
		filesIncomplete.map((data) => {
			checkedListIncomplete.map((element) => {
				if (data.id === element) {
					newElementArrayOwner.push(data.owner);
					newElementArrayType.push(data.type);
				}
			});
		});
		setArrayOwner(newElementArrayOwner);
		setArrayType(newElementArrayType);
	}, [checkedListIncomplete]);

	useEffect(() => {
		if (arrayOwner.length !== 0) {
			comparatorArray(arrayOwner) ? setBolOwner(arrayOwner[0]) : setBolOwner(undefined);
		}
	}, [arrayOwner]);
	useEffect(() => {
		if (arrayType.length !== 0) {
			comparatorArray(arrayType) ? setBolType(arrayType[0]) : setBolType(undefined);
		}
	}, [arrayType]);

	const comparatorArray = (array, set) => {
		return (
			array.every((e) => array[0] === e) && array.length !== 0 && array.every((e) => e !== null)
		);
	};

	const miOrderedArray = (myArray) =>
		_.orderBy(
			myArray,
			[
				(e) => {
					return e.owner === null;
				},
				(e) => {
					return e.owner !== null && e.owner.includes('guaran');
				},
				'owner',
				(e) => {
					return e.owner !== null && e.type === null;
				},
				'type'
			],
			['desc', 'asc', 'asc', 'desc', 'desc', 'asc']
		);

	const loadDocumentsData = async () => {
		const promises = [];
		promises.push(
			axios.get(`${env.api.url}/v1/documents/get-operation-documents/${mortgageId}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
		);

		Promise.all(promises).then((responses) => {
			const [filesResponse] = responses;
			const apiFiles = miOrderedArray(filesResponse.data.data);
			const newTags = [];

			apiFiles.forEach((file) => {
				const matchedDocument = documents.find((document) => document.name === file.type);

				if (!matchedDocument && file.type && file.type !== '') {
					newTags.push(file.type);
				}
			});

			const apiFilesIncomplete = apiFiles.filter((file) => {
				if (file.name.includes(`.${file.extension}`)) {
					return file;
				}
				if (
					!file.name.includes(`.${file.extension}`) &&
					(file.owner === null || file.type === null || file.page === null)
				) {
					return file;
				}
			});
			const apiFilesComplete = apiFiles.filter((file) => {
				if (
					!file.name.includes(`.${file.extension}`) &&
					file.owner !== null &&
					file.type !== null &&
					file.page !== null &&
					(file.isVerified === null || file.isVerified === false)
				) {
					return file;
				}
			});
			const apiFilesCompleteVerified = apiFiles.filter((file) => {
				if (
					!file.name.includes(`.${file.extension}`) &&
					file.owner !== null &&
					file.type !== null &&
					file.page !== null &&
					file.isVerified !== null &&
					file.isVerified !== false
				) {
					return file;
				}
			});

			setCustomTags(newTags);
			setFiles(apiFiles);
			setFilesIncomplete(apiFilesIncomplete);
			setFilesComplete(apiFilesComplete);
			setFilesCompleteVerified(apiFilesCompleteVerified);

			setLoading(false);
		});
	};

	const getSelectDocumentTypes = () => {
		const optGroups = [];

		if (groups) {
			const groupKeys = Object.keys(groups);

			groupKeys.forEach((groupKey) => {
				const group = groups[groupKey];

				const pieceGroupOptions = [];
				const options = documents.filter((document) => document.group === groupKey);
				if (group.hasDocumentTypes) {
					options.forEach((option) => {
						pieceGroupOptions.push(
							<Option key={option.id} value={option.name}>
								{option.title}
							</Option>
						);
					});
				}

				if (group.hasDocumentTypes) {
					optGroups.push(
						<OptGroup key={group.id} label={group.title}>
							{pieceGroupOptions}
						</OptGroup>
					);
				}
			});
		}

		if (customTags.length > 0) {
			const tags = ([] = customTags.map((tag) => (
				<Option key={tag.id} value={tag}>
					{tag}
				</Option>
			)));

			optGroups.push(<OptGroup label="Otras etiquetas">{tags}</OptGroup>);
		}

		return optGroups;
	};

	if (loading) {
		return (
			<div style={{ textAlign: 'center', height: '100vh' }}>
				<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
			</div>
		);
	}
	const getOwnerName = (owner) => {
		let name = 'No_definido';

		if (owner.indexOf('owner') !== -1) {
			if (mortgageData[owner] === undefined) {
				message.error('Comprueba que todos los titulares tiene nombre, apellidos...');
			} else {
				name = `${mortgageData[owner].name.replace(/ /g, '')}_T${owner.replace('owner', '')}`;
			}
		}

		if (owner.indexOf('guarantor') !== -1) {
			if (mortgageData[owner] === undefined) {
				message.error('Comprueba que todos los avalistas tiene nombre, apellidos...');
			} else {
				name = `${mortgageData[owner].name.replace(/ /g, '')}_A${owner.replace('guarantor', '')}`;
			}
		}

		return name;
	};

	const getFileName = (owner, type, page) => {
		if (!type || !page || !owner || !documents) {
			return;
		}

		if (page === 't') {
			page = 'Todas';
		}

		const matchedDocument = documents.find((document) => document.name === type);

		let typeName = '';

		if (matchedDocument) {
			typeName = matchedDocument.title.replace(/\s+/gi, '_');
		} else {
			typeName = type;
		}

		let nameNew;
		if (page === 'Todas') {
			nameNew = `${typeName}_${getOwnerName(owner)}_${page}`;
		} else {
			nameNew = `${typeName}_${getOwnerName(owner)}_Pag${page}`;
		}

		return nameNew;
	};

	const updateFile = (file) => {
		axios
			.post(`${env.api.url}/v1/documents/update-document/${mortgageId}`, file, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((response) => {
				setLoading(false);
			});
	};

	const arrayOptionsPage = [];
	for (let i = 1; i < 51; i++) {
		arrayOptionsPage.push(
			<Option key={i} value={`${i}`}>
				{i}
			</Option>
		);
	}

	const saveData = (id, key, value, bol) => {
		const changedFiles = JSON.parse(JSON.stringify(files));
		let checkToSave = true;

		for (let i = 0; i < files.length; i++) {
			const file = changedFiles[i];

			if (file.id === id) {
				file[key] = value;

				let name;
				if (file.page === 't' || (file.page !== 't' && file.page !== '')) {
					name = getFileName(file.owner, file.type, file.page);
				}

				if (name) {
					file.name = name;
				}

				const hasFile = files.find((aFile) => {
					return (
						aFile.owner === file.owner &&
						file.type !== null &&
						aFile.type === file.type &&
						(aFile.page === 't' ||
							file.page === 't' ||
							(file.page !== null && aFile.page === file.page)) &&
						file.id !== aFile.id
					);
				});

				if (hasFile) {
					checkToSave = false;

					notification.error({
						message: <div className="">Documento duplicado</div>,
						description:
							'El documento que estás intentando modificar ya está duplicado, no puedes asignarlo',
						className: 'error-notification-gibobs',
						icon: <img src={'/images/errorIcon.svg'} alt="ErrorIcon" />
					});

					setFileErrorId(file.id);
				} else {
					if (fileErrorId === file.id) {
						setFileErrorId('');
					}
					if (bol) {
						file.owner = bolOwner;
						file.type = bolType;
						updateFile(file);
					} else {
						updateFile(file);
					}
					changedFiles[i] = file;
				}
			}
		}

		if (checkToSave) {
			setFiles(changedFiles);
		}
		if (paginaData) {
			setPaginaData();
		}
	};

	const loadSignedDocument = async (documentId) => {
		const response = await axios.get(`${env.api.url}/v1/documents/get-document-url/${documentId}`, {
			headers: { Authorization: `Bearer ${token}` }
		});

		setModalDocViewer(response.data.data.url);
		setConfirmLoading(false);

		const responseFile = await axios.get(response.data.data.url, {
			responseType: 'blob'
		});

		return {
			...response.data.data,
			fileData: responseFile.data.data
		};
	};

	const downloadDocumentsSelected = (dataurl, filename) => {
		const link = document.createElement('a');
		link.href = dataurl;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
	};

	const groupedDownload = async (documentsSelected) => {
		const response = await axios.post(
			`${env.api.url}/v1/hadmin/documents/zip-files`,
			{ documents: documentsSelected, operationId: mortgageId },
			{
				responseType: 'blob',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${authClient.getToken()}`
				}
			}
		);

		downloadDocumentsSelected(
			URL.createObjectURL(new Blob([response.data])),
			response.headers['content-disposition'].split('"')[1]
		);
	};

	const taggedInBlock = async (values) => {
		await axios
			.all(
				checkedListIncomplete.map((l) => {
					saveData(l, 'type', values.type, true);
				})
			)
			.then((res) => {
				setSelectDocument(checkedListIncomplete);
				setModalTag(!modalTag);
				setCheckAllIncomplete(false);
				setCheckedListIncomplete([]);
			});
	};

	const unCheckBoxComplete = (data1) => {
		const newElementArray = [];
		checkedListComplete.map((data) => {
			if (data1 !== data) newElementArray.push(data);
		});
		setCheckedListComplete(newElementArray);
	};
	const unCheckBoxIncomplete = (data1) => {
		const newElementArray = [];
		checkedListIncomplete.map((data) => {
			if (data1 !== data) newElementArray.push(data);
		});
		setCheckedListIncomplete(newElementArray);
	};

	const onChangeCheckBoxComplete = (list, dataDocument) => {
		if (list.length !== 0) {
			setCheckedListComplete([...checkedListComplete, ...list]);
		}
		setIndeterminateComplete(!!list.length && list.length < dataDocument.length);
		setCheckAllComplete(list.length === dataDocument.length);
	};
	const onChangeCheckBoxIncomplete = (list, dataDocument) => {
		if (list.length !== 0) {
			setCheckedListIncomplete([...checkedListIncomplete, ...list]);
		}
		setIndeterminateIncomplete(!!list.length && list.length < dataDocument.length);
		setCheckAllIncomplete(list.length === dataDocument.length);
	};

	const newArray = [];
	const onCheckAllChangeComplete = (e, dataDocument) => {
		dataDocument.map((data) => newArray.push(data.id));
		setCheckedListComplete(e.target.checked ? newArray : []);
		setIndeterminateComplete(false);
		setCheckAllComplete(e.target.checked);
	};

	const onCheckAllChangeIncomplete = (e, dataDocument) => {
		dataDocument.map((data) => newArray.push(data.id));
		setCheckedListIncomplete(e.target.checked ? newArray : []);
		setIndeterminateIncomplete(false);
		setCheckAllIncomplete(e.target.checked);
	};

	const reloadFile = () => {
		props.onUpdate();
		loadDocumentsData();
	};

	const removeFile = async (checkedListComplete) => {
		for (let index = 0; index < checkedListComplete.length; index++) {
			const element = checkedListComplete[index];
			const response = await axios.get(`${env.api.url}/v1/documents/delete-document/${element}`, {
				headers: { Authorization: `Bearer ${token}` }
			});
		}

		loadDocumentsData();
	};

	return (
		<Row style={{ marginTop: '16px' }} gutter={10}>
			<Col span={24}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					{filesIncomplete.length > 0 && (
						<div
							style={{
								fontWeight: 600,
								fontSize: '14px',
								marginBottom: '16px',
								justifyContent: 'space-between',
								alignItems: 'center',
								display: 'flex',
								color: '#2F4858'
							}}>
							Documentos sin etiquetar
						</div>
					)}

					<Button
						style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500, border: 'none' }}
						onClick={() => reloadFile()}>
						Actualizar
						<ReloadOutlined
							style={{ color: '#02C3CD', fontSize: 18, fontWeight: 500, marginLeft: 4 }}
						/>
					</Button>
				</div>
				<ModalTag
					visible={modalTag}
					getSelectDocumentTypes={() => getSelectDocumentTypes()}
					participants={participants}
					guarantors={guarantors}
					mortgageData={mortgageData}
					onCancel={() => {
						setModalTag(!modalTag);
						setCheckAllIncomplete(false);
						setCheckedListIncomplete([]);
					}}
					onCreate={(e) => taggedInBlock(e)}
					owner={bolOwner}
					type={bolType}
					setBolOwner={(e) => setBolOwner(e)}
					setBolType={(e) => setBolType(e)}
				/>
				{filesIncomplete.length > 0 && mortgageData && (
					<TableAndColumnsFile
						dataDocuments={filesIncomplete}
						mortgageData={mortgageData}
						getSelectDocumentTypes={() => getSelectDocumentTypes()}
						onChangeCheckBox={(e) => onChangeCheckBoxIncomplete(e, filesIncomplete)}
						onCheckAllChange={(e) => onCheckAllChangeIncomplete(e, filesIncomplete)}
						unCheckBox={(e) => unCheckBoxIncomplete(e)}
						participants={participants}
						guarantors={guarantors}
						saveData={(id, key, value) => saveData(id, key, value)}
						checkAll={checkAllIncomplete}
						indeterminate={indeterminateIncomplete}
						checkedList={checkedListIncomplete}
						profile={profile}
						fileErrorId={fileErrorId}
						loadSignedDocument={(e) => loadSignedDocument(e)}
						selectDocument={selectDocument}
						setSelectDocument={(e) => setSelectDocument(e)}
						reloadFile={() => reloadFile()}
						index={1}
					/>
				)}

				{filesComplete.length > 0 && (
					<>
						<div
							style={{
								fontWeight: 600,
								fontSize: '14px',
								marginTop: '24px',
								marginBottom: '16px',
								justifyContent: 'space-between',
								alignItems: 'center',
								display: 'flex',
								color: '#2F4858'
							}}>
							Documentos etiquetados
						</div>
						<TableAndColumnsFile
							dataDocuments={filesComplete}
							mortgageData={mortgageData}
							getSelectDocumentTypes={() => getSelectDocumentTypes()}
							onChangeCheckBox={(e) => onChangeCheckBoxComplete(e, filesComplete)}
							onCheckAllChange={(e) => onCheckAllChangeComplete(e, filesComplete)}
							unCheckBox={(e) => unCheckBoxComplete(e)}
							participants={participants}
							guarantors={guarantors}
							saveData={(id, key, value) => saveData(id, key, value)}
							checkAll={checkAllComplete}
							indeterminate={indeterminateComplete}
							checkedList={checkedListComplete}
							profile={profile}
							fileErrorId={fileErrorId}
							loadSignedDocument={(e) => loadSignedDocument(e)}
							selectDocument={selectDocument}
							setSelectDocument={(e) => setSelectDocument(e)}
							reloadFile={() => reloadFile()}
							index={2}
						/>
					</>
				)}

				{filesCompleteVerified.length > 0 && (
					<>
						<div
							style={{
								fontWeight: 600,
								fontSize: '14px',
								marginTop: '24px',
								marginBottom: '16px',
								justifyContent: 'space-between',
								alignItems: 'center',
								display: 'flex',
								color: '#2F4858'
							}}>
							Documentos etiquetados y verificados
						</div>

						<TableAndColumnsFile
							dataDocuments={filesCompleteVerified}
							mortgageData={mortgageData}
							getSelectDocumentTypes={() => getSelectDocumentTypes()}
							onChangeCheckBox={(e) => onChangeCheckBoxComplete(e, filesCompleteVerified)}
							onCheckAllChange={(e) => onCheckAllChangeComplete(e, filesCompleteVerified)}
							unCheckBox={(e) => unCheckBoxComplete(e)}
							participants={participants}
							guarantors={guarantors}
							saveData={(id, key, value) => saveData(id, key, value)}
							checkAll={checkAllComplete}
							indeterminate={indeterminateComplete}
							checkedList={checkedListComplete}
							profile={profile}
							fileErrorId={fileErrorId}
							//loadDocumentsData={() => loadDocumentsData()}
							loadSignedDocument={(e) => loadSignedDocument(e)}
							selectDocument={selectDocument}
							setSelectDocument={(e) => setSelectDocument(e)}
							reloadFile={() => reloadFile()}
							index={3}
						/>
					</>
				)}
				<div style={{ display: 'flex' }}>
					<Button
						className="button-primari-gibobs"
						style={{ marginTop: 16, paddingBottom: -5, height: 24 }}
						type="primary"
						disabled={
							statusJob === 'finish'
								? true
								: checkedListComplete && checkedListComplete.length === 0
								? true
								: profile.type === 'risk' || profile.type === 'admin' || profile.type === 'prerisk'
								? false
								: true
						}
						target="_blank"
						onClick={() => {
							getAccounts(checkedListComplete);
							setCheckedListComplete([]);
							setCheckAllComplete(false);
						}}>
						Descargar seleccionados
					</Button>

					<Button
						className="button-primari-delete-gibobs"
						style={{ marginTop: 16, height: 24, marginLeft: 8 }}
						type="danger"
						disabled={
							statusJob === 'finish'
								? true
								: checkedListComplete && checkedListComplete.length === 0
								? true
								: profile.type === 'risk' || profile.type === 'admin' || profile.type === 'prerisk'
								? false
								: true
						}
						target="_blank"
						onClick={() => {
							const modal = Modal.confirm({
								title: 'Borrar seleccionados',
								closable: true,
								width: '40%',
								content: (
									<div style={{ marginTop: 50 }}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											¿Estas seguro de que deseas borrar {checkedListComplete.length} documentos?
										</Text>
									</div>
								),
								okCancel: true,
								onOk: () => {
									if (download === false) {
										//getAccounts(checkedListComplete);
										setCheckedListComplete(checkedListComplete);
										removeFile(checkedListComplete);
										setCheckAllComplete(false);
									}
								},
								okText: 'Borrar',
								okButtonProps: { style: { background: '#ff4d4f', borderColor: '#ff4d4f' } },

								onCancel: () => {
									modal.destroy();
								}
							});
						}}>
						Borrar seleccionados
					</Button>
				</div>
			</Col>
		</Row>
	);
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
	Avatar,
	Button,
	Modal,
	Typography,
	message,
	Row,
	Col,
	Form,
	Input,
	Select,
	notification
} from 'antd';
import axios from 'axios';

import authClient from '../../Auth/Auth';
import BraftEditor from 'braft-editor';
import InputEmailAddresses from '../../Pages/Hadmin/MortgageDetail/Components/bank/pageEmailbank/components/input-email-addresses';
import env from '../../environment';

const { Text } = Typography;

const ModalsIncludesComponent = ({
	bankRequestId,
	idBank,
	mortgageId,
	visibleConfirm,
	setVisibleConfirm,
	bankRequest,
	mortgageName,
	getBankRequestClaim,
	notClaim
}) => {
	const { t } = useTranslation();
	const [constactsBanks, setContactsBanks] = useState(undefined);
	const [constactsBanksCC, setContactsBanksCC] = useState(undefined);
	const [form] = Form.useForm();
	const [dataBanksOnGoing, setDataBanksOnGoing] = useState(null);
	const [constactsBanksNull, setContactsBanksNull] = useState([]);
	const [htmlEmail, setHtmlEmail] = useState(undefined);
	const [editorState, setEditorState] = useState(undefined);
	const [mortgage, setMortgage] = useState(undefined);

	useEffect(() => {
		if (dataBanksOnGoing && htmlEmail) {
			setEditorState(BraftEditor.createEditorState(htmlEmail));
		}
	}, [dataBanksOnGoing, htmlEmail]);

	useEffect(() => {
		getBanksOnGoing();
		getEmailCompouser();
		getParticipants();
		getContacts();
	}, []);

	const getParticipants = async () => {
		await axios
			.get(`${env.api.url}/v1/mortgages/data/get/${mortgageId}`, {
				headers: {
					Authorization: `Bearer ${authClient.getToken()}`
				}
			})
			.then((response) => {
				setMortgage(response.data.data);
			});
	};

	const getEmailCompouser = () => {
		axios
			.post(
				`${env.api.url}/banks/email-composer/template/manual-claim-offer`,
				{
					operationId: mortgageId,
					bankRequestId: bankRequestId
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setHtmlEmail(response.data.data);
			});
	};

	const getBanksOnGoing = () => {
		axios
			.get(`${env.api.url}/v1/banks/get-banks?status=ongoing&managementCode`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setDataBanksOnGoing(response.data.data);
			});
	};

	const getContacts = () => {
		axios
			.get(
				`${env.api.url}/banks/bank-contact/by-bank-request/${bankRequestId}`,

				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setContactsBanks(
					_.filter(response.data.data, function (c) {
						return c.preloaded === 'to';
					})
				);
				setContactsBanksCC(
					_.filter(response.data.data, function (c) {
						return c.preloaded === 'cc';
					})
				);
				setContactsBanksNull(
					_.filter(response.data.data, function (c) {
						return c.preloaded === null;
					})
				);
			});
	};

	const renderConstactsBanksCC = () => {
		let contacts = [];
		contacts.push(
			constactsBanksCC.map((c) => {
				return c.email;
			})
		);

		return contacts[0];
	};

	const handleEditorChange = (editorState2) => {
		setEditorState(editorState2);
	};

	const sendBankEmail = (values, files, bankcode) => {
		//values.to = values.to.filter((email) => email !== null && email !== ' ');

		const requestEmail = {
			bankRequestId: values.bankRequestId,
			operationId: mortgageId,
			to: Array.isArray(values.to) ? values.to : [values.to],
			from: values.from,
			cc: values.CC,
			cco: values.CCO,
			subject: values.subject,
			message: values.message.toHTML()
		};
		if (requestEmail) {
			console.log(requestEmail.attachments);
		}

		axios
			.post(
				`${env.api.url}/banks/email-composer/bank-request/send-to-bank/manual-claim-offer`,
				requestEmail,
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (!notClaim) {
					getBankRequestClaim();
				}

				notification.success({
					message: (
						<Text style={{ color: '#3E9314', fontSize: 16, fontWeight: 600 }}>
							{'Enviado correctamente'}
						</Text>
					),
					description: (
						<Text
							style={{
								color: '#2F4858',
								fontSize: 14,
								fontWeight: 400
							}}>
							{'Se ha enviado correctamente el email de reclamación al banco'}
						</Text>
					),
					duration: 10,
					style: {
						background: '#DFF4D4',
						borderColor: '#52C41A'
					}
				});

				setVisibleConfirm(false);
			})
			.catch((error) => {
				notification.error({
					message: (
						<Text style={{ color: '#BF3A3B', fontSize: 16, fontWeight: 600 }}>
							{'Se ha producido un error'}
						</Text>
					),
					description:
						error.response !== undefined ? (
							<Text
								style={{
									color: '#2F4858',
									fontSize: 14,
									fontWeight: 400
								}}>{`Ha habido un error en el envío. Por favor, abre un ticket con este ID: ${error.response.data.debugId}`}</Text>
						) : (
							<Text
								style={{
									color: '#2F4858',
									fontSize: 14,
									fontWeight: 400
								}}>{`Ha habido un error en el envío. Por favor, rellena de nuevo el mail.`}</Text>
						),
					duration: 0,
					style: {
						background: '#FFDEDE',
						borderColor: '#FF4D4F'
					}
				});
			});
	};

	const renderContactBank = (bankRequest) => {
		let contacts = [];

		if (bankRequest.bankAccountData && bankRequest.bankAccountData.email) {
			contacts.push(bankRequest.bankAccountData.email);
		}

		contacts.push(
			constactsBanks.map((c) => {
				return c.email;
			})
		);

		return contacts[0];
	};

	const renderName = () => {
		const name = mortgage?.owner1?.name;
		const firstSurname = mortgage?.owner1?.firstSurname || '';
		const lastSurname = mortgage?.owner1?.lastSurname || '';
		return `${name} ${firstSurname} ${lastSurname} `;
	};

	const renderSubject = () => {
		if (bankRequest && (bankRequest?.bank?.bankCode === 'evo' || bankRequest.bankCode === 'evo')) {
			return (
				'Solicitud estudio nueva hipoteca ' +
				`${mortgageName} ` +
				renderName() +
				`${mortgage?.owner1?.nif}`
			);
		} else if (
			bankRequest &&
			(bankRequest?.bank?.bankCode === 'sabadell' || bankRequest.bankCode === 'sabadell') &&
			mortgage.accountType !== 'web'
		) {
			return (
				'Nueva operación de PARTNER PREMIUM ' +
				`${mortgageName} ` +
				renderName() +
				`${mortgage?.owner1?.nif}`
			);
		} else if (bankRequest?.bank?.bankCode === 'imagin' || bankRequest.bankCode === 'imagin') {
			return (
				'IMAGIN: Nueva operación ' + `${mortgageName} ` + renderName() + `${mortgage?.owner1?.nif}`
			);
		} else if (mortgageName) {
			return 'Nueva operación ' + `${mortgageName} ` + renderName() + `${mortgage?.owner1?.nif}`;
		} else if (
			bankRequest &&
			(bankRequest?.bank?.bankCode === 'targobank' || bankRequest.bankCode === 'targobank')
		) {
			return (
				'Esperamos una respuesta para la solicitud de hipoteca de ' +
				renderName() +
				' DNI: ' +
				`${mortgage?.owner1?.nif} ` +
				`${mortgageName}` +
				' - Nº expediente'
			);
		} else {
			if (mortgageName) {
				return (
					'Esperamos una respuesta para la solicitud de hipoteca de ' +
					renderName() +
					' DNI: ' +
					`${mortgage?.owner1?.nif} ` +
					`${mortgageName}`
				);
			}
		}
	};

	return (
		<Modal
			className="gb-modal-style-common"
			cancelButtonProps={{
				className: 'button-secundary-gibobs',
				style: { display: 'none' }
			}}
			okButtonProps={{ className: 'button-primari-gibobs', style: { display: 'none' } }}
			title={
				<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
					{'Reclamación de oferta'}
				</Text>
			}
			closable={true}
			open={visibleConfirm}
			onOk={undefined}
			okText={'Si'}
			cancelText={'No'}
			onCancel={() => setVisibleConfirm(!visibleConfirm)}
			width={1200}>
			<div style={{ marginTop: 0, marginBottom: 10 }}>
				{constactsBanks && constactsBanksCC && (
					<Row style={{ marginTop: -40 }}>
						<Form
							form={form}
							layout="vertical"
							name="sendMessageForm"
							style={{ width: '100%' }}
							initialValues={{
								bankRequestId: bankRequestId,
								bank: idBank !== undefined ? idBank : '',
								send: authClient.getProfile().email,
								CC: renderConstactsBanksCC(),
								recepient: '',
								message: editorState,
								CCO: [
									authClient.getProfile().email,
									'gestionhipotecaria@gibobs.com',
									'envio_bancos@gibobs.com'
								],
								from: authClient.getProfile().email,
								subject: mortgage && mortgageName && bankRequest && renderSubject(),
								to: renderContactBank(bankRequest)
							}}>
							<Form.Item name="bankRequestId">
								<Input type={'hidden'} />
							</Form.Item>
							<Row gutter={[16, 16]}>
								<Col lg={8} md={8} sm={16} xs={24} xxl={8}>
									<Form.Item
										name="bank"
										rules={[{ required: true, message: 'Please add a bank' }]}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Banco'}
											</Text>
										}>
										<Select disabled={true} style={{ width: '100%' }}>
											{dataBanksOnGoing &&
												dataBanksOnGoing.map((banks) => (
													<Option key={banks.id} value={banks.id}>
														{banks.largeName}
													</Option>
												))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[16, 16]} style={{ marginTop: -10 }}>
								<Col lg={16} md={16} sm={16} xs={24} xxl={16}>
									<Form.Item
										name="to"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Para'}
											</Text>
										}
										rules={[{ required: true, message: 'Por favor, añade un destinatario' }]}>
										{constactsBanksNull.length > 0 ? (
											<InputEmailAddresses contacts={[...constactsBanks, ...constactsBanksNull]} />
										) : (
											<InputEmailAddresses contacts={constactsBanks} />
										)}
									</Form.Item>
								</Col>
								<Col lg={8} md={8} sm={8} xs={24} xxl={8}>
									<Form.Item
										name="from"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'DE'}
											</Text>
										}>
										<Input type="text" style={{ width: '100%' }} disabled={true} />
									</Form.Item>
								</Col>

								<Col lg={12} md={12} sm={16} xs={24} xxl={12} style={{ marginTop: -30 }}>
									<Form.Item
										name="CC"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'CC'}
											</Text>
										}>
										<InputEmailAddresses contacts={constactsBanksCC} />
									</Form.Item>
								</Col>

								<Col lg={12} md={12} sm={12} xs={24} xxl={12} style={{ marginTop: -30 }}>
									<Form.Item
										name="CCO"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'CCO'}
											</Text>
										}>
										<InputEmailAddresses
											contacts={[
												{ id: authClient.getProfile().email, email: authClient.getProfile().email },
												{ id: 'envio_bancos@gibobs.com', email: 'envio_bancos@gibobs.com' }
											]}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row style={{ marginTop: -10, width: '100%' }}>
								<Form.Item
									style={{ width: '100%' }}
									name="subject"
									label={
										<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
											{'Asunto'}
										</Text>
									}
									rules={[{ required: true, message: 'Por favor, añade un asunto' }]}>
									<Input
										style={{ width: '100%' }}
										type="text"
										placeholder="Nueva solicitud hipotecaria HPXXXX"
									/>
								</Form.Item>
							</Row>
							<Row style={{ marginTop: -10, width: '100%' }}>
								<Form.Item
									style={{ width: '100%' }}
									name="message"
									label={
										<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
											{'Mensaje'}
										</Text>
									}>
									<BraftEditor
										language="en"
										defaultValue={editorState}
										onChange={handleEditorChange}
									/>
								</Form.Item>
							</Row>

							<Row style={{ marginTop: 12, width: '100%', marginBottom: -24 }}>
								<Col span={12} style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
									<Button
										type="dashed"
										onClick={() => setVisibleConfirm(false)}
										className="button-secundary-gibobs"
										style={{ width: 'auto', marginLeft: 6, marginTop: 1, height: 32 }}>
										Cancelar
									</Button>

									<Button
										type="primary"
										className="button-primary-gibobs"
										onClick={() =>
											form
												.validateFields()
												.then((values) => {
													sendBankEmail(values);
													form.resetFields();
												})
												.catch((info) => {
													console.log('Validate Failed:', info);
												})
										}
										style={{ width: 'auto', marginLeft: 6, marginTop: 1, height: 32 }}>
										Enviar
									</Button>
								</Col>
							</Row>
						</Form>
					</Row>
				)}
			</div>
		</Modal>
	);
};

export default ModalsIncludesComponent;

import React from 'react';
import mapJson from './data.json';

function Map({ minSize = 5, provinces, width, height }) {
	const sortableObject = [];

	if (provinces) {
		var sortable = [];
		for (var vehicle in provinces) {
			sortable.push([vehicle, provinces[vehicle]]);
		}

		sortable.sort(function (a, b) {
			return b[1] - a[1];
		});

		sortableObject.push(sortable[1][0], sortable[2][0], sortable[3][0], sortable[4][0]);
	}

	const searchingRadio = (a) => {
		if (a === 0) {
			return 25;
		} else if (a === 1) {
			return 20;
		} else if (a === 2) {
			return 15;
		} else if (a === 3) {
			return 10;
		}
	};

	const states = {
		mancha: {
			x: 235,
			y: 210,
			r: sortableObject.find((element) => element === 'Castilla-La Mancha')
				? searchingRadio(sortableObject.indexOf('Castilla-La Mancha'))
				: null
		},
		valencia: {
			x: 310,
			y: 220,
			r: sortableObject.find((element) => element === 'Comunidad Valenciana')
				? searchingRadio(sortableObject.indexOf('Comunidad Valenciana'))
				: null
		},
		andalucia: {
			x: 185,
			y: 275,
			r: sortableObject.find((element) => element === 'Andalucía')
				? searchingRadio(sortableObject.indexOf('Andalucía'))
				: null
		},
		vascongadas: {
			x: 245,
			y: 30,
			r: sortableObject.find((element) => element === 'País Vasco')
				? searchingRadio(sortableObject.indexOf('País Vasco'))
				: null
		},
		asturias: {
			x: 125,
			y: 30,
			r: sortableObject.find((element) => element === 'Asturias')
				? searchingRadio(sortableObject.indexOf('Asturias'))
				: null
		},
		leon: {
			x: 160,
			y: 100,
			r: sortableObject.find((element) => element === 'Castilla y León')
				? searchingRadio(sortableObject.indexOf('Castilla y León'))
				: null
		},
		extremadura: {
			x: 120,
			y: 200,
			r: sortableObject.find((element) => element === 'Extremadura')
				? searchingRadio(sortableObject.indexOf('Badajoz'))
				: null
		},
		baleares: {
			x: 412,
			y: 185,
			r: sortableObject.find((element) => element === 'Baleares')
				? searchingRadio(sortableObject.indexOf('Baleares'))
				: null
		},
		aragon: {
			x: 285,
			y: 110,
			r: sortableObject.find((element) => element === 'Aragón')
				? searchingRadio(sortableObject.indexOf('Aragón'))
				: null
		},
		cataluna: {
			x: 375,
			y: 110,
			r: sortableObject.find((element) => element === 'Cataluña')
				? searchingRadio(sortableObject.indexOf('Cataluña'))
				: null
		},
		murcia: {
			x: 270,
			y: 275,
			r: sortableObject.find((element) => element === 'Región de Murcia')
				? searchingRadio(sortableObject.indexOf('Región de Murcia'))
				: null
		},
		melilla: {
			x: 230,
			y: 330,
			r: sortableObject.find((element) => element === 'Melilla')
				? searchingRadio(sortableObject.indexOf('Melilla'))
				: null
		},
		ceuta: {
			x: 130,
			y: 330,
			r: sortableObject.find((element) => element === 'Ceuta')
				? searchingRadio(sortableObject.indexOf('Ceuta'))
				: null
		},
		canarias: {
			x: 395,
			y: 310,
			r: sortableObject.find((element) => element === 'Canarias')
				? searchingRadio(sortableObject.indexOf('Canarias'))
				: null
		},
		cantabria: {
			x: 185,
			y: 20,
			r: sortableObject.find((element) => element === 'Cantabria')
				? searchingRadio(sortableObject.indexOf('Cantabria'))
				: null
		},
		galicia: {
			x: 60,
			y: 40,
			r: sortableObject.find((element) => element === 'Galicia')
				? searchingRadio(sortableObject.indexOf('Galicia'))
				: null
		},
		rioja: {
			x: 250,
			y: 80,
			r: sortableObject.find((element) => element === 'La Rioja')
				? searchingRadio(sortableObject.indexOf('La Rioja'))
				: null
		},
		madrid: {
			x: 200,
			y: 155,
			r: sortableObject.find((element) => element === 'Comunidad de Madrid')
				? searchingRadio(sortableObject.indexOf('Comunidad de Madrid'))
				: null
		}
	};

	const statesBalls = [];

	const keys = Object.keys(states);
	keys.forEach((key) => {
		const ball = (
			<circle
				key={`ball-${key}`}
				cx={states[key].x}
				cy={states[key].y}
				r={states[key].r || minSize}
				fill="#02C3CD"
			/>
		);
		statesBalls.push(ball);
	});

	const points = mapJson.map((point) => (
		<circle key={`point-${point.x}-${point.y}`} cx={point.x} cy={point.y} r="2" fill="#2F4858" />
	));

	return (
		<div className="gibobs-map">
			<svg
				id="map"
				width={width}
				height={height}
				viewBox="0 0 460 332"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				{points}
				{statesBalls}
			</svg>
		</div>
	);
}

export default Map;

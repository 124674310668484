import React from 'react';
import { Card, Typography } from 'antd';

const { Text } = Typography;

const ComponentData = ({
	title,
	image,
	property1,
	property2,
	property3,
	property4,
	property4Label,
	property5Label
}) => {
	return (
		<Card
			style={{
				border: '1px solid #C0DAEE',
				borderRadius: '4px',
				height: '100%'
			}}
		>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<img src={image} alt="icons" style={{ marginRight: '7px' }} />
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '500',
						color: '#2F4858'
					}}
				>
					{title}
				</Text>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					flexWrap: 'wrap',
					marginTop: '12px',
					flexDirection: 'column'
				}}
			>
				<div>
					<Text
						style={{
							fontSize: '12px',
							fontWeight: '500',
							color: '#2F4858'
						}}
					>
						{property4Label}
					</Text>
					<Text
						style={{
							fontSize: '12px',
							fontWeight: '500',
							color: '#748EA0'
						}}
					>
						{property1}
					</Text>
				</div>
				<div>
					<Text
						style={{
							fontSize: '12px',
							fontWeight: '500',
							color: '#2F4858'
						}}
					>
						{property5Label}
					</Text>
					<Text
						style={{
							fontSize: '12px',
							fontWeight: '500',
							color: '#748EA0'
						}}
					>
						{property2}
					</Text>
					{property4 !== undefined && (
						<Text
							style={{
								fontSize: '12px',
								fontWeight: '500',
								color: '#748EA0'
							}}
						>
							{` - ${property4} de antigüedad`}
						</Text>
					)}
				</div>
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '500',
						color: '#748EA0'
					}}
				>
					{property3}
				</Text>
			</div>
		</Card>
	);
};

export default ComponentData;

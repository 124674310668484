import React, { useEffect, useState } from 'react';
import { Col, Row, Typography, Card } from 'antd';
import numeral from 'numeral';
import axios from 'axios';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import { useParams } from 'react-router-dom';
import {
	CarOutlined,
	HeartOutlined,
	FileDoneOutlined,
	LockOutlined,
	HomeOutlined,
	ContainerOutlined,
	EuroOutlined,
	CreditCardOutlined,
	EyeOutlined
} from '@ant-design/icons';

const { Text } = Typography;

const DataNewFormatMortgage = ({
	mortgage,
	operationType,
	dataClient,
	isSabadell,
	mortgageVerifiedDataAll
}) => {
	const [mortgageStatus, setMortgageStatus] = useState([]);
	const [dataBankBlock, setDataBankBlock] = useState([]);
	const params = useParams();
	const [bankCode, setBankCode] = useState(undefined);

	const typePropertyType = (a) => {
		if (a === 'newConstruction' || a === 'Obra Nueva') {
			return 'Obra nueva';
		} else if (a === 'secondHand' || a === 'Segunda Mano') {
			return 'Segunda mano';
		}
	};

	useEffect(() => {
		if (mortgageVerifiedDataAll !== undefined) {
			axios
				.get(`${env.api.url}/api/hadmin/mortgages/${params.mortgageId}`, {
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				})
				.then((response) => {
					setMortgageStatus(response.data);
				});
			getBankBloked();

			if (mortgage?.haveABank) {
				axios
					.get(`${env.api.url}/banks/banks/${mortgage?.haveABank}`, {
						headers: { Authorization: `Bearer ${authClient.getToken()}` }
					})
					.then((response) => {
						setBankCode(response.data.data);
					});
			}
		}
	}, [mortgageVerifiedDataAll]);

	const typeHouseType = (a) => {
		if (a === 'main') {
			return 'Vivienda principal';
		} else if (a === 'second') {
			return 'Segunda vivienda';
		} else if (a === 'investor') {
			return 'Inversión';
		}
	};

	const changeHaveAInterest = (a) => {
		if (a === 'fixed' || a === 'FIXED') {
			return 'Fijo: ';
		} else if (a === 'variable' || a === 'VARIABLE') {
			return 'Variable: ';
		} else if (a === 'mixed') {
			return 'Mixto: ';
		}
	};

	const nameLinkages = {
		lifeInsurance: 'Seguro de vida',
		homeInsurance: 'Seguro del hogar',
		pensionPlan: 'Plan de pensiones',
		creditCards: 'Tarjetas de crédito/débito',
		carInsurance: 'Seguro del coche',
		unemploymentInsurance: 'Seguro de desempleo',
		receiptsDomiciliation: 'Domiciliación de recibos',
		payrollDomiciliation: 'Domiciliación de nómina',
		protectedPayments: 'Seguro de protección de pagos'
	};

	const formatData = (title, data) => (
		<div
			style={{
				display: 'flex',
				alignItems: title !== 'Proyecto que le interesa:' && 'center'
			}}>
			<Text
				style={{
					fontSize: '12px',
					fontWeight: '500',
					color: '#2F4858',
					marginRight: '4px'
				}}>
				{title}
			</Text>
			<Text
				style={{
					fontSize: '12px',
					fontWeight: '500',
					color: '#02C3CD'
				}}>
				{data}
			</Text>
		</div>
	);
	const formatIcons = (data) => {
		let arrayIcons = [];

		if (data === 'Tarjetas de crédito/débito') {
			arrayIcons.push(<CreditCardOutlined style={{ fontSize: 20 }} />);
		} else if (data === 'Plan de pensiones') {
			arrayIcons.push(<EuroOutlined style={{ fontSize: 20 }} />);
		} else if (data === 'Seguro del coche') {
			arrayIcons.push(<CarOutlined style={{ fontSize: 20 }} />);
		} else if (data === 'Seguro del hogar') {
			arrayIcons.push(<HomeOutlined style={{ fontSize: 20 }} />);
		} else if (data === 'Seguro de vida') {
			arrayIcons.push(<HeartOutlined style={{ fontSize: 20 }} />);
		} else if (data === 'Domiciliación de nómina') {
			arrayIcons.push(<FileDoneOutlined style={{ fontSize: 20 }} />);
		} else if (data === 'Domiciliación de recibos') {
			arrayIcons.push(<ContainerOutlined style={{ fontSize: 20 }} />);
		} else if (data === 'Seguro de desempleo') {
			arrayIcons.push(<LockOutlined style={{ fontSize: 20 }} />);
		} else if (data === 'Seguro de protección de pagos') {
			arrayIcons.push(<img alt="seguro" src={'/images/protectedPay.png'}></img>);
		}

		return arrayIcons;
	};

	const formatOffert = (data) => (
		<div>
			<Text
				style={{
					fontSize: '14px',
					fontWeight: '600',
					color: '#2F4858'
				}}>
				{data}
			</Text>
		</div>
	);
	const titleName = {
		lessThanSixMonths: 'En menos de 6 meses',
		withinSixOrTwelveMonths: 'Dentro de 6-12 meses',
		whitinOneOrTwoYears: 'Dentro de 1 o 2 años'
	};

	const selectImagebank = (bankCode) => (
		<div style={{ width: 35, height: 32 }}>
			<img
				src={
					'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
					`${bankCode}` +
					'_iso.png'
				}
				alt={bankCode}
				style={{ height: '100%', width: '100%' }}
			/>
		</div>
	);

	const getBankBloked = (bank) => {
		axios
			.get(`${env.api.url}/banks/operation-blocked-bank/by-operation/${params.mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				if (mortgageVerifiedDataAll.desiredOffer.interest) {
					if (mortgageVerifiedDataAll.desiredOffer.sections[1]?.tin !== undefined) {
						mortgageVerifiedDataAll.desiredOffer.sections[1].tin =
							mortgageVerifiedDataAll.desiredOffer.sections[1].tin * 100;
					}
					if (mortgageVerifiedDataAll.desiredOffer.sections[0]?.tin !== undefined) {
						mortgageVerifiedDataAll.desiredOffer.sections[0].tin =
							mortgageVerifiedDataAll.desiredOffer.sections[0].tin * 100;
					}

					const offerSabadell = {
						rateType:
							mortgageVerifiedDataAll.desiredOffer.interest === 'FIXED'
								? 'fixed'
								: mortgageVerifiedDataAll.desiredOffer.interest === 'MIXED'
								? 'mixed'
								: 'variable',
						stages: mortgageVerifiedDataAll.desiredOffer.sections,
						rate:
							mortgageVerifiedDataAll.desiredOffer.interest === 'FIXED'
								? mortgageVerifiedDataAll.desiredOffer.sections[0].tin
								: undefined,
						linkages: mortgageVerifiedDataAll.desiredOffer.linkages
					};

					setDataBankBlock([offerSabadell, ...response.data.data]);
				} else {
					setDataBankBlock(response.data.data);
				}
			});
	};

	return (
		<>
			<Row gutter={24}>
				<Col span={12}>
					<Card
						style={{
							border: '1px solid #C0DAEE',
							borderRadius: '4px',
							backgroundColor: 'white',
							height: '100%'
						}}>
						<Row gutter={24}>
							<img
								src="/images/foto.png"
								height="100%"
								width="20%"
								style={{ position: 'absolute', left: 0, top: 0 }}
							/>
							<Col lg={4} xs={4}></Col>
							<Col lg={20} xs={20} style={{ paddingRight: 0 }}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										marginRight: 16,
										marginLeft: 24
									}}>
									<div style={{ width: '100%' }}>
										<div
											style={{
												marginBottom: '16px',
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center'
											}}>
											<Text
												style={{
													fontSize: '14px',
													fontWeight: '600',
													color: '#2F4858'
												}}>
												Datos de la propiedad
											</Text>
											{isSabadell && (
												<a href={`/operations/mortgages/${params.mortgageId}/recommender-data`}>
													<Text
														style={{
															color: '#006DFF',
															fontSize: 12,
															fontWeight: 500,
															display: 'flex',
															alignItems: 'center',
															cursor: 'pointer'
														}}>
														Datos para recomendador{' '}
														<EyeOutlined style={{ marginLeft: 4, fontSize: 14 }} />
													</Text>
												</a>
											)}
										</div>
										{formatData(
											'Ubicación:',
											mortgage && mortgage.province ? mortgage.province : '-'
										)}
										{formatData(
											'Tipo:',
											mortgage && mortgage.propertyType
												? typePropertyType(mortgage.propertyType)
												: '-'
										)}
										{formatData(
											'Uso previsto:',
											mortgage && mortgage.houseType ? typeHouseType(mortgage.houseType) : '-'
										)}
										<div>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}>
												{mortgage?.houseState === 'searching' ? 'Techo:' : 'Precio:'}
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#02C3CD'
												}}>
												{mortgage?.houseState !== 'searching' &&
													mortgage?.maxBudget &&
													numeral(mortgage.maxBudget).format('0,0 $')}
												{mortgage &&
													!mortgage.viabilityData &&
													mortgage?.houseState === 'searching' &&
													'Precio no calculado'}
												{mortgage &&
													mortgage.houseState === 'searching' &&
													mortgage.viabilityData &&
													mortgage.viabilityData.operation &&
													mortgage.viabilityData.operation.property &&
													numeral(mortgage.viabilityData.operation.property).format('0,0 $')}
											</Text>
										</div>
										{formatData(
											'Ahorros disponibles:',
											mortgage && numeral(mortgage.savings).format('0,0 $')
										)}
										{formatData(
											'Previsión de compra:',
											mortgage && mortgage.purchaseForecast && titleName[mortgage.purchaseForecast]
										)}
										{mortgage?.operationType === 'green-mortgage' ? (
											formatData(
												'Presupuesto verde:',
												mortgage && numeral(mortgage.ecoBudget).format('0,0 $')
											)
										) : (
											<div>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: '500',
														color: '#2F4858',
														marginRight: '4px'
													}}></Text>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: '500',
														color: '#02C3CD'
													}}></Text>
											</div>
										)}
									</div>
								</div>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col
					span={12}
					style={{
						marginTop: operationType === 'green-mortgage' && 24
					}}>
					<Card
						style={{
							border: '1px solid #C0DAEE',
							borderRadius: '4px',
							backgroundColor: 'white',
							height: '100%'
						}}>
						<Row gutter={24}>
							<Col lg={21}>
								<div style={{ marginBottom: '16px' }}>
									<Text
										style={{
											fontSize: '14px',
											fontWeight: '600',
											color: '#2F4858'
										}}>
										Oferta hipotecaria
									</Text>
								</div>
							</Col>
						</Row>
						{dataClient && mortgage && bankCode && (
							<Row gutter={24} style={{ marginBottom: 16 }}>
								<Col lg={6}>{selectImagebank(bankCode.bankCode)}</Col>
								{formatOffert(
									mortgage?.haveAInterest ? changeHaveAInterest(mortgage?.haveAInterest) : '-'
								)}

								{formatOffert(
									mortgage?.haveATin !== undefined
										? `${parseFloat(mortgage?.haveATin).toFixed(2)}%`
										: '-'
								)}
							</Row>
						)}

						{dataClient === false &&
							dataBankBlock?.map((data) => (
								<Row key={data.id} gutter={24} style={{ marginBottom: 16 }}>
									<Col lg={2}>
										{data.bank === undefined ? (
											selectImagebank('sabadell')
										) : data?.bank?.bankCode ? (
											selectImagebank(data?.bank?.bankCode)
										) : (
											<Text
												style={{
													fontSize: 14,
													fontWeight: 500,
													color: '#2F4858'
												}}>
												{data?.bank?.name}
											</Text>
										)}
									</Col>
									{data?.rateType === 'fixed' && (
										<Col lg={22} style={{ marginTop: 6 }}>
											<Row>
												<Col lg={11}>
													<div style={{ display: 'flex', columnGap: 4 }}>
														{formatOffert(
															data?.rateType ? changeHaveAInterest(data?.rateType) : '-'
														)}
														{formatOffert(
															data?.rate !== undefined
																? `${parseFloat(data?.rate).toFixed(2)}%`
																: '-'
														)}
													</div>
												</Col>
												<Col lg={2}>
													<div
														style={{
															height: 1,
															background: '#748EA0',
															width: 24,
															marginTop: 10
														}}></div>
												</Col>
												<Col lg={11}>
													{data?.linkages ? (
														<div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 4 }}>
															{Object.keys(data?.linkages)?.map(
																(linkages) =>
																	data.linkages[linkages] && formatIcons(nameLinkages[linkages])
															)}
														</div>
													) : (
														'-'
													)}
												</Col>
											</Row>
										</Col>
									)}
									{data?.rateType !== 'fixed' && (
										<Col lg={18} style={{ marginTop: 6 }}>
											<Row gutter={20}>
												<Col lg={11} style={{ display: 'flex' }}>
													{formatOffert(data?.rateType ? changeHaveAInterest(data?.rateType) : '-')}
													{formatOffert(
														data?.stages?.[0]?.tin
															? `${parseFloat(data?.stages?.[0]?.tin).toFixed(2)}%`
															: '-'
													)}
													{formatOffert('/ Eur +')}
													{formatOffert(
														data?.stages?.[1]?.tin
															? `${parseFloat(data?.stages?.[1]?.tin).toFixed(2)}%`
															: '-'
													)}
												</Col>
												<Col lg={2}>
													<div
														style={{
															height: 1,
															background: '#748EA0',
															width: 24,
															marginTop: 10
														}}></div>
												</Col>
												<Col lg={11}>
													{data?.linkages ? (
														<div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 4 }}>
															{Object.keys(data?.linkages)?.map(
																(linkages) =>
																	data.linkages[linkages] && formatIcons(nameLinkages[linkages])
															)}
														</div>
													) : (
														'-'
													)}
												</Col>
											</Row>
										</Col>
									)}
								</Row>
							))}
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default DataNewFormatMortgage;

import React, { useState } from 'react';
import { Modal, Col, Row, Select } from 'antd';

const { Option } = Select;

const ModalTag = ({
	visible,
	onCancel,
	onCreate,
	getSelectDocumentTypes,
	participants,
	mortgageData,
	guarantors,
	owner,
	type,
	setBolOwner,
	setBolType
}) => {
	const [ownerData, setOwnerData] = useState(undefined);
	const [typeData, setTypeData] = useState(undefined);

	const participantsOptions = [];
	const guarantorsOptions = [];
	for (let i = 1; i <= participants; i++) {
		participantsOptions.push(
			<Option key={`owner-option-${i}`} value={`owner${i}`}>
				Titular {i}: {mortgageData && mortgageData[`owner${i}`] && mortgageData[`owner${i}`].name}
			</Option>
		);
	}

	for (let i = 0; i < guarantors; i++) {
		guarantorsOptions.push(
			<Option key={`guarantors-option-${i + 1}`} value={`guarantor${i + 1}`}>
				Avalista {i + 1}:{' '}
				{mortgageData &&
					mortgageData[`guarantor${i + 1}`] &&
					mortgageData[`guarantor${i + 1}`].name}
			</Option>
		);
	}
	return (
        (<Modal
			className="gb-modal-style-common modal-task-button-style"
			cancelButtonProps={{ className: 'button-secundary-gibobs', style: { width: '96px' } }}
			okButtonProps={{ className: 'button-primari-gibobs', style: { width: '96px' } }}
			open={visible}
			title="Etiquetar en bloque"
			okText="Confirmar"
			cancelText="Cancelar"
			width={320}
			onCancel={() => {
				onCancel();
			}}
			onOk={() => {
				onCreate({
					owner: owner,
					type: type
				});
			}}
		>
            <div className="gb-form-task-documentation">
				<Row gutter={24}>
					<Col lg={24} xs={24} style={{ textAlignLast: 'left' }}>
						<div className="blockForm">
							<div className="label">Titular</div>
							<Select
								style={{ width: '100%' }}
								value={owner}
								onChange={(e) => {
									// setOwnerData(e);
									setBolOwner(e);
								}}
								placeholder={'Seleccione'}
							>
								{participantsOptions}
								{guarantorsOptions}
							</Select>
						</div>
					</Col>
					<Col lg={24} xs={24} style={{ textAlignLast: 'left' }}>
						<div className="blockForm">
							<div className="label"> Tipo de documento</div>
							<Select
								value={type}
								style={{ width: '100%', textAlign: 'left' }}
								placeholder={'Seleccione'}
								onChange={(e) => {
									// setTypeData(e);
									setBolType(e);
								}}
								dropdownRender={(menu) => {
									return menu;
								}}
							>
								{getSelectDocumentTypes()}
							</Select>
						</div>
					</Col>
				</Row>
			</div>
        </Modal>)
    );
};

export default ModalTag;

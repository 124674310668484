import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'moment/locale/es';
import moment from 'moment';
import numeral from 'numeral';
import { setTwoToneColor } from '@ant-design/icons';
import { Provider } from 'react-redux';
import { store } from './store';
import './App.less';
import { ConfigProvider, theme } from 'antd';
import { AnalyticsProvider } from 'use-analytics';
import Analytics from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';
import amplitudePlugin from '@analytics/amplitude';
import { BrowserRouter } from 'react-router-dom';
import es_ES from 'antd/lib/locale/es_ES';
import { PostHogProvider } from 'posthog-js/react';

moment.locale('es');

numeral.register('locale', 'es', {
	delimiters: {
		thousands: '.',
		decimal: ','
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't'
	},
	ordinalIgnore: function (number) {
		return number === 1 ? 'er' : 'ème';
	},
	currency: {
		symbol: '€'
	}
});

numeral.locale('es');

setTwoToneColor('#5cb889');

const analytics = Analytics({
	app: 'gibobs-app',
	plugins: [
		amplitudePlugin({
			apiKey: '14b5cb95ff98ea2071960fb05f023c0d',
			options: {
				trackingOptions: {
					ip_address: true
				}
			}
		}),
		mixpanelPlugin({
			token: '6e00ab62f1f5de247c695b83a4aa3eaa'
		})
	]
});

const options = {
	api_host: 'https://eu.i.posthog.com'
};

window.addEventListener('error', (e) => {
	if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
		const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
		const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
		if (resizeObserverErr) {
			resizeObserverErr.setAttribute('style', 'display: none');
		}
		if (resizeObserverErrDiv) {
			resizeObserverErrDiv.setAttribute('style', 'display: none');
		}
	}
});
ReactDOM.render(
	<AnalyticsProvider instance={analytics}>
		<PostHogProvider apiKey="phc_wCPqoKRGKNNCy2WcLWT0bxOfodcN0u1nLb3xMtVhqDS" options={options}>
			<Provider store={store}>
				<ConfigProvider
					locale={es_ES}
					theme={{
						algorithm: theme.defaultAlgorithm,
						token: { colorPrimary: '#02C3CD', colorBgLayout: '#FAFEFE' }
					}}>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</ConfigProvider>
			</Provider>
		</PostHogProvider>
	</AnalyticsProvider>,

	document.getElementById('root')
);

serviceWorker.unregister();

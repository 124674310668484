import React, { useState } from 'react';
import { Button, Typography, Col, Row, Card, Input, Modal, Form, Select, notification } from 'antd';
import {
	CheckCircleOutlined,
	EditOutlined,
	MailOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const InfoBankOffers = ({
	showModalEmail,
	requestExtra,
	mortgageId,
	banksOffer,
	onRefresh,
	dataBankRequest,
	numberRequest
}) => {
	const [registerRequest, setRegisterRequest] = useState(false);
	const [form, formEdit] = Form.useForm();

	const [resolveModal, setResolveModal] = useState(false);
	const [request, setRequest] = useState();

	const [registerRequestEdit, setRegisterRequestEdit] = useState(false);
	let navigate = useNavigate();

	const [showModalRegister, setShowModalRegister] = useState(false);
	const [visible, setVisible] = useState(false);
	const [valueTextAclarations, setValueTextAclarations] = useState(undefined);



	const handleRegisterRequest = () => {
		setRegisterRequest(!registerRequest);
	};

	const handleRegisterRequestEmail = () => {

		navigate(`/operations/mortgages/${mortgageId}/view-document-list`,
		{
			state: {
				bankId: dataBankRequest.bankId,
				bankRequestId: dataBankRequest.id,
				bankRequest: dataBankRequest,
				response: true
			}
		});
	};

	const cancelRegisterRequest = () => {
		setRegisterRequest(!registerRequest);
	};

	const okRegisterRequest = (values) => {
		axios
			.post(
				`${env.api.url}/banks/bank-request-extra/create`,
				{
					operationId: mortgageId,
					status: true,
					bankCode: banksOffer.bankCode,
					bankRequest: banksOffer.id,
					type: values.type,
					explanation: values.description || ''
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				onRefresh();
			});

		setRegisterRequest(!registerRequest);
	};

	const handleRegisterRequestEdit = () => {
		setRegisterRequestEdit(!registerRequestEdit);
	};

	const cancelRegisterRequestEdit = () => {
		setRegisterRequestEdit(!registerRequestEdit);
	};

	const okRegisterRequestEdit = (values) => {
		axios
			.post(
				`${env.api.url}/banks/bank-request-extra/update/${requestExtra.id}`,
				{
					operationId: mortgageId,
					status: true,
					bankCode: banksOffer.bankCode,
					bankRequest: banksOffer.id,
					type: values.type,
					explanation: values.description
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				onRefresh();
			});

		setRegisterRequestEdit(!registerRequestEdit);
	};

	const resolveOffer = () => {
		setResolveModal(!resolveModal);
	};

	const okResolveOfferExtra = (type, text) => {
		if (type === 'EVO Aclaraciones') {
			notification.success({
				message: 'Enviando aclaraciones a EVO',
				duration: 25,
				description: 'Se están enviando las aclaraciones a EVO. Si hay algún fallo se creará una tarea de error.'
			});
			axios
				.post(
					`${env.api.url}/banks/evo/send-clarifications`,
					{
						bankRequestExtraId: requestExtra.id,
						clarification: valueTextAclarations !== undefined ? valueTextAclarations : text
					},
					{
						headers: { Authorization: `Bearer ${authClient.getToken()}` }
					}
				)
				.then((response) => {
					
					onRefresh();
				});
			setResolveModal(!resolveModal);
		} else {
			axios
				.get(`${env.api.url}/banks/bank-request-extra/remove/${requestExtra.id}`, {
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				})
				.then((response) => {
					onRefresh();
				});
			setResolveModal(!resolveModal);

		}
	};

	const hideAddTaskModal = () => {
		setVisible(false);
	};

	const handleSubmitForm = (values, id) => {
		const objAsigment = {
			bankRequestId: id,
			bankAccountData: {
				name: values.name === undefined ? '' : values.name,
				email: values.email,
				phone: values.phone === undefined ? '' : values.phone,
				office: values.office === undefined ? '' : values.office,
				reference: values.reference === undefined ? '' : values.reference
			}
		};
		axios
			.post(`${env.api.url}/banks/bank-request/assign-to-account-executive`, objAsigment, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setVisible(!visible);
				onRefresh();
			});
	};

	return (
		<>
			<Card
				style={{ border: '1px solid #C0DAEE', borderRadius: 4, minHeight: 175, height: '100%' }}>
				{requestExtra === undefined && (
					<Row style={{ display: 'block' }}>
						<Text style={{ fontSize: 12, fontWeight: 600, color: '#2F4858' }}>Solicitud extra</Text>
					</Row>
				)}
				{requestExtra === undefined && (
					<div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, width: 206 }}>
						<Text style={{ fontSize: 12, fontWeight: 600, color: '#748EA0' }}>
							{'No hay solicitudes extra'}
						</Text>
					</div>
				)}
				{requestExtra === undefined && (
					<div style={{ width: '100', textAlign: 'center', marginTop: 32 }}>
						<Button
							disabled={
								dataBankRequest.status === 'error' || dataBankRequest.status === 'canceled'
									? true
									: false
							}
							className="button-secundary-gibobs"
							style={{ marginTop: 'auto' }}
							onClick={() => handleRegisterRequest()}>
							Registrar solicitud extra
						</Button>
					</div>
				)}
				{requestExtra !== undefined && (
					<Row style={{ display: 'block' }}>
						<Text style={{ fontSize: 12, fontWeight: 600, color: '#2F4858' }}>Solicitud extra</Text>
						<div style={{ float: 'right' }}>
							<a
								onClick={() =>
									dataBankRequest.bankAccountData.email !== undefined
										? handleRegisterRequestEmail()
										: setVisible(!visible)
								}
								style={{ cursor: 'pointer' }}>
								<MailOutlined style={{ color: '#02C3CD', fontSize: 14 }} />
							</a>
							<a onClick={() => handleRegisterRequestEdit()} style={{ cursor: 'pointer' }}>
								<EditOutlined style={{ color: '#02C3CD', fontSize: 14, marginLeft: 6 }} />
							</a>
							<a onClick={() => resolveOffer()} style={{ cursor: 'pointer' }}>
								<CheckCircleOutlined style={{ color: '#02C3CD', fontSize: 14, marginLeft: 6 }} />
							</a>
						</div>
					</Row>
				)}
				{requestExtra !== undefined && (
					<div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, width: 206 }}>
						<Text style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD' }}>
							{requestExtra.type}
						</Text>
						<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 10 }}>
							{requestExtra.explanation}
						</Text>
					</div>
				)}

				{numberRequest > 1 && (
					<div style={{ position: 'absolute', bottom: 10, right: 10 }}>
						<Text style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD' }}>
							{'+ ' + numberRequest}
						</Text>
					</div>
				)}
			</Card>

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				title={
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						{'Solicitudes extras'}
					</Text>
				}
				closable={true}
				open={registerRequest}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							okRegisterRequest(values);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				onCancel={() => cancelRegisterRequest()}>
				<Form
					form={form}
					layout="horizontal"
					name="basic"

					//onFinish={onFinish}
				>
					<Row style={{ marginBottom: -30 }}>
						<Col lg={24} style={{ textAlignLast: 'left' }}>
							<Form.Item
								style={{ display: 'table-caption', flexDirection: 'column' }}
								rules={[{ required: true, message: 'Seleccione un tipo de solicitud' }]}
								label={
									<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
										Tipo de solicitud extra
									</Text>
								}
								name="type">
								<Select placeholder="Seleccione" style={{ width: '240px' }}>
									<Option value="Documentación">Documentación</Option>
									<Option value="Replanteamiento de la operación">
										Replanteamiento de la operación
									</Option>
									<Option value="Otros">Otros</Option>
								</Select>
							</Form.Item>

							<Form.Item
								style={{ display: 'flex', flexDirection: 'column', marginTop: -15 }}
								rules={[{ required: true, message: 'Seleccione una explicación' }]}
								label={
									<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
										Explicación
									</Text>
								}
								name="description">
								<TextArea
									style={{ height: 72, borderRadius: 0, marginBottom: 16 }}
									placeholder={
										'Incluye las nuevas solicitudes que te haya trasladado el banco'
									}></TextArea>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				title={
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						{'Solicitudes extras'}
					</Text>
				}
				closable={true}
				visible={registerRequestEdit}
				destroyOnClose={true}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							okRegisterRequestEdit(values);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				onCancel={() => cancelRegisterRequestEdit()}>
				<Form
					form={form}
					layout="horizontal"
					name="basic"

					//onFinish={onFinish}
				>
					<Row style={{ marginBottom: -30 }}>
						<Col lg={24} style={{ textAlignLast: 'left' }}>
							<Form.Item
								style={{ display: 'table-caption', flexDirection: 'column' }}
								label={
									<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
										{'Tipo de solicitud extra'}
									</Text>
								}
								name="type">
								<Select
									defaultValue={requestExtra && requestExtra.type}
									placeholder="Seleccione"
									style={{ width: '240px' }}>
									<Option value="Documentación">Documentación</Option>
									<Option value="Replanteamiento de la operación">
										Replanteamiento de la operación
									</Option>
									<Option value="Otros">Otros</Option>
								</Select>
							</Form.Item>

							<Form.Item
								style={{ display: 'flex', flexDirection: 'column', marginTop: -15 }}
								label={
									<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
										Explicación
									</Text>
								}
								name="description">
								<TextArea
									defaultValue={requestExtra && requestExtra.explanation}
									style={{ height: 72 }}
									placeholder={
										'Incluye las nuevas solicitudes que te haya trasladado el banco'
									}></TextArea>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				title={
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						{'Solicitudes extras'}
					</Text>
				}
				closable={true}
				visible={resolveModal}
				onOk={() => okResolveOfferExtra(requestExtra && requestExtra.type, requestExtra.explanation )}
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				onCancel={() => resolveOffer()}
				width={400}>
				<div style={{ marginBottom: 10 }}>
					<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
						¿Quieres resolver esta solicitud extra?
					</Text>
				</div>
				{requestExtra && requestExtra.type === 'EVO Aclaraciones' && (
					<TextArea defaultValue={requestExtra.explanation} onChange={(value) => setValueTextAclarations(value.target.value)} style={{ height: 70 }}></TextArea>
				)}
			</Modal>

			<Modal
				visible={visible}
				className="gb-modal-style-common ModalCommonSendMessage"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				title="Registrar asignación"
				okText="Aceptar"
				cancelText="Cancelar"
				width={496}
				onCancel={() => hideAddTaskModal()}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							handleSubmitForm(values, dataBankRequest.id);
							form.resetFields();
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}>
				<div>
					<Text style={{ fontSize: 12, color: '#2F4858', fontWeight: 400 }}>
						El ejecutivo de cuentas no está registrado todavía. Por favor, introduce sus datos para
						poder asignarle esta operación.
					</Text>
				</div>

				<Form
					form={form}
					layout="vertical"
					name="normal_login"
					className="login-form"
					initialValues={request !== undefined ? request : {}}
					style={{ marginTop: 16 }}>
					<Row gutter={[16, 16]}>
						<Col span={12}>
							<Form.Item
								className="inputFormAnswers"
								name="name"
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Nombre y Apellidos'}
									</Text>
								}>
								<Input placeholder="Nombre y Apellidos" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								className="inputFormAnswers"
								name="email"
								rules={[
									{
										required: true
									}
								]}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'Email'}</Text>
								}>
								<Input type="email" placeholder="Email" />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[16, 24]} style={{ marginBottom: -15, marginTop: -12 }}>
						<Col span={12}>
							<Form.Item
								className="inputFormAnswers"
								name="phone"
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Telefono'}
									</Text>
								}>
								<Input type="number" placeholder="Telefono de contacto" />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								className="inputFormAnswers"
								name="office"
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Oficina/Sucursal'}
									</Text>
								}>
								<Input placeholder="Información de oficina/sucursal" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[16, 24]} style={{ marginBottom: -15, marginTop: -12 }}>
						<Col span={24}>
							<Form.Item
								className="inputFormAnswers"
								name="reference"
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Referencia'}
									</Text>
								}>
								<Input placeholder="Referencia del banco" />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};
export default InfoBankOffers;

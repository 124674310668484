import React, { useEffect, useRef } from 'react';
import { InputNumber as AntDInputNumber, InputNumberProps } from 'antd';
import numeral from 'numeral';
import './InputNumber.less';

type GBInputType = 'currency' | 'm2' | 'number';
type GBInputDecimalType = 'integer' | 'currency';

export type AfroditaInputNumberProps = {
	suffix: string;
	gbType: GBInputType;
	gbDecimalType?: GBInputDecimalType;
};

const inputTypesList = {
	currency: '€',
	m2: 'm²',
	number: ''
};
const inputDecimalFormatList = {
	currency: '0,0[.]0[0]',
	integer: '0,0'
};

export const InputNumber: React.FC<Props & InputNumberProps> = (props) => {
	const cssClasses =
		props.gbType === 'number'
			? ['afrodita', 'gb-input-number', props.className]
			: ['afrodita', 'gb-input-number', 'gb-input-number-money'];

	const ref = useRef<HTMLInputElement>(null);
	const suffix = props.suffix;

	useEffect(() => {
		if (suffix) {
			ref.current?.parentElement?.setAttribute('gb-suffix-input', suffix);
		}
	}, [ref]);

	const removeCurrencySymbol = (value: string | undefined): string => {
		if (!value) return '';
		return value.replace(/€/g, '');
	};

	const formatValue = (value: number | string | undefined): string => {
		if (value === undefined) return '';
		const cleanValue = removeCurrencySymbol(value.toString());
		const result = numeral(parseFloat(cleanValue)).format(
			inputDecimalFormatList[props.gbDecimalType || 'currency']
		);
		return result;
	};

	const parseValue = (value: string | undefined): number => {
		const cleanValue = removeCurrencySymbol(value).replace(/\./g, '').replace(',', '.');
		return parseFloat(cleanValue);
	};

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const cleanValue = removeCurrencySymbol(e.target.value);
		const parsedValue = parseValue(cleanValue);
		e.target.value = formatValue(parsedValue);

		if (props.onBlur) {
			props.onBlur(e);
		}
	};

	return (
		<AntDInputNumber
			ref={ref}
			max={999999999999999}
			min={0}
			{...props}
			className={[...cssClasses, props.className || ''].join(' ')}
			formatter={(value: number | string | undefined): string => {
				const result = numeral(parseFloat((value || '').toString())).format(
					inputDecimalFormatList[props.gbDecimalType || 'currency']
				);
				return result;
			}}
			parser={(value: string | undefined): number => {
				const result = (value || '').replace(/\./g, '').replace(',', '.');
				return parseFloat(result);
			}}
			controls={false}
			onBlur={handleBlur}
			onKeyDown={(e) => {
				const isComplex = e.ctrlKey || e.altKey || e.shiftKey || e.metaKey;
				const isSpecials =
					e.key === 'ArrowLeft' ||
					e.key === 'ArrowRight' ||
					e.key === 'Backspace' ||
					e.key === 'Escape' ||
					e.key === 'Tab' ||
					e.key === 'Enter' ||
					e.key === ',';

				if (isNaN(parseInt(e.key)) && !isComplex && !isSpecials) {
					e.preventDefault();
				}
			}}
		/>
	);
};

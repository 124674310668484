/* eslint-disable indent */
import { SHOWMODAL, UPDATEMORTGAGES, TABSPAGE, ANALYST } from './Constants';

export const INITIAL_STATE = {
	showModal: false,
	tabsPage: undefined,
	updateMortgages: false,
	analyst: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SHOWMODAL:
			return { ...state, showModal: action.payload };
		case UPDATEMORTGAGES:
			return { ...state, updateMortgages: action.payload };
		default:
			return state;
		case TABSPAGE:
			return { ...state, tabsPage: action.payload };
		case ANALYST:
			return { ...state, analyst: action.payload };
	}
};

import React from 'react';
import { Input, Layout, Form, Modal, Row, Col, Typography } from 'antd';

const { Text } = Typography;

const RegisterAssigment = ({ visible, onCancel, onCreate, bankAccountData }) => {
	const [form] = Form.useForm();

	return (
        (<Modal
			open={visible}
			className="gb-modal-style-common ModalCommonSendMessage"
			cancelButtonProps={{ className: 'button-secundary-gibobs', style: { height: 32, width: 96 } }}
			okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
			title="Registrar asignación"
			okText="Aceptar"
			cancelText="Cancelar"
			width={496}
			onCancel={onCancel}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						onCreate(values, bankAccountData.bankRequestId);
						form.resetFields();
						// setFileName("");
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
            <div>
				<Text style={{ fontSize: 12, color: '#2F4858', fontWeight: 400 }}>
					El ejecutivo de cuentas no está registrado todavía. Por favor, introduce sus datos para
					poder asignarle esta operación.
				</Text>
			</div>
            <Form
				form={form}
				layout="vertical"
				name="normal_login"
				className="login-form"
				initialValues={bankAccountData !== undefined ? bankAccountData : {}}
				// onFinish={handleSubmitForm}
				style={{ marginTop: 16 }}
			>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Form.Item
							className="inputFormAnswers"
							name="name"
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Nombre y Apellidos'}
								</Text>
							}
						>
							<Input placeholder="Nombre y Apellidos" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							className="inputFormAnswers"
							name="email"
							rules={[
								{
									required: true
								}
							]}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'Email'}</Text>
							}
						>
							<Input type="email" placeholder="Email" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={[16, 24]} style={{ marginBottom: -15, marginTop: -12 }}>
					<Col span={12}>
						<Form.Item
							className="inputFormAnswers"
							name="phone"
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Telefono'}
								</Text>
							}
						>
							<Input type="number" placeholder="Telefono de contacto" />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item
							className="inputFormAnswers"
							name="office"
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Oficina/Sucursal'}
								</Text>
							}
						>
							<Input placeholder="Información de oficina/sucursal" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 24]} style={{ marginBottom: -15, marginTop: -12 }}>
					<Col span={24}>
						<Form.Item
							className="inputFormAnswers"
							name="reference"
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Referencia'}
								</Text>
							}
						>
							<Input placeholder="Referencia del banco" />
						</Form.Item>
					</Col>
				</Row>
			</Form>
        </Modal>)
    );
};

export default RegisterAssigment;

import React, { useState, useEffect } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import axios from 'axios';
import {
	Row,
	Spin,
	Col,
	Button,
	Progress,
	Typography,
	Card,
	DatePicker,
	Modal,
	Select,
	message,
	notification
} from 'antd';
import ListOfGroupFile from './ListOfGroupFile';
import FilesTableUpload from './filesTableUpload';
import moment from 'moment';
import { getOperationDocuments } from '../../../../../store/documents/actions';
import { saveAs } from 'file-saver';
import mimeTypes from 'mime-types';
import jszip from 'jszip';
import { updateStateMortgages } from '../../../../../store/mortgages/actions';
import { Buffer } from 'buffer';

import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { buildAndSaveRecomendadorPdf } from './PDFFunctions';
import { UploadOutlined } from '@ant-design/icons';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');

const { Text } = Typography;
const { Option } = Select;

export default (props) => {
	const [profile, setProfile] = useState({});
	const [updateMark, setUpdateMark] = useState(0);
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);
	let { mortgageId, tabs, subTab, nameMortgage, mortgage } = props;
	const token = authClient.getToken();
	const [mortgageData, setMortgageData] = useState(null);
	const [mortgageDataAll, setMortgageDataAll] = useState(null);
	const [account, setAccount] = useState(null);
	const [ownerList, setOwnerList] = useState([]);
	let [download, setDownload] = useState(false);
	const dateNow = moment().format('YYYY-MM-DD');
	const [infoProfile, setInfoProfile] = useState([]);
	const [groupedDownloadIsAllowed, setGroupedDownloadIsAllowed] = useState(true);
	const loading = useSelector((state) => state.documentation.loading);
	const documentsNeed = useSelector((state) => state.documentation.needed);
	const documentsCompleted = useSelector((state) => state.documentation.completed);
	const dispatch = useDispatch();
	const location = useLocation();
	const [loadingRecBtn, setLoadingRecBtn] = useState(false);

	const tabskey = location.search;
	const documents = useSelector((state) => state.documentation.documents);
	const mortgageUpda = useSelector((state) => state.mortgages.updateMortgages);
	const [viewModal, setViewModal] = useState(false);

	const [loading2, setLoading2] = useState(false);
	const [documentUploads, setDocumentUploads] = useState([]);

	useEffect(() => {
		setProfile(authClient.getProfile());
		loadDocumentsData();
		loadData();
	}, [mortgageId, tabskey]);

	useEffect(() => {
		axios
			.get(`${env.api.url}/v1/hadmin/user/profile/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setInfoProfile(response.data.data);
			});

		axios
			.get(`${env.api.url}/v1/documents/is-over-limits/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setGroupedDownloadIsAllowed(response.data.data.isOverLimits);
			});

		getDocumentsUpload();
	}, []);

	useEffect(() => {
		if (account) {
			axios
				.all(
					account.map((l) =>
						axios.get(l.data.data.url, {
							responseType: 'arraybuffer'
						})
					)
				)
				.then((res) => {
					const docs = res.map((doc, i) => {
						return {
							name: account[i].data.data.name,
							extension: mimeTypes.extension(account[i].data.data.mime),
							base64: Buffer.from(doc.data, 'binary').toString('base64')
						};
					});
					exportZip(docs);
				});
		}
	}, [account]);

	const getDocumentsUpload = () => {
		axios
			.get(`${env.api.url}/v1/documents/get-operation-documents/${mortgageId}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((response) => {
				setDocumentUploads(response.data.data);
			})
			.catch((error) => {});
	};

	const saveData = (participant, key, value) => {
		if (moment(value).isBefore(dateNow, 'day')) {
			notification.error({
				message: 'Error en fecha de compromiso',
				description: 'La fecha debe ser posterior a la fecha y hora actual',
				className: 'error-notification-gibobs',
				icon: <img src={'/images/errorIcon.svg'} alt="ErrorIcon" />
			});
		} else {
			axios
				.post(
					`${env.api.url}/v1/mortgages/data/update/${mortgageId}`,
					{ key, participant, value },
					{
						headers: { Authorization: `Bearer ${authClient.getToken()}` }
					}
				)
				.then(() => {
					loadData();
					setViewModal(true);
				});
		}
	};

	const createTask = (item) => {
		setLoading2(true);

		axios
			.post(
				`${env.api.url}/v1/task/generate-task-documents`,
				{
					sendEmail: item,
					operationId: mortgageId
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				setLoading2(false);

				notification.success({
					message: 'Tarea creada para llamar al cliente en la fecha indicada'
				});
				setViewModal(false);
				dispatch(updateStateMortgages(!mortgageUpda));
			})
			.catch((error) => {
				setLoading2(false);
			});
	};

	const getAccounts = async (id) => {
		if (id === undefined) {
			const response = await axios.get(
				`${env.api.url}/v1/documents/get-operation-documents/${mortgageId}`,
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			);

			await axios
				.all(
					response.data.data.map((l) =>
						axios.get(`${env.api.url}/v1/documents/get-document-url/${l.id}`, {
							headers: { Authorization: `Bearer ${token}` }
						})
					)
				)
				.then((res) => {
					message.success('Estamos trabajando en la descarga de los documentos');

					const merged = [].concat(res);
					setAccount(merged);
				});
		}
		if (id !== undefined) {
			await axios
				.all(
					id.map((l) =>
						axios.get(`${env.api.url}/v1/documents/get-document-url/${l}`, {
							headers: { Authorization: `Bearer ${token}` }
						})
					)
				)
				.then((res) => {
					message.success('Estamos trabajando en la descarga de los documentos seleccionados');

					const merged = [].concat(res);
					setAccount(merged);
				});
		}
	};

	const exportZip = (files) => {
		const zip = jszip();

		files.map((file) => {
			let name = file.name;

			if (!name.endsWith(file.extension)) {
				name = `${name}.${file.extension}`;
			}

			name = name.replace(/\//, '');

			return zip.file(name, file.base64, { base64: true });
		});

		zip.generateAsync({ type: 'blob' }).then(function (content) {
			saveAs(content, `Gibobs-${nameMortgage}-documents.zip`);
		});
	};

	const loadData = async () => {
		setProfile(authClient.getProfile());

		const response = await axios.get(`${env.api.url}/v1/mortgages/data/get/${mortgageId}`, {
			headers: { Authorization: `Bearer ${authClient.getToken()}` }
		});
		setMortgageDataAll(response.data.data);

		const ownerList2 = [];

		for (let i = 1; i <= Number(response.data.data.mortgage.participants); i++) {
			ownerList2.push({
				ownerType: 'owner',
				ownerNumber: i,
				...response.data.data[`owner${i}`]
			});
		}

		for (let i = 0; i < Number(response.data.data.mortgage.guarantors); i++) {
			ownerList2.push({
				ownerType: 'guarantor',
				ownerNumber: i + 1,
				...response.data.data[`guarantor${i + 1}`]
			});
		}

		setOwnerList(ownerList2);
	};

	const loadDocumentsData = () => {
		dispatch(getOperationDocuments(token, mortgageId));
	};

	if (loading) {
		return (
			<div style={{ textAlign: 'center', height: '100vh' }}>
				<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
			</div>
		);
	}
	if (loading2) {
		return (
			<div style={{ textAlign: 'center', height: '100vh' }}>
				<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
			</div>
		);
	}

	const getRecoDocuments = async () => {
		try {
			setLoadingRecBtn(true);
			const response = await axios.get(
				`${env.api.url}/docs/sabadell/structured-documents/${mortgageId}`,
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			);

			await buildAndSaveRecomendadorPdf(response.data.data);
		} catch (error) {
			console.error('Error fetching documents:', error);
		} finally {
			setLoadingRecBtn(false);
		}
	};

	return (
		<div>
			<Row style={{ marginTop: '10px' }} gutter={20}>
				<Col lg={19} md={19} sm={19} xs={24} style={{ display: 'flex' }}>
					<div style={{ marginRight: '20px' }}>
						<Card
							style={{
								width: '426px',
								border: '1px solid #C0F0F3',
								height: 48,
								borderRadius: '4px'
							}}>
							<div style={{ display: 'flex', marginTop: -10 }}>
								<Text
									style={{
										color: '#2F4858',
										fontSize: 12,
										fontWeight: 600,
										marginRight: 16
									}}>
									Documentación validada
								</Text>
								<Progress
									style={{ width: '50%', marginTop: -3 }}
									percent={(documentsCompleted / documentsNeed).toFixed(2) * 100}
									strokeColor={'#02C3CD'}
									format={(percent) => `${documentsCompleted} de ${documentsNeed}`}
								/>
							</div>
						</Card>
					</div>

					{documentsNeed - documentsCompleted !== 0 ? (
						<Card
							style={{
								width:
									// (mortgageDataAll &&
									//   mortgageDataAll.mortgage !== undefined &&
									//   mortgageDataAll.mortgage.documentClientDate === undefined) ||
									// moment(mortgageDataAll && mortgageDataAll.mortgage.documentClientDate).isAfter(
									//   dateNow,
									//   'day'
									// ) ?: '170px'
									moment(mortgageDataAll && mortgageDataAll.mortgage.documentClientDate).isAfter(
										dateNow,
										'day'
									)
										? 300
										: 340,
								border: '1px solid #FF4D4F',
								height: 48,
								borderRadius: '4px'
							}}>
							<div style={{ display: 'flex', marginTop: -10 }}>
								<>
									<Text
										style={{
											color: moment(
												mortgageDataAll && mortgageDataAll.mortgage.documentClientDate
											).isAfter(dateNow, 'day')
												? '#748EA0'
												: '#2F4858',
											fontSize: 12,
											fontWeight: 600
										}}>
										{moment(mortgageDataAll && mortgageDataAll.mortgage.documentClientDate).isAfter(
											dateNow,
											'day'
										)
											? 'Fecha de compromiso'
											: 'Fecha de compromiso vencida'}
									</Text>

									<DatePicker
										format={'DD/MM/YYYY'}
										onChange={(date, dateString) =>
											saveData('mortgage', 'documentClientDate', dayjs(date).format('YYYY-MM-DD'))
										}
										value={
											mortgageDataAll &&
											mortgageDataAll.mortgage !== undefined &&
											mortgageDataAll.mortgage.documentClientDate !== undefined &&
											mortgageDataAll.mortgage.documentClientDate !== 'Invalid date' &&
											mortgageDataAll.mortgage.documentClientDate !== 'Invalid Date' &&
											mortgageDataAll.mortgage.documentClientDate !== 'Fecha inválida'
												? dayjs(mortgageDataAll.mortgage.documentClientDate)
												: ''
										}
										style={{
											color: '#748EA0',
											fontSize: 12,
											fontWeight: 600,
											marginRight: -35,
											border: 'none',
											marginTop: 0,
											height: 16,
											width: 120,
											float: 'right'
										}}
									/>
								</>
							</div>
						</Card>
					) : (
						<></>
					)}
				</Col>
				<Col lg={5} md={5} sm={5} xs={24}>
					<div style={{ float: 'right' }}>
						<div style={{ display: 'flex' }}>
							<UploadOutlined style={{ color: statusJob === 'finish' ? '#d9d9d9' : '#02c3cd' }} />

							<NavLink to={`/operations/mortgages/${mortgageId}/documents`}>
								<Button
									style={{
										width: '100%',
										borderColor: 'white',
										background: 'white',
										boxShadow: 'none'
									}}
									disabled={statusJob === 'finish' ? true : false}
									type="primary">
									<Text style={{ color: statusJob === 'finish' ? '#d9d9d9' : '#02c3cd' }}>
										Subir documentos
									</Text>
								</Button>
							</NavLink>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', float: 'right' }}>
							<Button
								className="button-secundary-gibobs"
								style={{ marginTop: 8, paddingBottom: -5, height: 24 }}
								disabled={
									statusJob === 'finish'
										? true
										: profile?.type === 'risk' ||
										  profile?.type === 'admin' ||
										  profile?.type === 'prerisk'
										? false
										: true
								}
								target="_blank"
								onClick={() => getAccounts()}>
								Descarga documentos
							</Button>

							{mortgage.managementCode === 'sabadell-es' && (
								<Button
									className="button-secundary-gibobs"
									style={{
										marginTop: 8,
										paddingBottom: -5,
										height: 24,
										borderColor: statusJob === 'finish' ? '#d9d9d9' : '#006DFF'
									}}
									disabled={
										statusJob === 'finish'
											? true
											: !(
													profile.type === 'risk' ||
													profile.type === 'admin' ||
													profile.type === 'prerisk'
											  )
									}
									loading={loadingRecBtn}
									target="_blank"
									onClick={getRecoDocuments}>
									<Text style={{ color: statusJob === 'finish' ? '#d9d9d9' : '#006DFF' }}>
										{' '}
										Descarga doc. recos
									</Text>
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
			<Row gutter={24} style={{ marginTop: '10px' }}>
				{ownerList &&
					infoProfile &&
					ownerList.map((owner, index) => {
						return (
							<ListOfGroupFile
								key={index}
								mortgageId={mortgageId}
								updateMark={updateMark}
								owner={owner}
								index={index}
								loadDocumentsData={loadDocumentsData}
								contactPreferences={infoProfile}
								mortgageData={mortgageDataAll}
								mortgage={mortgage}
								nameMortgage={nameMortgage}
								documentUploads={documentUploads}
								infoProfile={infoProfile}
							/>
						);
					})}
			</Row>
			<Modal
				className="gb-modal-style-common"
				open={viewModal}
				title="¿Quieres enviar el email de documentación pendiente al cliente?"
				okText="Si"
				cancelText="No"
				onCancel={() => createTask(false)}
				onOk={() => createTask(true)}
				cancelButtonProps={{ className: 'button-secundary-gibobs', style: { width: '40%' } }}
				okButtonProps={{ className: 'button-primari-gibobs', style: { width: '40%' } }}
				width={480}></Modal>
			{mortgageDataAll !== null && (
				<div style={{ marginTop: 24 }}>
					<FilesTableUpload
						mortgageId={mortgageId}
						onUpdate={() => loadDocumentsData()}
						download={download}
						getAccounts={(id) => getAccounts(id)}
						mortgageData={mortgageDataAll}
					/>
				</div>
			)}
		</div>
	);
};

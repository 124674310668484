import React, { useState, useEffect } from 'react';
import {
	Button,
	Table,
	Tooltip,
	Spin,
	Popover,
	message,
	Modal,
	Typography,
	notification,
	Switch,
	Col,
	Row
} from 'antd';
import { useNavigate } from 'react-router-dom';
import Layout from 'antd/lib/layout/layout';
import {
	FilePdfOutlined,
	CarOutlined,
	HeartOutlined,
	FileDoneOutlined,
	LockOutlined,
	HomeOutlined,
	ContainerOutlined,
	EuroOutlined,
	CreditCardOutlined,
	EditOutlined,
	MoreOutlined,
	DeleteOutlined,
	HeartFilled,
	RedoOutlined,
	ThunderboltOutlined,
	LineChartOutlined,
	EyeOutlined
} from '@ant-design/icons';
import axios from 'axios';
import env from '../../../../../environment';
import numeral from 'numeral';
import authClient from '../../../../../Auth/Auth';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import RegisterAssigment from './registerAssignment/registerAssigment';

const { confirm } = Modal;
const { Text } = Typography;

const OfertsTableNewStyle = ({
	userMortgageId,
	loadData,
	goBankHp,
	mortgageInformation,
	setMortgageInformation,
	mortgage
}) => {
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);
	const [loading, setLoading] = useState(false);
	const [favApi, setFavApi] = useState(false);
	const [changeFav, setChangeFav] = useState(false);
	const [favBank, setFavBank] = useState(undefined);
	const [showExpiry, setShowExpiry] = useState(null);
	const navigate = useNavigate();
	const [columns, setColumns] = useState([
		{
			title: '',
			dataIndex: 'name',
			width: 184,
			backgroundColor: 'white',
			textWrap: 'word-break',
			fixed: 'left'
		}
	]);
	const [modalEmail, setModalEmail] = useState(undefined);
	const [favBankEmail, setFavBankEmail] = useState(undefined);
	const [bankOffers, setBankOffers] = useState([]);
	const [onOff, setOnOff] = useState(true);
	const [modalComments, setmodalComments] = useState(false);
	const [selectOffer, setSelectOffer] = useState(false);

	useEffect(() => {
		if (mortgageInformation !== undefined) {
			getBankOffers();
		}
	}, [mortgageInformation]);

	useEffect(() => {
		renderDataColumns();
	}, [bankOffers]);

	useEffect(() => {
		if (statusJob) {
			setColumns([
				{
					title: '',
					dataIndex: 'name',
					width: 135,
					backgroundColor: 'white',
					textWrap: 'word-break'
				}
			]);
		}
	}, [userMortgageId, statusJob]);

	useEffect(() => {
		axios
			.post(
				`${env.api.url}/campaigns/runner/experiment/by-event`,
				{
					event: 'offers-expiration',
					data: {
						operationId: userMortgageId
					}
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.data.offersExpiration === 'WITH_OFFERS_EXPIRATION') {
					setShowExpiry(true);
				} else {
					setShowExpiry(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	let data = [
		{
			key: '1',
			name: <div style={{ fontSize: '12px', color: '#748EA0', width: 190 }}>Tipo de envío</div>,
			money: '',
			address: '',
			code: 'send',
			fontSize: '12px'
		},
		{
			key: '2',
			name: <div style={{ fontSize: '12px', color: '#748EA0', width: 190 }}>Vinculaciones</div>,
			money: '',
			address: '',
			code: 'linkages',
			fontSize: '12px'
		},
		{
			key: '3',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>Importe</div>,
			money: '',
			address: '',
			code: 'amount'
		},
		{
			key: '4',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>Plazo</div>,
			money: '',
			address: '',
			code: 'term'
		},
		{
			key: '5',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>Tipo</div>,
			money: '',
			address: '',
			code: 'ratio'
		},
		{
			key: '6',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>TIN</div>,
			money: '',
			address: '',
			code: 'tin'
		},
		{
			key: '7',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>TAE</div>,
			money: '',
			address: '',
			code: 'tae'
		},
		{
			key: '8',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>Cuota mensual</div>,
			money: '',
			address: '',
			code: 'quote'
		},
		{
			key: '9',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>Revisión</div>,
			money: '',
			address: '',
			code: 'revision'
		},
		{
			key: '10',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>Comisión de apertura</div>,
			money: '',
			address: '',
			code: 'openFee'
		},
		{
			key: '11',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>Amortización parcial</div>,
			money: '',
			address: '',
			code: 'partialFee'
		},
		{
			key: '12',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>Amortización total</div>,
			money: '',
			address: '',
			code: 'totalFee'
		},
		{
			key: '13',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>Cargada por</div>,
			money: '',
			address: '',
			code: 'loadBy'
		},
		{
			key: '14',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>Comentarios</div>,
			money: '',
			address: '',
			code: 'comments'
		},
		{
			key: '15',
			name: <div style={{ fontSize: '12px', color: '#748EA0' }}>Oferta</div>,
			render: (
				<div>
					{' '}
					<Button
						style={{
							width: '60px',
							height: '30px',
							textAlign: 'center',
							marginLeft: '25px',
							fontSize: '12px',
							borderColor: '#02C3CD',
							color: '#02C3CD',
							borderRadius: '5px'
						}}></Button>
				</div>
			),
			money: '',
			address: '',
			code: 'but'
		}
	];

	if (showExpiry) {
		data.unshift({
			key: '0',
			name: <div style={{ fontSize: '12px', color: '#748EA0', width: 190 }}>Caducidad usuario</div>,
			money: '',
			address: '',
			code: 'expiry',
			fontSize: '12px'
		});
	}

	if (goBankHp === 'sabadell-es') {
		data.push(
			{
				key: '14',
				name: <div style={{ fontSize: '12px', color: '#748EA0' }}>Otros documentos</div>,
				money: '',
				address: '',
				code: 'otherDocs'
			},
			{
				key: '15',
				money: '',
				address: '',
				code: 'offer'
			}
		);
	}

	const selectImagebank = (bankName, bankCode) => (
		<img
			src={
				'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
				`${bankCode}` +
				'.png'
			}
			alt={bankName}
			style={{ marginRight: '5px', height: '35px', width: '100%' }}
		/>
	);

	const handleClickChoose = (id, bankName) => {
		axios
			.get(`${env.api.url}/api/offers/${userMortgageId}/selectOffer/${id}?isHadmin=${true}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setColumns([
					{
						title: '',
						dataIndex: 'name',
						width: 184,
						backgroundColor: 'white',
						textWrap: 'word-break'
					}
				]);
				message.success(`Ha seleccionado la oferta de ${bankName} con exito`);
				setMortgageInformation(response.data);
			});
	};

	const handleClickUpdate = (id) => {
		const data = { bankOfferId: id };
		axios
			.post(`${env.api.url}/banks/evo/update-operation-status`, data, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setLoading(false);
				if (response.data.success === true) {
					message.success('Se actualizó con exito');
					setColumns([
						{
							title: '',
							dataIndex: 'name',
							width: 184,
							backgroundColor: 'white',
							textWrap: 'word-break'
						}
					]);
					getMortgageDetail();
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const removeOffer = (dataBanks) => {
		axios
			.get(`${env.api.url}/v1/banks/remove-offer/${userMortgageId}/${dataBanks}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				if (response.data.data.deleted) {
					message.success('Se actualizo con exito');
					setColumns([
						{
							title: '',
							dataIndex: 'name',
							width: 184,
							backgroundColor: 'white',
							textWrap: 'word-break'
						}
					]);
					getMortgageDetail();
				}
			});
	};

	function showDeleteConfirm(name, id) {
		confirm({
			title: `¿Estás seguro de que quieres eliminar esta oferta de ${name}?`,
			className: 'ModalCommonConfirm',
			icon: null,
			okText: 'Si',
			okType: 'danger',
			cancelText: 'No',
			onOk() {
				removeOffer(id);
			},
			onCancel() {
				console.log('Cancel');
			}
		});
	}

	const getMortgageDetail = async () => {
		const response = await axios.get(`${env.api.url}/api/hadmin/mortgages/${userMortgageId}`, {
			headers: { Authorization: `Bearer ${authClient.getToken()}` }
		});
		setMortgageInformation(response.data);
	};

	const getBankOffers = () => {
		setLoading(true);
		setColumns([
			{
				title: '',
				dataIndex: 'name',
				width: 184,
				backgroundColor: 'white',
				textWrap: 'word-break',
				fixed: 'left'
			}
		]);

		axios
			.get(`${env.api.url}/banks/bank-offer/find-by-operation/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setBankOffers(_.orderBy(response.data.data, [(a) => a.bankCode], ['asc']));
				setLoading(false);
			});
	};

	const renderOriginal = (bank, banksIdFav) => {
		let buttonsOriginal = [];
		if (bank) {
			bank.files.map((file, index) => {
				if (file.type === 'bank-request') {
					buttonsOriginal.push(
						<div style={{ display: 'flex' }}>
							<Button
								key={index}
								onClick={() => downloadFile(file.id, file.name)}
								style={{
									width: '60px',
									textAlign: 'center',
									fontSize: '12px',
									border: 'none',
									display: 'contents',
									color: calculateFav6(bank, banksIdFav)
								}}>
								<FilePdfOutlined style={{ fontSize: '20px' }} />
								<div
									style={{
										marginLeft: '10px'
									}}>
									Ver Original
								</div>
							</Button>
						</div>
					);
				}
			});
		}
		return buttonsOriginal;
	};

	const renderOtherDocs = (bank, banksIdFav) => {
		let buttonsOriginal = [];
		if (bank) {
			bank.files.map((file, index) => {
				if (file.type === 'other-documents-offer') {
					buttonsOriginal.push(
						<Col span={12} style={{ display: 'flex' }}>
							<Button
								key={index}
								onClick={() => downloadFile(file.id, file.name)}
								style={{
									width: '60px',
									textAlign: 'center',
									fontSize: '12px',
									border: 'none',
									display: 'contents',
									color: calculateFav6(bank, banksIdFav)
								}}>
								<FilePdfOutlined style={{ fontSize: '20px' }} />
								<div
									style={{
										marginLeft: '10px'
									}}>
									{file.name}
								</div>
							</Button>
						</Col>
					);
				}
			});
		}
		return buttonsOriginal;
	};

	function download(dataurl, filename) {
		const link = document.createElement('a');
		link.href = dataurl;
		link.download = filename;
		link.click();
	}

	const downloadFile = (id, name) => {
		//goBankHp === 'sabadell-es'
		axios
			.get(`${env.api.url}/v1/hadmin/assets/download/${id}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				download(response.data.data.link, name);
			});
	};

	const sendEmail = (id) => {
		axios
			.post(
				`${env.api.url}/v1/banks/send-mail-call-insurance`,
				{
					bankOfferId: id !== undefined ? id : favBankEmail.id
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				window.location.reload();
			});
	};

	const handleSubmitForm = (values, id) => {
		const objAsigment = {
			bankRequestId: favBankEmail.bankRequest.id,
			bankAccountData: {
				name: values.name === undefined ? '' : values.name,
				email: values.email,
				phone: values.phone === undefined ? '' : values.phone,
				office: values.office === undefined ? '' : values.office,
				reference: values.reference === undefined ? '' : values.reference
			}
		};
		axios
			.post(`${env.api.url}/banks/bank-request/assign-to-account-executive`, objAsigment, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setModalEmail(false);
				sendEmail(id);
				notification.success({
					message: <Text style={{ color: '#3E9314', fontSize: 16, fontWeight: 600 }}></Text>,
					description: (
						<Text
							style={{
								color: '#2F4858',
								fontSize: 14,
								fontWeight: 400
							}}>
							{
								'El ejecutivo de cuentas se ha registrado correctamente y ya hemos solicitado la llamada de seguros para tu cliente. '
							}
						</Text>
					),
					duration: 0,
					style: {
						background: '#DFF4D4',
						borderColor: '#52C41A',
						borderRadius: 4
					}
				});
			});
	};

	const checkLinkages = (linkages) => {
		if (linkages) {
			const homeChecked = linkages.hasOwnProperty('home') && linkages.home.isChecked === true;
			const insecuranceChecked =
				linkages.hasOwnProperty('insecurance') && linkages.insecurance.isChecked === true;
			const protectedPaymentsChecked =
				linkages.hasOwnProperty('protectedPayments') &&
				linkages.protectedPayments.isChecked === true;

			return homeChecked || insecuranceChecked || protectedPaymentsChecked;
		} else {
			return false;
		}
	};

	const sendFav = (id, bank) => {
		setFavBankEmail(bank);
		axios
			.post(
				`${env.api.url}/banks/bank-offer/toggle-favorite`,
				{
					bankOfferId: id,
					isFromHadmin: true
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setFavApi(!favApi);
				if (
					goBankHp === 'sabadell-es' &&
					!bank.isFavorite &&
					mortgage?.mortgage?.sendEmailInsecurance === undefined &&
					checkLinkages(bank.data.entailments[0].linkages) &&
					bank.bankRequest?.bankAccountData?.email === undefined
				) {
					getBankOffers();
					setModalEmail(true);
				} else if (
					goBankHp === 'sabadell-es' &&
					!bank.isFavorite &&
					mortgage?.mortgage?.sendEmailInsecurance === undefined &&
					checkLinkages(bank.data.entailments[0].linkages) &&
					bank.bankRequest?.bankAccountData?.email !== undefined
				) {
					sendEmail(id);
					getBankOffers();
				} else {
					window.location.reload();
				}
			});
	};

	const modalShow = (bank) => {
		setFavApi(true);
		setFavBank(bank);
	};

	const deSelect = () => {
		axios
			.get(`${env.api.url}/v1/hadmin/banks/unselect-bank-offer/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				window.location.reload();
			});
	};

	const renderRevision = (revision) => {
		if (revision === 'BIANNUAL') {
			return 'Semestral';
		} else {
			return 'Anual';
		}
	};

	const renderAmortization = (revision) => {
		if (revision === 'LAW') {
			return 'Según ley';
		} else if (revision === 'ZERO') {
			return '0%';
		} else {
			return 'Otro';
		}
	};

	const renderRatio = (ratio) => {
		if (ratio === 'MIXED') {
			return 'Mixto';
		} else if (ratio === 'VARIABLE') {
			return 'Variable';
		} else {
			return 'Fijo';
		}
	};

	const calculateFav = (bank, banksIdFav) => {
		if (bank.isFavorite === true && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return '#02C3CD';
		} else if (bank.isFavorite === false && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return '#C0DAEE';
		} else {
			return '#02C3CD';
		}
	};

	const calculateFav2 = (bank, banksIdFav) => {
		if (bank.isFavorite === true && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return '#0088C6';
		} else if (bank.isFavorite === false && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return '#C0DAEE';
		} else {
			return '#0088C6';
		}
	};

	const calculateFav3 = (bank, banksIdFav) => {
		if (bank.isFavorite === true && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return '/images/question.png';
		} else if (bank.isFavorite === false && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return '/images/question4.png';
		} else {
			return '/images/question.png';
		}
	};

	const calculateFav4 = (bank, banksIdFav) => {
		if (bank.isFavorite === true && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return 'iconOffersTable';
		} else if (bank.isFavorite === false && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return 'iconOffersTableDisabled';
		} else {
			return 'iconOffersTable';
		}
	};

	const calculateFav5 = (bank, banksIdFav) => {
		if (
			bank.isFavorite === true &&
			banksIdFav.includes(bank.bankRequest.bank.id) &&
			bank.id !== mortgageInformation.selectedOffer
		) {
			return 'styleTableOffer';
		} else if (
			bank.isFavorite === false &&
			banksIdFav.includes(bank.bankRequest.bank.id) &&
			bank.id !== mortgageInformation.selectedOffer
		) {
			return 'styleTableOfferSelect';
		} else if (bank.isFavorite === true && bank.id === mortgageInformation.selectedOffer) {
			return 'textStyleTableOfferSelect';
		} else {
			return 'styleTableOffer';
		}
	};

	const calculateFav6 = (bank, banksIdFav) => {
		if (bank.isFavorite === true && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return '#748EA0';
		} else if (bank.isFavorite === false && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return '#C0DAEE';
		} else {
			return '#748EA0';
		}
	};

	const calculateFav7 = (bank, banksIdFav) => {
		if (bank.isFavorite === true && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return false;
		} else if (bank.isFavorite === false && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return true;
		} else {
			return false;
		}
	};

	const styleFav = (bank, banksIdFav) => {
		if (bank.isFavorite === true && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return true;
		} else if (bank.isFavorite === false && banksIdFav.includes(bank.bankRequest.bank.id)) {
			return false;
		} else {
			return true;
		}
	};

	const renderNotification = () => {
		notification.error({
			message:
				'Una oferta marcada como favorita y enviada mendiante API, no se puede quitar como favorita'
		});
	};

	const calculateColorExpiry = (minutesUntilExpiration) => {
		if (minutesUntilExpiration <= 0) {
			return '#E8E8E8';
		} else if (minutesUntilExpiration > 21600) {
			return '#ECF7FC';
		} else if (minutesUntilExpiration >= 11520 && minutesUntilExpiration <= 21600) {
			return '#FEF0D3';
		} else {
			return '#FFDEDE';
		}
	};

	const onChangesend = (value, bankOfferId) => {
		const objEditFinal = {
			bankOfferId: bankOfferId,
			type: value ? 'offer' : 'preOffer'
		};

		axios
			.post(`${env.api.url}/banks/bank-offer/switch-type`, objEditFinal, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				getBankOffers();
			});
	};

	const renderText = (comments) => {
		if (comments === '<p></p>' || comments === 'sin nota' || _.isNil(comments) || comments === '') {
			return '-';
		} else {
			return comments.substr(0, 35) + '...';
		}
	};
	const renderDataColumns = () => {
		const banksIdFav = [];
		setColumns([
			{
				title: '',
				dataIndex: 'name',
				width: 184,
				backgroundColor: 'white',
				textWrap: 'word-break',
				fixed: 'left'
			}
		]);
		const offerColums = [...columns];

		_.find(bankOffers, function (o) {
			if (o.isFavorite === true) {
				banksIdFav.push(o.bankRequest.bank.id);
			}
		});
		bankOffers.length > 0 &&
			bankOffers.map((bank, index) => {
				let years = bank.term / 12;
				offerColums.push({
					title: (
						<div style={{ display: 'flex' }}>
							<div
								style={{
									width: '150px',
									height: '25px',
									margin: 'auto'
								}}>
								{selectImagebank(bank.bankName, bank.bankCode)}{' '}
							</div>
							{/**recargar pagina para que se vena bien las ofertas*/}
							{!bank.isFavorite && (
								//comprobar que viene de api la oferta o no
								<Button
									onClick={() =>
										bank?.bankRequest.type === 'API' ? modalShow(bank) : sendFav(bank.id, bank)
									}
									style={{
										border: 'none',
										height: 20,
										width: 20,
										color: calculateFav(bank, banksIdFav),
										background: '#fafafa'
									}}
									disabled={calculateFav7(bank, banksIdFav)}
									icon={<HeartOutlined style={{ borderColor: '#FF7A7B' }} />}></Button>
							)}
							{bank.isFavorite && (
								//comprobar que viene de api la oferta o no
								<Button
									onClick={() =>
										bank?.bankRequest.type === 'API' ? renderNotification() : sendFav(bank.id, bank)
									}
									style={{
										border: 'none',
										height: 20,
										width: 20,
										color: '#02C3CD',
										background: '#fafafa'
									}}
									icon={<HeartFilled style={{ color: '#FF7A7B' }} />}></Button>
							)}

							<Popover
								placement="right"
								trigger="hover"
								content={
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<EditOutlined
											onClick={() =>
												navigate(`/operations/mortgages/${userMortgageId}/answerLog`, {
													state: {
														dataBanks: {
															data: bank,
															edit: true
														}
													}
												})
											}
											style={{
												margin: '5px',
												color: '#02C3CD',
												fontSize: '14px'
											}}
										/>

										<DeleteOutlined
											style={{
												margin: '5px',
												color: '#02C3CD',

												fontSize: '14px'
											}}
											onClick={() => showDeleteConfirm(bank.bankName, bank.id)}
										/>
										{/* </Popconfirm> */}
									</div>
								}>
								<Button
									icon={<MoreOutlined style={{ fontSize: '120%' }} />}
									style={{
										height: 24,
										width: 24,
										border: 'none',
										color: calculateFav(bank, banksIdFav),
										background: 'transparent',
										fontWeight: 600,
										boxShadow: 'none'
									}}
									disabled={calculateFav7(bank, banksIdFav)}
								/>
							</Popover>
						</div>
					),
					align: 'center',
					width: 135,
					className: calculateFav5(bank, banksIdFav),

					render: (item) => {
						var field = [];
						const [entailment] = bank.data.entailments;
						if (item.code === 'expiry') {
							const creationDate = moment(bank?.createdAt);
							const today = moment();
							const minutesUntilExpiration = creationDate.add(21, 'days').diff(today, 'minutes');
							const expiryDays = Math.floor(minutesUntilExpiration / (24 * 60));
							const remainingMinutes = minutesUntilExpiration % (24 * 60);
							const expiryHours = Math.floor(remainingMinutes / 60);
							const expired = minutesUntilExpiration <= 0;
							if (minutesUntilExpiration) {
								return (
									<div
										style={{
											backgroundColor: calculateColorExpiry(minutesUntilExpiration),
											width: '100%',
											height: '100%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}>
										{!expired ? (
											<Text
												style={{
													fontSize: 12,
													fontWeight: 500,
													color: '#2F4858',
													marginTop: 2
												}}>
												{expiryDays > 0 && (
													<>
														<span style={{ fontWeight: 600 }}>
															{String(expiryDays).padStart(2, '0')}
														</span>
														&nbsp;
														{expiryDays > 0 ? 'días' : 'día'}&nbsp;
													</>
												)}
												{expiryHours > 0 && (
													<>
														<span style={{ fontWeight: 600 }}>
															{String(expiryHours).padStart(2, '0')}
														</span>
														&nbsp;
														{expiryHours > 1 ? 'horas' : 'hora'}&nbsp;
													</>
												)}
											</Text>
										) : (
											<Text style={{ fontSize: 12, fontWeight: 500, color: '#748EA0' }}>
												Oferta Caducada
											</Text>
										)}
									</div>
								);
							}
						}
						if (item.code === 'send') {
							if (bank?.bankRequest.type === 'API') {
								return (
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<Tooltip
											placement="bottom"
											title={
												<Text
													style={{
														fontSize: 14,
														fontWeight: 400,
														color: calculateFav2(bank, banksIdFav),
														width: 440
													}}>
													{bank?.customOffer?.statusApi}
												</Text>
											}
											overlayClassName="toolTipProperty">
											<Text
												style={{
													fontSize: 14,
													fontWeight: 400,
													color: calculateFav2(bank, banksIdFav)
												}}>
												{'API'}
											</Text>
											<img
												src={calculateFav3(bank, banksIdFav)}
												alt="question"
												style={{ marginTop: -5 }}></img>
										</Tooltip>
										{bank.bankCode === 'evo' && bank.isFavorite === true && (
											<Tooltip placement="bottom" title="Actualizar estado de la oferta">
												<Button
													icon={<RedoOutlined style={{ fontSize: 16 }} />}
													style={{
														marginLeft: 8,
														backgroundColor: '#037FB8',
														color: '#fff',
														width: 24,
														height: 24,
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderColor: '#037FB8'
													}}
													onClick={() => handleClickUpdate(bank.id)}
												/>
											</Tooltip>
										)}
									</div>
								);
							} else {
								return (
									<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
										{'Estandar'}
									</Text>
								);
							}
						} else if (item.code === 'linkages') {
							if (entailment?.linkages !== undefined) {
								if (entailment.linkages.insecurance.isChecked !== false) {
									field.push(
										<Tooltip
											placement="bottom"
											title={
												<div style={{ textAlign: 'center' }}>
													Seguro de vida <br />
													{`${entailment?.linkages?.insecurance?.amount} % - ${entailment?.linkages?.insecurance?.price} €`}
												</div>
											}>
											<HeartOutlined className={calculateFav4(bank, banksIdFav)} />
										</Tooltip>
									);
								}
								if (entailment.linkages.car.isChecked !== false) {
									field.push(
										<Tooltip
											placement="bottom"
											title={
												<div style={{ textAlign: 'center' }}>
													Seguro del coche <br />
													{`${entailment?.linkages?.car?.amount} % - ${entailment?.linkages?.car?.price} €`}
												</div>
											}>
											<CarOutlined className={calculateFav4(bank, banksIdFav)} />
										</Tooltip>
									);
								}
								if (entailment.linkages.payroll.isChecked !== false) {
									field.push(
										<Tooltip
											placement="bottom"
											title={
												<div style={{ textAlign: 'center' }}>
													Domiciliación de nómina <br />
													{`${entailment?.linkages?.payroll?.amount} % - ${entailment?.linkages?.payroll?.price} €`}
												</div>
											}>
											<FileDoneOutlined className={calculateFav4(bank, banksIdFav)} />
										</Tooltip>
									);
								}
								if (entailment.linkages.unemployment.isChecked !== false) {
									field.push(
										<Tooltip
											placement="bottom"
											title={
												<div style={{ textAlign: 'center' }}>
													Seguro de desempleo <br />
													{`${entailment?.linkages?.unemployment?.amount} % - ${entailment?.linkages?.unemployment?.price} €`}
												</div>
											}>
											<LockOutlined className={calculateFav4(bank, banksIdFav)} />
										</Tooltip>
									);
								}
								if (entailment.linkages.home.isChecked !== false) {
									field.push(
										<Tooltip
											placement="bottom"
											title={
												<div style={{ textAlign: 'center' }}>
													Seguro de hogar <br />
													{`${entailment?.linkages?.home?.amount} % - ${entailment?.linkages?.home?.price} €`}
												</div>
											}>
											<HomeOutlined className={calculateFav4(bank, banksIdFav)} />
										</Tooltip>
									);
								}
								if (entailment.linkages.payments.isChecked !== false) {
									field.push(
										<Tooltip
											placement="bottom"
											title={
												<div style={{ textAlign: 'center' }}>
													Domiciliación de recibos <br />
													{`${entailment?.linkages?.payments?.amount} % - ${entailment?.linkages?.payments?.price} €`}
												</div>
											}>
											<ContainerOutlined className={calculateFav4(bank, banksIdFav)} />
										</Tooltip>
									);
								}
								if (entailment.linkages.pensionPlan.isChecked !== false) {
									field.push(
										<Tooltip
											placement="bottom"
											title={
												<div style={{ textAlign: 'center' }}>
													Plan de pensiones <br />
													{`${entailment?.linkages?.pensionPlan?.amount} % - ${entailment?.linkages?.pensionPlan?.price} €`}
												</div>
											}>
											<EuroOutlined className={calculateFav4(bank, banksIdFav)} />
										</Tooltip>
									);
								}
								if (entailment.linkages.cards.isChecked !== false) {
									field.push(
										<Tooltip
											placement="bottom"
											title={
												<div style={{ textAlign: 'center' }}>
													Tarjeta de credito/débito <br />
													{`${entailment?.linkages?.cards?.amount} % - ${entailment?.linkages?.cards?.price} €`}
												</div>
											}>
											<CreditCardOutlined className={calculateFav4(bank, banksIdFav)} />
										</Tooltip>
									);
								}

								if (entailment.linkages.accidents.isChecked !== false) {
									field.push(
										<Tooltip
											placement="bottom"
											title={
												<div style={{ textAlign: 'center' }}>
													Seguro de accidentes <br />
													{`${entailment?.linkages?.accidents?.amount} % - ${entailment?.linkages?.accidents?.price} €`}
												</div>
											}>
											<img
												src={
													styleFav(bank, banksIdFav)
														? '/images/accidents.png'
														: '/images/accidents_dis.png'
												}
												height={styleFav(bank, banksIdFav) ? 20 : 14}
												className="iconOffersTable"
											/>
										</Tooltip>
									);
								}
								if (entailment.linkages.energyCertification.isChecked !== false) {
									field.push(
										<Tooltip
											placement="bottom"
											title={
												<div style={{ textAlign: 'center' }}>
													Calificación energética A o B <br />
													{`${entailment?.linkages?.energyCertification?.amount} % - ${entailment?.linkages?.energyCertification?.price} €`}
												</div>
											}>
											<ThunderboltOutlined className={calculateFav4(bank, banksIdFav)} />
										</Tooltip>
									);
								}

								if (entailment.linkages.investmentFunds.isChecked !== false) {
									field.push(
										<Tooltip
											placement="bottom"
											title={
												<div style={{ textAlign: 'center' }}>
													Fondo de inversión <br />
													{`${entailment?.linkages?.investmentFunds?.amount} % - ${entailment?.linkages?.investmentFunds?.price} €`}
												</div>
											}>
											<LineChartOutlined className={calculateFav4(bank, banksIdFav)} />
										</Tooltip>
									);
								}
								if (entailment.linkages.protectedPayments.isChecked !== false) {
									field.push(
										<Tooltip
											placement="bottom"
											title={
												<div style={{ textAlign: 'center' }}>
													Seguro de protección de pagos <br />
													{`${entailment?.linkages?.protectedPayments?.amount} % - ${entailment?.linkages?.protectedPayments?.price} €`}
												</div>
											}>
											<img
												src={
													styleFav(bank, banksIdFav)
														? '/images/protectedPay.png'
														: '/images/protectedPay_dis.png'
												}
												height={14}
												className="iconOffersTable"
											/>
										</Tooltip>
									);
								}
							}
						} else if (item.code === 'amount') {
							field = <div>{numeral(Number(bank.amount)).format('0,0.00 $')}</div>;
						} else if (item.code === 'term') {
							field = years + ' ' + 'años';
						} else if (item.code === 'ratio') {
							field = renderRatio(bank.ratio);
						} else if (item.code === 'tin') {
							if (
								bank.data &&
								bank.data.entailments.length > 0 &&
								bank.data.entailments[0].stages &&
								bank.data.entailments[0].stages.length > 0
							) {
								field.push(
									bank.data.entailments[0].stages.map((data) => (
										<div>
											{data.period !== undefined && data.tin !== '' && `Tramo ${data.period}: `}
											{data.tin !== '' && data.rateType === 'VARIABLE'
												? `EUR + ${data.tin} %`
												: `${data.tin} %`}
										</div>
									))
								);
							}
						} else if (item.code === 'tae') {
							if (bank.data && bank.data.entailments.length > 0 && bank.data.entailments[0]) {
								field = bank.data.entailments[0].tae ? bank.data.entailments[0].tae + ' %' : '';
							}
						} else if (item.code === 'quote') {
							if (
								bank.data &&
								bank.data.entailments.length > 0 &&
								bank.data.entailments[0].stages &&
								bank.data.entailments[0].stages.length > 0
							) {
								field = numeral(Number(bank.data.entailments[0].stages[0].quote)).format(
									'0,0.00 $'
								);
							}
						} else if (item.code === 'revision') {
							field = bank.revision === 'Seleccione' ? '' : renderRevision(bank.revision);
						} else if (item.code === 'openFee') {
							field = bank.openFee;
						} else if (item.code === 'partialFee') {
							field = renderAmortization(bank.partialFee);
						} else if (item.code === 'totalFee') {
							field = renderAmortization(bank.totalFee);
						} else if (item.code === 'loadBy') {
							field = bank?.customOffer?.createdBy && (
								<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
									<div style={{ display: 'flex' }}>
										{bank?.customOffer?.createdBy?.name +
											' ' +
											bank?.customOffer?.createdBy?.lastName}
									</div>
									<div>{bank?.customOffer?.createdBy?.email}</div>
								</div>
							);
						} else if (item.code === 'comments') {
							field = (
								<div
									style={{
										display: 'flex',
										width: 150,
										justifyContent:
											bank.comments === '<p></p>' ||
											bank.comments === 'sin nota' ||
											_.isNil(bank.comments) ||
											bank.comments === ''
												? 'center'
												: undefined
									}}>
									<div
										dangerouslySetInnerHTML={{
											__html: renderText(bank.comments)
										}}></div>
									{bank.comments === '<p></p>' ||
									bank.comments === 'sin nota' ||
									_.isNil(bank.comments) ||
									bank.comments === '' ? (
										''
									) : (
										<EyeOutlined
											style={{ marginLeft: 10 }}
											onClick={() => {
												setSelectOffer(bank);
												setmodalComments(true);
											}}></EyeOutlined>
									)}
								</div>
							);
						}

						if (item.code === 'but') {
							field.push(
								<div
									style={{
										display: 'inline-block',
										gridTemplateRows: '0.5fr 0.5fr'
									}}>
									{renderOriginal(bank, banksIdFav)}
									{goBankHp !== 'sabadell-es' && (
										<>
											<div style={{ display: 'flex', marginTop: 10 }}>
												<Button
													href={`${env.api.url}/v1/banks/generate-pdf-bank/${
														bank.id
													}?token=${authClient.getToken()}`}
													target={'_blank'}
													style={{
														width: '60px',
														textAlign: 'center',
														fontSize: '12px',
														border: 'none',
														display: 'contents',
														color: calculateFav6(bank, banksIdFav)
													}}>
													<FilePdfOutlined style={{ fontSize: '20px' }} />
													<div
														style={{
															marginTop: '-4px',
															marginLeft: '10px'
														}}>
														Ver PDF
													</div>
												</Button>
											</div>

											<div style={{ marginTop: 8 }}>
												<Switch
													defaultChecked={bank.type === 'offer' ? true : false}
													onChange={(value) => onChangesend(value, bank.id)}
													size="small"
												/>
												{bank.type === 'offer' && (
													<Text
														style={{
															color: '#2F4858',
															fontSize: '14px',
															fontWeight: 400,
															marginLeft: 10
														}}>
														Oferta
													</Text>
												)}
												{bank.type !== 'offer' && (
													<Text
														style={{
															color: '#2F4858',
															fontSize: '14px',
															fontWeight: 400,
															marginLeft: 10
														}}>
														Pre-oferta
													</Text>
												)}
											</div>

											<div>
												{_.isNil(mortgageInformation.selectedOffer) &&
												bank.isFavorite === true &&
												mortgageInformation.stage === 'appraisal' ? (
													<Button
														disabled={
															statusJob === 'finish' ||
															bank.type !== 'offer' ||
															(mortgageInformation !== undefined &&
																mortgageInformation.stage === 'formalization')
																? true
																: false
														}
														onClick={() => modalConfirm(bank.id, bank.bankName)}
														className="button-primari-gibobs"
														type="primary"
														style={{
															width: '88px',
															height: '24px',
															padding: 0,
															fontSize: '12px',
															marginTop: '12px'
														}}>
														Elegir
													</Button>
												) : (
													<></>
												)}
												{!_.isNil(mortgageInformation.selectedOffer) &&
													mortgageInformation.selectedOffer === bank.id &&
													(mortgageInformation.stage === 'appraisal' ||
														mortgageInformation.stage === 'formalization') && (
														<Button
															onClick={() => deSelect()}
															className="button-secundary-gibobs"
															type="dashed"
															style={{
																width: '100px',
																height: '24px',
																padding: 0,
																fontSize: '12px',
																marginTop: '12px'
															}}>
															Dejar de elegir
														</Button>
													)}
											</div>
										</>
									)}
								</div>
							);
						}
						if (item.code === 'otherDocs') {
							field.push(
								<Row gutter={[20, 8]} style={{ maxWidth: 500 }} align={'middle'}>
									{renderOtherDocs(bank, banksIdFav)}
								</Row>
							);
						}
						if (item.code === 'offer') {
							field.push(
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<div style={{ marginTop: 8 }}>
										<Switch
											defaultChecked={bank.type === 'offer' ? true : false}
											onChange={(value) => onChangesend(value, bank.id)}
											size="small"
										/>
										{bank.type === 'offer' && (
											<Text
												style={{
													color: '#2F4858',
													fontSize: '14px',
													fontWeight: 400,
													marginLeft: 10
												}}>
												Oferta
											</Text>
										)}
										{bank.type !== 'offer' && (
											<Text
												style={{
													color: '#2F4858',
													fontSize: '14px',
													fontWeight: 400,
													marginLeft: 10
												}}>
												Pre-oferta
											</Text>
										)}
									</div>
									<div>
										{_.isNil(mortgageInformation.selectedOffer) &&
										bank.isFavorite === true &&
										mortgageInformation.stage === 'appraisal' ? (
											<Button
												disabled={
													statusJob === 'finish' ||
													bank.type !== 'offer' ||
													(mortgageInformation !== undefined &&
														mortgageInformation.stage === 'formalization')
														? true
														: false
												}
												onClick={() => modalConfirm(bank.id, bank.bankName)}
												className="button-primari-gibobs"
												type="primary"
												style={{
													width: '88px',
													height: '24px',
													padding: 0,
													fontSize: '12px',
													marginTop: '12px'
												}}>
												Elegir
											</Button>
										) : (
											<></>
										)}
										{!_.isNil(mortgageInformation.selectedOffer) &&
											mortgageInformation.selectedOffer === bank.id &&
											(mortgageInformation.stage === 'appraisal' ||
												mortgageInformation.stage === 'formalization') && (
												<Button
													onClick={() => deSelect()}
													className="button-secundary-gibobs"
													type="dashed"
													style={{
														width: '100px',
														height: '24px',
														padding: 0,
														fontSize: '12px',
														marginTop: '36px'
													}}>
													Dejar de elegir
												</Button>
											)}
									</div>
								</div>
							);
						}
						return (
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								{field}
							</div>
						);
					}
				});
			});
		setColumns(offerColums);
	};

	const modalConfirm = (id, bankName) => {
		Modal.confirm({
			className: 'ModalCommon gb-offer-modal',
			okButtonProps: { className: 'button-primari-gibobs' },
			cancelButtonProps: { className: 'button-secundary-gibobs' },
			title: <div style={{ marginBottom: '16px' }}>Elegir oferta</div>,
			icon: null,
			okText: 'Elegir',
			cancelText: 'Cancelar',
			content: (
				<div>
					<div>¿Vas a elegir la oferta de {bankName},quieres confirmar la operación?</div>
				</div>
			),
			onOk() {
				handleClickChoose(id, bankName);
			},
			onCancel() {}
		});
	};

	const renderTextRatio = (bank) => {
		if (bank.ratio === 'MIXED') {
			return 'MIXTO';
		} else if (bank.ratio === 'VARIABLE') {
			return 'VARIABLE';
		} else {
			return 'FIJO';
		}
	};

	const renderTextLinkajes = (bank) => {
		if (bank.data.entailments[0].linkages !== undefined) {
			return 'CON';
		} else {
			return 'SIN';
		}
	};

	return (
		<Layout style={{ backgroundColor: 'white' }}>
			<div>
				{loading && (
					<div
						style={{
							textAlign: 'center',
							height: '100vh',
							backgroundColor: 'white',
							marginTop: 300
						}}>
						<Spin indicator={antIcon} className="spin" />
					</div>
				)}

				{!loading && (
					<Table
						columns={columns}
						dataSource={data}
						bordered
						pagination={false}
						scroll={{ x: 1000 }}
						rowClassName={(record) => (record.code === 'expiry' ? 'row-without-padding' : '')}
					/>
				)}
			</div>

			{favBank && (
				<Modal
					className="gb-modal-style-common"
					cancelButtonProps={{
						className: 'button-secundary-gibobs',
						style: { width: '100px', height: 40 }
					}}
					okButtonProps={{
						className: 'button-primari-gibobs',
						style: { width: '100px', height: 40 }
					}}
					title={'Marcar oferta favorita - envío via API'}
					open={favApi}
					width={480}
					onOk={() => {
						sendFav(favBank.id);
					}}
					onCancel={() => {
						setFavApi(!favApi);
					}}
					okText={'Continuar'}
					cancelText={'Cancelar'}
					closable={true}>
					<div>
						<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
							{'Vas a marcar como favorita la oferta a tipo ' +
								renderTextRatio(favBank) +
								', ' +
								renderTextLinkajes(favBank) +
								' productos asociados de banco ' +
								`${favBank.bankName}` +
								'. Al hacerlo, se activará esta oferta en el banco de forma automática y no podrás cambiar de opinión.'}
						</Text>
					</div>
					<div style={{ marginTop: 24 }}>
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#748EA0' }}>
							¿Quieres continuar?
						</Text>
					</div>
				</Modal>
			)}

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '100px', height: 40 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '100px', height: 40 }
				}}
				title={'Cambio de favorito'}
				open={changeFav}
				width={480}
				onOk={() => {
					setChangeFav(!changeFav);
				}}
				onCancel={() => {
					setChangeFav(!changeFav);
				}}
				okText={'Confirmar'}
				cancelText={'Cancelar'}
				closable={true}>
				<div>
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
						Ya tienes marcada una oferta favorita de banco X.
					</Text>
				</div>
				<div style={{ marginTop: 24 }}>
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#748EA0' }}>
						¿Quieres cambiar la elección de la oferta favorita en este banco?
					</Text>
				</div>
			</Modal>

			{selectOffer && (
				<Modal
					className="gb-modal-style-common"
					cancelButtonProps={{
						style: { display: 'none' }
					}}
					okButtonProps={{
						style: { display: 'none' }
					}}
					onCancel={() => {
						setmodalComments(!modalComments);
					}}
					title={'Detalle del comentario'}
					open={modalComments}
					width={480}
					closable={true}>
					<div
						style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}
						dangerouslySetInnerHTML={{
							__html: selectOffer.comments
						}}></div>
				</Modal>
			)}

			<RegisterAssigment
				visible={modalEmail}
				onCreate={handleSubmitForm}
				onCancel={() => setModalEmail(!modalEmail)}
				width={800}
				bankAccountData={''}
			/>
		</Layout>
	);
};

export default OfertsTableNewStyle;

import React, { useState, useEffect } from 'react';
import { Button, Typography, Col, Row, Card, Checkbox } from 'antd';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MyBreadcrumb from '../../../../../../Components/ModalsAndAlerts/MyBreadcrumb';

const { Text } = Typography;

const GeneratorURL = ({ showModalEmail }) => {
	const [mortgage, setMortgageData] = useState(undefined);
	const param = useParams();
	let data = [];
	const [dataParticipants, setDataParticipants] = useState(undefined);

	useEffect(() => {
		getMortgageDetails();
		getParticipants();
	}, []);

	const getMortgageDetails = async () => {
		await axios
			.get(`${env.api.url}/api/hadmin/mortgages/${param.mortgageId}`, {
				headers: {
					Authorization: `Bearer ${authClient.getToken()}`
				}
			})
			.then((response) => {
				setMortgageData(response.data);
			});
	};

	const getParticipants = async () => {
		await axios
			.get(`${env.api.url}/v1/mortgages/data/get/${param.mortgageId}`, {
				headers: {
					Authorization: `Bearer ${authClient.getToken()}`
				}
			})
			.then((response) => {
				setDataParticipants(response.data.data);
			});
	};

	if (mortgage) {
		data = [
			{
				name: 'Operaciones',
				link: '/management-area'
			},
			{
				name: 'Hipotecas',
				link: '/operations/mortgages/list/active'
			},
			{
				name: mortgage.name,
				link: `/operations/mortgages/${param.mortgageId}`
			},
			{
				name: 'Enviar nueva operación a banco',
				link: `/operations/mortgages/${param.mortgageId}/form-send-bank`
			},
			{
				name: 'Selección de documentación',
				link: `generator-url-link-message/${param.mortgageId}`
			}
		];
	}

	const renderParticipants = () => {
		let owners = [];

		if (dataParticipants) {
			for (
				let index = 1;
				index <= Number.parseInt(dataParticipants.mortgage.participants);
				index++
			) {
				owners.push(
					<Col span={12}>
						<Card style={{ border: '1px solid #C0DAEE', borderRadius: 4, marginTop: 24 }}>
							<Text
								style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}
							>{`Titular ${index}`}</Text>

							<div style={{ marginTop: 16, display: 'flex', flexDirection: 'column' }}>
								<Checkbox.Group>
									<Checkbox value={1}> Documento de identificación DNI/NIE/Pasaporte</Checkbox>
									<Checkbox value={2}> Documento de identificación DNI/NIE/Pasaporte</Checkbox>
									<Checkbox value={3}> Documento de identificación DNI/NIE/Pasaporte</Checkbox>
									<Checkbox value={4}> Documento de identificación DNI/NIE/Pasaporte</Checkbox>
									<Checkbox value={5}> Documento de identificación DNI/NIE/Pasaporte</Checkbox>
									<Checkbox value={6}> Documento de identificación DNI/NIE/Pasaporte</Checkbox>
									<Checkbox value={7}> Documento de identificación DNI/NIE/Pasaporte</Checkbox>
									<Checkbox value={8}> Documento de identificación DNI/NIE/Pasaporte</Checkbox>
									<Checkbox value={9}> Documento de identificación DNI/NIE/Pasaporte</Checkbox>
								</Checkbox.Group>
							</div>
						</Card>
					</Col>
				);
			}
			return owners;
		}
	};
	const renderGuarantor = () => {
		let guarantors = [];
		if (dataParticipants) {
			for (let index = 1; index <= Number.parseInt(dataParticipants.mortgage.guarantors); index++) {
				guarantors.push(
					<Col span={12}>
						<Card style={{ border: '1px solid #C0DAEE', borderRadius: 4, marginTop: 24 }}>
							<Text
								style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}
							>{`Avalista ${index}`}</Text>
						</Card>
					</Col>
				);
			}
			return guarantors;
		}
	};

	return (
		<div style={{ padding: 40 }}>
			<MyBreadcrumb data={data}></MyBreadcrumb>

			<Card className="gcard" style={{ marginTop: 24 }}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						Selección de documentación para enviar al banco
					</Text>
					<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858', marginTop: 12 }}>
						Selecciona los archivos necesarios según el perfil.
					</Text>
				</div>
				<Row gutter={[24, 24]} style={{ marginTop: 40 }}>
					{renderParticipants()}
					{renderGuarantor()}
				</Row>
			</Card>

			<Row style={{ justifyContent: 'center', marginTop: 20 }}>
				<Button
					style={{ height: 32, width: 96, fontSize: 14 }}
					className="button-secundary-gibobs"
					type="dashed"
				>
					Cancelar
				</Button>

				<Button
					style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
					type="primary"
					onClick={() => showModalEmail()}
					className="button-primari-gibobs"
				>
					Siguiente
				</Button>
			</Row>
		</div>
	);
};
export default GeneratorURL;

import React, { useEffect, useState } from 'react';
import { Col, message, Row, Input, Typography, DatePicker, Select, Divider } from 'antd';
import authClient from '../../../../../Auth/Auth';
import axios from 'axios';
import env from '../../../../../environment';
import Loading from '../../../../../Components/ModalsAndAlerts/Loading';
// import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import _ from 'lodash';
// import DeleteModal from './modals/modalDelete';
import StepsRecommenders from './stepsRecommenders';

const { Text } = Typography;
const { TextArea } = Input;

interface RecommendersProps {
	mortgageId: string;
}

const Recommenders: React.FC<RecommendersProps> = ({ mortgageId }) => {
	const [mortgageData, setMortgageData] = useState(undefined);
	const [operationType, setOperationType] = useState(undefined);
	const [dataBankRequest, setDataBankRequest] = useState(undefined);
	const [bankOffers, setBankOffers] = useState(undefined);
	const [favOffer, setFavOffer] = useState(undefined);
	const [rejectionRows, setRejectionRows] = useState([]);
	// const [deleteId, setDeleteId] = useState(undefined);
	// const [visibleModalDelete, setVisibleModalDelete] = useState(false);
	const [mortgageDirectors, setMortgageDirectors] = useState(undefined);

	const rejectionReasonsOptions = [
		{ value: 'missingDocumentation', label: 'Falta documentación' },
		{ value: 'missingInformation', label: 'Falta información' },
		{ value: 'dataError', label: 'Error en los datos (guía de precios, tipos de interés...)' },
		{ value: 'cirbe', label: 'CIRBE' },
		{ value: 'dhDiscrepancy', label: 'Discrepancia de criterio con DH (denegada por el DH)' },
		{ value: 'reprocessingDueToError', label: 'Retramitación por error en gestoría' },
		{ value: 'noReasonProvidedByCH', label: 'El CH no indica el motivo' },
		{ value: 'other', label: 'Otro' }
	];

	useEffect(() => {
		getMortgageData();
	}, [mortgageId]);

	useEffect(() => {
		getRequests();
		getBankOffers();
		getMortgageDirectorsReco();
	}, []);

	useEffect(() => {
		setFavOffer(bankOffers?.find((offer: any) => offer.isFavorite));
	}, [bankOffers]);

	const getMortgageData = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setMortgageData(response.data.data.mortgage);
				setOperationType(response.data.data.operation.subtype);
				setRejectionRows(response.data.data.recommendedResult?.rejections ?? []);
			})
			.catch((error) => {
				console.error('Error al obtener los datos de la hipoteca:', error);
			});
	};
	const getBankOffers = () => {
		axios
			.get(`${env.api.url}/banks/bank-offer/find-by-operation/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setBankOffers(response.data.data);
			});
	};

	const getRequests = () => {
		axios
			.get(`${env.api.url}/v1/banks/get-operation-bank-requests/${mortgageId}?hadmin=${true}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				response.data.data = _.orderBy(response.data.data, [(d) => d.id], ['desc']);
				setDataBankRequest(response.data.data);
			});
	};

	const getMortgageDirectorsReco = () => {
		axios
			.get(`${env.api.url}/sb/mortgages/directors/list`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setMortgageDirectors(response.data.data);
			})
			.catch((error) => {
				console.error('Error al obtener la lista de directores:', error);
			});
	};

	const saveData = (subkey: string, key: string, value: any) => {
		if (value === 'Invalid Date') value = null;
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${mortgageId}`,
				{ key, participant: subkey, value },
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			)
			.then((response) => {
				if (response.data.success) {
					message.success('Se actualizó con éxito');
					getMortgageData();
				} else {
					message.error('No se pudieron guardar los datos');
				}
			})
			.catch(() => {
				message.error('No se pudieron guardar los datos');
			});
	};

	const generateId: () => string = () => {
		const timestamp = Math.floor(Date.now() / 1000);
		const hexTimestamp = timestamp.toString(16);
		const randomNum1 = Math.floor(Math.random() * (999999999 - 100000000 + 1) + 100000000);
		const randomNum2 = Math.floor(Math.random() * (999999999 - 100000000 + 1) + 100000000);
		const hexRandom1 = randomNum1.toString(16).padStart(9, '0');
		const hexRandom2 = randomNum2.toString(16).padStart(9, '0');

		return hexTimestamp + hexRandom1 + hexRandom2;
	};

	const handleChange = (id: string, key: string, value: string) => {
		const updatedRows = rejectionRows.map((row) => {
			if (row.id === id) {
				return { ...row, [key]: value };
			}
			return row;
		});

		setRejectionRows(updatedRows);
		saveData('recommendedResult', 'rejections', updatedRows);
	};

	const handleOnAdd = () => {
		const updatedRows = [...rejectionRows];
		updatedRows.push({
			id: generateId(),
			date: undefined,
			mortgageDirector: undefined,
			reason: undefined,
			explanation: undefined
		});
		setRejectionRows(updatedRows);
	};

	// const handleOnDelete = () => {
	// 	const updatedRows = rejectionRows.filter((row) => row.id !== deleteId);
	// 	setRejectionRows(updatedRows);
	// 	setVisibleModalDelete(false);
	// 	saveData('recommendedResult', 'rejections', updatedRows);
	// };

	// const showModalConfirmDelete = (id: string) => {
	// 	setVisibleModalDelete(true);
	// 	setDeleteId(id);
	// };

	if (!mortgageData || !dataBankRequest) {
		return <Loading />;
	}

	return (
		<div style={{ padding: '8px 24px' }}>
			<StepsRecommenders
				mortgageId={mortgageId}
				mortgageData={mortgageData}
				operationType={operationType}
				favOffer={favOffer}
				dataBankRequest={dataBankRequest}
				getMortgageData={getMortgageData}
				saveData={saveData}
				mortgageDirectors={mortgageDirectors}
			/>
			<Divider style={{ borderColor: '##F1F7F8', margin: '24px 0px' }} />
			<Text onClick={handleOnAdd} className="text-button-reco">
				Añadir rechazo +
			</Text>
			{rejectionRows.length > 0 && mortgageDirectors && (
				<div style={{ marginTop: 16 }}>
					{rejectionRows.map((row, index) => {
						return (
							<Row gutter={[16, 16]} key={row.id}>
								{index > 0 && <Divider style={{ margin: '16px 0px 0px 0px' }} />}
								<Col span={3}>
									<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
										<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
											{index + 1}. Rechazo
										</Text>
										{/* <DeleteOutlined
											onClick={() => showModalConfirmDelete(row.id)}
											style={{ fontSize: 18, color: '#FF7A7B', cursor: 'pointer' }}
										/> */}
									</div>
								</Col>
								<Col span={21} key={row.id}>
									<Row gutter={[16, 16]} align="middle">
										<Col span={6} className="form-reco">
											<Text className="label-reco">Fecha</Text>
											<DatePicker
												className="datepicker-reco"
												style={{ width: '100%' }}
												onChange={(date) =>
													handleChange(row.id, 'date', dayjs(date).format('YYYY-MM-DD'))
												}
												format={'DD-MM-YYYY'}
												placeholder={'00-00-0000'}
												defaultValue={row.date ? dayjs(row.date, 'YYYY-MM-DD') : undefined}
											/>
										</Col>
										<Col span={6} className="form-reco">
											<Text className="label-reco">Rechazado por</Text>
											<Select
												className="select-reco"
												style={{ width: '100%' }}
												placeholder="Seleccionar"
												options={[
													{ value: 'directSales', label: 'Ventas directas' },
													{ value: 'managementService', label: 'Gestoría' }
												]}
												onChange={(value) => handleChange(row.id, 'rejectedBy', value)}
												defaultValue={row.rejectedBy}
											/>
										</Col>
										<Col span={6} className="form-reco">
											<Text className="label-reco">Director hipotecario</Text>
											<Select
												className="select-reco"
												style={{ width: '100%' }}
												placeholder="Seleccionar"
												options={mortgageDirectors?.map((dir: any) => ({
													value: dir.name,
													label: dir.name
												}))}
												onChange={(value) => handleChange(row.id, 'mortgageDirector', value)}
												defaultValue={row.mortgageDirector}
												showSearch
												filterOption={(input: any, option: any) =>
													option.label.toLowerCase().includes(input.toLowerCase())
												}
											/>
										</Col>
										<Col span={6} className="form-reco">
											<Text className="label-reco">Motivo</Text>
											<Select
												className="select-reco"
												placeholder="Seleccionar"
												options={rejectionReasonsOptions}
												onChange={(value) => handleChange(row.id, 'reason', value)}
												defaultValue={row.reason}
											/>
										</Col>
										<Col span={24} className="form-reco">
											<Text className="label-reco">Explicación</Text>
											<TextArea
												style={{ width: '100%' }}
												autoSize={{ minRows: 1, maxRows: 3 }}
												placeholder="Indica cualquier otra cuestión"
												onBlur={(e) => handleChange(row.id, 'explanation', e.target.value)}
												defaultValue={row.explanation}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						);
					})}
				</div>
			)}
			{/* <DeleteModal
				showModal={visibleModalDelete}
				setShowModal={setVisibleModalDelete}
				handleOnDelete={handleOnDelete}
			/> */}
		</div>
	);
};

export default Recommenders;

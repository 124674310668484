export const renderComponent = (key) => {
	if (key === 'nationality' || key === 'cityzenship') {
		return {
			input: 'select',
			options: [
				{
					label: 'Afghanistan',
					value: 'af'
				},
				{
					label: 'Albania',
					value: 'al'
				},
				{
					label: 'Algeria',
					value: 'dz'
				},
				{
					label: 'American Samoa',
					value: 'as'
				},
				{
					label: 'Andorra',
					value: 'ad'
				},
				{
					label: 'Angola',
					value: 'ao'
				},
				{
					label: 'Anguilla',
					value: 'ai'
				},
				{
					label: 'Antigua and Barbuda',
					value: 'ag'
				},
				{
					label: 'Argentina',
					value: 'ar'
				},
				{
					label: 'Armenia',
					value: 'am'
				},
				{
					label: 'Aruba',
					value: 'aw'
				},
				{
					label: 'Australia',
					value: 'au'
				},
				{
					label: 'Austria',
					value: 'at'
				},
				{
					label: 'Azerbaijan',
					value: 'az'
				},
				{
					label: 'Bahamas',
					value: 'bs'
				},
				{
					label: 'Bahrain',
					value: 'bh'
				},
				{
					label: 'Bangladesh',
					value: 'bd'
				},
				{
					label: 'Barbados',
					value: 'bb'
				},
				{
					label: 'Belarus',
					value: 'by'
				},
				{
					label: 'Belgium',
					value: 'be'
				},
				{
					label: 'Belize',
					value: 'bz'
				},
				{
					label: 'Benin',
					value: 'bj'
				},
				{
					label: 'Bermuda',
					value: 'bm'
				},
				{
					label: 'Bhutan',
					value: 'bt'
				},
				{
					label: 'Bolivia',
					value: 'bo'
				},
				{
					label: 'Bosnia and Herzegovina',
					value: 'ba'
				},
				{
					label: 'Botswana',
					value: 'bw'
				},
				{
					label: 'Brazil',
					value: 'br'
				},
				{
					label: 'Brunei Darussalam',
					value: 'bn'
				},
				{
					label: 'Bulgaria',
					value: 'bg'
				},
				{
					label: 'Burkina Faso',
					value: 'bf'
				},
				{
					label: 'Burundi',
					value: 'bi'
				},
				{
					label: 'Cambodia',
					value: 'kh'
				},
				{
					label: 'Cameroon',
					value: 'cm'
				},
				{
					label: 'Canada',
					value: 'ca'
				},
				{
					label: 'Cape Verde',
					value: 'cv'
				},
				{
					label: 'Cayman Islands',
					value: 'ky'
				},
				{
					label: 'Central African Republic',
					value: 'cf'
				},
				{
					label: 'Chad',
					value: 'td'
				},
				{
					label: 'Chile',
					value: 'cl'
				},
				{
					label: 'China',
					value: 'cn'
				},
				{
					label: 'Colombia',
					value: 'co'
				},
				{
					label: 'Comoros',
					value: 'km'
				},
				{
					label: 'Congo (DRC)',
					value: 'cd'
				},
				{
					label: 'Congo (Republic)',
					value: 'cg'
				},
				{
					label: 'Cook Islands',
					value: 'ck'
				},
				{
					label: 'Costa Rica',
					value: 'cr'
				},
				{
					label: 'Ivory Coast',
					value: 'ci'
				},
				{
					label: 'Croatia',
					value: 'hr'
				},
				{
					label: 'Cuba',
					value: 'cu'
				},
				{
					label: 'Cyprus',
					value: 'cy'
				},
				{
					label: 'Czech Republic',
					value: 'cz'
				},
				{
					label: 'Denmark',
					value: 'dk'
				},
				{
					label: 'Djibouti',
					value: 'dj'
				},
				{
					label: 'Dominica',
					value: 'dm'
				},
				{
					label: 'Dominican Republic',
					value: 'do'
				},
				{
					label: 'Ecuador',
					value: 'ec'
				},
				{
					label: 'Egypt',
					value: 'eg'
				},
				{
					label: 'El Salvador',
					value: 'sv'
				},
				{
					label: 'Equatorial Guinea',
					value: 'gq'
				},
				{
					label: 'Eritrea',
					value: 'er'
				},
				{
					label: 'Estonia',
					value: 'ee'
				},
				{
					label: 'Ethiopia',
					value: 'et'
				},
				{
					label: 'Faroe Islands',
					value: 'fo'
				},
				{
					label: 'Fiji',
					value: 'fj'
				},
				{
					label: 'Finland',
					value: 'fi'
				},
				{
					label: 'France',
					value: 'fr'
				},
				{
					label: 'French Polynesia',
					value: 'pf'
				},
				{
					label: 'Gabon',
					value: 'ga'
				},
				{
					label: 'Gambia',
					value: 'gm'
				},
				{
					label: 'Georgia',
					value: 'ge'
				},
				{
					label: 'Germany',
					value: 'de'
				},
				{
					label: 'Ghana',
					value: 'gh'
				},
				{
					label: 'Gibraltar',
					value: 'gi'
				},
				{
					label: 'Greece',
					value: 'gr'
				},
				{
					label: 'Greenland',
					value: 'gl'
				},
				{
					label: 'Grenada',
					value: 'gd'
				},
				{
					label: 'Guadeloupe',
					value: 'gp'
				},
				{
					label: 'Guam',
					value: 'gu'
				},
				{
					label: 'Guatemala',
					value: 'gt'
				},
				{
					label: 'Guernsey',
					value: 'gg'
				},
				{
					label: 'Guinea',
					value: 'gn'
				},
				{
					label: 'Guinea-Bissau',
					value: 'gw'
				},
				{
					label: 'Guyana',
					value: 'gy'
				},
				{
					label: 'Haiti',
					value: 'ht'
				},
				{
					label: 'Honduras',
					value: 'hn'
				},
				{
					label: 'Hong Kong',
					value: 'hk'
				},
				{
					label: 'Hungary',
					value: 'hu'
				},
				{
					label: 'Iceland',
					value: 'is'
				},
				{
					label: 'India',
					value: 'in'
				},
				{
					label: 'Indonesia',
					value: 'id'
				},
				{
					label: 'Iran',
					value: 'ir'
				},
				{
					label: 'Iraq',
					value: 'iq'
				},
				{
					label: 'Ireland',
					value: 'ie'
				},
				{
					label: 'Isle of Man',
					value: 'im'
				},
				{
					label: 'Israel',
					value: 'il'
				},
				{
					label: 'Italy',
					value: 'it'
				},
				{
					label: 'Jamaica',
					value: 'jm'
				},
				{
					label: 'Japan',
					value: 'jp'
				},
				{
					label: 'Jersey',
					value: 'je'
				},
				{
					label: 'Jordan',
					value: 'jo'
				},
				{
					label: 'Kazakhstan',
					value: 'kz'
				},
				{
					label: 'Kenya',
					value: 'ke'
				},
				{
					label: 'Kiribati',
					value: 'ki'
				},
				{
					label: 'Kuwait',
					value: 'kw'
				},
				{
					label: 'Kyrgyzstan',
					value: 'kg'
				},
				{
					label: 'Laos',
					value: 'la'
				},
				{
					label: 'Latvia',
					value: 'lv'
				},
				{
					label: 'Lebanon',
					value: 'lb'
				},
				{
					label: 'Lesotho',
					value: 'ls'
				},
				{
					label: 'Liberia',
					value: 'lr'
				},
				{
					label: 'Libya',
					value: 'ly'
				},
				{
					label: 'Liechtenstein',
					value: 'li'
				},
				{
					label: 'Lithuania',
					value: 'lt'
				},
				{
					label: 'Luxembourg',
					value: 'lu'
				},
				{
					label: 'Macao',
					value: 'mo'
				},
				{
					label: 'Macedonia',
					value: 'mk'
				},
				{
					label: 'Madagascar',
					value: 'mg'
				},
				{
					label: 'Malawi',
					value: 'mw'
				},
				{
					label: 'Malaysia',
					value: 'my'
				},
				{
					label: 'Maldives',
					value: 'mv'
				},
				{
					label: 'Mali',
					value: 'ml'
				},
				{
					label: 'Malta',
					value: 'mt'
				},
				{
					label: 'Marshall Islands',
					value: 'mh'
				},
				{
					label: 'Martinique',
					value: 'mq'
				},
				{
					label: 'Mauritania',
					value: 'mr'
				},
				{
					label: 'Mauritius',
					value: 'mu'
				},
				{
					label: 'Mexico',
					value: 'mx'
				},
				{
					label: 'Micronesia',
					value: 'fm'
				},
				{
					label: 'Moldova',
					value: 'md'
				},
				{
					label: 'Monaco',
					value: 'mc'
				},
				{
					label: 'Mongolia',
					value: 'mn'
				},
				{
					label: 'Montenegro',
					value: 'me'
				},
				{
					label: 'Montserrat',
					value: 'ms'
				},
				{
					label: 'Morocco',
					value: 'ma'
				},
				{
					label: 'Mozambique',
					value: 'mz'
				},
				{
					label: 'Myanmar (Burma)',
					value: 'mm'
				},
				{
					label: 'Namibia',
					value: 'na'
				},
				{
					label: 'Nauru',
					value: 'nr'
				},
				{
					label: 'Nepal',
					value: 'np'
				},
				{
					label: 'Netherlands',
					value: 'nl'
				},
				{
					label: 'New Caledonia',
					value: 'nc'
				},
				{
					label: 'New Zealand',
					value: 'nz'
				},
				{
					label: 'Nicaragua',
					value: 'ni'
				},
				{
					label: 'Niger',
					value: 'ne'
				},
				{
					label: 'Nigeria',
					value: 'ng'
				},
				{
					label: 'North Korea',
					value: 'kp'
				},
				{
					label: 'Norway',
					value: 'no'
				},
				{
					label: 'Oman',
					value: 'om'
				},
				{
					label: 'Pakistan',
					value: 'pk'
				},
				{
					label: 'Palau',
					value: 'pw'
				},
				{
					label: 'Palestinian Territory',
					value: 'ps'
				},
				{
					label: 'Panama',
					value: 'pa'
				},
				{
					label: 'Papua New Guinea',
					value: 'pg'
				},
				{
					label: 'Paraguay',
					value: 'py'
				},
				{
					label: 'Peru',
					value: 'pe'
				},
				{
					label: 'Philippines',
					value: 'ph'
				},
				{
					label: 'Poland',
					value: 'pl'
				},
				{
					label: 'Portugal',
					value: 'pt'
				},
				{
					label: 'Puerto Rico',
					value: 'pr'
				},
				{
					label: 'Qatar',
					value: 'qa'
				},
				{
					label: 'Reunion',
					value: 're'
				},
				{
					label: 'Romania',
					value: 'ro'
				},
				{
					label: 'Russian Federation',
					value: 'ru'
				},
				{
					label: 'Rwanda',
					value: 'rw'
				},
				{
					label: 'Saint Kitts and Nevis',
					value: 'kn'
				},
				{
					label: 'Saint Lucia',
					value: 'lc'
				},
				{
					label: 'Saint Vincent and the Grenadines',
					value: 'vc'
				},
				{
					label: 'Samoa',
					value: 'ws'
				},
				{
					label: 'San Marino',
					value: 'sm'
				},
				{
					label: 'Sao Tome and Principe',
					value: 'st'
				},
				{
					label: 'Saudi Arabia',
					value: 'sa'
				},
				{
					label: 'Senegal',
					value: 'sn'
				},
				{
					label: 'Serbia',
					value: 'rs'
				},
				{
					label: 'Seychelles',
					value: 'sc'
				},
				{
					label: 'Sierra Leone',
					value: 'sl'
				},
				{
					label: 'Singapore',
					value: 'sg'
				},
				{
					label: 'Slovakia',
					value: 'sk'
				},
				{
					label: 'Slovenia',
					value: 'si'
				},
				{
					label: 'Solomon Islands',
					value: 'sb'
				},
				{
					label: 'Somalia',
					value: 'so'
				},
				{
					label: 'South Africa',
					value: 'za'
				},
				{
					label: 'South Korea',
					value: 'kr'
				},
				{
					label: 'Spain',
					value: 'es'
				},
				{
					label: 'Sri Lanka',
					value: 'lk'
				},
				{
					label: 'Sudan',
					value: 'sd'
				},
				{
					label: 'Surilabel',
					value: 'sr'
				},
				{
					label: 'Swaziland',
					value: 'sz'
				},
				{
					label: 'Sweden',
					value: 'se'
				},
				{
					label: 'Switzerland',
					value: 'ch'
				},
				{
					label: 'Syrian Arab Republic',
					value: 'sy'
				},
				{
					label: 'Taiwan, Province of China',
					value: 'tw'
				},
				{
					label: 'Tajikistan',
					value: 'tj'
				},
				{
					label: 'Tanzania',
					value: 'tz'
				},
				{
					label: 'Thailand',
					value: 'th'
				},
				{
					label: 'Timor-Leste',
					value: 'tl'
				},
				{
					label: 'Togo',
					value: 'tg'
				},
				{
					label: 'Tonga',
					value: 'to'
				},
				{
					label: 'Trinidad and Tobago',
					value: 'tt'
				},
				{
					label: 'Tunisia',
					value: 'tn'
				},
				{
					label: 'Turkey',
					value: 'tr'
				},
				{
					label: 'Turkmenistan',
					value: 'tm'
				},
				{
					label: 'Turks and Caicos Islands',
					value: 'tc'
				},
				{
					label: 'Tuvalu',
					value: 'tv'
				},
				{
					label: 'Uganda',
					value: 'ug'
				},
				{
					label: 'Ukraine',
					value: 'ua'
				},
				{
					label: 'United Arab Emirates',
					value: 'ae'
				},
				{
					label: 'United Kingdom',
					value: 'gb'
				},
				{
					label: 'United States',
					value: 'us'
				},
				{
					label: 'Uruguay',
					value: 'uy'
				},
				{
					label: 'Uzbekistan',
					value: 'uz'
				},
				{
					label: 'Vanuatu',
					value: 'vu'
				},
				{
					label: 'Vatican City',
					value: 'va'
				},
				{
					label: 'Venezuela',
					value: 've'
				},
				{
					label: 'Viet Nam',
					value: 'vn'
				},
				{
					label: 'Virgin Islands (British)',
					value: 'vg'
				},
				{
					label: 'Virgin Islands (U.S.)',
					value: 'vi'
				},
				{
					label: 'Western Sahara',
					value: 'eh'
				},
				{
					label: 'Yemen',
					value: 'ye'
				},
				{
					label: 'Zambia',
					value: 'zm'
				},
				{
					label: 'Zimbabwe',
					value: 'zw'
				}
			]
		};
	} else if (key === 'lang') {
		return {
			input: 'select',
			options: [
				{ label: 'Español', value: 'es' },
				{ label: 'Inglés', value: 'en' }
			]
		};
	} else if (key === 'maritalStatus') {
		return {
			input: 'select',
			options: [
				{ label: 'Soltero', value: 'single' },
				{ label: 'Casado en gananciales', value: 'married_property' },
				{ label: 'Divorciado', value: 'divorced' },
				{ label: 'Viudo', value: 'widowed' },
				{ label: 'Pareja de hecho', value: 'unmarried_partner' },
				{
					label: 'Casado en separación de bienes',
					value: 'married_separation'
				}
			]
		};
	} else if (key === 'activity') {
		return {
			input: 'select',
			options: [
				{ label: 'Autónomo', value: 'FREELANCER' },
				{ label: 'Empresario', value: 'BUSINESS_OWNER' },
				{ label: 'Pensionista', value: 'PENSIONER' },
				{ label: 'Desempleado', value: 'UNEMPLOYED' },
				{ label: 'Rentista', value: 'RENTIER' },
				{ label: 'Asalariado fijo', value: 'EMPLOYEE_FIXED' },
				{ label: 'Asalariado temporal', value: 'EMPLOYEE_TEMPORAL' },
				{ label: 'Funcionario', value: 'FUNCTIONARY' },
				{ label: 'Funcionario interino', value: 'FUNCTIONARY_INTERIM' },
				{ label: 'Desconocido', value: 'UNKNOW' }
			]
		};
	} else if (key === 'seniority') {
		return {
			input: 'select',
			options: [
				{ label: 'Sin antigüedad', value: '0' },
				{ label: '1 año', value: '1' },
				{ label: '2 años', value: '2' },
				{ label: '3 años', value: '3' },
				{ label: '4 años', value: '4' },
				{ label: '5 años o mas', value: '5' }
			]
		};
	} else if (key === 'numberOfPayments') {
		return {
			input: 'select',
			options: [
				{ label: '12', value: '12' },
				{ label: '13', value: '13' },
				{ label: '14', value: '14' },
				{ label: '15', value: '15' },
				{ label: '16', value: '16' },
				{ label: '18', value: '18' },
				{ label: '19', value: '19' },
				{ label: '20', value: '20' },
				{ label: '21', value: '21' },
				{ label: '22', value: '22' },
				{ label: '23', value: '23' },
				{ label: '24', value: '24' },
				{ label: '25', value: '25' },
				{ label: '26', value: '26' },
				{ label: '27', value: '27' },
				{ label: '28', value: '28' },
				{ label: '29', value: '29' },
				{ label: '30', value: '30' }
			]
		};
	} else if (key === 'province') {
		return {
			input: 'select',
			options: [
				{ label: 'A Coruña', value: 'A Coruña' },
				{ label: 'Álava', value: 'Álava' },
				{ label: 'Albacete', value: 'Albacete' },
				{ label: 'Alicante', value: 'Alicante' },
				{ label: 'Almería', value: 'Almería' },
				{ label: 'Asturias', value: 'Asturias' },
				{ label: 'Ávila', value: 'Ávila' },
				{ label: 'Badajoz', value: 'Badajoz' },
				{ label: 'Barcelona', value: 'Barcelona' },
				{ label: 'Baleares', value: 'Baleares' },
				{ label: 'Burgos', value: 'Burgos' },
				{ label: 'Cáceres', value: 'Cáceres' },
				{ label: 'Cádiz', value: 'Cádiz' },
				{ label: 'Cantabria', value: 'Cantabria' },
				{ label: 'Castellón', value: 'Castellón' },
				{ label: 'Ceuta', value: 'Ceuta' },
				{ label: 'Ciudad Real', value: 'Ciudad Real' },
				{ label: 'Córdoba', value: 'Córdoba' },
				{ label: 'Cuenca', value: 'Cuenca' },
				{ label: 'Girona', value: 'Girona' },
				{ label: 'Granada', value: 'Granada' },
				{ label: 'Guadalajara', value: 'Guadalajara' },
				{ label: 'Guipúzcoa', value: 'Guipúzcoa' },
				{ label: 'Huelva', value: 'Huelva' },
				{ label: 'Huesca', value: 'Huesca' },
				{ label: 'Jaén', value: 'Jaén' },
				{ label: 'La Rioja', value: 'La Rioja' },
				{ label: 'Las Palmas', value: 'Las Palmas' },
				{ label: 'León', value: 'León' },
				{ label: 'Lleida', value: 'Lleida' },
				{ label: 'Lugo', value: 'Lugo' },
				{ label: 'Madrid', value: 'Madrid' },
				{ label: 'Málaga', value: 'Málaga' },
				{ label: 'Murcia', value: 'Murcia' },
				{ label: 'Melilla', value: 'Melilla' },
				{ label: 'Navarra', value: 'Navarra' },
				{ label: 'Ourense', value: 'Ourense' },
				{ label: 'Palencia', value: 'Palencia' },
				{ label: 'Pontevedra', value: 'Pontevedra' },
				{ label: 'Salamanca', value: 'Salamanca' },
				{ label: 'Santa Cruz de Tenerife', value: 'Santa Cruz de Tenerife' },
				{ label: 'Segovia', value: 'Segovia' },
				{ label: 'Sevilla', value: 'Sevilla' },
				{ label: 'Soria', value: 'Soria' },
				{ label: 'Tarragona', value: 'Tarragona' },
				{ label: 'Teruel', value: 'Teruel' },
				{ label: 'Toledo', value: 'Toledo' },
				{ label: 'Valencia', value: 'Valencia' },
				{ label: 'Valladolid', value: 'Valladolid' },
				{ label: 'Vizcaya', value: 'Vizcaya' },
				{ label: 'Zamora', value: 'Zamora' },
				{ label: 'Zaragoza', value: 'Zaragoza' }
			]
		};
	} else {
		return {};
	}
};

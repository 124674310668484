import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const TypeIndicesViability = ({ title, propertyColor, propertyData, percent }) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between'
			}}
		>
			<Text
				style={{
					fontSize: '12px',
					fontWeight: '600',
					color: '#2F4858',
					marginRight: '16px'
				}}
			>
				{title}
			</Text>
			<div
				style={{
					justifyContent: 'right'
				}}
			>
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '500',
						color: propertyColor,
						justifyContent: 'right'
					}}
				>
					{propertyData}
				</Text>
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '500',
						color: '#748EA0',
						justifyContent: 'right',
						marginLeft: '4px'
					}}
				>
					{percent}
				</Text>
			</div>
		</div>
	);
};

export default TypeIndicesViability;

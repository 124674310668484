import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Col,
	Row,
	Input,
	Modal,
	Form,
	Select,
	InputNumber,
	message,
	Tooltip
} from 'antd';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { InputDate } from 'afrodita';
import { renderComponent } from '../../data/renderComponent';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const TableLiberBank = ({ mortgageId, showModalEmail, mortgage }) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const location = useLocation()
	const idBank = location && location.state !== undefined ? location.state.bankId : undefined;
	const bankRequestId = location && location.state !== undefined ? location.state.bankRequestId : undefined;
	const bankRequest = location && location.state !== undefined ? location.state.bankRequest : undefined;
	const [visibleCancel, setVisibleCancel] = useState(false);
	const [emptyFields, setEmptyFields] = useState([])

	useEffect(() => {
		if (mortgage) {
			const values = form.getFieldsValue();
			const undefinedFields = Object.entries(values)
				.filter(([fieldName, value]) => value === undefined || value === "" || value === "Fecha inválida")
				.map(([fieldName]) => fieldName);
			setEmptyFields(undefinedFields);
		}
	}, [form, mortgage]);

	const handleFormChange = () => {
		const values = form.getFieldsValue();
		const undefinedFields = Object.entries(values)
			.filter(([fieldName, value]) => value === undefined || value === "" || value === "Fecha inválida")
			.map(([fieldName]) => fieldName);
		setEmptyFields(undefinedFields);
	};

	const renderForm = () => {
		const arrayform = [];

		for (let index = 1; index <= mortgage.mortgage.participants; index++) {
			arrayform.push(
				<>
					<Row>
						<Text
							style={{
								fontSize: 14,
								fontWeight: 600,
								color: '#2F4858',
								marginTop: index === 1 ? 32 : 24
							}}>
							{'Titular ' + index}
						</Text>
					</Row>
					<Row gutter={[32, 32]} style={{ marginTop: 24 }}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'fullName' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Nombre y apellidos'}
									</Text>
								}
								className={emptyFields.includes(`fullName${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'birthdate' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Fecha de nacimiento'}
									</Text>
								}
								className={emptyFields.includes(`birthdate${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputDate 
									type="date"
									style={{ width: '100%', borderRadius: 4 }} 
									onChange={handleFormChange}/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'nif' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'DNI'}</Text>
								}
								className={emptyFields.includes(`nif${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						{index !== 2 && (
							<Col span={6}>
								<Form.Item
									style={{ width: '100%' }}
									name={'maritalStatus' + index}
									label={
										<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
											{'Estado Civil'}
										</Text>
									}
									className={emptyFields.includes(`maritalStatus${index}`) ? 'shadowed' : ''}
									rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
									<Select placeholder="Seleccione" onChange={handleFormChange}>
										{renderComponent('maritalStatus').options.map((status) => (
											<Option key={status.value} value={status.value}>
												{status.label}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						)}
					</Row>
					{index !== 2 && (
						<Row gutter={32} style={{ marginTop: -10 }}>
							<Col span={6}>
								<Form.Item
									style={{ width: '100%' }}
									name={'children' + index}
									label={
										<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
											{'Numero de hijos'}
										</Text>
									}
									className={emptyFields.includes(`children${index}`) ? 'shadowed' : ''}
									rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
									<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
								</Form.Item>
							</Col>
						</Row>
					)}
					<Row gutter={[32, 0]} style={{ marginTop: -10 }}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'profession' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Profesión'}
									</Text>
								}
								className={emptyFields.includes(`profession${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'company' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Empresa'}
									</Text>
								}
								className={emptyFields.includes(`company${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'hiringDate' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Fecha de inicio en la empresa actual'}
									</Text>
								}
								className={emptyFields.includes(`hiringDate${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputDate 
									type="date" 
									style={{ width: '100%', borderRadius: 4 }} 
									onChange={handleFormChange}/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'seniority' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Años de vida laboral'}
									</Text>
								}
								className={emptyFields.includes(`seniority${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>

						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'payroll1' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Nómina 1'}
									</Text>
								}
								className={emptyFields.includes(`payroll1${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'payroll2' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Nómina 2'}
									</Text>
								}
								className={emptyFields.includes(`payroll2${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'payroll3' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Nómina 3'}
									</Text>
								}
								className={emptyFields.includes(`payroll3${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>

						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'payments' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Nº de pagas'}
									</Text>
								}
								className={emptyFields.includes(`payments${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'avgPayroll' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Media mensual neta'}
									</Text>
								}
								className={emptyFields.includes(`avgPayroll${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'netIncomes' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Rendimiento neto IRPF'}
									</Text>
								}
								className={emptyFields.includes(`netIncomes${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'netTaxes' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Retención de rendimiento IRPF'}
									</Text>
								}
								className={emptyFields.includes(`netTaxes${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
					</Row>
				</>
			);
		}

		return arrayform;
	};

	const onFinish = (values) => {
		let valuesFormat = [];
		let valuesFormatOperation = [];

		for (let index = 1; index <= parseInt(mortgage.mortgage.participants); index++) {
			valuesFormat.push({
				['owner' + index]: {
					fullName: values[`${'fullName' + index}`],
					birthdate: values[`${'birthdate' + index}`],
					nif: values[`${'nif' + index}`],
					maritalStatus: values[`${'maritalStatus' + index}`],
					children: values[`${'children' + index}`],
					profession: values[`${'profession' + index}`],
					company: values[`${'company' + index}`],
					hiringDate: values[`${'hiringDate' + index}`],
					payroll1: values[`${'payroll1' + index}`],
					payroll2: values[`${'payroll2' + index}`],
					payroll3: values[`${'payroll3' + index}`],
					payments: values[`${'payments' + index}`],
					avgPayroll: values[`${'avgPayroll' + index}`],
					netIncomes: values[`${'netIncomes' + index}`],
					netTaxes: values[`${'netTaxes' + index}`],
					seniority: values[`${'seniority' + index}`]
				}
			});
		}
		valuesFormatOperation.push({
			['operation']: {
				amount: values[`${'amount'}`],
				term: values[`${'term'}`],
				housePrice: values[`${'housePrice'}`],
				savings: values[`${'savings'}`],
				ltv: values[`${'ltv'}`],
				cirbe: values[`${'cirbe'}`],
				rateType: values[`${'rateType'}`],
				currentHouse: values[`${'currentHouse'}`],
				rent: values[`${'rent'}`],
				heritage: values[`${'heritage'}`],
				appraisalAmount: values[`${'appraisalAmount'}`],
				loanDescription: values[`${'loanDescription'}`]
			}
		});

		if (valuesFormat[1] !== undefined) {
			axios
				.post(
					`${env.api.url}/v1/hadmin/banks/generate-excel-liberbank`,
					{
						operationId: mortgageId,
						operation: valuesFormatOperation[0].operation,
						owner1: valuesFormat[0].owner1,
						owner2: valuesFormat[1] !== undefined ? valuesFormat[1].owner2 : {},
						comments: values.comments
					},
					{
						headers: { Authorization: `Bearer ${authClient.getToken()}` }
					}
				)
				.then((response) => {
					message.success('Archivo csv creado correctamente');
					window.open(response.data.url);

					
					navigate(`/operations/mortgages/${mortgageId}/view-document-list`,{
						state: { bankId: idBank, bankRequestId: bankRequestId, bankRequest: bankRequest }
					});
				});
		} else {
			axios
				.post(
					`${env.api.url}/v1/hadmin/banks/generate-excel-liberbank`,
					{
						operationId: mortgageId,
						operation: valuesFormatOperation[0].operation,
						owner1: valuesFormat[0].owner1,
						comments: values.comments
					},
					{
						headers: { Authorization: `Bearer ${authClient.getToken()}` }
					}
				)
				.then((response) => {
					message.success('Archivo csv creado correctamente');
					window.open(response.data.url);

					
					navigate(`/operations/mortgages/${mortgageId}/view-document-list`,{
						state: { bankId: idBank, bankRequestId: bankRequestId, bankRequest: bankRequest }
					});
				});
		}
	};

	const cancelSend = () => {
		setVisibleCancel(!visibleCancel);
	};
	const handleOk = () => {
		setVisibleCancel(!visibleCancel);

		navigate(`/operations/mortgages/${mortgageId}/send-to-bank`,{
			state: { bankId: idBank, bankRequestId: bankRequestId, bankRequest: bankRequest }
		});
	};

	const handleOk2 = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/form-send-bank`);
	};
	const handleCancel = () => {
		setVisibleCancel(!visibleCancel);
	};

	const renderName = (mortgage, owner) => {
		let name = mortgage[owner].name !== undefined ? mortgage[owner].name : '' 
		let firstSurname = mortgage[owner].firstSurname !== undefined ? mortgage[owner].firstSurname : '' 
		let lastSurname = mortgage[owner].lastSurname !== undefined ? mortgage[owner].lastSurname : '' 
		
		return name.concat(' ', firstSurname, ' ', lastSurname);
	};

	const renderCurrentHouse = (mortgage) => {
		if (mortgage['owner1'].currentlyLiving) {
			if (mortgage['owner1'].currentlyLiving === 'RENTAL') {
				return 'Alquiler'
			} else if (mortgage['owner1'].currentlyLiving === 'OWN_HOME') {
				return 'Casa propia'
			} else if (mortgage['owner1'].currentlyLiving === 'PARENTS_HOME') {
				return 'Vive con familiares'
			}
		}
		return undefined;
	};

	return (
		<>
			<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
				Datos de la operación
			</Text>
			<Form
				form={form}
				layout="vertical"
				name="sendMessageForm"
				style={{ width: '100%' }}
				initialValues={{
					activity1: mortgage['owner1'].activity !== undefined ? mortgage['owner1'].activity : '',
					activity2: mortgage['owner2'] !== undefined ? mortgage['owner2'].activity : '',
					avgPayroll1:
						mortgage['owner1'].netIncomes !== undefined ? mortgage['owner1'].netIncomes : '',
					avgPayroll2: mortgage['owner2'] !== undefined ? mortgage['owner2'].netIncomes : '',
					fullName1: mortgage['owner1'] ? renderName(mortgage, 'owner1') : '',
					fullName2: mortgage['owner2'] !== undefined ? renderName(mortgage, 'owner2') : '',
					birthdate1:
						mortgage['owner1'].birthdate !== undefined
							? moment(mortgage['owner1'].birthdate, 'YYYY-MM-DD').format('DD-MM-YYYY')
							: '',
					birthdate2:
						mortgage['owner2'] !== undefined
							? moment(mortgage['owner2'].birthdate, 'YYYY-MM-DD').format('DD-MM-YYYY')
							: '',
					nif1: mortgage['owner1'].nif !== undefined ? mortgage['owner1'].nif : '',
					nif2: mortgage['owner2'] !== undefined ? mortgage['owner2'].nif : '',
					maritalStatus1:
						mortgage['owner1'].maritalStatus !== undefined ? mortgage['owner1'].maritalStatus : undefined,
					maritalStatus2: mortgage['owner2'] !== undefined ? mortgage['owner2'].maritalStatus : undefined,
					payments1:
						mortgage['owner1'].numberOfPayments !== undefined
							? mortgage['owner1'].numberOfPayments
							: '',
					payments2: mortgage['owner2'] !== undefined ? mortgage['owner2'].numberOfPayments : '',
					housePrice:
						mortgage.subrogation !== undefined
							? mortgage.subrogation.maxBudget
							: mortgage.mortgage.maxBudget !== undefined
							? mortgage.mortgage.maxBudget
							: '',
					savings: mortgage.mortgage.savings !== undefined ? mortgage.mortgage.savings : '',
					currentHouse: renderCurrentHouse(mortgage),
					term: bankRequest.months / 12,
					amount: bankRequest !== undefined ? bankRequest?.amount : undefined,
					ltv: bankRequest.loanToValue !== undefined ? bankRequest.loanToValue : '',
					rateType: mortgage.desiredOffer?.interest !== undefined ? mortgage.desiredOffer?.interest.toLowerCase() : undefined,
					seniority1:
						mortgage['owner1']?.employment?.totalDaysContributed !== undefined
							? parseFloat(mortgage['owner1'].employment.totalDaysContributed / 365).toFixed(1)
							: '',
					seniority2:
						mortgage['owner2']?.employment?.totalDaysContributed !== undefined
							? parseInt(mortgage['owner2'].employment.totalDaysContributed / 365).toFixed(2)
							: '',
					company1: mortgage['owner1'].companyName !== undefined ? mortgage['owner1'].companyName : '',
					company2:
					mortgage['owner2']?.companyName !== undefined ? mortgage['owner2'].companyName : '',
					profession1: mortgage['owner1'].currentProfession !== undefined ? mortgage['owner1'].currentProfession : '',
					children1: mortgage['owner1'].numberSons !== undefined ? mortgage['owner1'].numberSons : '',
					netIncomes1: mortgage['owner1']?.incomesWealth?.IRPF_0017 !== undefined ? mortgage['owner1']?.incomesWealth?.IRPF_0017 : '',  
					hiringDate1: moment( mortgage['owner1'].employment?.dateSeniorityCompany, 'YYYY-MM-DD').format('DD-MM-YYYY'),
					hiringDate2: mortgage['owner2'] !== undefined ? moment( mortgage['owner2'].employment?.dateSeniorityCompany, 'YYYY-MM-DD').format('DD-MM-YYYY') : undefined,
					profession2:
						mortgage['owner2']?.currentProfession !== undefined
							? mortgage['owner2'].currentProfession
							: '',				
					netIncomes2:
						mortgage['owner2']?.incomesWealth?.IRPF_0017 !== undefined
							? mortgage['owner2']?.incomesWealth?.IRPF_0017
							: '',
					netTaxes1: mortgage['owner1']?.incomesWealth?.IRPF_0596,
					netTaxes2:
						mortgage['owner2'] !== undefined ? mortgage['owner2']?.incomesWealth?.IRPF_0596 : '',
					appraisalAmount: mortgage.subrogation !== undefined
					? mortgage.subrogation.maxBudget
					: mortgage.mortgage.maxBudget !== undefined
					? mortgage.mortgage.maxBudget
					: '',
				}}
				onChange={handleFormChange}
				onFinish={onFinish}>
				{renderForm()}

				<div style={{ marginTop: 24 }}>
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>Operacion</Text>
				</div>
				<Row gutter={[32, 0]} style={{ marginTop: 24 }}>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'amount'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Importe préstamo'}
								</Text>
							}
							className={emptyFields.includes('amount') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<InputNumber style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'term'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Plazo (años)'}
								</Text>
							}
							className={emptyFields.includes('term') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<InputNumber style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'housePrice'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Precio de compra'}
								</Text>
							}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<InputNumber style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'savings'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'Ahorros'}</Text>
							}
							className={emptyFields.includes('savings') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<InputNumber style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'ltv'}
							label={
								<div style={{ display: 'flex' }}>
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'LTV'}</Text>
									<Tooltip title={'Ratio de financiación de la operación'}>
										<img src={'/images/tooltip.png'} style={{ height: 12, width: 12, marginLeft: 4 }}></img>
									</Tooltip>
								</div>
							}
							className={emptyFields.includes('ltv') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<InputNumber style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'cirbe'}
							label={
								<div style={{ display: 'flex' }}>
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{' CIRBE (opcional)'}
									</Text>
									<Tooltip title={'Suma de las obligaciones de pago de todos los titulares al mes'}>
										<img src={'/images/tooltip.png'} style={{ height: 12, width: 12, marginLeft: 4 }}></img>
									</Tooltip>
								</div>
							}>
							<InputNumber style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'rateType'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									Tipo de interés
								</Text>
							}
							className={emptyFields.includes('rateType') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<Select
								showSearch
								onChange={handleFormChange}
								placeholder="Seleccione"
								name="new/second"
								style={{ width: '100%', borderRadius: 4 }}>
								<Option value="fixed">Fijo</Option>
								<Option value="variable">Variable</Option>
								<Option value="mixed">Mixto</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'currentHouse'}
							label={
								<div style={{ display: 'flex' }}>
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Viv. Hab'}
									</Text>
									<Tooltip title={'Vive de alquiler o con familiares'}>
										<img src={'/images/tooltip.png'} style={{ height: 12, width: 12, marginLeft: 4 }}></img>
									</Tooltip>
								</div>
							}>
							<Input style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'rent'}
							label={
								<div style={{ display: 'flex' }}>
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Años y renta'}
									</Text>
									<Tooltip title={'Tiempo viviendo en la vivienda de alquiler e importe que pagan'}>
										<img src={'/images/tooltip.png'} style={{ height: 12, width: 12, marginLeft: 4 }}></img>
									</Tooltip>
								</div>
							}>
							<InputNumber style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'heritage'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Patrimonio'}
								</Text>
							}>
							<InputNumber style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'appraisalAmount'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Valor de tasación'}
								</Text>
							}>
							<InputNumber style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							style={{ width: '100%' }}
							name={'loanDescription'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Descripción de los préstamos de otras entidades'}
								</Text>
							}>
							<TextArea style={{ width: '100%', borderRadius: 4, height: 70 }} />
						</Form.Item>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Form.Item
							style={{ width: '100%' }}
							name={'comments'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Observaciones'}
								</Text>
							}>
							<TextArea style={{ width: '100%', borderRadius: 4, height: 70 }} />
						</Form.Item>
					</Col>
				</Row>

				<Row style={{ justifyContent: 'center', marginTop: 20 }}>
					<Button
						style={{ height: 32, width: 96, fontSize: 14 }}
						className="button-secundary-gibobs"
						onClick={() => cancelSend()}
						type="dashed">
						Cancelar
					</Button>

					<Button
						style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
						type="primary"
						htmlType="submit"
						className="button-primari-gibobs">
						Aceptar
					</Button>
				</Row>
			</Form>
			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{ className: 'button-secundary-gibobs', style: { width: 96 } }}
				okButtonProps={{ className: 'button-primari-gibobs', style: { width: 96 } }}
				visible={visibleCancel}
				title={
					<Text style={{ fontSize: '14px', fontWeight: '600px', color: '#2F4858' }}>
						{'Cancelar envío'}
					</Text>
				}
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				width={404}
				onCancel={handleCancel}
				onOk={handleOk2}>
				<Text style={{ fontSize: '12px', fontWeight: '400px', color: '#2F4858' }}>
					{'¿Aceptas la cancelación de la generación del excel de La Liberbank?'}
				</Text>
			</Modal>
		</>
	);
};
export default TableLiberBank;

import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Typography, Checkbox, Dropdown, Modal } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { FileSearchOutlined } from '@ant-design/icons';

const { Text } = Typography;

function FilterTask({ notesArray, setShowMortgageNoteModal, setShowMortgageNoteFromImageModal }) {
	const [noteArray, setNoteArray] = useState(notesArray);
	const [noteArrayTotal, setNoteArrayTotal] = useState(undefined);
	const [modal, contextHolder] = Modal.useModal();

	useEffect(() => {
		if (notesArray) {
			filterNotes(['other', 'call', 'task'], notesArray);
			setNoteArray(notesArray.reverse());
		}
	}, [notesArray]);

	const filterNotes = (filter, notes) => {
		const tmpArray = [];
		setNoteArray(undefined);

		for (let i = 0; i < filter.length; i++) {
			if (filter[i] === 'call') {
				tmpArray.push(
					_.filter(notes === undefined ? noteArrayTotal : notes, function (n) {
						return (
							n.body.includes('ha realizado una llamada') ||
							n.body.includes('ha recibido una llamada')
						);
					})
				);
			}
			if (filter[i] === 'task') {
				tmpArray.push(
					_.filter(notes === undefined ? noteArrayTotal : notes, function (n) {
						return (
							n.body.startsWith('Ha creado una') ||
							n.body.startsWith('Ha finalizado la tarea') ||
							n.body.startsWith('Ha re-agendado la tarea')
						);
					})
				);
			}
			if (filter[i] === 'other') {
				tmpArray.push(
					_.filter(notes === undefined ? noteArrayTotal : notes, function (n) {
						return (
							!n.body.startsWith('Ha creado una') &&
							!n.body.startsWith('Ha finalizado la tarea') &&
							!n.body.startsWith('Ha re-agendado la tarea') &&
							!n.body.includes('ha realizado una llamada')
						);
					})
				);
			}
		}
		const tmp = _.reduceRight(
			tmpArray,
			function (flattened, other) {
				return flattened.concat(other);
			},
			[]
		);
		const sort = _.orderBy(tmp, ['date'], ['desc']);
		setNoteArray(sort);
	};

	const renderText = (text) => {
		text = text.replace(/\n/g, '<br />');

		return text;
	};

	const addNoteMenuClick = (e) => {
		if (
			e.key === 'image-gpt' &&
			setShowMortgageNoteFromImageModal &&
			typeof setShowMortgageNoteFromImageModal === 'function'
		) {
			setShowMortgageNoteFromImageModal(true);
		}
	};

	return (
		<Col lg={16} xs={24} style={{ marginTop: '27px' }}>
			<Row gutter={24}>
				<Col lg={3} xs={3}>
					<div>
						<Text
							style={{
								fontSize: '14px',
								fontWeight: '600',
								color: '#2F4858'
							}}>
							Notas
						</Text>
					</div>
				</Col>
				<Col lg={17} xs={17}>
					<Checkbox.Group
						onChange={(e) => filterNotes(e, notesArray)}
						defaultValue={['other', 'call', 'task']}
						style={{ width: '100%' }}>
						<Row gutter={24}>
							<Col lg={8} xs={8}>
								<div>
									<Checkbox value={'task'}>
										<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
											{'Notas de tareas'}
										</Text>
									</Checkbox>
								</div>
							</Col>

							<Col lg={9} xs={9}>
								<div>
									<Checkbox value={'call'}>
										<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
											{'Notas de llamadas'}
										</Text>
									</Checkbox>
								</div>
							</Col>

							<Col lg={7} xs={7}>
								<div>
									<Checkbox value={'other'}>
										<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
											{'Otras notas'}
										</Text>
									</Checkbox>
								</div>
							</Col>
						</Row>
					</Checkbox.Group>
				</Col>
				<Col lg={4} xs={4} style={{ textAlign: 'right' }}>
					<Dropdown.Button
						className="button-secundary-gibobs"
						icon={<FileSearchOutlined />}
						onClick={() => {
							setShowMortgageNoteModal(true);
						}}
						menu={{
							items: [
								{
									key: 'image-gpt',
									label: 'Nota como imagen'
								}
							],
							onClick: addNoteMenuClick
						}}>
						Añadir nota
					</Dropdown.Button>
					{contextHolder}
					{/* <Button
						type="dashed"
						className="button-secundary-gibobs"
						onClick={() => {
							setShowMortgageNoteModal(true);
						}}>
						Añadir nota
					</Button> */}
				</Col>
			</Row>
			<Card
				style={{
					border: '1px solid #C0DAEE',
					borderRadius: '4px',
					marginTop: '19px'
				}}>
				{noteArray
					? noteArray.map((data, i) => (
							<div style={{ marginBottom: '27px' }} key={`${data.userId}-${i}`}>
								<div>
									<Text
										style={{
											fontSize: '14px',
											fontWeight: '600',
											color: '#02C3CD'
										}}>
										{data.userFullname}
									</Text>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: 'normal',
											color: '#2F4858',
											marginLeft: '8px'
										}}>
										{moment(data.date).format('L')} - {moment(data.date).format('LT')}
									</Text>
								</div>
								<div>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 'normal',
											color: '#748EA0',
											marginRight: '8px'
										}}
										dangerouslySetInnerHTML={{ __html: renderText(data.body) }}></div>
								</div>
							</div>
					  ))
					: null}
			</Card>
		</Col>
	);
}

export default FilterTask;

import React, { useEffect, useState } from 'react';
import {
	Row,
	Modal,
	Radio,
	Col,
	Form,
	Input,
	Checkbox,
	DatePicker,
	TimePicker,
	message
} from 'antd';
import { useDispatch } from 'react-redux';
import authClient from '../../Auth/Auth';
import env from '../../environment';
import axios from 'axios';
import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const { TextArea } = Input;

function ModalReScheduleTask({ visible, setShowModalActionAsing, initialValuesStatus, getTask }) {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [selectSchedule, setSelectSchedule] = useState(
		initialValuesStatus && initialValuesStatus.radiogroup ? initialValuesStatus.radiogroup : 1
	);
	const [hoursSelected, setHoursSelected] = useState(dayjs(initialValuesStatus.hour));

	
	const [notification, setNotification] = useState(
		initialValuesStatus && initialValuesStatus.notification
			? initialValuesStatus.notification
			: false
	);

	useEffect(() => {
		setHoursSelected(
			initialValuesStatus && initialValuesStatus.dueDate ? dayjs(initialValuesStatus.hour) : ''
		);
	}, [initialValuesStatus]);

	const handleOk = (values, type) => {
		setShowModalActionAsing(!visible);
		if (selectSchedule === 1) {
			values.hour = dayjs('08:00', 'HH:mm');
		}
		if (selectSchedule === 2) {
			values.hour = dayjs(hoursSelected, 'HH:mm');
		}

		axios
			.post(
				`${env.api.url}/v1/hadmin/tasks/action/${type}/${values.taskIdDete}`,
				{
					note: values.note,
					dueDate:
						values.date !== undefined
							? dayjs(
									`${values.date.format('YYYY-MM-DD')} ${values.hour.format('HH:mm')}`,
									'YYYY-MM-DD HH:mm'
							  ).toDate()
							: undefined,
					notification: notification,
					notified: notification ? false : true
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.errorCode === 'FORBIDDEN') {
					message.error('Comprueba que tu eres el que ha creado la tarea a eliminar');
				} else {
					message.success('Tarea reagendada con éxito');
				}
				getTask();
			})
			.catch((error) => {
				message.error('Compruebe que la fecha es mayor que hoy');
			});
	};

	const handleCancel = () => {
		setShowModalActionAsing(!visible);
	};

	return (
        (<Modal
			className="gb-modal-style-common modal-task-button-style"
			cancelButtonProps={{ className: 'button-secundary-gibobs', style: { width: '96px' } }}
			okButtonProps={{ className: 'button-primari-gibobs', style: { width: '96px' } }}
			title={'Reagendar tarea'}
			open={visible}
			width={497}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						form.resetFields();
						handleOk(values, 'postpone');
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
			okText={'Reagendar'}
			cancelText={'Cancelar'}
			onCancel={handleCancel}
			closable={false}
		>
            <Form
				form={form}
				layout="horizontal"
				name="basic"
				className="gb-form-task"
				initialValues={
					initialValuesStatus !== undefined
						? initialValuesStatus
						: {
								hour: dayjs('21:00', 'HH:mm')
						  }
				}
			>
				<Row gutter={24}>
					<Col span={24}>
						<Form.Item
							label="Nueva fecha"
							name="date"
							rules={[
								{
									required: true,
									message: <div style={{ marginTop: -20 }}>Introduzca la fecha</div>
								}
							]}
						>
							<DatePicker locale="es_Es" format="DD/MM/YYYY" placeholder="Fecha" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label="Hora"
							name="radiogroup"
							rules={[
								{
									required: true
								}
							]}
						>
							<Radio.Group defaultValue={1} onChange={(e) => setSelectSchedule(e.target.value)}>
								<Radio value={1} style={{ marginBottom: 13 }}>
									En cualquier momento del dia
								</Radio>
								<Radio value={2}>
									<TimePicker
										locale="es_Es"
										format="HH:mm"
										defaultValue={
											hoursSelected !== undefined && hoursSelected !== ""
												? dayjs(hoursSelected, 'HH:mm')
												: dayjs('21:00', 'HH:mm')
										}
										onChange={(e) => setHoursSelected(e)}
										style={{ width: '100%', border: '1px solid #C0DAEE' }}
									/>
								</Radio>
								<Checkbox
									checked={notification !== '' ? notification : false}
									onChange={(e) => setNotification(e.target.checked)}
									disabled={selectSchedule === 1 ? true : false}
								>
									Avísame
								</Checkbox>
							</Radio.Group>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label="Nota" name="note">
							<TextArea placeholder="Nota" />
						</Form.Item>
					</Col>
					<Form.Item hidden={true} name="taskIdDete">
						<Input hidden={true}></Input>
					</Form.Item>
				</Row>
			</Form>
        </Modal>)
    );
}

export default ModalReScheduleTask;

import React, { useState } from 'react';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import {
	Table,
	Select,
	Button,
	Popconfirm,
	Radio,
	Checkbox,
	Modal,
	Tooltip,
	Typography,
	Space
} from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;

const { Option } = Select;

const TableAndColumnsFile = ({
	getSelectDocumentTypes,
	loadSignedDocument,
	dataDocuments,
	onChangeCheckBox,
	onCheckAllChange,
	unCheckBox,
	mortgageData,
	participants,
	guarantors,
	saveData,
	checkAll,
	indeterminate,
	checkedList,
	profile,
	fileErrorId,
	selectDocument,
	setSelectDocument,
	notVerified,
	reloadFile,
	index
}) => {
	const token = authClient.getToken();
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);

	const removeFile = async (documentId) => {
		const response = await axios.get(`${env.api.url}/v1/documents/delete-document/${documentId}`, {
			headers: { Authorization: `Bearer ${token}` }
		});

		reloadFile();
	};

	const arrayOptionsPage = [];
	for (let i = 1; i < 51; i++) {
		arrayOptionsPage.push(
			<Option key={i} value={`${i}`}>
				{i}
			</Option>
		);
	}

	const renderCheck = (dataValue, model) => {
		if (dataValue && model.isVerified) {
			return dataValue;
		} else {
			return model.isVerified;
		}
	};

	const columnsFiles = [
		{
			title: (
				<div style={{ fontWeight: 600, fontSize: '12px' }}>
					<Checkbox
						indeterminate={indeterminate}
						onChange={onCheckAllChange}
						checked={checkAll}
						style={{ marginRight: 8 }}
					/>
					{index === 1 && 'Fichero'}
				</div>
			),
			dataIndex: 'name',
			key: 'name',
			width: index === 1 ? '15%' : '80px',
			render: (text, data) => (
				<div style={{ display: 'flex' }}>
					<Checkbox.Group
						style={{ alignSelf: 'center', marginRight: 24, marginTop: -3 }}
						value={checkedList}
						onChange={onChangeCheckBox}>
						<Checkbox value={data.id} onChange={(e) => unCheckBox(e.target.value)}></Checkbox>
					</Checkbox.Group>

					{data.size > 10000 && (
						<Tooltip title={'Este archivo ocupa más de 10M'}>
							<img
								src="/images/Icon.png"
								alt="icon"
								style={{ width: 16, height: 16, marginLeft: -10, marginRight: 10 }}></img>
						</Tooltip>
					)}

					{index === 1 && (
						<Tooltip title={text}>
							<div
								style={{
									width: 240,
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									whiteSpace: 'pre'
								}}>
								<Text style={{ color: '#02C3CD', fontWeight: 600, fontSize: 12 }}>{text}</Text>
							</div>
						</Tooltip>
					)}
				</div>
			)
		},
		{
			title: <div style={{ fontWeight: 600, fontSize: '12px', width: '112px' }}>Fecha</div>,
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: '12%',
			render: (date) => (
				<>
					<div style={{ textAlign: 'center' }}>
						<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
							{moment(date).format('YY-MM-DD')}
						</Text>
						<br />
						<Text style={{ color: '#2F4858', fontSize: 9, fontWeight: 500 }}>
							{'(' + moment.duration(moment(date).diff(moment())).locale('es').humanize(true) + ')'}
						</Text>
					</div>
				</>
			)
		},
		{
			title: <div style={{ fontWeight: 600, fontSize: '12px' }}>Titular</div>,
			dataIndex: 'owner',
			key: 'owner',
			width: '100px',
			render: (dataValue, model) => {
				const participantsOptions = [];
				const guarantorsOptions = [];
				for (let i = 1; i <= participants; i++) {
					participantsOptions.push(
						<Option key={`owner-option-${i}`} value={`owner${i}`}>
							T{i}: {mortgageData && mortgageData[`owner${i}`] && mortgageData[`owner${i}`].name}
						</Option>
					);
				}

				for (let i = 0; i < guarantors; i++) {
					guarantorsOptions.push(
						<Option key={`guarantors-option-${i + 1}`} value={`guarantor${i + 1}`}>
							A{i + 1}:{' '}
							{mortgageData &&
								mortgageData[`guarantor${i + 1}`] &&
								mortgageData[`guarantor${i + 1}`].name}
						</Option>
					);
				}

				return (
					<Select
						disabled={statusJob === 'finish' ? true : false}
						style={{ width: '100%' }}
						defaultValue={dataValue}
						key={model.id}
						onChange={(value) => {
							saveData(model.id, 'owner', value);
							//setSelectDocument([model.id]);
						}}>
						{participantsOptions}
						{guarantorsOptions}
					</Select>
				);
			}
		},
		{
			title: <div style={{ fontWeight: 600, fontSize: '12px' }}>Tipo de documento</div>,
			dataIndex: 'type',
			key: 'type',
			width: '25%',
			render: (dataValue, model) => {
				return (
					<Select
						showSearch
						filterOption={(input, option) => {
							return (
								option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
								option?.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
							);
						}}
						disabled={statusJob === 'finish' ? true : false}
						placeholder={model.suggest}
						style={{ width: '100%' }}
						defaultValue={dataValue}
						key={model.id}
						onChange={(value) => {
							saveData(model.id, 'type', value);
							//setSelectDocument([model.id]);
						}}
						dropdownRender={(menu) => {
							return menu;
						}}>
						{getSelectDocumentTypes()}
					</Select>
				);
			}
		},
		{
			title: <div style={{ fontWeight: 600, fontSize: '12px' }}>Página</div>,
			dataIndex: 'page',
			key: 'page',
			width: '140px',
			render: (dataValue, model) => {
				return (
					<Radio.Group
						style={{ display: 'flex' }}
						disabled={statusJob === 'finish' ? true : false}
						key={model.id}
						defaultValue={dataValue === 't' ? 't' : dataValue !== '' ? 'p' : ''}
						onChange={(e) => {
							const value = e.target.value;
							saveData(model.id, 'page', value);
							//setSelectDocument([model.id]);
						}}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ display: 'flex', flexWrap: 'wrap' }}>
								<Radio value="t">Todas</Radio>
								<Radio value="p">Pagina &nbsp;</Radio>
							</div>
							<Select
								key={model.id}
								showSearch
								disabled={statusJob === 'finish' ? true : false}
								defaultValue={dataValue}
								style={{ width: 54, height: 24 }}
								onChange={(value) => saveData(model.id, 'page', value)}>
								{arrayOptionsPage}
							</Select>
						</div>
					</Radio.Group>
				);
			}
		},
		{
			title: <div style={{ fontWeight: 600, fontSize: '12px' }}>Verificado</div>,
			dataIndex: 'isVerified',
			key: 'isVerified',
			width: '80px',
			render: (dataValue, model) => {
				return (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Checkbox
							key={model.id}
							defaultChecked={renderCheck(dataValue, model)}
							onChange={(value) => {
								saveData(model.id, 'isVerified', value.target.checked);
							}}></Checkbox>
						{/*model.source === 'Hadmin' && _.isNil(model.suggest) && index > 2 &&(
							<img
								src="/images/hadmin.png"
								alt="asf"
								height={14}
								width={16}
								style={{ marginLeft: 16, marginTop: 3 }}></img>
						)*/}
						{model.source === 'BANKFLIP_SESSION_B2C' && index > 2 && (
							<img
								src="/images/bankflip.png"
								alt="as"
								height={14}
								width={16}
								style={{ marginLeft: 16, marginTop: 3 }}></img>
						)}
						{/*!_.isNil(model.suggest) &&  model.source !== 'BANKFLIP_SESSION_B2C'&& index > 2 && (
							<img
								src="/images/ocr.png"
								alt="wret"
								height={14}
								width={16}
								style={{ marginLeft: 16, marginTop: 3 }}></img>
						)*/}
					</div>
				);
			}
		},

		{
			title: <div style={{ fontWeight: 600, fontSize: '12px' }}>Acciones</div>,
			key: 'actions',
			width: '120px',
			render: (text, data) => {
				const url = `${env.api.viewer}/api/document/${data.key}/attachment?token=${token}`;

				return (
					<div style={{ display: 'flex' }}>
						<Button
							//disabled={statusJob === 'finish' ? true : false}
							type="link"
							onClick={async () => {
								const { url, name, mime, fileData } = await loadSignedDocument(data.id);
								setSelectDocument([data.id]);

								const modal = Modal.confirm({
									title: 'Visualizar fichero',
									content: (
										<DocViewer
											pluginRenderers={DocViewerRenderers}
											documents={[{ uri: url, fileType: mime, fileData: fileData }]}
										/>
									),
									closable: true,
									okCancel: true,
									width: '100%',
									onOk: () => {
										document.location = url;
									},
									okText: 'Descargar',
									cancelText: 'Cerrar',
									onCancel: () => {
										modal.destroy();
									}
								});
							}}
							style={{ marginRight: -15, color: '#02C3CD' }}>
							<EyeOutlined style={{ fontSize: 20, color: '#02C3CD' }} />
						</Button>
						{profile.type === 'risk' || profile.type === 'admin' || profile.type === 'prerisk' ? (
							<Popconfirm
								disabled={statusJob === 'finish' ? true : false}
								title="¿Estás seguro de borrar?"
								onConfirm={() => {
									removeFile(data.id);
								}}
								okText="Si"
								cancelText="No">
								<Button
									disabled={statusJob === 'finish' ? true : false}
									type="link"
									style={{ color: '#02C3CD' }}>
									<DeleteOutlined style={{ fontSize: 20, color: '#02C3CD' }} />
								</Button>
							</Popconfirm>
						) : undefined}
					</div>
				);
			}
		}
	];

	return (
		<>
			<Table
				className={`table-hover-disabled${index}`}
				dataSource={dataDocuments}
				columns={columnsFiles}
				pagination={false}
				size="small"
				rowClassName={(record) => {
					const classes = ['small-font-size'];

					if (record.id === fileErrorId) {
						classes.push('documents-list-row-error');
					}
					selectDocument &&
						selectDocument.map((dataSelect) => {
							if (record.id === dataSelect) {
								return classes.push(`table-color-selector`);
							}
						});

					// if (
					//   record.owner &&
					//   record.owner !== '' &&
					//   record.page &&
					//   record.page !== '' &&
					//   record.page !== 'p' &&
					//   record.type &&
					//   record.type !== ''
					// ) {
					//   classes.push('documents-list-row-completed');
					// }

					return classes;
				}}
			/>
		</>
	);
};

export default TableAndColumnsFile;

import React, { useEffect, useState } from 'react';
import {
	Button,
	Upload,
	Col,
	message,
	Radio,
	Row,
	Input,
	Checkbox,
	Modal,
	Typography,
	Divider
} from 'antd';
import { BankOutlined } from '@ant-design/icons';
import authClient from '../../../../../Auth/Auth';
import axios from 'axios';
import env from '../../../../../environment';
import Loading from '../../../../../Components/ModalsAndAlerts/Loading';
import { UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
import { InputNumber } from 'afrodita';
import _ from 'lodash';

const { Text } = Typography;

export default (props) => {
	const { mortgageId } = props;
	const [mortgageData, setMortgageData] = useState();
	const [fileName, setFileName] = useState('');
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);
	const [billingFormData, setBillingFormData] = useState(undefined);
	const [isSabadell, setIsSabadell] = useState(undefined);
	const [dataBankRequest, setDataBankRequest] = useState(undefined);
	const [bankOffers, setBankOffers] = useState(undefined);
	const [favOffer, setFavOffer] = useState(undefined);
	const [modalConfirmBack, setModalConfirmBack] = useState(false);
	const [mortgageOperation, setMortgageOperation] = useState(undefined);

	useEffect(() => {
		getMortgageData();
	}, [mortgageId]);

	useEffect(() => {
		getManagementId();
		getBankOffers();
		getBillingData();
	}, []);

	useEffect(() => {
		setFavOffer(bankOffers?.find((offer) => offer.isFavorite));
	}, [bankOffers]);

	const getMortgageData = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setMortgageOperation(response.data.data.operation);
				setMortgageData(response.data.data.mortgage);
			})
			.catch((error) => {
				console.error('Error al obtener los datos de la hipoteca:', error);
			});
	};

	const getBillingData = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				const billingData = response.data.data.billing || {};
				const owner1Data = response.data.data.owner1 || {};

				const newBilling = {
					name: billingData.name || renderName(owner1Data),
					direction: billingData.direction || renderAddress(owner1Data),
					nif: billingData.nif || owner1Data.nif || ''
				};
				setBillingFormData(newBilling);
				saveBilling(newBilling.name, newBilling.direction, newBilling.nif);
			})
			.catch((error) => {
				console.error('Error al obtener los datos de la hipoteca:', error);
			});
	};

	const saveBilling = (name, address, nif) => {
		axios.post(
			`${env.api.url}/v1/mortgages/data/multiple/update/${mortgageId}`,
			{
				values: [
					{
						key: 'billing',
						subkey: 'name',
						value: name
					},
					{
						key: 'billing',
						subkey: 'direction',
						value: address
					},
					{
						key: 'billing',
						subkey: 'nif',
						value: nif
					}
				]
			},
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
	};

	const renderName = (owner1) => {
		return (
			(owner1?.name || '') +
			(owner1?.firstSurname ? ' ' + owner1.firstSurname : '') +
			(owner1?.lastSurname ? ' ' + owner1.lastSurname : '')
		);
	};

	const renderAddress = (owner1) => {
		return (
			(owner1?.address ? owner1.address : '') +
			(owner1?.population ? ', ' + owner1.population : '') +
			(owner1?.postalCode ? ', ' + owner1.postalCode : '') +
			(owner1?.province ? ', ' + owner1.province : '')
		);
	};

	const getBankOffers = () => {
		axios
			.get(`${env.api.url}/banks/bank-offer/find-by-operation/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setBankOffers(response.data.data);
			});
	};

	const getRequests = () => {
		axios
			.get(`${env.api.url}/v1/banks/get-operation-bank-requests/${mortgageId}?hadmin=${true}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				response.data.data = _.orderBy(response.data.data, [(d) => d.id], ['desc']);
				setDataBankRequest(response.data.data);
			});
	};

	const getManagementId = () => {
		axios
			.post(
				`${env.api.url}/management/management/by-operation`,
				{ operationId: mortgageId },
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			)
			.then((response) => {
				if (response.data.data.code === 'sabadell-es') {
					getRequests();
					setIsSabadell(true);
				} else {
					setIsSabadell(false);
				}
			});
	};

	const saveData = (participant, key, value) => {
		setTimeout(() => {
			message.success('Se actualizo con exito');
		}, 2000);
		if (key === 'appraisalPaid') {
			saveData('mortgage', 'paidAppraisalDate', moment().format('YYYY-MM-DD'));
		}

		if (value === 'Invalid Date') {
			value = null;
		}

		axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${mortgageId}`,
				{ key, participant, value, origin: 'https://app.gibobs.com' },
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				getMortgageData();
				if (key === 'bankAppraisalApproval') {
					window.location.reload();
				}
			});
	};

	if (!mortgageData || !billingFormData || isSabadell === undefined) {
		return <Loading />;
	}

	const saveDataOnClick = (participant, key, value) => {
		let valueRadio = {
			key: 'operation',
			subkey: 'firstClickToAppraisal',
			value: moment().format('YYYY-MM-DD')
		};

		if (
			mortgageData.reportAppraisal !== undefined &&
			mortgageOperation.firstClickToAppraisal !== undefined
		) {
			valueRadio = {
				key: 'operation',
				subkey: 'ultimateClickToAppraisal',
				value: moment().format('YYYY-MM-DD')
			};
		}

		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${mortgageId}`,
				{
					values: [
						{
							key: participant,
							subkey: key,
							value: value
						},
						{
							key: valueRadio.key,
							subkey: valueRadio.subkey,
							value: valueRadio.value
						}
					],
					origin: 'https://app.gibobs.com'
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				getMortgageData();
			});
	};

	const dataMultiple = (key, owner, value, key1, owner1, value1) => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${mortgageId}`,
				{
					values: [
						{
							key: key,
							subkey: owner,
							value: value
						},
						{
							key: key1,
							subkey: owner1,
							value: value1
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				getMortgageData();
			});
	};

	const saveFileData = (file, name) => {
		axios
			.put(
				`${env.api.url}/api/mortgages/attachment`,
				{
					mortgageId,
					file,
					fileName: name,
					isOnAgent: true,
					type: 'appraisal',
					page: '1'
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				message.success('Se actualizo con exito');
			});
	};

	const uploadProps = {
		beforeUpload: (file) => {
			setFileName(file.name);
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					saveFileData(base64File, file.name);
				};
			});
		}
	};

	const showModalconfirm = () => {
		const modal = Modal.confirm({
			title: 'Confirmar aprobación',
			content: (
				<Text>
					¿Estás seguro de que quieres confirmar la aprobación de la tasación por parte del banco?
				</Text>
			),
			closable: true,
			okCancel: true,
			width: 480,
			className: 'gb-modal-24-padding',
			okButtonProps: { className: 'button-primari-gibobs' },
			cancelButtonProps: { className: 'button-secundary-gibobs' },
			onOk: () => {
				saveData('mortgage', 'bankAppraisalApproval', true);
			},
			okText: 'Confirmar',
			cancelText: 'Cancelar',
			onCancel: () => {
				modal.destroy();
			}
		});
	};

	return (
		<div style={{ padding: '8px 24px' }}>
			{isSabadell && (
				<Row>
					<Col span={24}>
						<Text style={{ fontSize: 20, fontWeight: 600, color: '#2F4858' }}>Cuenta online</Text>
					</Col>
					<Col span={24} style={{ marginTop: 8, display: 'flex', alignItems: 'center', gap: 16 }}>
						<Checkbox
							className="checkbox-reco"
							style={{ color: '#2F4858' }}
							checked={mortgageData?.hasOnlineAccount}
							onChange={(e) => {
								saveData('mortgage', 'hasOnlineAccount', e.target.checked);
							}}>
							<BankOutlined /> Cta. online
						</Checkbox>
						<div className="form-reco">
							<Text className="label-reco">Nº cuenta</Text>
							<Input
								style={{ maxWidth: 164 }}
								className="input-reco"
								defaultValue={mortgageData?.bankAccountNumber}
								disabled={statusJob === 'finish' ? true : false}
								onBlur={(e) => saveData('mortgage', 'bankAccountNumber', e.target.value)}
								type="text"
								placeholder="Nº cuenta"
							/>
						</div>
					</Col>
				</Row>
			)}
			<Row style={{ marginTop: isSabadell && 24, maxWidth: 700 }}>
				<Col span={14}>
					<Text style={{ fontSize: 20, fontWeight: 600, color: '#2F4858' }}>
						Informe de tasación
					</Text>
				</Col>
				<Col span={10}>
					<Upload {...uploadProps}>
						<Button
							disabled={statusJob === 'finish' ? true : false}
							type="dashed"
							className="button-secundary-gibobs"
							icon={<UploadOutlined />}>
							Subir informe de tasación
						</Button>
						<small>{fileName}</small>
					</Upload>
				</Col>
			</Row>
			<Row style={{ marginTop: 8, maxWidth: 700 }}>
				<Col span={14}>
					<Radio.Group
						style={{ display: 'flex', flexDirection: 'column', gap: 12 }}
						disabled={statusJob === 'finish' ? true : false}
						name="reportAppraisal"
						defaultValue={mortgageData.reportAppraisal}
						onChange={(e) => saveDataOnClick('mortgage', 'reportAppraisal', e.target.value)}>
						<Radio value={'h1'}>
							<Text style={{ fontSize: 14, color: '#748EA0', fontWeight: 400 }}>
								La tasación se hace con Gibobs
							</Text>
						</Radio>
						<Radio value={'own'}>
							<Text style={{ fontSize: 14, color: '#748EA0', fontWeight: 400 }}>
								La tasación la gestiona el cliente por su cuenta
							</Text>
						</Radio>
						<Radio value={'bank'}>
							<Text style={{ fontSize: 14, color: '#748EA0', fontWeight: 400 }}>
								La tasación la hace directamente el banco
							</Text>
						</Radio>
					</Radio.Group>
					<div className="form-reco" style={{ marginTop: 12 }}>
						<Text className="label-reco">Tasadora</Text>
						<Input
							defaultValue={mortgageData.appraiser}
							disabled={statusJob === 'finish' ? true : false}
							onBlur={(e) => saveData('mortgage', 'appraiser', e.target.value)}
							type="text"
							placeholder="Tasadora"
							clasNamme="input-reco"
							style={{ width: 128 }}
						/>
					</div>
					<div className="form-reco" style={{ marginTop: 12 }}>
						<Text className="label-reco">Coste tasación</Text>
						<div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
							<InputNumber
								disabled={statusJob === 'finish' ? true : false}
								style={{ width: 128, borderRadius: 4 }}
								defaultValue={mortgageData.appraisalAmount}
								placeholder="0"
								onBlur={(e) => {
									const inputValue = e.target.value.replace(/\./g, '').replace(',', '.');
									parseFloat(inputValue);
									if (numeral(inputValue).value() !== '' && numeral(inputValue).value() !== null) {
										if (numeral(parseFloat(inputValue)).value() < 0) {
											message.error('El valor mínimo de la tasación debe ser 0');
										} else if (numeral(parseFloat(inputValue)).value() > 4000) {
											message.error('El valor máximo de la tasación debe ser 4.000 €');
										} else {
											saveData('mortgage', 'appraisalAmount', inputValue);
										}
									}
								}}
								gbType="currency"
							/>
							<Checkbox
								name="pagado"
								checked={mortgageData.appraisalPaid ? true : false}
								onChange={(e) =>
									dataMultiple(
										'mortgage',
										'appraisalPaid',
										e.target.checked,
										'mortgage',
										'paidAppraisalDate',
										moment().format('YYYY-MM-DD')
									)
								}
								disabled={statusJob === 'finish' ? true : false}>
								<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>Pagado</Text>
							</Checkbox>
						</div>
					</div>
					<div className="form-reco" style={{ marginTop: 12 }}>
						<Text className="label-reco">Resultado tasación</Text>
						<InputNumber
							disabled={statusJob === 'finish' ? true : false}
							style={{ width: 128, borderRadius: 4 }}
							defaultValue={mortgageData.valueAppraisal}
							placeholder="0"
							onBlur={(e) => {
								const inputValue = e.target.value.replace(/\./g, '').replace(',', '.');
								parseFloat(inputValue);
								if (numeral(inputValue).value() !== '' && numeral(inputValue).value() !== null) {
									if (numeral(parseFloat(inputValue)).value() < 0) {
										message.error('El valor mínimo de la tasación debe ser 0');
									} else {
										saveData('mortgage', 'valueAppraisal', inputValue);
									}
								}
							}}
							gbType="currency"
						/>
					</div>
				</Col>
				<Col span={10}>
					{billingFormData && Object.keys(billingFormData).length > 0 && (
						<div className="form-reco" style={{ marginTop: 12 }}>
							<Text className="label-reco">Nombre de facturación</Text>
							<Input
								className="input-reco"
								defaultValue={billingFormData?.name || ''}
								onBlur={(e) => saveData('billing', 'name', e.target.value)}
								type="text"
								placeholder="Nombre"
								style={{ width: 286 }}
							/>
							<Text className="label-reco">Dirección de facturación</Text>
							<Input
								className="input-reco"
								defaultValue={billingFormData?.direction || ''}
								placeholder="Dirección"
								onBlur={(e) => saveData('billing', 'direction', e.target.value)}
								style={{ width: 286 }}
							/>
							<Text className="label-reco">NIF/CIF de facturación</Text>
							<Input
								className="input-reco"
								defaultValue={billingFormData?.nif || ''}
								placeholder="0000000A"
								onBlur={(e) => saveData('billing', 'nif', e.target.value)}
								style={{ width: 286 }}
							/>
						</div>
					)}
				</Col>
			</Row>
			<Row style={{ marginTop: 24, maxWidth: 700 }} align={'middle'}>
				<Col span={14}>
					<Text style={{ fontSize: 20, fontWeight: 600, color: '#2F4858' }}>
						Gestión de la tasación
					</Text>
				</Col>
				<Col span={10}>
					{(mortgageData.bankAppraisalApproval === false ||
						mortgageData.bankAppraisalApproval === null ||
						mortgageData.bankAppraisalApproval === undefined) && (
						<Button
							type="primary"
							disabled={mortgageData.reportAppraisal === undefined ? true : false}
							onClick={() => showModalconfirm()}>
							Avanzar con tasación
						</Button>
					)}
					{mortgageData.reportAppraisal && mortgageData.bankAppraisalApproval && (
						<Button
							type="dashed"
							className="button-secundary-gibobs"
							onClick={() => setModalConfirmBack(true)}>
							Retroceder con tasación
						</Button>
					)}
				</Col>
			</Row>

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '49%', height: 40 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '49%', height: 40 }
				}}
				title={'Retroceder con tasación'}
				open={modalConfirmBack}
				width={480}
				onOk={() => {
					saveData('mortgage', 'bankAppraisalApproval', null);
					setModalConfirmBack(!modalConfirmBack);
				}}
				onCancel={() => {
					setModalConfirmBack(!modalConfirmBack);
				}}
				okText={'Confirmar'}
				cancelText={'Cancelar'}
				closable={true}>
				<div style={{ marginBottom: 32 }}>
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
						¿Estás seguro de que quieres retroceder con la tasación?
					</Text>
				</div>
			</Modal>
		</div>
	);
};

import React, { useState, useEffect } from 'react';
import { Form, message, Modal } from 'antd';
import axios from 'axios';
import authClient from '../../Auth/Auth';
import env from '../../environment';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';

const MortgageNoteCreate = (props) => {
	const [editorState, setEditorState] = useState(null);

	useEffect(() => {
		if (props.imageString !== undefined) {
			setEditorState(BraftEditor.createEditorState(props.imageString));
		}else {
			setEditorState(BraftEditor.createEditorState(''));
		}
		
	}, [props.imageString]);

	useEffect(() => {
		if (props.visible) {
			setTimeout(() => {
				document.querySelector('.bfi-align-left').click();
			}, '500');
			document.querySelector('.bfi-align-left').click();
		}
	}, [props.visible]);

	const createNote = async () => {
		const htmlContent = editorState.toHTML();

		await axios.post(
			`${env.api.url}/api/hadmin/mortgage-note`,
			{
				mortgageId: props.mortgageId,
				body: htmlContent
			},
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
		message.success('Su nota se ha creado correctamente.');
		if (props.onSuccess) {
			props.onSuccess();
		}
	};

	const handleEditorChange = (editorState) => {
		setEditorState(editorState);
	};

	const { visible } = props;

	return (
		<Modal
			open={visible}
			title="Crear una nueva nota"
			okText="Crear"
			onCancel={() => {
				props.onCancel();
			}}
			onOk={() => {
				createNote();
			}}
			width="85%"
			className="gb-modal-create-note">
			<Form layout="vertical">
				<Form.Item label="Contenido">
					<BraftEditor language="en" value={editorState} onChange={handleEditorChange} />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default MortgageNoteCreate;

import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography, Col, Row, Form, Select, Input, Upload, notification, Tag } from 'antd';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import InputEmailAddresses from './components/input-email-addresses';

const { Text } = Typography;
const { Option } = Select;

let sumSizeFile = 0;

const EmailPageSendBank = ({
	mortgageId,
	mortgageName,
	mortgage,
	loadData,
	onCreate,
	onCancel,
	channel,
	mortgageData
}) => {
	const [form] = Form.useForm();
	const [dataBanksOnGoing, setDataBanksOnGoing] = useState(null);
	const [fileName, setFileName] = useState([]);
	const [saveFileDataField, setSaveFileDataField] = useState([]);
	const [bankName, setBankName] = useState(undefined);
	const [editorState, setEditorState] = useState(undefined);
	const location = useLocation();
	const locationReal = location.pathname;
	let navigate = useNavigate();
	const idBank = location && location.state !== undefined ? location.state.bankId : undefined;
	const bankRequestId =
		location && location.state !== undefined ? location.state.bankRequestId : undefined;
	const bankRequest =
		location && location.state !== undefined ? location.state.bankRequest : undefined;
	const response = location && location.state !== undefined ? location.state.response : undefined;
	const [htmlEmail, setHtmlEmail] = useState(undefined);
	const [constactsBanks, setContactsBanks] = useState(undefined);
	const [subjectTmp, setSubjectTmp] = useState('asdf');
	const [fileName2, setFileName2] = useState(false);
	const [constactsBanksCC, setContactsBanksCC] = useState(undefined);
	const [constactsBanksNull, setContactsBanksNull] = useState([]);

	let files = [];

	useEffect(() => {
		getBanksOnGoing();
		if (response) {
			getEmailCompouserResponse();
		} else {
			getEmailCompouser();
		}
		getContacts();
	}, []);

	useEffect(() => {
		renderFiles();
	}, [fileName2]);

	const renderSubject = () => {
		if (bankRequest && bankRequest.bank.bankCode === 'evo' && !response) {
			return (
				'Solicitud estudio nueva hipoteca ' +
				`${mortgageName}` +
				' ' +
				`${mortgage.owner1.name} ${
					mortgage.owner1.firstSurname !== undefined ? mortgage.owner1.firstSurname : ''
				} ${mortgage.owner1.lastSurname !== undefined ? mortgage.owner1.lastSurname : ''} - ` +
				' ' +
				`${mortgage.owner1.nif}`
			);
		} else if (
			bankRequest &&
			bankRequest.bank.bankCode === 'sabadell' &&
			channel.accountType !== 'web' &&
			!response
		) {
			return (
				'Nueva operación de PARTNER PREMIUM ' +
				`${mortgageName}` +
				' ' +
				`${mortgage.owner1.name} ${
					mortgage.owner1.firstSurname !== undefined ? mortgage.owner1.firstSurname : ''
				} ${mortgage.owner1.lastSurname !== undefined ? mortgage.owner1.lastSurname : ''} - ` +
				`${mortgage.owner1.nif}`
			);
		} else if (!response && bankRequest?.bank?.bankCode === 'imagin') {
			return (
				'IMAGIN: Nueva operación ' +
				`${mortgageName}` +
				' ' +
				`${mortgage.owner1.name} ${
					mortgage.owner1.firstSurname !== undefined ? mortgage.owner1.firstSurname : ''
				} ${mortgage.owner1.lastSurname !== undefined ? mortgage.owner1.lastSurname : ''}  ` +
				' ' +
				`${mortgage.owner1.nif}`
			);
		} else if (!response && mortgageName) {
			return (
				'Nueva operación ' +
				`${mortgageName}` +
				' ' +
				`${mortgage.owner1.name} ${
					mortgage.owner1.firstSurname !== undefined ? mortgage.owner1.firstSurname : ''
				} ${mortgage.owner1.lastSurname !== undefined ? mortgage.owner1.lastSurname : ''} ` +
				' ' +
				`${mortgage.owner1.nif}`
			);
		} else if (response && bankRequest && bankRequest?.bank?.bankCode === 'targobank') {
			return (
				'Esperamos una respuesta para la solicitud de hipoteca de ' +
				`${mortgage.owner1.name} ${
					mortgage.owner1.firstSurname !== undefined ? mortgage.owner1.firstSurname : ''
				}  ${mortgage.owner1.lastSurname !== undefined ? mortgage.owner1.lastSurname : ''} ` +
				' DNI: ' +
				`${mortgage.owner1.nif}` +
				' ' +
				`${mortgageName}` +
				' - Nº expediente'
			);
		} else {
			if (response && mortgageName) {
				return (
					'Esperamos una respuesta para la solicitud de hipoteca de ' +
					`${mortgage.owner1.name} ${
						mortgage.owner1.firstSurname !== undefined ? mortgage.owner1.firstSurname : ''
					} ${mortgage.owner1.lastSurname !== undefined ? mortgage.owner1.lastSurname : ''}` +
					' DNI: ' +
					`${mortgage.owner1.nif}` +
					' ' +
					`${mortgageName}`
				);
			}
		}
	};

	const getContacts = () => {
		axios
			.get(
				`${env.api.url}/banks/bank-contact/by-bank-request/${bankRequestId}`,

				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setContactsBanks(
					_.filter(response.data.data, function (c) {
						return c.preloaded === 'to';
					})
				);
				setContactsBanksCC(
					_.filter(response.data.data, function (c) {
						return c.preloaded === 'cc';
					})
				);
				setContactsBanksNull(
					_.filter(response.data.data, function (c) {
						return c.preloaded === null;
					})
				);
			});
	};

	const getEmailCompouser = () => {
		axios
			.post(
				`${env.api.url}/banks/email-composer/template/new-bank-request`,
				{
					operationId: mortgageId,
					bankRequestId: bankRequestId
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setHtmlEmail(response.data.data);
			});
	};

	const getEmailCompouserResponse = () => {
		axios
			.post(
				`${env.api.url}/banks/email-composer/template/additional-request`,
				{
					operationId: mortgageId,
					bankRequestId: bankRequestId
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setHtmlEmail(response.data.data);
			});
	};

	useEffect(() => {
		if (dataBanksOnGoing && htmlEmail) {
			setEditorState(BraftEditor.createEditorState(htmlEmail));
		}
	}, [dataBanksOnGoing, htmlEmail]);

	const handleEditorChange = (editorState2) => {
		setEditorState(editorState2);
	};

	const deleteFile = (index) => {
		const newArrayFile = [...saveFileDataField];
		newArrayFile.splice(index, 1);
		setSaveFileDataField(newArrayFile);
	};

	const getBanksOnGoing = async () => {
		const response = await axios.get(
			`${env.api.url}/v1/banks/get-banks?status=ongoing&managementCode`,
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
		await setDataBanksOnGoing(response.data.data);
	};

	const saveFileData = (file, name) => {
		const documentFile = saveFileDataField;
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
		setFileName2(!fileName2);
	};

	const backToAllDocuments = () => {
		navigate(`/operations/mortgages/${mortgageId}/view-document-list`, {
			state: { bankId: idBank, bankRequestId: bankRequestId, bankRequest: bankRequest }
		});
	};

	const uploadProps = {
		multiple: true,
		beforeUpload: (file) => {
			if (file.size > 8000000) {
				notification.error({
					message: 'Error: archivo demasiado grande',
					description: 'Su documento ocupa mas de 8mb. No se pueden enviar archivos de más de 8mb'
				});

				setFileName('Error: archivo demasiado grande');

				return;
			}
			sumSizeFile = sumSizeFile + file.size;

			if (sumSizeFile >= 25000000) {
				notification.error({
					message: 'Error: archivos demasiado grandes ',
					description: 'La suma de los archivos no puede superar los 25MB'
				});

				setFileName('Error: archivo demasiado grande');

				return;
			}

			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					saveFileData(base64File, file.name);
				};
			});
		}
	};

	const renderFiles = () => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginLeft: 0,
					marginRight: 8,
					marginTop: 24,
					marginBottom: 100
				}}>
				{saveFileDataField.length > 0 ? (
					saveFileDataField.map((data, index) => (
						<div
							key={index}
							style={{
								fontSize: '12px',
								fontWeight: 600,
								color: '#02C3CD',
								marginBottom: '10px'
							}}>
							{data.fileName} <DeleteOutlined onClick={() => deleteFile(index)} />
						</div>
					))
				) : (
					<div
						style={{
							fontSize: '12px',
							fontWeight: 600,
							color: '#FFFF'
						}}>
						.
					</div>
				)}
			</div>
		);
	};

	const renderOtherSubject = (bankRequest) => {
		if (
			bankRequest.bankAccountData.reference !== '' &&
			bankRequest.bankAccountData.reference !== undefined
		) {
			return `Envío de información adicional. Referencia ${bankRequest.bankAccountData.reference} Operación ${mortgageName} - ${mortgage.owner1.name} - ${mortgage.owner1.firstSurname} - ${mortgage.owner1.lastSurname} - ${mortgage.owner1.nif}`;
		} else {
			return `Envío de información adicional. Operación ${mortgageName} - ${
				mortgage.owner1.name
			} - ${mortgage.owner1.firstSurname} - ${
				mortgage.owner1.lastSurname !== undefined ? mortgage.owner1.lastSurname : ''
			} - ${mortgage.owner1.nif}`;
		}
	};

	const renderContactBank = () => {
		let contacts = [];
		contacts.push(
			constactsBanks.map((c) => {
				return c.email;
			})
		);

		return contacts[0];
	};

	const renderConstactsBanksCC = () => {
		let contacts = [];
		contacts.push(
			constactsBanksCC.map((c) => {
				return c.email;
			})
		);

		return contacts[0];
	};

	return (
		<div style={{ width: '100%' }}>
			<Row>
				<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
					{'Enviar nueva operación a banco'}
				</Text>
			</Row>
			{constactsBanks && constactsBanksCC && (
				<Row style={{ marginTop: -40 }}>
					<Form
						form={form}
						layout="vertical"
						name="sendMessageForm"
						style={{ width: '100%' }}
						initialValues={{
							bankRequestId: bankRequestId,
							bank: idBank !== undefined ? idBank : '',
							send: authClient.getProfile().email,
							CC: renderConstactsBanksCC(),
							recepient: '',
							message: editorState,
							CCO: [
								authClient.getProfile().email,
								'gestionhipotecaria@gibobs.com',
								'envio_bancos@gibobs.com'
							],
							from: authClient.getProfile().email,
							subject: response === true ? renderOtherSubject(bankRequest) : renderSubject(),
							to: renderContactBank()
						}}>
						<Form.Item name="bankRequestId">
							<Input type={'hidden'} />
						</Form.Item>
						<Row gutter={[16, 16]}>
							<Col lg={8} md={8} sm={16} xs={24} xxl={8}>
								<Form.Item
									name="bank"
									rules={[{ required: true, message: 'Please add a bank' }]}
									label={
										<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
											{'Banco'}
										</Text>
									}>
									<Select disabled={true} style={{ width: '100%' }}>
										{dataBanksOnGoing &&
											dataBanksOnGoing.map((banks) => (
												<Option key={banks.id} value={banks.id}>
													{banks.largeName}
												</Option>
											))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={[16, 16]} style={{ marginTop: -10 }}>
							<Col lg={16} md={16} sm={16} xs={24} xxl={16}>
								<Form.Item
									name="to"
									label={
										<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
											{'Para'}
										</Text>
									}
									rules={[{ required: true, message: 'Por favor, añade un destinatario' }]}>
									{constactsBanksNull.length > 0 ? (
										<InputEmailAddresses contacts={[...constactsBanks, ...constactsBanksNull]} />
									) : (
										<InputEmailAddresses contacts={constactsBanks} />
									)}
								</Form.Item>
							</Col>
							<Col lg={8} md={8} sm={8} xs={24} xxl={8}>
								<Form.Item
									name="from"
									label={
										<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'DE'}</Text>
									}>
									<Input type="text" style={{ width: '100%' }} disabled={true} />
								</Form.Item>
							</Col>

							<Col lg={12} md={12} sm={16} xs={24} xxl={12} style={{ marginTop: -30 }}>
								<Form.Item
									name="CC"
									label={
										<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'CC'}</Text>
									}>
									<InputEmailAddresses contacts={constactsBanksCC} />
								</Form.Item>
							</Col>

							<Col lg={12} md={12} sm={12} xs={24} xxl={12} style={{ marginTop: -30 }}>
								<Form.Item
									name="CCO"
									label={
										<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'CCO'}</Text>
									}>
									<InputEmailAddresses
										contacts={[
											{ id: authClient.getProfile().email, email: authClient.getProfile().email },
											{ id: 'envio_bancos@gibobs.com', email: 'envio_bancos@gibobs.com' }
										]}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row style={{ marginTop: -10, width: '100%' }}>
							<Form.Item
								style={{ width: '100%' }}
								name="subject"
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Asunto'}
									</Text>
								}
								rules={[{ required: true, message: 'Por favor, añade un asunto' }]}>
								<Input
									style={{ width: '100%' }}
									type="text"
									placeholder="Nueva solicitud hipotecaria HPXXXX"
								/>
							</Form.Item>
						</Row>
						<Row style={{ marginTop: -10, width: '100%' }}>
							<Form.Item
								style={{ width: '100%' }}
								name="message"
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Mensaje'}
									</Text>
								}>
								<BraftEditor
									language="en"
									defaultValue={editorState}
									onChange={handleEditorChange}
								/>
							</Form.Item>
						</Row>

						<Row style={{ marginTop: 12, width: '100%', marginBottom: -24 }}>
							<Col span={12}>
								<Form.Item name="field">
									<div style={{ display: 'flex' }}>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<Upload {...uploadProps} style={{ display: 'flex' }}>
												<Button
													type="dashed"
													className="button-secundary-gibobs"
													style={{ width: 'auto', height: 32, marginRight: 8 }}>
													Subir documentación manualmente
												</Button>
											</Upload>
										</div>
										<Button
											onClick={() => backToAllDocuments()}
											type="dashed"
											className="button-secundary-gibobs"
											style={{ width: 'auto', marginLeft: 6, marginTop: 1, height: 32 }}>
											Regenerar zip
										</Button>
									</div>
								</Form.Item>
							</Col>

							<Col span={12} style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
								<Button
									type="dashed"
									onClick={() => onCancel()}
									className="button-secundary-gibobs"
									style={{ width: 'auto', marginLeft: 6, marginTop: 1, height: 32 }}>
									Cancelar
								</Button>

								<Button
									type="primary"
									className="button-primary-gibobs"
									onClick={() =>
										form
											.validateFields()
											.then((values) => {
												onCreate(values, saveFileDataField);
												form.resetFields();
												setFileName('');
											})
											.catch((info) => {
												console.log('Validate Failed:', info);
											})
									}
									style={{ width: 'auto', marginLeft: 6, marginTop: 1, height: 32 }}>
									Enviar
								</Button>
							</Col>
						</Row>
					</Form>
				</Row>
			)}

			{renderFiles()}
		</div>
	);
};
export default EmailPageSendBank;

import React from 'react';
import { notification } from 'antd';
import { fetchTaskExpired, fetchTaskList } from '../../store/task/actions';
import authClient from '../../Auth/Auth';
import env from '../../environment';
import axios from 'axios';
import moment from 'moment';

const renderTypeTask = (task) => {
	let type = undefined;

	if (task === 'call') {
		type = 'Llamar';
	} else if (task === 'doc') {
		type = 'Pedir documentación';
	} else if (task === 'other') {
		type = 'Otro';
	} else if (task === 'claimoffer') {
		type = 'Reclamar Oferta';
	} else if (task === 'comdoc') {
		type = 'Compromiso de documentación';
	} else if (task === 'crtanalysis') {
		type = 'Certificado a analisis';
	} else if (task === 'tag') {
		type = 'Etiquetar';
	} else if (task === 'claim') {
		type = 'Reclamar documentación';
	} else if (task === 'firstcall') {
		type = 'Primera llamada';
	} else if (task === 'followup') {
		type = 'Llamada de seguimiento';
	} else if (task === 'phpanalysis') {
		type = 'Promotor Analisis';
	} else if (task === 'customerqualification') {
		type = 'Cualificación de cliente';
	} else if (task === 'sendbanks') {
		type = 'Enviar a bancos';
	} else if (task === 'choosenoffer') {
		type = 'Selección de oferta';
	} else if (task === 'analysis') {
		type = 'Nueva operación en analisis';
	} else if (task === 'gibobsappraisal') {
		type = 'Tasacion con Gibobs';
	} else if (task === 'automaticofferexpires5days') {
		type = 'La oferta automática caduca a los 5 días';
	} else if (task === 'automaticofferexpires1days') {
		type = 'La oferta automática caduca en un 1 días';
	} else if (task === 'automaticofferexpires') {
		type = 'La oferta automática caduca';
	} else if (task === 'recommender') {
		type = 'Recomendador';
	} else {
		type = 'Reclamar tasación';
	}

	return type;
};

const modalClose = (id, dispatch) => {
	axios
		.post(
			`${env.api.url}/v1/hadmin/tasks/edit/${id}`,
			{ notified: true },
			{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
		)
		.then(() => {
			dispatch(fetchTaskList(undefined));
			//dispatch(fetchTaskExpired(authClient.getToken(), false));
			dispatch(fetchTaskExpired(authClient.getToken(), true));
		})
		.catch((error) => {
			console.log(error);
		});
};

export const openNotification = (task, dispatch) => {
	const dateNow = moment().format();
	notification.config({
		placement: 'top'
	});
	if (task !== undefined) {
		task.map((info) => {
			const key = `${info.id}`;
			const btn = (
				<div
					key={key}
					className="button-gibobs-notifications"
					style={{ cursor: 'pointer' }}
					onClick={() => {
						notification.destroy(key);
						modalClose(info.id, dispatch);
					}}>
					<img src={'/images/arrow_up.png'} />
				</div>
			);
			if (
				info.notification === true &&
				info.notified === false &&
				info.status === 'active' &&
				moment(info.duedDate).isBefore(dateNow)
			) {
				notification.open({
					message: 'Esta tarea acaba de vencer',
					icon: <img src={'/images/remind.svg'} alt="remindIcon" height={24} width={24} />,
					description: (
						<div style={{ marginRight: 4 }} key={key}>
							{info && info.name}{' '}
							<a
								style={{
									color: '#FAAD14',
									fontWeight: 700,
									fontsize: 16,
									lineHeight: '24px',
									textDecoration: 'underline #FAAD14'
								}}
								target="_blank"
								href={`/operations/mortgages/${info && info.operationId}`}>
								{info && info.operationName}
							</a>{' '}
							{info &&
								info.operation &&
								info.operation.user &&
								info.operation.user.name + ' ' + `de ${info.operation.user.surname}`}{' '}
							de tipo {renderTypeTask(info.type)}
						</div>
					),
					btn,
					key,
					duration: 420,
					className: 'notification-gibobs'
				});
			}
		});
	}
};

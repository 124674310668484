import env from '../environment';
import axios from 'axios';

class Auth {
	constructor() {
		this.isAuthenticated = this.isAuthenticated.bind(this);
		this.signOut = this.logout.bind(this);
		this.getToken = this.getToken.bind(this);
		this.getProfile = this.getProfile.bind(this);
		this.expiresInMillis = this.expiresInMillis.bind(this);
	}

	async login(username, password) {
		alert('avisar de error');
		const response = await axios
			.post('https://dev.api.hipotecaprimero.com/v1/auth/login', {
				username,
				password
			})
			.catch((ex) => {
				console.log(ex);
			});

		if (!response) {
			return false;
		}
		localStorage.setItem('access_token', response.data.data.token);
		localStorage.setItem('expiresAt', response.data.data.expiresAt);
		localStorage.setItem('profile', JSON.stringify(response.data.data.profile));

		return response.data;
	}

	setSession(authResult) {
		// Set the time that the Access Token will expire at
		let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());

		localStorage.setItem('expires_in_millis', authResult.expiresIn * 1000);
		localStorage.setItem('expires_at', expiresAt);
		localStorage.setItem('access_token', authResult.accessToken);
		localStorage.setItem('authresult', JSON.stringify(authResult));
	}

	getToken() {
		if (localStorage.getItem('access_token') === null) {
			return '';
		}

		return localStorage.getItem('access_token');
	}

	expiresInMillis() {
		const expiresAt = localStorage.getItem('expiresAt');

		return JSON.parse(expiresAt);
	}

	async refreshProfile() {
		const response = await axios
			.get(`${env.api.url}/api/user/salesman`, {
				headers: { Authorization: `Bearer ${this.getToken()}` }
			})
			.catch((ex) => {
				console.log(ex);
			});

		if (response) {
			localStorage.setItem('profile-api', JSON.stringify(response.data));
		}
	}

	getProfile() {
		/*const authProfile = localStorage.getItem("profile");
    const apiProfile = localStorage.getItem("profile-api");

    console.log('get-profile', authProfile, apiProfile)

    const profile = Object.assign(authProfile, apiProfile);

    if(profile && profile !== 'undefined') {
      return JSON.parse(profile);
    } else {
      return {};
    }*/

		let authProfile;
		let apiProfile;

		if (localStorage.getItem('profile') && localStorage.getItem('profile') !== 'undefined') {
			authProfile = JSON.parse(localStorage.getItem('profile'));
		} else {
			authProfile = {};
		}

		if (
			localStorage.getItem('profile-api') &&
			localStorage.getItem('profile-api') !== 'undefined'
		) {
			apiProfile = JSON.parse(localStorage.getItem('profile-api'));
		} else {
			apiProfile = {};
		}

		const profile = Object.assign(authProfile, apiProfile);

		return profile || {};
	}

	getRoles() {
		const roles = localStorage.getItem('roles');

		if (roles) {
			return JSON.stringify(roles);
		} else {
			return null;
		}
	}

	logout() {
		localStorage.clear();
	}

	isAuthenticated() {
		const expiresAt = localStorage.getItem('expiresAt');

		if (!expiresAt || expiresAt === 'undefined') {
			return false;
		}
		const json = JSON.parse(expiresAt);

		if (typeof json !== 'number') {
			return false;
		}
		return new Date().getTime() < json;
	}
}

const authClient = new Auth();

export default authClient;

import React, { useEffect, useState } from 'react';
import '../../../styles/Mortgages.css'
import axios from 'axios';
import env from '../../../environment';
import { Layout, Typography } from 'antd';
import authClient from '../../../Auth/Auth';
import { PlusOutlined } from '@ant-design/icons';
import SuperSearchMobile from '../../../Components/Search/SuperSearchMobile';

const { Content } = Layout;
const { Text } = Typography;

function DashboardMobile(props) {
	const [mortgageProvinces, setMortgageProvinces] = useState([]);
	const [realestateProvinces, setRealestateProvinces] = useState([]);
	const [profile, setProfile] = useState();
	const [value, setValue] = useState(0);
	const [allTask, setAllTask] = useState([]);
	const [agentsRisk, setAgentsRisk] = useState([]);

	useEffect(() => {
		const profile = authClient.getProfile();
		setProfile(profile);
		// handleApi();
		let roles = authClient.getRoles();
		getSalesAgents();
		setInterval(() => {
			getTask('current');
		}, 180000);
		getTask('current');
	}, []);

	const getSalesAgents = () => {
		axios
			.get(`${env.api.url}/v1/hadmin/sales-agents`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setAgentsRisk(response.data.data);
			});
	};

	const getTask = (type) => {
		axios
			.get(`${env.api.url}/v1/hadmin/tasks?status=${type}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				let tasks = [];

				for (let index = 0; index < response.data.task.length; index++) {
					const element = response.data.task[index];
					element.attemptsPlus = [];
					for (let index = 0; index < element.attempts; index++) {
						if (index <= 4) {
							element.attemptsPlus.push(
								<PlusOutlined
									rotate={45}
									style={{
										color: 'red',
										fontSize: 16,
										marginTop: 20,
										marginRight: 20
									}}
								/>
							);
						}
					}
					tasks.push(element);
				}
				setAllTask(tasks);
				setValue(1);
			});
	};

	return (
		<Layout className="page-mortgages">
			<Content
				style={{
					padding: '24px 16px',
					minHeight: 280
				}}
			>
				<SuperSearchMobile></SuperSearchMobile>
			</Content>
		</Layout>
	);
}
export default DashboardMobile

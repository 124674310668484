import React, { useEffect, useState } from 'react';
import axios from 'axios';
import env from '../../environment';
import authClient from '../../Auth/Auth';
import { Select } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

function SuperSearch({
	modal,
	operation,
	onOperationChange,
	header = false,
	operationName = false,
	task
}) {
	let timeout;
	let currentValue;
	let navigate = useNavigate();

	const [data, setData] = useState([]);
	const [value, setValue] = useState(undefined);

	useEffect(() => {
		if (operation && modal) {
			setValue(operation);
		}
	}, []);

	const capitalizeWords = (str) => {
		let words = str.split(' ');

		for (let i = 0; i < words.length; i++) {
			words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
		}

		return words.join(' ');
	};

	const search = (value) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		currentValue = value;

		const paramsSearch = {
			page: 1,
			limit: 10,
			fields: {
				guarantors: false,
				otherOwners: false,
				owner1: true,
				type: ['mortgage', 'php', 'certificate']
			},
			text: currentValue || ''
		};

		const searchData = () => {
			axios
				.post(`${env.api.url}/operations/metasearch/list`, paramsSearch, {
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				})
				.then((response) => {
					setData(response.data.data.metasearchs);
				});
		};
		timeout = setTimeout(searchData, 300);
	};

	const handleSearch = (value) => {
		if (value) {
			search(value);
		} else {
			setData([]);
		}
	};

	const handleChange = (value) => {
		if (!modal) {
			//Comprobamos si la operacion es tipo php en caso de serlo redirigimos a la pagina de php
			for (let i = 0; i < data.length; i++) {
				if (data[i].id == value) {
					if (data[i].type === 'php') {
						navigate(`/promoter-loan/operations/${data[i].id}`);
						break;
					} else {
						navigate(`/operations/mortgages/${data[i].id}`);
						break;
					}
				}
			}
			setValue('');
			if (header) {
				setData([]);
			}
		} else {
			if (onOperationChange) {
				setValue(value);
				onOperationChange(value);
			}
		}
	};

	const options = data.map((d) => (
		<>
			{!modal &&
				(d.type === 'mortgage' ||
					d.type === 'subrogation' ||
					d.type === 'green-mortgage' ||
					d.type === 'green-subrogation' ||
					d.type === 'php') && (
					<Option key={d.operationId} clearTimeout={1}>
						{d.type === 'php' ? (
							<>
								<strong>{d.subname.toUpperCase()}</strong> {capitalizeWords(d.owner1Name)} | {d.subname}
							</>
						) : (
							<>
								<strong>{d.name.toUpperCase()}</strong> {capitalizeWords(d.owner1Name)} | {d.subname}
							</>
						)}
					</Option>
				)}

			{modal &&
				(d.type === 'mortgage' ||
					d.type === 'subrogation' ||
					d.type === 'green-mortgage' ||
					d.type === 'green-subrogation' ||
					d.type === 'php') && (
					<Option key={d.operationId} clearTimeout={1}>
						{d.type === 'php' ? (
							<span
								style={{
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									width: 300
								}}>
								<strong>{d.subname.toUpperCase()}</strong> {capitalizeWords(d.owner1Name)} | {d.subname}
							</span>
						) : (
							<span
								style={{
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									width: 300
								}}>
								<strong>{d.name.toUpperCase()}</strong> {capitalizeWords(d.owner1Name)} | {d.subname}
							</span>
						)}
					</Option>
				)}
		</>
	));

	return (
		<div>
			<Select
				autoClearSearchValue={true}
				showSearch
				value={value !== '' ? value : ''}
				style={{
					minWidth: modal ? 300 : '500px',
					width: modal ? 300 : '100%'
				}}
				placeholder="Escribe para buscar..."
				defaultActiveFirstOption={false}
				showArrow={false}
				filterOption={false}
				// value={operation}
				onSearch={(value) => {
					handleSearch(value);
				}}
				onChange={(value) => {
					handleChange(value);
				}}
				notFoundContent={null}>
				{options}
			</Select>
		</div>
	);
}

export default SuperSearch;

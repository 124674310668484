import React, { useState, useEffect } from 'react';
import {
	Row,
	Modal,
	Radio,
	Col,
	Form,
	Input,
	Select,
	Checkbox,
	DatePicker,
	TimePicker,
	message,
	notification
} from 'antd';
import { ArrowUpOutlined, PauseOutlined, ArrowDownOutlined } from '@ant-design/icons';
import SuperSearch from '../Search/SuperSearch';
import authClient from '../../Auth/Auth';
import env from '../../environment';
import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.locale('en');
dayjs.locale('vi');

const { Option } = Select;
const { TextArea } = Input;

function ModalCreateTasks({
	allTask,
	operationIdModal,
	profile,
	agentsRisk,
	visible,
	getTask,
	setShowModalEdit,
	initialValuesStatus,
	edit
}) {
	const [form] = Form.useForm();
	const [selectSchedule, setSelectSchedule] = useState(
		initialValuesStatus && initialValuesStatus.radiogroup ? initialValuesStatus.radiogroup : 1
	);
	const [hoursSelected, setHoursSelected] = useState(edit ? dayjs(initialValuesStatus.hour) : '');
	const [notification1, setNotification1] = useState(false);

	useEffect(() => {
		setNotification1(
			initialValuesStatus &&
				initialValuesStatus.notification !== undefined &&
				initialValuesStatus.notification !== null
				? initialValuesStatus.notification
				: false
		);
		setHoursSelected(
			initialValuesStatus && initialValuesStatus.hour ? dayjs(initialValuesStatus.hour) : ''
		);
	}, [initialValuesStatus]);

	useEffect(() => {
		form.setFieldsValue(initialValuesStatus);
	}, [initialValuesStatus]);

	const handleOk = (values) => {
		setShowModalEdit(!visible);
		if (selectSchedule === 1) {
			values.hour = dayjs('08:00', 'HH:mm');
		}
		if (selectSchedule === 2) {
			if (hoursSelected === '') {
				values.hour = dayjs('21:00', 'HH:mm');
			} else {
				values.hour = dayjs(hoursSelected, 'HH:mm');
			}
		}

		axios
			.post(
				edit
					? `${env.api.url}/v1/hadmin/tasks/edit/${initialValuesStatus.id}`
					: `${env.api.url}/v1/hadmin/tasks/create`,
				{
					date: dayjs(
						`${values.date.format('YYYY-MM-DD')} ${values.hour.format('HH:mm')}`,
						'YYYY-MM-DD HH:mm'
					).toDate(),
					operation: operationIdModal !== undefined ? operationIdModal : values.operationId,
					type: values.type,
					name: values.name,
					description: values.description,
					assigned:
						agentsRisk &&
						agentsRisk.find(
							(item) =>
								item.name === values.assigned ||
								item.id === values.assigned ||
								item.id === initialValuesStatus.assigned
						)?.id,
					priority: Number.parseInt(values.priority),
					notification: notification1,
					notified: false
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.task.name) {
					notification.success({
						className: 'notification-success',
						message: 'Exito',
						description: 'La tarea se ha creado correctamente'
					});
					getTask && getTask();
				} else {
					if (response.data.task === 'comdoc') {
						notification.error({
							className: 'error-notification-gibobs',
							message: 'Error al crear',
							description:
								'Esta operación ya tiene una tarea de tipo “Compromiso de doc” y no es posible crear otra igual. Si lo necesitas, edita la existente'
						});
					} else if (response.data.task === 'tag') {
						notification.error({
							className: 'error-notification-gibobs',

							message: 'Error al crear',
							description:
								'Esta operación ya tiene una tarea de tipo “Etiquetado” y no es posible crear otra igual. Si lo necesitas, edita la existente'
						});
					} else if (response.data.task === 'firstcall') {
						notification.error({
							className: 'error-notification-gibobs',

							message: 'Error al crear',
							description:
								'Esta operación ya tiene una tarea de tipo “Primera llamada” y no es posible crear otra igual. Si lo necesitas, edita la existente'
						});
					} else if (response.data.task === 'customerqualification') {
						notification.error({
							className: 'error-notification-gibobs',

							message: 'Error al crear',
							description:
								'Esta operación ya tiene una tarea de tipo “Cualificación de cliente” y no es posible crear otra igual. Si lo necesitas, edita la existente'
						});
					} else {
						notification.error({
							className: 'error-notification-gibobs',

							message: 'Error al crear',
							description: 'No se ha podido crear la tarea. Motivo:' + response.data.task
						});
					}
				}
			})
			.catch((error) => {
				notification.error({
					className: 'error-notification-gibobs',

					message: 'Error al crear',
					description: 'No se ha podido crear la tarea. Motivo:' + error
				});
			});
	};

	const initDelete = {
		assigned: undefined,
		assigneeId: undefined,
		assigneeName: undefined,
		description: undefined,
		dueDate: undefined,
		frontId: undefined,
		id: undefined,
		name: undefined,
		notification: undefined,
		notified: undefined,
		operation: undefined,
		operationId: undefined,
		operationName: undefined,
		priority: undefined,
		radiogroup: undefined,
		reporterId: undefined,
		reporterName: undefined,
		status: undefined,
		type: undefined
	};

	const handleCancel = () => {
		setShowModalEdit(!visible);
		form.setFieldsValue(initDelete);
	};
	return (
		<Modal
			className="gb-modal-style-common modal-task-button-style"
			cancelButtonProps={{ className: 'button-secundary-gibobs', style: { width: '96px' } }}
			okButtonProps={{ className: 'button-primari-gibobs', style: { width: '96px' } }}
			title={edit ? 'Ver/Editar tarea' : 'Crear nueva tarea'}
			open={visible}
			width={497}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						form.setFieldsValue(initDelete);
						form.resetFields();
						handleOk(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
			okText={edit ? 'Actualizar' : 'Crear'}
			cancelText={'Salir'}
			onCancel={handleCancel}
			closable={false}
			destroyOnClose={true}>
			<Form
				form={form}
				layout="horizontal"
				name="basic"
				className="gb-form-task"
				initialValues={
					initialValuesStatus !== undefined
						? initialValuesStatus
						: {
								operation: allTask !== undefined ? allTask.name : '',
								operationId: allTask !== undefined ? allTask.id : '',
								assigned: allTask !== undefined ? allTask.risk : '',
								hour: dayjs('21:00', 'HH:mm'),
								priority: 20,
								radiogroup: 1
						  }
				}>
				<Row gutter={24}>
					<Col span={24}>
						<Form.Item
							label="Fecha"
							name="date"
							rules={[
								{
									required: true,
									validator: (e, value, callback) => {
										return new Promise((resolve, reject) => {
											if (initialValuesStatus && initialValuesStatus.date !== undefined) {
												if (moment(value).format('YYYY/MM/DD') < moment().format('YYYY/MM/DD')) {
													reject('Compruebe que la fecha es mayor que hoy');
												} else {
													resolve();
												}
											} else {
												if (value === undefined) {
													reject('Introduzca la fecha');
												} else {
													resolve();
												}
											}
										});
									}
								}
							]}>
							<DatePicker locale="es_Es" format="DD/MM/YYYY" placeholder="Fecha" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label="Hora"
							name="radiogroup"
							rules={[
								{
									required: true,
									message: 'Introduzca la hora'
								}
							]}>
							<Radio.Group defaultValue={1} onChange={(e) => setSelectSchedule(e.target.value)}>
								<Radio value={1} style={{ marginBottom: 13 }}>
									En cualquier momento del dia
								</Radio>
								<Radio value={2}>
									<TimePicker
										locale="es_Es"
										format="HH:mm"
										defaultValue={
											hoursSelected !== undefined && hoursSelected !== ''
												? dayjs(hoursSelected, 'HH:mm')
												: dayjs('21:00', 'HH:mm')
										}
										onChange={(e) => setHoursSelected(e)}
										style={{ width: '100%' }}
									/>
								</Radio>
								<Checkbox
									checked={notification1}
									onChange={(e) => setNotification1(e.target.checked)}
									disabled={selectSchedule === 1 ? true : false}>
									Avísame
								</Checkbox>
							</Radio.Group>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item
							label="Prioridad"
							name="priority"
							rules={[{ required: true, message: 'Introduzca la prioridad' }]}>
							<Radio.Group
								defaultValue={initialValuesStatus !== undefined ? initialValuesStatus.priority : 20}
								style={{ width: '100%' }}>
								<Radio value={30}>
									Alta <ArrowUpOutlined style={{ color: '#FF4D4F', marginLeft: 8 }} />
								</Radio>
								<Radio value={20}>
									Normal <PauseOutlined rotate={90} style={{ color: '#FAAD14', marginLeft: 8 }} />
								</Radio>
								<Radio value={10}>
									Baja <ArrowDownOutlined style={{ color: '#52C41A', marginLeft: 8 }} />
								</Radio>
							</Radio.Group>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item label="Operación asociada" name="operation">
							<SuperSearch
								className="selectorProperty"
								style={{ border: '1px solid #C0DAEE' }}
								modal={true}
								task={true}
								operation={
									initialValuesStatus !== undefined
										? initialValuesStatus.operation
										: allTask && allTask.name
								}
								onOperationChange={(operationId) => {
									operationIdModal = operationId;
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item
							label="Tipo:"
							name="type"
							rules={[{ required: true, message: 'Introduzca el tipo' }]}>
							<Select style={{ width: '100%' }} name="type" placeholder="Selecciona">
								<Option value="comdoc">Compromiso de documentación</Option>
								<Option value="crtanalysis">Certificado a analisis</Option>
								<Option value="tag">Etiquetar</Option>
								<Option value="call">Llamar</Option>
								<Option value="doc">Pedir documentación</Option>
								<Option value="firstcall">Primera llamada</Option>
								<Option value="other">Otro</Option>
								<Option value="claim">Reclamar documentación</Option>
								<Option value="claimoffer">Reclamar Oferta</Option>
								<Option value="claimappraisal">Reclamar tasación</Option>
								<Option value="recommender">Recomendador</Option>
								<Option value="sendbanks">Enviar a bancos</Option>
								<Option value="choosenoffer">Selección de oferta</Option>
								<Option value="followup">LLamada de seguimiento</Option>
								<Option value="customerqualification">Cualificación de cliente</Option>
								<Option value="phpanalysis">Promotor analisis</Option>
								<Option value="analysis">Nueva operacion en analisis</Option>
								<Option value="automaticofferexpires5days">
									La oferta automática caduca a los 5 días
								</Option>
								<Option value="automaticofferexpires1days">
									La oferta automática caduca en un 1 días
								</Option>
								<Option value="automaticofferexpires">La oferta automática caduca</Option>
								<Option value="gibobsappraisal">Tasación con gibobs</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label="Título de tarea"
							name="name"
							rules={[{ required: true, message: 'Introduzca el titulo de la tarea' }]}>
							<Input name="name" style={{ width: '100%' }} placeholder="Título de la tarea" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label="Descripción"
							name="description"
							rules={[{ required: true, message: 'Introduzca la descripción' }]}>
							<TextArea
								name="description"
								style={{ width: '100%' }}
								placeholder="Descripcion (máx. 250 caracteres)"
							/>
						</Form.Item>
					</Col>

					<Col span={24}>
						{allTask && (
							<Form.Item
								label="Asignada a:"
								name="assigned"
								rules={[{ required: true, message: 'Asigne la tarea' }]}>
								<Select
									placeholder="Selecciona"
									showSearch
									style={{ width: '100%' }}
									filterOption={(input, option) =>
										option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									defaultValue={allTask.risk}>
									{agentsRisk &&
										agentsRisk.map((m, id) => (
											<Option key={id} value={m.name}>
												{m.name}{' '}
											</Option>
										))}
								</Select>
							</Form.Item>
						)}
					</Col>
					<Col span={24}>
						<Form.Item label="Creada por: " name="owner1">
							<div style={{ color: '#748EA0', fontSize: 14 }}>{profile && profile.name}</div>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item
					hidden={true}
					name="operationId"
					style={{ float: 'left', marginRight: 200, width: 150 }}>
					<Input hidden={true} value={operationIdModal && operationIdModal}></Input>
				</Form.Item>
				<Form.Item
					hidden={true}
					name="agentId"
					style={{ float: 'left', marginRight: 200, width: 150 }}>
					<Input hidden={true}></Input>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default ModalCreateTasks;

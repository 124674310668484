import React, { useEffect, useState } from 'react';
import '../../../styles/Mortgages.css'
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import env from '../../../environment';
import auth from '../../../Auth/Auth';
import { Col, Layout, Row, Table } from 'antd';

const { Content } = Layout;

function MortgagesHistory({ dataALLMortgages, salesOwnerAll, agentsRisk, allProvince }) {
	const [filters, setFilters] = useState({
		salesOwner: [],
		status: [],
		closeReasons: [],
		province: [],
		realstateName: [],
		salesOwnerManagement: [],
		accountType: [],
		updatedAt: [],
		scoring: [],
		cierre: [],
		motivo: [],
		brands: []
	});

	const [mortgagesLoading, setMortgagesLoading] = useState(true);
	const [mortgagesData, setMortgagesData] = useState([]);

	useEffect(() => {
		habldeApi();
	}, []);

	const habldeApi = async () => {
		const response = await axios.get(`${env.api.url}/api/hadmin/mortgages-history`, {
			headers: { Authorization: `Bearer ${auth.getToken()}` }
		});

		const mortgages = response.data.map((d) => ({
			id: d.id,
			hp: d.name,
			clientName:
				d.operationData && d.operationData.owner1 !== undefined ? d.operationData.owner1.name : '',
			clientContact: [d.email, d.phone].filter(Boolean).join(', '),
			updatedAt: moment(d.updateAt).format('DD/MM/YYYY'),
			closeDate: d.closeDate ? moment(d.closeDate) : moment('2018-01-01'),
			closeDateDiff: d.closeDate
				? moment.duration(moment(d.closeDate).diff(moment())).locale('es').humanize(true)
				: '01/01/2018',
			updateDiff: moment.duration(moment(d.updateAt).diff(moment())).locale('es').humanize(true),
			updateDiffSeconds: moment.duration(moment(d.updateAt).diff(moment())).asSeconds(),
			status: d.status, //this.adaptStatus(d.status)
			salesOwner: d.salesOwner,
			salesOwnerManagement: d.salesOwnerManagement,
			realestateName: d.realestateName,
			finalStatus: d.finalStatus,
			scoring: d.scoring,
			province: d.province,
			createAt: moment(d.createAt).format('DD/MM/YYYY'),
			closeReason: d.closeReason,
			subCloseReason: d.subCloseReason,
			closeApplicant: d.closeApplicant,
			closeReasonOld: d.closeReasonOld,
			accountType: d.accountType,
			closeComunicationStatus: d.closeComunicationStatus,
			brandName: d.brandName
		}));

		mortgages.sort((a, b) => (a.hp < b.hp ? 1 : -1));

		const mortgagesData = mortgages.map((mortgage, index) => ({
			key: index,
			id: mortgage.id,
			hp: mortgage.hp,
			client: mortgage.clientName,
			contact: mortgage.clientContact,
			updatedAt: mortgage.updatedAt,
			updateDiff: mortgage.updateDiff,
			status: 'xxxx',
			salesOwner: mortgage.salesOwner,
			salesOwnerManagement: mortgage.salesOwnerManagement != null && mortgage.salesOwnerManagement,
			realestateName: mortgage.realestateName === null ? 'sin analista' : mortgage.realestateName,
			finalStatus: mortgage.finalStatus,
			scoring:
				mortgage.scoring === null
					? parseFloat(0).toFixed(2)
					: parseFloat(mortgage.scoring).toFixed(2),
			province: mortgage.province === null ? 'Sin provincia' : mortgage.province,
			createAt: moment(mortgage.createAt).format('DD/MM/YYYY'),
			closeReason: mortgage.closeReason,
			accountType: adaptType(mortgage.accountType),
			statusClose:
				mortgage.closeReason === 'Firmado con gibobs'
					? 'EXITO'
					: mortgage.closeReasonOld === 'h1_sign'
					? 'EXITO'
					: 'KO',
			closeReasonText: adaptCloseReason(mortgage.closeReason),
			closeComunicationStatus: mortgage.closeComunicationStatus,
			brandName: mortgage.brandName,
			closeDate: mortgage.closeDate,
			closeDateDiff: mortgage.closeDateDiff,
			subCloseReason: mortgage.subCloseReason,
			closeApplicant: mortgage.closeApplicant,
			closeReasonOld: mortgage.closeReasonOld
		}));

		const salesOwner = mortgagesData.map((item) => item.salesOwner);
		const province = mortgagesData.map((item) => item.province);
		const realestateNam = mortgagesData.map((item) => item.realestateName);
		const salesOwnerManagementUni = mortgagesData.map((item) => item.salesOwnerManagement);

		//const salesOwnerUni = mortgagesData.map((item) => item.salesOwner);
		const accountTypeUni = mortgagesData.map((item) => item.accountType);
		const statusUni = mortgagesData.map((item) => item.status);
		const closeReasonUni = mortgagesData.map((item) => item.closeReasonText);
		const brands = mortgagesData.map((item) => item.brandName);

		let uniqueProvinceArra = Array.from([...new Set(province)]).sort();
		let uniqueOwners = Array.from([...new Set(salesOwner)]).sort();
		let uniqueRealestateName = Array.from([...new Set(realestateNam)]).sort();
		let salesOwnerManagementArr = Array.from([...new Set(salesOwnerManagementUni)]).sort();

		//let salesOwnerUniArra = Array.from([...new Set(salesOwnerUni)]).sort();
		let accountTypeArra = Array.from([...new Set(accountTypeUni)]).sort();
		let statusArra = Array.from([...new Set(statusUni)]).sort();
		let closeReasonArra = Array.from([...new Set(closeReasonUni)]).sort();
		let brandUnique = Array.from([...new Set(brands)]).sort();
		let motivo = mortgagesData.map((item) => item.closeReason);

		const newFilers = {
			salesOwner: [],
			realestateName: [],
			status: [],
			province: [],
			closeReasons: [],
			salesOwnerManagement: [],
			accountType: [],
			scoring: [],
			cierre: [],
			brands: [],
			motivo: []
		};

		newFilers.brands = brandUnique.map((item) => ({
			text: item,
			value: item
		}));

		newFilers.salesOwner = uniqueOwners.map((item) => ({
			text: item,
			value: item
		}));

		await uniqueProvinceArra.forEach((item) => {
			let value = {
				text: item,
				value: item
			};
			newFilers.province.push(value);
		});

		await uniqueRealestateName.forEach((item) => {
			let value = {
				text: item,
				value: item
			};
			newFilers.realestateName.push(value);
		});

		await salesOwnerManagementArr.forEach((item) => {
			if (item != null) {
				let value = {
					text: item,
					value: item
				};
				newFilers.salesOwnerManagement.push(value);
			}
		});

		/*await salesOwnerUniArra.forEach((item) => {
      let value = {
        text: item,
        value: (item)
      }
      newFilers.salesOwner.push(value)
    });*/

		await accountTypeArra.forEach((item) => {
			if (item != null) {
				let value = {
					text: item,
					value: item
				};
				newFilers.accountType.push(value);
			}
		});

		await statusArra.forEach((item) => {
			if (item != null) {
				let value = {
					text: item,
					value: item
				};
				newFilers.status.push(value);
			}
		});

		await closeReasonArra.forEach((item) => {
			/*function getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
      }*/
			if (item != null) {
				// let key = getKeyByValue(statusMortages, item);
				let value = {
					text: item,
					value: item
				};
				newFilers.closeReasons.push(value);
			}
		});

		newFilers.updatedAt = [
			{
				text: 'Este mes',
				value: 'current-month'
			},
			{
				text: 'Mes anterior',
				value: 'last-month'
			},
			{
				text: 'Este trismestre',
				value: 'current-quarter'
			},
			{
				text: 'Trismestre anterior',
				value: 'last-quarter'
			}
		];

		newFilers.finish = [
			{
				text: 'FORMALIZADA',
				value: 'FORMALIZADA'
			},
			{
				text: 'NO FORMALIZADA CON HP',
				value: 'NO FORMALIZADA CON HP'
			}
		];

		newFilers.scoring = [
			{
				text: '<= 4',
				value: 'Mayor4'
			},
			{
				text: '>= 5',
				value: 'Menor5'
			}
		];

		newFilers.cierre = [
			{
				text: 'EXITO',
				value: 'EXITO'
			},
			{
				text: 'KO',
				value: 'KO'
			}
		];

		setMortgagesLoading(false);
		setMortgagesData(mortgagesData);
		setFilters(newFilers);
	};

	//  const adaptStatus=(sfStatus)=> {
	//     const status = {
	//       'make_call': 'Llamada HipotecaPrimero',
	//       'pending_declarative': 'Pendiente declarativas',
	//       'pending_documentation': 'Pendiente documentación',
	//       'risk_review': 'Revisión riesgos'
	//     }
	//     return { name: status[sfStatus] || "En proceso", className: "state ongoing" };
	//   }
	const adaptType = (sfType) => {
		const status = {
			realestate_property_developer: 'MIX',
			realestate: 'SM',
			property_developer: 'ON'
		};
		return status[sfType] || null;
	};

	const statusMortages = {
		h1_sign: 'Firmado con Hipoteca Primero',
		own_bank_sign: 'Firmado con su banco',
		no_savings: 'No tiene ahorros',
		insufficient_savings: 'Ahorros insuficientes',
		no_upload_documents: 'No aporta documentación',
		does_not_answer: 'No contesta',
		not_to_buy_a_home: 'Decide no comprar una vivienda',
		wait_for_sale_current_home: 'Tiene que esperar a la venta de su vivienda actual',
		bank_deny: 'Denegada por los bancos',
		client_cancel: 'Cancelado por el cliente',
		house_searc: 'Búsqueda de vivienda',
		full_loan: 'Financiación 100%',
		debt_ratio: 'Ratio de endeudamiento',
		postpone_home_purchase: 'Pospone la compra de la vivienda',
		economic_liquidity: 'Liquidez',
		asnef: 'ASNEF',
		duplicated: 'Titular duplicado',
		buy_with_another_real_estate: 'Compra con otra inmobiliaria',
		required_guarantor: 'Avalista Requerido',
		covid19: 'covid19',
		pending_own_bank_offer: 'Pendiente de oferta de su banco',
		loantovalue_90: 'Financiación 90%'
	};

	const adaptCloseReason = (sfCloseReason) => {
		if (sfCloseReason !== null) {
			return sfCloseReason;
		} else {
			return statusMortages[sfCloseReason];
		}
	};

	// const loadMortgages= async () => {
	//   const response = await axios.get(`${env.api.url}/api/hadmin/mortgages-comunication-pending`, {
	//     headers: {Authorization: `Bearer ${auth.getToken()}`}
	//   });
	// }

	const columns = [
		{
			title: 'Referencia',
			dataIndex: 'hp',
			key: 'hp',

			//defaultSortOrder: ['descend'],
			//filters: filters.status,
			render: (text, mortgage, row) => (
				<div>
					<NavLink to={`/operations/mortgages/${mortgage.id}`}>{text}</NavLink> <br />
					{mortgage.client}
				</div>
			),

			//onFilter: (value, record) => record.status.indexOf(value) === 0,
			sorter: (a, b, sortOrder) => {
				const sort = a.hp.localeCompare(b.hp, {
					sensitivity: 'base',
					ignorePunctuation: true,
					numeric: true
				});
				return sort * 1;
			}
		},

		/*{
      title: 'Tipo',
      dataIndex: 'accountType',
      key: 'accountType',
      width: "5%",
      sorter: (a, b) => {
        a = a.accountType || '';
        b = b.accountType || '';
        return a.localeCompare(b)
      },
      filters:  filters.accountType,
      onFilter: (value, record) => record.accountType !== null && record.accountType.includes(value)
    },*/
		{
			title: 'Comercial',
			dataIndex: 'salesOwner',
			key: 'salesOwner',
			sorter: (a, b) => {
				a = a.salesOwner || '';
				b = b.salesOwner || '';
				return a.localeCompare(b);
			},
			filters: filters.salesOwner,
			onFilter: (value, record) => record.salesOwner !== null && record.salesOwner.includes(value)
		},
		{
			title: 'Agencia',
			dataIndex: 'brandName',
			key: 'brandName',
			sorter: (a, b) => {
				a = a.brandName || '';
				b = b.brandName || '';
				return a.localeCompare(b);
			},
			filters: filters.brands,
			onFilter: (value, record) => record.brandName !== null && record.brandName.includes(value) // record.realestateName.indexOf(value) === -1
			// ...this.getColumnSearchNoRend('realestateName',)
		},
		{
			title: 'Agente',
			dataIndex: 'realestateName',
			key: 'realestateName',
			sorter: (a, b) => {
				a = a.realestateName || '';
				b = b.realestateName || '';
				return a.localeCompare(b);
			},
			filters: filters.realestateName,
			onFilter: (value, record) => record.realestateName.indexOf(value) === 0
		},
		{
			title: 'Analista Riesgos',
			dataIndex: 'salesOwnerManagement',
			key: 'salesOwnerManagement',
			defaultFilteredValue: auth.getProfile().type === 'risk' ? [auth.getProfile().name] : [],
			sorter: (a, b) => {
				a = a.salesOwnerManagement || '';
				b = b.salesOwnerManagement || '';
				return a.localeCompare(b);
			},
			filters: filters.salesOwnerManagement,
			onFilter: (value, record) =>
				record.salesOwnerManagement !== null && record.salesOwnerManagement.includes(value)
		},
		{
			/*
      title: "Scoring",
      dataIndex: "scoring",
      key: "scoring",
      width: "10",
      filters: filters.scoring,
      sorter: {
        compare: (a, b) => a.scoring - b.scoring,
      },
      render: (text, mortgage) => (
        <span>
          {mortgage.scoring <= 4 ? (
            <span style={{ color: "red" }}>{mortgage.scoring} </span>
          ) : (
            <span style={{ color: "green" }}> {mortgage.scoring}</span>
          )}{" "}
        </span>
      ),
      onFilter: (value, record) => {
        if (value === "Menor5") {
          return parseFloat(record.scoring) > 4 && record.scoring;
        } else if (value === "Mayor4") {
          return parseFloat(record.scoring) < 5 && record.scoring;
        }
      },
    */
		},
		{
			title: 'Cierre',
			dataIndex: 'statusClose',
			key: 'statusClose',
			filters: filters.cierre,
			onFilter: (value, record) =>
				record.statusClose !== null && record.statusClose.includes(value),
			render: (text, mortgage) => (
				<span>
					{mortgage.statusClose === 'EXITO' ? (
						<span style={{ color: 'green' }}>EXITO</span>
					) : (
						<span style={{ color: 'red' }}>KO</span>
					)}{' '}
				</span>
			)
		},
		{
			title: 'Fecha Cierre',
			dataIndex: 'closeDate',
			key: 'closeDate',
			defaultSortOrder: ['descend'],
			filters: filters.updatedAt,
			sorter: (a, b, sortOrder) => {
				const sort = a.closeDate.format('x').localeCompare(b.closeDate.format('x'), {
					sensitivity: 'base',
					ignorePunctuation: true,
					numeric: true
				});
				return sort * 1;
			},
			onFilter: (value, record) => {
				if (value === 'current-month') {
					return record.closeDate.isBetween(moment().startOf('month'), moment().endOf('month'));
				} else if (value === 'last-month') {
					return record.closeDate.isBetween(
						moment().subtract(1, 'month').startOf('month'),
						moment().subtract(1, 'month').endOf('month')
					);
				} else if (value === 'current-quarter') {
					return record.closeDate.quarter() === moment().quarter();
				} else if (value === 'last-quarter') {
					return record.closeDate.quarter() === moment().subtract(1, 'quarter').quarter();
				}

				//return record.agent.indexOf(value) === 0
			},
			render: (text, mortgages) => (
				<span style={{ textAlign: 'center' }}>
					{text.format('DD/MM/YYYY')}
					<br />
					<small>({mortgages.closeDateDiff})</small> <br />
				</span>
			)
		},
		{
			title: 'Motivo',
			dataIndex: 'closeReason',
			key: 'closeReason',
			defaultSortOrder: ['descend'],
			sorter: (a, b) => {
				a = (a.closeReasonOld ? statusMortages[a.closeReasonOld] : a.closeReason) || '';
				b = (b.closeReasonOld ? statusMortages[b.closeReasonOld] : b.closeReason) || '';
				return a.localeCompare(b);
			},
			filters: filters.closeReasons,
			onFilter: (value, record) =>
				record.closeReasonText !== null &&
				record.closeReasonText !== undefined &&
				record.closeReasonText.includes(value),

			render: (text, mortgages) => (
				<span style={{ textAlign: 'center' }}>
					<br />
					<small>
						{mortgages.closeReasonOld
							? statusMortages[mortgages.closeReasonOld]
							: mortgages.closeReason}
					</small>
				</span>
			)
		},
		{
			/*
      title: "Comunicacion",
      dataIndex: "status",
      key: "status",
      render: (text, mortgage) => {
        if (mortgage.closeComunicationStatus === false) {
          return (
            <Button
              style={{ height: "80px" }}
              type="primary"
              onClick={() => {
                const self = this;
                confirm({
                  title: "¿Deseas confirmarlo?",
                  content:
                    "Acepta si has comunicado a la inmobiliaria el motivo de cierre de esta hipoteca",
                  onOk() {
                    axios
                      .get(
                        `${env.api.url}/api/hadmin/mortgage-realestate-comunicated/${mortgage.id}`,
                        {
                          headers: {
                            Authorization: `Bearer ${auth.getToken()}`,
                          },
                        }
                      )
                      .then((response) => {
                        habldeApi();
                      });
                  },
                  //onCancel() {},
                });
              }}
            >
              <Text style={{ color: "white" }}>
                Marcar como
                <br />
                comunicado
              </Text>
            </Button>
          );
        } else {
          return (
            <Button style={{ height: "80px" }} type="primary" disabled>
              <Text>
                Cierre
                <br />
                comunicado
              </Text>
            </Button>
          );
        }
      },
    */
		}

		/*
      {
        title: 'Provincia',
        dataIndex: 'province',
        key: 'province',
        sorter: (a, b) => {
          a = a.province || '';
          b = b.province || '';
          return a.localeCompare(b)
        },
        filters:  filters.province,
        onFilter: (value, record) => record.province !== null && record.province.includes(value)  // console.log(record)//record.province.indexOf(value) === 0
      },*/

		/* {
        title: 'Motivo de Cierre',
        dataIndex: 'closeReasonText',
        key: 'closeReasonText',
        filters: filters.closeReasons,
        onFilter: (value, record) => record.closeReason !== null && record.closeReason.includes(value),
        render: (text, mortgage) => <span>{ mortgage.closeReasonText} </span>,

      },*/
	];

	mortgagesData.sort(function (a, b) {
		return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
	});

	const newContent = (
		<Table
			rowClassName="table-row"
			bordered={false}
			loading={mortgagesLoading}
			showHeader={true}
			showFooter={true}
			columns={columns}
			dataSource={mortgagesData}
			//onChange={this.onChange}
			pagination={{
				onChange: (page, pageSize) => console.log('Pagination => onChange: ', page, pageSize)
			}}
			scroll={{ x: 1000 }}></Table>
	);

	return (
		<Layout className="page-mortgages" style={{ background: 'white' }}>
			<Content
				style={{
					padding: 0,
					minHeight: 280
				}}>
				<Content style={{ padding: 24, minHeight: 280 }}>
					<Row gutter={16}>
						<Col span={24}>{newContent}</Col>
					</Row>
				</Content>
			</Content>
		</Layout>
	);
}

export default MortgagesHistory

/*
,
      {
        title: 'Comercial',
        dataIndex: 'salesOwner',
        key: 'salesOwner',
        sorter: (a, b) => {
          a = a.salesOwner || '';
          b = b.salesOwner || '';
          return a.localeCompare(b)
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Agencia',
        dataIndex: 'realestateName',
        key: 'realestateName',
        sorter: (a, b) => {
          a = a.realestateName || '';
          b = b.realestateName || '';
          return a.localeCompare(b)
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Agente',
        dataIndex: 'salesOwner',
        key: 'salesOwner',
        sorter: (a, b) => {
          a = a.salesOwner || '';
          b = b.salesOwner || '';
          return a.localeCompare(b)
        },
        //sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Analista Riesgos',
        dataIndex: 'salesOwnerManagement',
        key: 'salesOwnerManagement',
        sorter: (a, b) => {
          a = a.salesOwnerManagement || '';
          b = b.salesOwnerManagement || '';
          return a.localeCompare(b)
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'scoring',
        dataIndex: 'scoring',
        key: 'scoring',
        sorter: {
          compare: (a, b) => a.scoring - b.scoring,
          multiple: 2,
        },
        render: (text, mortgage) => <span>{ isNaN(parseFloat(mortgage.scoring))? null : parseFloat(mortgage.scoring).toFixed(2)   } </span>,
      },
 */

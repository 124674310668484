import {
	CONFIRMATIONAPROVAL,
	FORMALIZATIONOBJ,
	MOTIVOCIERRE,
	STATUSJOB,
	TASACIONOBJ
} from './Constants';

export const BtnAproConf = (bool) => async (dispatch) => {
	await dispatch({
		type: CONFIRMATIONAPROVAL,
		payload: bool
	});
};

export const changeStatusJob = (status) => {
	return {
		type: STATUSJOB,
		payload: status
	};
};

export const updateAnswerFormalization = (token, mortgageId, answer) => async (dispatch) => {
	let answersFormalization = {
		...answer
	};
	await dispatch({
		type: FORMALIZATIONOBJ,
		payload: answersFormalization
	});
};

export const updateAnswerTasacion = (token, mortgageId, answer) => async (dispatch) => {
	let answersFormalization = {
		...answer
	};
	await dispatch({
		type: TASACIONOBJ,
		payload: answersFormalization
	});
};

export const updateMotivoCiere = (token, mortgageId, answer) => async (dispatch) => {
	let answersFormalization = {
		...answer
	};
	await dispatch({
		type: MOTIVOCIERRE,
		payload: answersFormalization
	});
};

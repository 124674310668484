import React from 'react';
import { Spin } from 'antd';

export default () => {
	return (
		<div style={{ textAlign: 'center', height: '100vh' }}>
			<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import { Col, Row, Typography, Card } from 'antd';
import numeral from 'numeral';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';

const { Text } = Typography;

const DataNewFormatSubrogation = ({
	banks,
	subrogation,
	dataClient,
	dataBankBlock,
	isSabadell
}) => {
	const params = useParams();

	const changeHaveAInterest = (a) => {
		if (a === 'fixed') {
			return 'Fijo';
		} else if (a === 'variable') {
			return 'Variable';
		} else {
			return 'Mixto';
		}
	};

	const nameLinkages = {
		lifeInsurance: 'Seguro de vida',
		homeInsurance: 'Seguro del hogar',
		pensionPlan: 'Plan de pensiones',
		creditCards: 'Tarjetas de crédito/débito',
		carInsurance: 'Seguro del coche',
		unemploymentInsurance: 'Seguro de desempleo',
		receiptsDomiciliation: 'Domiciliación de recibos',
		payrollDomiciliation: 'Domiciliación de nómina'
	};

	const formatData = (title, data) => {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: title !== 'Proyecto que le interesa:' && 'center'
				}}>
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '500',
						color: '#2F4858',
						marginRight: '4px'
					}}>
					{title}
				</Text>
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '500',
						color: '#02C3CD'
					}}>
					{data}
				</Text>
			</div>
		);
	};

	const formatData2 = (title, data) => {
		return (
			<div style={{ display: 'flex', columnGap: 4 }}>
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '500',
						color: '#2F4858',
						marginRight: '4px'
					}}>
					{title}
				</Text>
				{data.initialTermVariable === undefined ? (
					<Text
						style={{
							fontSize: '12px',
							fontWeight: '500',
							color: '#02C3CD'
						}}>
						{data ? `${data.haveATin}%` : '-'}
					</Text>
				) : (
					<Text
						style={{
							fontSize: '12px',
							fontWeight: '500',
							color: '#02C3CD'
						}}>
						{data ? `${data.initialTermVariable} años ` : '-'}
						{data ? `${data.initialInterestVariable}%` : '-'} {'/ Resto: Eur +'}
						{data ? `${data.finalInterestVariable}%` : '-'}
					</Text>
				)}
			</div>
		);
	};

	const selectImagebank = (bank) => {
		return (
			<div style={{ height: 32, width: 'auto' }}>
				<img
					src={
						'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
						`${bank.bankCode}` +
						'.png'
					}
					alt={bank.name}
					style={{ height: 32, width: 'auto' }}
				/>
			</div>
		);
	};

	const formatOffert = (data) => {
		return (
			<div>
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '500',
						color: '#02C3CD'
					}}>
					{data}
				</Text>
			</div>
		);
	};

	return (
		<>
			<Row gutter={16} style={{ width: '100%' }}>
				<Col lg={16}>
					<Card
						style={{
							border: '1px solid #C0DAEE',
							boxShadow: '0px 0px 8px rgba(47, 72, 88, 0.1)',
							borderRadius: '4px',
							backgroundColor: 'white',
							height: '100%'
						}}>
						<Row gutter={24}>
							<Col span={isSabadell ? 8 : 21}>
								<div style={{ marginBottom: '16px' }}>
									<Text
										style={{
											fontSize: '14px',
											fontWeight: '600',
											color: '#2F4858'
										}}>
										Oferta hipotecaria
									</Text>
								</div>
							</Col>
							<Col
								span={isSabadell ? 8 : 3}
								style={{ display: 'flex', justifyContent: 'center', marginTop: isSabadell && -4 }}>
								{banks && subrogation?.mortgageCurrentBank !== undefined
									? banks.map((banks) => {
											if (banks.id === subrogation?.mortgageCurrentBank) {
												return selectImagebank(banks);
											}
									  })
									: '-'}
							</Col>
							{isSabadell && (
								<Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<a href={`/operations/mortgages/${params.mortgageId}/recommender-data`}>
										<Text
											style={{
												color: '#006DFF',
												fontSize: 12,
												fontWeight: 500,
												display: 'flex',
												alignItems: 'center',
												cursor: 'pointer'
											}}>
											Datos para recomendador{' '}
											<EyeOutlined style={{ marginLeft: 4, fontSize: 14 }} />
										</Text>
									</a>
								</Col>
							)}
						</Row>

						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'space-between'
							}}>
							<div>
								{formatData(
									'Tipo de interés:',
									subrogation && subrogation.interest
										? changeHaveAInterest(subrogation.interest)
										: '-'
								)}
								{formatData2(
									subrogation && subrogation.interest === 'fixed' ? 'TIN:' : 'Diferencial:',
									subrogation && subrogation ? subrogation : '-'
								)}
								{formatData(
									'Cuota actual:',
									subrogation && numeral(subrogation.quota).format('0,0 $')
								)}
							</div>
							<div>
								{formatData(
									'Importe c/v inicial:',
									subrogation && numeral(subrogation?.maxBudget).format('0,0 $')
								)}
								{formatData(
									'Importe hip inicial:',
									subrogation && numeral(subrogation?.amountInitial).format('0,0 $')
								)}
								{formatData(
									'Capital pendiente:',
									subrogation && numeral(subrogation?.amount).format('0,0 $')
								)}
							</div>

							<div>
								{formatData(
									'Fecha firma :',
									subrogation &&
										subrogation.dateSign &&
										moment(subrogation.dateSign, ['YYYY-MM-DD']).format('DD-MM-YYYY') !==
											'Fecha inválida'
										? moment(subrogation.dateSign, ['YYYY-MM-DD']).format('DD-MM-YYYY')
										: dataClient
										? subrogation?.whenSignedMortgage === 'moreThanOneYear'
											? 'mas de un año'
											: 'menos de un año'
										: '-'
								)}
								{formatData(
									'Plazo pte.:',
									subrogation && subrogation?.pendingTerms
										? `${subrogation?.pendingTerms} cuotas`
										: moment(subrogation?.dateEndMortgage).diff(moment(), 'months') + ' cuotas'
								)}
								{formatData(
									'Fecha de fin:',
									subrogation &&
										subrogation?.dateEndMortgage &&
										moment(subrogation?.dateEndMortgage, ['YYYY-MM-DD']).format('DD-MM-YYYY') !==
											'Fecha inválida'
										? moment(subrogation?.dateEndMortgage, ['YYYY-MM-DD']).format('DD-MM-YYYY')
										: moment(subrogation?.dateSign)
												.add(subrogation?.subrogationSigned, 'y')
												.format('DD-MM-YYYY')
								)}
							</div>
							<div>
								{formatData('Ubicación de la vivienda:', subrogation?.province)}

								<div
									style={{
										display: 'flex',
										alignItems: !subrogation?.vinculations && 'center'
									}}>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: '500',
											color: '#2F4858',
											marginRight: '4px'
										}}>
										Vinculaciones:
									</Text>
									{subrogation?.vinculations ? (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{Object.keys(subrogation?.vinculations)?.map((info, i) => (
												<Text
													key={`${info}-${i}`}
													style={{
														fontSize: '12px',
														fontWeight: '500',
														color: '#02C3CD'
													}}>
													{subrogation?.vinculations[info] && nameLinkages[info]}
												</Text>
											))}
										</div>
									) : (
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#02C3CD'
											}}>
											{subrogation?.costOtherProducts + ' €/año'}
										</Text>
									)}
								</div>
							</div>
						</div>
					</Card>
				</Col>

				<Col span={8}>
					<Card
						style={{
							border: '1px solid #C0DAEE',
							boxShadow: '0px 0px 8px rgba(47, 72, 88, 0.1)',
							borderRadius: '4px',
							backgroundColor: 'white',
							height: '100%'
						}}>
						<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
							{'Oferta de otros bancos'}
						</Text>

						{dataBankBlock?.map((data) => {
							return (
								<Row key={data.id} gutter={24} style={{ marginBottom: 16 }}>
									<Col lg={6}>
										{data?.bank?.bankCode ? (
											selectImagebank(data?.bank)
										) : (
											<Text
												style={{
													fontSize: 14,
													fontWeight: 500,
													color: '#2F4858'
												}}>
												{data?.bank?.name}
											</Text>
										)}
									</Col>
									{data?.rateType === 'fixed' && (
										<Col lg={18}>
											<div style={{ display: 'flex', columnGap: 4 }}>
												{formatOffert(data?.rateType ? changeHaveAInterest(data?.rateType) : '-')}
												{formatOffert(
													data?.rate !== undefined ? `${parseFloat(data?.rate).toFixed(2)}%` : '-'
												)}
											</div>
											{data?.linkages ? (
												<div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 4 }}>
													{Object.keys(data?.linkages)?.map(
														(linkages) =>
															data.linkages[linkages] && formatOffert(nameLinkages[linkages])
													)}
												</div>
											) : (
												'-'
											)}
										</Col>
									)}
									{data?.rateType !== 'fixed' && (
										<Col lg={18}>
											<div style={{ display: 'flex', columnGap: 4 }}>
												{formatOffert(data?.rateType ? changeHaveAInterest(data?.rateType) : '-')}
												{formatOffert(
													data?.stages?.[0]?.from ? `${data?.stages?.[0]?.from / 12} años` : '-'
												)}
												{formatOffert(
													data?.stages?.[0]?.tin
														? `${parseFloat(data?.stages?.[0]?.tin).toFixed(2)}%`
														: '-'
												)}{' '}
												{formatOffert('/ Resto: Eur +')}
												{formatOffert(
													data?.stages?.[1]?.tin
														? `${parseFloat(data?.stages?.[1]?.tin).toFixed(2)}%`
														: '-'
												)}
											</div>
											{data?.linkages ? (
												<div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 4 }}>
													{Object.keys(data?.linkages)?.map(
														(linkages) =>
															data.linkages[linkages] && formatOffert(nameLinkages[linkages])
													)}
												</div>
											) : (
												'-'
											)}
										</Col>
									)}
								</Row>
							);
						})}
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default DataNewFormatSubrogation;

import React, { useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Input,
	InputNumber,
	message,
	Row,
	Image,
	Upload,
	notification,
	Typography
} from 'antd';
import authClient from '../../../../../Auth/Auth';
import axios from 'axios';
import env from '../../../../../environment';
import moment from 'moment';
import { AlertOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { changeStatusJob } from '../../../../../store/FormalizationTasacion/actions';
import Loading from '../../../../../Components/ModalsAndAlerts/Loading';
import { UploadOutlined } from '@ant-design/icons';
import numeral from 'numeral';
import dayjs from 'dayjs';
import _ from 'lodash';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const { Text } = Typography;

export default (props) => {
	const { mortgageId } = props;
	const [data, setData] = useState();
	const [mortgage, setMortgage] = useState();
	const [offer, setOffer] = useState(undefined);
	const [fileName, setFileName] = useState('');
	const [signDate, setSignDate] = useState();
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);
	const dispatch = useDispatch();
	const [isSabadell, setIsSabadell] = useState(undefined);

	useEffect(() => {
		getMortgageData();
		getMortgageDetail();
		getBanksOnGoing();
		getManagementId();
	}, [mortgageId]);
	useEffect(() => {
		if (data && data.mortgage && data.mortgage.signDate) {
			setSignDate(data.mortgage.signDate);
		}
	}, [data]);

	const getManagementId = () => {
		axios
			.post(
				`${env.api.url}/management/management/by-operation`,
				{ operationId: mortgageId },
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			)
			.then((response) => {
				if (response.data.data.code === 'sabadell-es') {
					setIsSabadell(true);
				} else {
					setIsSabadell(false);
				}
			});
	};

	const getBanksOnGoing = async () => {
		const response = await axios.get(`${env.api.url}/v1/banks/get-banks?status=ongoing`, {
			headers: { Authorization: `Bearer ${authClient.getToken()}` }
		});
	};

	const getMortgageData = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setData(response.data.data);
			});
	};

	const getMortgageDetail = () => {
		axios
			.get(`${env.api.url}/api/hadmin/mortgages/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setMortgage(response.data);
				getOffers(response.data.selectedOffer);
			});
	};

	const getOffers = async (selectedOffer) => {
		const response = await axios.get(
			`${env.api.url}/v1/banks/get-operation-bank-offers/${mortgageId}`,
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);

		const offer = response.data.data.find((bo) => bo.id === selectedOffer);

		setOffer(offer);
	};

	if (!data || !mortgage) {
		return <Loading />;
	}

	const saveData = async (participant, key, value) => {
		if (key === 'digitalContractLink' && !value.startsWith('https://')) {
			value = 'https://' + value;
		}

		await axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${mortgageId}`,
				{ key, participant, value, origin: 'https://app.gibobs.com' },
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				message.success('Se actualizo con exito');
				getMortgageData();
			});
	};

	const saveDataZoom = async (owner, key, value, owner1, key1, value1) => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${mortgageId}`,
				{
					values: [
						{
							key: owner,
							subkey: key,
							value: value
						},
						{
							key: owner1,
							subkey: key1,
							value: value1
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				getMortgageData();
			});
	};

	const saveFileData = (file, name) => {
		axios
			.put(
				`${env.api.url}/api/mortgages/attachment`,
				{
					mortgageId,
					file,
					fileName: name,
					isOnAgent: true,
					type: 'fein'
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				message.success('Se actualizo con exito');
			});
	};

	const closeMortgage = async () => {
		await axios.post(
			`${env.api.url}/api/hadmin/mortgage-close`,
			{
				mortgageId,
				applicant: 'h1',
				closeReason: 'h1sign',
				subCloseReason: null,
				body: 'Operación firmada con Hipoteca Primero'
			},
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
	};

	const uploadProps = {
		beforeUpload: (file) => {
			setFileName(file.name);
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					saveFileData(base64File, file.name);
				};
			});
		}
	};

	const generateUserCode = (type) => {
		const name = `${type.indexOf('owner') != -1 ? 'Titular' : 'Avalista'} ${type
			.replace('owner', '')
			.replace('guarantor', '')}`;

		return (
			<Row
				style={{ marginTop: name !== 'Titular 1' && 24 }}
				key={`user-digital-${type}`}
				className="form-formalization">
				<Col span={6}>
					<Text className="label-formalization">Usuario {name}:</Text>
				</Col>
				<Col span={18}>
					<Input
						className="input-formalization"
						disabled={statusJob === 'finish' ? true : false}
						name={type}
						id={type}
						defaultValue={
							data[type] && data[type].userBankCode !== undefined ? data[type].userBankCode : ''
						}
						onBlur={(e) => saveData(type, 'userBankCode', e.target.value)}
						placeholder="Código de usuario banca digital"
					/>
				</Col>
			</Row>
		);
	};

	const closeMortgageBell = () => {
		if (isSabadell && data?.mortgage?.numberLoan) {
			if (signDate) {
				saveData('mortgage', 'ringTheBell', true);
				dispatch(changeStatusJob('finish'));
			} else {
				notification.error({
					message: 'Error',
					description: 'Para cerrar la operacion introduce la fecha de firma, por favor'
				});
			}
		} else if (isSabadell && data?.mortgage?.numberLoan === undefined) {
			notification.error({
				message: 'Error',
				description:
					'Es necesario completar el nº de préstamo de la operación. Lo puedes encontrar en Sinfonía'
			});
		} else {
			if (signDate) {
				saveData('mortgage', 'ringTheBell', true);
				dispatch(changeStatusJob('finish'));
			} else {
				notification.error({
					message: 'Error',
					description: 'Para cerrar la operacion introduce la fecha de firma, por favor'
				});
			}
		}
	};

	const saveDataOnClick = (participant, key, value) => {
		let checkValue = {
			key: 'operation',
			subkey: key === 'signedDocumentation' ? 'firstClickToSignDocs' : 'firstClickToIssueFein',
			value: moment().format('YYYY-MM-DD')
		};

		if (key === 'signedDocumentation' && data.operation.firstClickToSignDocs !== undefined) {
			checkValue = {
				key: 'operation',
				subkey: 'ultimateClickToSignDocs',
				value: moment().format('YYYY-MM-DD')
			};
		}
		/*if (key === 'docFeinIssued' && data.operation.firstClickToIssueFein !== undefined) {
			checkValue = {
				key: 'operation',
				subkey: 'ultimateClickToIssueFein',
				value: moment().format('YYYY-MM-DD')
			};
		}*/
		if (key === 'signed' && value === true) {
			checkValue = {
				key: 'mortgage',
				subkey: 'signedDocumentation',
				value: true
			};
		}

		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${mortgageId}`,
				{
					values: [
						{
							key: participant,
							subkey: key,
							value: value
						},
						{
							key: checkValue.key,
							subkey: checkValue.subkey,
							value: checkValue.value
						}
					],
					origin: 'https://app.gibobs.com'
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => getMortgageData());
	};

	const userDigitals = [];

	for (let i = 1; i <= data.mortgage.participants; i++) {
		userDigitals.push(generateUserCode(`owner${i}`));
	}

	for (let i = 0; i < data.mortgage.guarantors; i++) {
		userDigitals.push(generateUserCode(`guarantor${i + 1}`));
	}

	return (
		<div style={{ padding: '8px 24px' }}>
			<Row className="form-formalization">
				<Col span={24}>
					<Text style={{ fontSize: 20, fontWeight: 600, color: '#2F4858' }}>
						Documentos para la formalización
					</Text>
				</Col>
				{isSabadell && (
					<Col span={24} style={{ marginTop: 8 }}>
						<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
							Nº de Sinfonía 1:{' '}
							<span style={{ color: '#2F4858', fontWeight: 500 }}>
								{data.mortgage.symphonyNumber ?? '-'}
							</span>
						</Text>
					</Col>
				)}
			</Row>
			<Row style={{ marginTop: 24 }} className="form-formalization">
				<Col span={6}>
					<Text className="label-formalization">Link contrato digital: {statusJob}</Text>
				</Col>
				<Col span={18}>
					<Input
						className="input-formalization"
						style={{ marginBottom: 24 }}
						disabled={statusJob === 'finish' ? true : false}
						defaultValue={data.mortgage.digitalContractLink}
						onBlur={(e) => saveData('mortgage', 'digitalContractLink', e.target.value)}
						placeholder="Link contrato digital para que firme el cliente"
					/>
				</Col>
			</Row>
			{userDigitals}
			<Row style={{ marginTop: 24 }} className="form-formalization">
				<Col span={6}>
					<Text className="label-formalization">Doc. en papel:</Text>
				</Col>
				<Col span={18}>
					<Upload {...uploadProps}>
						<Button
							disabled={statusJob === 'finish' ? true : false}
							className="button-secundary-gibobs"
							icon={<UploadOutlined />}>
							Subir FEIN - FIAE
						</Button>
						<small>{fileName}</small>
					</Upload>
				</Col>
			</Row>
			{!isSabadell && (
				<Row style={{ marginTop: 24 }} className="form-formalization">
					<Col span={7}>
						<Checkbox
							className="checkbox-reco"
							disabled={statusJob === 'finish' ? true : false}
							name="docFeinIssued"
							checked={data.mortgage.docFeinIssued}
							onChange={(e) => saveDataOnClick('mortgage', 'docFeinIssued', e.target.checked)}>
							Documentación/FEIN emitida
						</Checkbox>
					</Col>
				</Row>
			)}
			{isSabadell && (
				<Row style={{ marginTop: 24 }} align={'middle'} className="form-formalization">
					<Col span={6}>
						<Checkbox
							className="checkbox-reco"
							disabled={statusJob === 'finish' ? true : false}
							name="docFeinIssued"
							checked={data.mortgage.docFeinIssued}
							onChange={(e) => saveDataOnClick('mortgage', 'docFeinIssued', e.target.checked)}>
							FEIN emitida
						</Checkbox>
					</Col>
					<Col span={18}>
						<DatePicker
							className="datepicker-reco"
							style={{ width: 156 }}
							disabled={statusJob === 'finish' ? true : false}
							onChange={(date) =>
								saveData('mortgage', 'docFeinIssuedDate', dayjs(date).format('YYYY-MM-DD'))
							}
							format={'DD-MM-YYYY'}
							defaultValue={
								data &&
								data?.mortgage?.docFeinIssuedDate !== 'Invalid Date' &&
								data?.mortgage?.docFeinIssuedDate !== undefined
									? dayjs(data.mortgage.docFeinIssuedDate)
									: undefined
							}
							placeholder={'Fecha'}
						/>
					</Col>
				</Row>
			)}
			<Row style={{ marginTop: 24 }} className="form-formalization">
				<Checkbox
					className="checkbox-reco"
					disabled={statusJob === 'finish' ? true : false}
					name="fileSign"
					checked={data.mortgage.signedDocumentation}
					onChange={(e) => saveDataOnClick('mortgage', 'signedDocumentation', e.target.checked)}
					style={{ marginRight: 15 }}>
					{isSabadell ? 'FEIN Firmada' : 'Documentación firmada'}
				</Checkbox>
			</Row>
			<Row style={{ marginTop: 24 }} align={'middle'} className="form-formalization">
				<Col span={6}>
					<Text className="label-formalization">Fecha estimada de firma:</Text>
				</Col>
				<Col span={18}>
					<DatePicker
						className="datepicker-reco"
						disabled={statusJob === 'finish' ? true : false}
						style={{ width: 156 }}
						onChange={(date, dateString) =>
							saveData('mortgage', 'estimatedSignDate', dayjs(date).format('YYYY-MM-DD'))
						}
						format={'DD-MM-YYYY'}
						defaultValue={
							data &&
							data?.mortgage?.estimatedSignDate !== 'Invalid Date' &&
							data?.mortgage?.estimatedSignDate !== undefined
								? dayjs(data.mortgage.estimatedSignDate)
								: undefined
						}
						placeholder={'Fecha'}
					/>
				</Col>
			</Row>
			<Row style={{ marginTop: 24 }} className="form-formalization">
				<Col span={24} style={{ display: 'flex', alignItems: 'center', gap: 40 }}>
					<Checkbox
						className="checkbox-reco"
						disabled={statusJob === 'finish' ? true : false}
						name="hpSign"
						checked={data.mortgage.signed}
						onChange={(e) => saveDataOnClick('mortgage', 'signed', e.target.checked)}>
						Hipoteca Firmada
					</Checkbox>
					<Checkbox
						className="checkbox-reco"
						disabled={statusJob === 'finish' ? true : false}
						name="notaryOferted"
						checked={data?.zoom?.notaryOferted}
						onChange={(e) =>
							saveDataZoom(
								'zoom',
								'notaryOferted',

								e.target.checked,
								'zoom',
								'notaryOfertedDate',
								moment().format('YYYY-MM-DD')
							)
						}>
						Ofrecido cupón de notaria
					</Checkbox>
					<Checkbox
						className="checkbox-reco"
						disabled={statusJob === 'finish' ? true : false}
						name="notaryUsed"
						checked={data?.zoom?.notaryUsed}
						onChange={(e) =>
							saveDataZoom(
								'zoom',
								'notaryUsed',
								e.target.checked,
								'zoom',
								'notaryUsedDate',
								moment().format('YYYY-MM-DD')
							)
						}>
						Cupón de notaría utilizado
					</Checkbox>
				</Col>
			</Row>
			{data.mortgage.signed === true && (
				<div>
					<Row
						gutter={24}
						style={{ marginTop: 24 }}
						className="form-formalization"
						align={'middle'}>
						<Col span={5}>
							<Text className="label-formalization">Fecha de firma</Text>
							<div>
								<DatePicker
									className="datepicker-reco"
									disabled={statusJob === 'finish' ? true : false}
									style={{ width: '100%', marginTop: 8 }}
									onChange={(date) => {
										if (!_.isNil(date)) {
											saveData('mortgage', 'signDate', dayjs(date).format('YYYY-MM-DD'));
										}
									}}
									format={'DD/MM/YYYY'}
									value={
										signDate === undefined || signDate === 'Invalid date'
											? undefined
											: dayjs(signDate)
									}
									placeholder={'00-00-0000'}
								/>
							</div>
						</Col>
						<Col span={7}>
							<Text className="label-formalization">Banco</Text>
							<Input
								className="input-formalization"
								disabled={statusJob === 'finish' ? true : false}
								style={{ width: '100%', marginTop: 8 }}
								readOnly={true}
								value={mortgage.bank}
								placeholder="Banco"
							/>
						</Col>
						<Col span={5}>
							<Text className="label-formalization">Importe</Text>
							{offer !== undefined && (
								<InputNumber
									className="input-formalization"
									disabled={statusJob === 'finish' ? true : false}
									style={{ width: '100%', marginTop: 8 }}
									formatter={(value) => numeral(value).format('0,0 $')}
									parser={(value) => numeral(value).value()}
									defaultValue={
										offer?.amount === 0 || offer?.amount === undefined
											? data?.mortgage?.amount
											: offer.amount
									}
									onBlur={(e) => {
										saveData('mortgage', 'amount', numeral(e.target.value).value());
									}}
									placeholder="0 €"
								/>
							)}
						</Col>
						<Col span={7}>
							<Text className="label-formalization">Nº de prestamo/Nº de acuerdo</Text>
							<Input
								className="input-formalization"
								disabled={statusJob === 'finish' ? true : false}
								style={{ width: '100%', marginTop: 8 }}
								defaultValue={data.mortgage.numberLoan}
								onBlur={(e) => {
									saveData('mortgage', 'numberLoan', e.target.value);
								}}
								placeholder="Nº de prestamo/Nº de acuerdo"
							/>
						</Col>
					</Row>

					{data.mortgage.signed && signDate && (
						<Row style={{ paddingTop: 40 }} type="flex" align="middle">
							<Col style={{ textAlign: 'center' }} span={24}>
								{!data.mortgage.ringTheBell ? (
									<Button
										disabled={statusJob === 'finish' ? true : false}
										type="primary"
										icon={<AlertOutlined style={{ fontSize: '15px', paddingTop: 3 }} />}
										onClick={async () => {
											closeMortgageBell();
										}}>
										TOCAR LA CAMPANA
									</Button>
								) : (
									<Image preview={false} src="/images/bell.gif" />
								)}
							</Col>
						</Row>
					)}
				</div>
			)}
		</div>
	);
};

import React from 'react';
import { Card, Typography, Form, Row, Col, Input } from 'antd';
import numeral from 'numeral';

const { Text } = Typography;

const TypeOfInterest = ({
	typeInterest,
	name1,
	name2,
	name3,
	dataTypes,
	icon,
	changeValueFinacial,
	variable,
	loading
}) => {
	return (
		<>
			{name1 !== 'Plazo (años)' ? (
				<Card style={{ border: '1px solid #80E1E6', borderRadius: '4px' }}>
					<div style={{ textAlign: 'center', marginBottom: 26 }}>
						<img src={icon} alt="iconInterest" style={{ marginRight: '8px' }} />
						<Text
							style={{
								fontSize: '14px',
								fontWeight: '500',
								color: '2f4858'
							}}
						>
							{typeInterest}
						</Text>
					</div>
					<div style={{ marginBottom: -24 }}>
						<Form
							name="basic"
							layout="vertical"
							className="gb-form-type"
							initialValues={
								dataTypes !== undefined
									? {
											quote: `${numeral(dataTypes.quote).format('0,0 $')}/mes`,
											// spread: `${(dataTypes.spread * 100).toFixed(2)} %`,
											spread: (dataTypes.spread * 100).toFixed(2),
											ratio: numeral(dataTypes.debtPercentage).format('0 %')
									  }
									: {}
							}
						>
							<Row gutter={24}>
								<Col lg={8} xs={24}>
									<Form.Item label={name1} name="spread">
										<Input
											onBlur={(e) => {
												const decimal = e.target.value.replace(/,/g, '.');
												changeValueFinacial(variable, decimal.replace('%', '') / 100);
												loading();
											}}
										/>
									</Form.Item>
								</Col>
								<Col lg={8} xs={24}>
									<Form.Item label={name2} name="quote">
										<Input disabled={true} style={{ border: 'none' }} />
									</Form.Item>
								</Col>
								<Col lg={8} xs={24}>
									<Form.Item label={name3} name="ratio">
										<Input
											readOnly={true}
											style={{
												border: 'none',
												background: '#EFFCFC',
												fontWeight: '600',
												color: '#02C3CD',
												fontSize: '16px'
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</div>
				</Card>
			) : (
				<Card style={{ border: '1px solid #80E1E6', borderRadius: '4px' }}>
					<div style={{ marginBottom: '-24px' }}>
						<Form
							name="basic"
							layout="vertical"
							className="gb-form-type"
							initialValues={
								dataTypes !== undefined
									? {
											quotes: dataTypes
									  }
									: {}
							}
						>
							<Row gutter={24} style={{ justifyContent: 'center' }}>
								<Col lg={4} xs={4}>
									<Form.Item label={name1} name="quotes">
										<Input
											// style={{ width: "190px" }}
											onBlur={(e) => {
												changeValueFinacial(variable, e.target.value * 12);
												loading();
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</div>
				</Card>
			)}
		</>
	);
};

export default TypeOfInterest;

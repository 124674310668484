import React, { useEffect, useState } from 'react';
import LayoutOperations from '../../../Operations/LayoutOperations';
import {
	Typography,
	Row,
	Col,
	Button,
	Layout,
	Card,
	Modal,
	Form,
	Input,
	DatePicker,
	Checkbox,
	Slider,
	InputNumber,
	Table,
	Tooltip,
} from 'antd';
import Map from '../../../../Hadmin/graphics/map/Map';
import axios from 'axios';
import env from '../../../../../environment';
import { EyeOutlined } from '@ant-design/icons';
import auth from '../../../../../Auth/Auth';
import moment from 'moment';
import { ProvinceSelector } from 'afrodita';

const { Content } = Layout;
const { Text } = Typography;

const Building = (props) => {
	const [count, setCount] = useState(['Israel', 'Rosa', 'Javi', 'Jose', 'Juan']);
	const [showFilter, setShowFilter] = useState(false);
	const [form] = Form.useForm();
	const [budgetLoading, setBudgetLoading] = useState(true);
	const [dataValues, setDataValues] = useState([]);

	const [filters, setFilters] = useState({
		accountName: [],
		brandName: []
	});

	useEffect(async () => {
		getSolarSVH();
		setBudgetLoading(false);
	}, []);

	let solarValueData = undefined;

	const getSolarSVH = () => {
		axios
			.get(`${env.api.url}/v1/hadmin/operations/list?type=solar-value`, {
				headers: { Authorization: `Bearer ${auth.getToken()}` }
			})
			.then((response) => {
				setDataValues(response.data.data.operations);

				solarValueData = response.data.data.operations.map((solar, index) => {
					return {
						key: index,
						id: solar.subname,
						houseType: solar.operationData['solar-value'].ecoHouseType,
						address: solar.operationData['solar-value'].address,
						accountName: solar.accountName,
						brandName: solar.brandName,
						createdAt: solar.createdAt,
						createDiff: moment
							.duration(moment(solar.createdAt).diff(moment()))
							.locale('es')
							.humanize(true),
						// vliving: solar,
						actions: solar
					};
				});
				setDataValues(solarValueData);

				let accountName = response.data.data.operations.map((item) => item.accountName);
				let accountNameUnique = Array.from([...new Set(accountName)]).sort();
				let brandName = response.data.data.operations.map((item) => item.brandName);
				let brandNameUnique = Array.from([...new Set(brandName)]).sort();

				const newFilers = {
					accountName: [],
					brandName: []
				};

				newFilers.accountName = accountNameUnique.map((item) => {
					return {
						text: item,
						value: item
					};
				});

				newFilers.brandName = brandNameUnique.map((item) => {
					return {
						text: item,
						value: item
					};
				});

				setFilters(newFilers);
			});
	};

	const showModalFilter = () => {
		setShowFilter(!showFilter);
	};

	const handleOk = () => {
		setShowFilter(!showFilter);
	};

	const handleCancel = () => {
		setShowFilter(!showFilter);
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			defaultSortOrder: ['descend'],
			//filters: filters.tipo,
			sorter: (a, b) => {
				a = a.id || '';
				b = b.id || '';
				return a.localeCompare(b);
			},

			render: (text, record) => {
				return <span>{text}</span>;
			}
			/* onFilter: (value, record) => {
       
         if (value === "not") {
           return !record.stage;
         }
 
         return value === record.id;
       },*/
		},

		{
			title: 'Fecha de creación',
			dataIndex: 'createdAt',
			key: 'createdAt',
			defaultSortOrder: ['descend'],
			render: (text, record) => {
				return (
					<div style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
						<small>{record.createDiff}</small>
						<br />
						{moment(text).format('DD-MM-YYYY')}
					</div>
				);
			},

			sorter: (a, b) => {
				a = a.createdAt || '';
				b = b.createdAt || '';
				return a.localeCompare(b);
			}
		},

		{
			title: 'Tipo de vivienda',
			dataIndex: 'houseType',
			key: 'houseType',
			sorter: (a, b) => {
				a = a.houseType || '';
				b = b.houseType || '';
				return a.localeCompare(b);
			},
			render: (text, record) => {
				return <div style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>{text}</div>;
			}

			/*onFilter: (value, record) =>
      record.stage !== null && record.stage.includes(value), */
		},
		{
			title: 'Dirección',
			dataIndex: 'address',
			key: 'address',
			sorter: (a, b) => {
				a = a.address || '';
				b = b.address || '';
				return a.localeCompare(b);
			},
			render: (text, record) => {
				return <div style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>{text}</div>;
			}
		},

		{
			title: 'Agente',
			dataIndex: 'accountName',
			key: 'accountName',
			filters: filters.accountName,
			onFilter: (value, record) => {
				if (value === 'not') {
					return !record.accountName;
				}

				return value === record.accountName;
			},
			render: (text, record) => {
				return <div style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>{text}</div>;
			}
		},

		{
			title: 'Agencia',
			dataIndex: 'brandName',
			key: 'brandName',
			filter: filters.brandName,
			render: (text, record) => {
				return (
					<div style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
						{text === null ? 'no tiene' : text}
					</div>
				);
			},
			sorter: (a, b) => {
				a = a.brandName || '';
				b = b.brandName || '';
				return a.localeCompare(b);
			},
			onFilter: (value, record) => {
				if (value === 'not') {
					return !record.brandName;
				}
				return value === record.brandName;
			}
		},

		{
			title: 'MVP Vliving',
			dataIndex: 'vliving',
			key: 'vliving',
			sorter: (a, b) => {
				a = a.client || '';
				b = b.client || '';
				return a.localeCompare(b);
			},
			render: (text, mortgage, row) => {
				return (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>{'SI'}</Text>
					</div>
				);
			},
			filters: filters.client,
			onFilter: (value, record) => record.client !== null && record.client.includes(value) // console.log(record)//record.province.indexOf(value) === 0
			//...this.getColumnSearchNoRend('province',)
		},
		{
			title: 'Acciones',
			dataIndex: 'actions',
			key: 'actions',
			render: (text, recover) => {
				return (
					<div>
						<Tooltip title={'Estamos trabajando en ello'}>
							<Button
								disabled={true}
								type="link"
								/* onClick={async () => {
                 const { url, name, mime, fileData } = await loadSignedDocument(
                   data.id
                 );
  
                 const modal = Modal.confirm({
                   title: "Visualizar fichero",
                   content: (
                     <DocViewer
                       pluginRenderers={DocViewerRenderers}
                       documents={[
                         { uri: url, fileType: mime, fileData: fileData },
                       ]}
                     />
                   ),
                   closable: true,
                   okCancel: true,
                   width: "100%",
                   onOk: () => {
                     document.location = url;
                   },
                   okText: "Descargar",
                   cancelText: "Cerrar",
                   onCancel: () => {
                     modal.destroy();
                   },
                 });
               }}*/
							>
								<EyeOutlined style={{ fontSize: 20, color: '#02C3CD' }} />
							</Button>
						</Tooltip>

						{/*profile.type === "risk" ||
       profile.type === "admin" ||
       profile.type === "prerisk" ? (
       <Popconfirm
         title="¿Estás seguro de borrar?"
         onConfirm={() => {
           removeFile(data.id);
         }}
         okText="Si"
         cancelText="No"
       >
     <Button type="link">
        <DownloadOutlined style={{ fontSize: 20, color: "#02C3CD" }} />
      </Button>
      </Popconfirm>
      ) : undefined*/}
					</div>
				);
			}
		}
	];

	return (
		<Content
			style={{
				padding: 10,
				minHeight: 280,
				marginLeft: 40,
				overflowX: 'hidden'
			}}
		>
			<LayoutOperations></LayoutOperations>
			{/* <Button className="button-secundary-gibobs" onClick={() => showModalFilter()} style={{ float: "right", marginTop: -50 }}>Filtro</Button> */}

			<div style={{ marginTop: 24, marginLeft: -10 }}>
				<Row gutter={24}>
					<Col lg={12} xs={24}>
						<Card className="gcard">
							<Text
								style={{
									fontWeight: 700,
									fontSize: 16,
									display: 'flex',
									placeContent: 'center',
									color: '#2F4858',
									marginTop: 0
								}}
							>
								Presupuestos generados
							</Text>
							{/*<GraphicSVH data={""}></GraphicSVH>*/}
							<div style={{ textAlign: 'center' }}>
								{' '}
								<img src="/images/job.png" alt="EmployeeOfTheMoanth" />
								<Text
									style={{
										fontWeight: 700,
										fontSize: 16,
										display: 'flex',
										placeContent: 'center',
										color: '#02C3CD',
										marginTop: -40
									}}
								>
									Work in progress
								</Text>
							</div>
						</Card>
					</Col>
					<Col lg={12} xs={24}>
						<Card className="gcard">
							<Text
								style={{
									fontWeight: 700,
									fontSize: 16,
									display: 'flex',
									placeContent: 'center',
									color: '#2F4858'
								}}
							>
								Desglose por comunidad
							</Text>

							<Row style={{ marginTop: 80 }}>
								<Col lg={12} xs={24}>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										{
											/*count.map(() =>*/
											<>
												<div style={{ display: 'flex' }}>
													<Text
														style={{
															fontWeight: 600,
															fontSize: 14,
															color: '#2F4858'
														}}
													>
														Madrid
													</Text>
													&nbsp;
													<Text
														style={{
															fontWeight: 640,
															fontSize: 14,
															color: '#C0DAEE'
														}}
													>
														123 operaciones
													</Text>
													&nbsp;
													<Text
														style={{
															fontWeight: 400,
															fontSize: 16,
															color: '#02C3CD'
														}}
													>
														33%
													</Text>
												</div>

												<div style={{ display: 'flex', marginTop: 16 }}>
													<Text
														style={{
															fontWeight: 600,
															fontSize: 14,
															color: '#2F4858'
														}}
													>
														Barcelona
													</Text>
													&nbsp;
													<Text
														style={{
															fontWeight: 640,
															fontSize: 14,
															color: '#C0DAEE'
														}}
													>
														55 operaciones
													</Text>
													&nbsp;
													<Text
														style={{
															fontWeight: 400,
															fontSize: 16,
															color: '#02C3CD'
														}}
													>
														33%
													</Text>
												</div>

												<div style={{ display: 'flex', marginTop: 16 }}>
													<Text
														style={{
															fontWeight: 600,
															fontSize: 14,
															color: '#2F4858'
														}}
													>
														Valencia
													</Text>
													&nbsp;
													<Text
														style={{
															fontWeight: 640,
															fontSize: 14,
															color: '#C0DAEE'
														}}
													>
														89 operaciones
													</Text>
													&nbsp;
													<Text
														style={{
															fontWeight: 400,
															fontSize: 16,
															color: '#02C3CD'
														}}
													>
														33%
													</Text>
												</div>
											</>

											/*)*/
										}
									</div>
								</Col>
								<Col lg={12} xs={24} style={{ marginTop: -90 }}>
									<div style={{ width: '100%', marginTop: 16 }}>
										<Map provinces={''} width="100%" height="395" />
									</div>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>

				<Row gutter={24} style={{ marginTop: 24 }}>
					<Col lg={12} xs={24}>
						<Row gutter={24}>
							<Col lg={12} xs={24}>
								<Card className="gcard">
									<Text
										style={{
											fontWeight: 700,
											fontSize: 16,
											display: 'flex',
											placeContent: 'center',
											color: '#2F4858'
										}}
									>
										Precio medio de la vivienda
									</Text>
									<Text
										style={{
											fontWeight: 700,
											fontSize: 32,
											display: 'flex',
											placeContent: 'center',
											color: '#7DD353'
										}}
									>
										150.000€
									</Text>
								</Card>
							</Col>
							<Col lg={12} xs={24}>
								<Card className="gcard">
									<Text
										style={{
											fontWeight: 700,
											fontSize: 16,
											display: 'flex',
											placeContent: 'center',
											color: '#2F4858'
										}}
									>
										Superficie media
									</Text>
									<Text
										style={{
											fontWeight: 700,
											fontSize: 32,
											display: 'flex',
											placeContent: 'center',
											color: '#748EA0'
										}}
									>
										126 m2
									</Text>
								</Card>
							</Col>
						</Row>
					</Col>

					<Col lg={12} xs={24}>
						<Card className="gcard">
							<Text
								style={{
									fontWeight: 700,
									fontSize: 16,
									display: 'flex',
									placeContent: 'center',
									color: '#2F4858'
								}}
							>
								Top 5 agentes
							</Text>
							<Row gutter={24}>
								{count.map((name, index) => (
									<Col lg={8} xs={24}>
										<div style={{ display: 'flex' }}>
											<Text
												style={{
													fontWeight: 600,
													fontSize: 16,
													color: '#2F4858'
												}}
											>
												{index + 1}.
											</Text>
											&nbsp;{/*index del map */}
											<Text
												style={{
													fontWeight: 700,
													fontSize: 16,
													color: '#02C3CD'
												}}
											>
												{name}
											</Text>
										</div>
									</Col>
								))}
							</Row>
						</Card>
					</Col>
				</Row>

				{/*<Text style={{ fontWeight: 600, fontSize: 14, color: "#2F4858", position: "relative", top: 24 }}>Presupuestos SVH</Text>*/}
				{/*index del map */}

				<Row gutter={[24, 24]} style={{ marginTop: 24 }}>
					<Col lg={24} xs={24}>
						<Card className="gcard">
							<Table
								rowClassName="table-row"
								bordered={false}
								loading={budgetLoading}
								showHeader={true}
								showFooter={true}
								columns={columns}
								dataSource={dataValues}
								scroll={{ x: 1000 }}
							>
								{/* onChange={() => onChange}*/}
								rowCount={0}
								pagination=
								{{
									position: ['bottomRight'],
									onChange: (page, pageSize) =>
										console.log('Pagination => onChange: ', page, pageSize)
								}}
							</Table>
						</Card>
					</Col>
				</Row>
			</div>

			<Modal
				className="gb-modal-style-common ModalFilter"
				title="Filtro"
				visible={showFilter}
				onOk={handleOk}
				onCancel={handleCancel}
				okText={'Mostrar resultados'}
				cancelText={'Quitar Filtros'}
				cancelButtonProps={{
					style: {
						border: 'none',
						color: '#02C3CD',
						backgroundColor: '#EFFCFC',
						float: 'left',
						textDecorationColor: '#02C3CD',
						textDecorationStyle: 'solid',
						textDecorationLine: 'none'
					}
				}}
				okButtonProps={{ style: { className: 'button-primari-gibobs' } }}
				closable={true}
				style={{ float: 'right' }}
			>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Form
						form={form}
						layout="vertical"
						name="filter"
						style={{ position: 'relative', top: -90, left: -35 }}
					>
						<Row>
							<Col lg={24}>
								<Form.Item
									label="Nombre de la operación"
									name="nameId"
									style={{
										float: 'left',
										width: 240,
										fontWeight: 600,
										color: '#2F4858',
										fontSize: 14
									}}
								>
									<Input
										//hidden={true}
										// value={operationIdModal && operationIdModal}
										placeholder="PSVH-XXXXX"
									></Input>
								</Form.Item>
							</Col>
							<Col lg={24}>
								<Form.Item
									label="Fecha"
									name="date"
									style={{
										float: 'left',
										width: 240,
										fontWeight: 600,
										color: '#2F4858',
										fontSize: 14
									}}
								>
									<DatePicker
										locale="es_Es"
										format="DD/MM/YYYY HH:mm"
										style={{ width: 240, border: '1px solid #C0DAEE' }}
									/>
								</Form.Item>
							</Col>

							<Col lg={24}>
								<Form.Item
									label="Tipo de vivienda"
									name="typeHouse"
									style={{
										float: 'left',
										width: 240,
										fontWeight: 600,
										color: '#2F4858',
										fontSize: 14
									}}
								>
									<div
										style={{
											display: 'flex',
											width: '100%',
											flexDirection: 'column'
										}}
									>
										<Checkbox checked={false}>
											<span
												style={{
													color: '#748EA0',
													fontSize: 14,
													fontWeight: 400
												}}
											>
												{' '}
												Unifamiliar{' '}
											</span>
										</Checkbox>

										<Checkbox checked={false} style={{ marginLeft: -0 }}>
											<span
												style={{
													color: '#748EA0',
													fontSize: 14,
													fontWeight: 400
												}}
											>
												{' '}
												Plurifamiliar{' '}
											</span>
										</Checkbox>
									</div>
								</Form.Item>
							</Col>
							<Col lg={24}>
								<Form.Item
									label="Superficie aproximada total"
									name="m2"
									style={{
										float: 'left',
										width: 240,
										fontWeight: 600,
										color: '#2F4858',
										fontSize: 14
									}}
								>
									<div style={{ display: 'flex', width: 300 }}>
										20
										<Slider
											defaultValue={20}
											tooltipVisible
											style={{ width: 240 }}
											min={20}
											max={500}
										/>
										500
									</div>
								</Form.Item>
							</Col>

							<Col lg={24}>
								<Form.Item
									label="Precio de la vivienda"
									name="amount"
									style={{ float: 'left', width: 240 }}
								>
									<InputNumber style={{ float: 'left', width: 240 }} placeholder="€"></InputNumber>
								</Form.Item>
							</Col>
							<Col lg={24}>
								<Form.Item
									label="Provincia"
									name="province"
									style={{
										float: 'left',
										width: 240,
										fontWeight: 600,
										color: '#2F4858',
										fontSize: 14
									}}
								>
									<ProvinceSelector placeholder='Provincia' style={{ width: '100%'}} />
								</Form.Item>
							</Col>

							<Form.Item
								label="Agencia / Agente"
								name="agent"
								style={{
									float: 'left',
									width: '100%',
									fontWeight: 600,
									color: '#2F4858',
									fontSize: 14
								}}
							>
								<Row gutter={[24, 16]}>
									{count.map(() => (
										<Col lg={8} xs={24}>
											<Checkbox checked={false}>
												<span
													style={{
														color: '#748EA0',
														fontSize: 14,
														fontWeight: 400
													}}
												>
													{' '}
													Texto{' '}
												</span>
											</Checkbox>
										</Col>
									))}
								</Row>
							</Form.Item>
						</Row>
					</Form>
				</div>
			</Modal>
		</Content>
	);
};

export default Building;

import React from 'react';
import { Typography, Row, Button } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';

const { Text } = Typography;

function LayoutOperations() {
	const location = useLocation();
	const styleTextTab = {
		fontSize: 12,
		fontWeight: 600,
		color: 'white',
		backgroundColor: '#02C3CD',
		borderRadius: '4px',
		border: 'none',
		height: 26,
		textAlign: 'center'
	};

	let isOperation = false;

	if (
		location.pathname === '/operations/mortgages/list/active' ||
		location.pathname === '/operations/mortgages/list/postpone' ||
		location.pathname === '/operations/mortgages/list/history'
	) {
		isOperation = true;
	}

	return (
		<div style={{ marginLeft: -10 }}>
			<Text strong style={{ fontWeight: 600, fontSize: 12, color: '#02C3CD' }}>
				Operaciones
			</Text>
			<Row
				gutter={[24, 24]}
				style={{
					justifyContent: 'space-between',
					margin: '0px 0px 12px'
				}}
			>
				<div className="new-style-layout-tools" style={{ marginTop: 24 }}>
					<div style={{ border: 'none' }} key="/operations/mortgages/list/active">
						<NavLink className="buttonOperations" to="/operations/mortgages/list/active">
							<Button className="buttonOperations" style={isOperation ? styleTextTab : undefined}>
								Hipotecas
							</Button>
						</NavLink>
					</div>
					<div style={{ border: 'none' }} key="/promoter-loan">
						<NavLink to="/promoter-loan/list/active">
							<Button
								className="buttonOperations"
								style={location.pathname.includes('/promoter-loan') ? styleTextTab : undefined}
							>
								Financiación promotor
							</Button>
						</NavLink>
					</div>
					<div style={{ border: 'none' }} key="/budgetsSVH">
						<NavLink to="/budgetsSVH">
							<Button
								className="buttonOperations"
								style={location.pathname === '/budgetsSVH' ? styleTextTab : undefined}
							>
								Presupuestos SVH
							</Button>
						</NavLink>
					</div>
					
					<div style={{ border: 'none' }} key="/certificates/list/active">
						<NavLink to="/certificates/list/active">
							<Button
								className="buttonOperations"
								style={
									location.pathname === '/certificates/list/active' ||
									location.pathname === '/certificates/list/pre-register' ||
									location.pathname === '/certificates/list/finish'
										? styleTextTab
										: undefined
								}
							>
								Certificados
							</Button>
						</NavLink>
					</div>
					<div style={{ border: 'none' }} key="/callcenter">
						<NavLink to="/callcenter">
							<Button
								className="buttonOperations"
								style={location.pathname === '/callcenter' ? styleTextTab : undefined}
							>
								Callcenter
							</Button>
						</NavLink>
					</div>
				</div>
			</Row>
		</div>
	);
}

export default LayoutOperations;

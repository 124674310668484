import React, { useState, useEffect } from 'react';
import { Button, Typography, Col, Row, Card, Checkbox, Spin, notification } from 'antd';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';

const { Text } = Typography;

const ViewDocumentList = ({ mortgage, mortgageId }) => {
	const navigate = useNavigate();
	const [allDocuments, setAllDocuments] = useState([]);
	const [clientDocuments, setClientDocuments] = useState([]);
	const location = useLocation();
	const [checkAll, setCheckAll] = useState({});
	let sendDocs = [];
	const idBank = location && location.state !== undefined ? location.state.bankId : undefined;
	const bankRequestId = location && location.state !== undefined ? location.state.bankRequestId : undefined;
	const bankRequest = location && location.state !== undefined ? location.state.bankRequest : undefined;
	const response2 = location && location.state !== undefined ? location.state.response : undefined;
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getDocuments();
	}, []);

	const getDocuments = () => {
		axios
			.get(
				`${env.api.url}/v1/documents/request-operation-documentation/${mortgageId}?generic=true`,
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				let documents = response.data.data.documents.filter((doc) => doc.name !== 'viability');
				documents = documents.filter((doc) => doc.name !== 'bankOffer');
				const clientDocuments = [];

				setAllDocuments(documents);

				for (let i = 1; i <= _.get(mortgage, 'mortgage.participants', 0); i++) {
					documents.forEach((doc) => {
						if (doc.completed[`owner${i}`] !== false) {
							const { customRequest } = bankRequest;
							const { documentTypeList } = customRequest ?? {};
							let required = true;
							const bankCode =
								bankRequest.bank !== undefined ? bankRequest.bank.bankCode : bankRequest.bankCode;

							if (!_.isNil(documentTypeList) && documentTypeList.length > 0) {
								const indexOf = _.findIndex(
									documentTypeList,
									(d) => d.owner === `owner${i}` && d.type === doc.name
								);
								required = indexOf !== -1 ? true : false;
							}

							if (doc.name.startsWith('excel_')) {
								if (!doc.name.includes(bankCode)) {
									return;
								}
								required = true;
							}
							if (doc.name.includes('consent_') && !doc.name.includes(bankCode)) {
								return;
							}

							if (doc.name.includes('consent_') && !doc.name.includes(bankCode)) {
								return;
							}

							clientDocuments.push({
								name: doc.title,
								type: doc.name,
								owner: `owner${i}`,
								required
							});
						}
					});
				}

				for (let i = 1; i <= _.get(mortgage, 'mortgage.guarantors', 0); i++) {
					documents.forEach((doc) => {
						if (doc.completed[`guarantor${i}`] !== false) {
							const { customRequest } = bankRequest;
							const { documentTypeList } = customRequest ?? {};
							let required = bankRequest.hasGuarantor;
							const bankCode =
								bankRequest.bank !== undefined ? bankRequest.bank.bankCode : bankRequest.bankCode;

							if (!_.isNil(documentTypeList) && documentTypeList.length > 0) {
								const indexOf = _.findIndex(
									documentTypeList,
									(d) => d.owner === `guarantor${i}` && d.type === doc.name
								);
								required = indexOf !== -1 ? true : false;
							}

							if (doc.name.startsWith('excel_')) {
								if (!doc.name.includes(bankCode)) {
									return;
								}
								required = true;
							}
							if (doc.name.includes('consent_') && !doc.name.includes(bankCode)) {
								return;
							}

							clientDocuments.push({
								name: doc.title,
								type: doc.name,
								owner: `guarantor${i}`,
								required
							});

							checkAll[`guarantor${i}`] = bankRequest.hasGuarantor;
							setCheckAll(checkAll);
						}
					});
				}

				documents.forEach((doc) => {
					if (doc.completed[`generic`] !== false) {
						const { customRequest } = bankRequest;
						const { documentTypeList } = customRequest ?? {};
						let required = true;
						const bankCode =
							bankRequest.bank !== undefined ? bankRequest.bank.bankCode : bankRequest.bankCode;

						if (!_.isNil(documentTypeList) && documentTypeList.length > 0) {
							const indexOf = _.findIndex(
								documentTypeList,
								(d) => d.owner === `generic` && d.type === doc.name
							);
							required = indexOf !== -1 ? true : false;
						}

						if (doc.name.startsWith('excel_')) {
							if (
								!doc.name.includes(
									bankRequest.bank !== undefined
										? bankRequest.bank.bankCode === 'imagin'
											? 'caixabank'
											: bankRequest.bank.bankCode
										: bankRequest.bankCode === 'imagin'
										? 'caixabank'
										: bankRequest.bankCode
								)
							) {
								return;
							}
							required = true;
						}

						if (doc.name.includes('consent_') && !doc.name.includes(bankCode)) {
							return;
						}
						if (doc.name.includes('operation_summary')) {
							return;
						}
						clientDocuments.push({
							name: doc.title,
							type: doc.name,
							owner: `generic`,
							required
						});
					}
				});

				setClientDocuments(clientDocuments);
			});
	};

	const onDocumentChecked = (ownerKey, checkedValue) => {
		const newClientDocuments = clientDocuments.map((doc) => {
			let checked = doc.required;

			if (doc.owner === ownerKey) {
				if (checkedValue.includes(doc.type)) {
					checked = true;
				} else {
					checked = false;
				}
			}

			return { ...doc, required: checked };
		});
		setClientDocuments(newClientDocuments);
	};

	const allDocumentChecked = (ownerKey, checkedValue) => {
		const newClientDocuments = clientDocuments.map((doc) => {
			let checked = doc.required;

			if (doc.owner === ownerKey) {
				checked = checkedValue;
			}

			return { ...doc, required: checked };
		});

		setClientDocuments(newClientDocuments);
	};

	const renderTittle = (ownerKey) => {
		let tmpOwner = '';

		if (ownerKey.startsWith('o')) {
			const index = ownerKey.substr(-1);
			tmpOwner = 'Documentos del titular ' + index;
		} else if (ownerKey.startsWith('gu')) {
			const index = ownerKey.substr(-1);
			tmpOwner = 'Documentos del avalista ' + index;
		} else {
			tmpOwner = 'Otros documentos ';
		}

		return tmpOwner;
	};

	if (loading) {
		return (
			<div style={{ textAlign: 'center', height: '100vh' }}>
				<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
			</div>
		);
	}

	const sendAllDocuments = () => {
		setLoading(true)
		axios
			.post(
				`${env.api.url}/banks/bank-request/update-custom-request/${bankRequestId}`,
				{
					documentTypeList: sendDocs
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.data) {
					/*if (idBank === 'a026N000000whCdQAI' && response2 === undefined) {
						let locationTmp = {
							pathname: `/operations/mortgages/${mortgageId}/table-santander`,
							state: { bankRequestId: bankRequestId, bankRequest: bankRequest }
						};
						navigate(locationTmp);
					} else*/ 
					if (idBank === 'a021t00000KGFCuAAP' && response2 === true) {
						axios
							.post(
								`${env.api.url}/banks/evo/prescoring/continue-process`,
								{
									bankRequestId: bankRequestId
								},
								{
									headers: { Authorization: `Bearer ${authClient.getToken()}` }
								}
							)
							.then((response) => {
								setLoading(false)
								navigate(`/operations/mortgages/${mortgageId}/?tab=5`);
							}).catch(()=> {
								setLoading(false)
							})
					}else if (response.data.data.bankName === 'UCI' && response2 === true) {
						axios
							.post(
								`${env.api.url}/banks/uci/continue-process`,
								{
									bankRequestExtraId: response.data.data.extras[response.data.data.extras.length - 1].id
								},
								{
									headers: { Authorization: `Bearer ${authClient.getToken()}` }
								}
							)
							.then((response) => {
								setLoading(false)
								navigate(`/operations/mortgages/${mortgageId}/?tab=5`);
								notification.success({
									message: 'Envio solicitado',
									description: 'Envio a UCI hecho correctamente'
								});
							}).catch(()=> {
								setLoading(false)
								notification.error({
									message: 'Envio fallido',
									description: 'Envio a UCI no ha sido posible'
								});
							})
					}
					 else {
						
						navigate( `/operations/mortgages/${mortgageId}/send-to-bank`,{
							state: {
								bankId: idBank,
								bankRequestId: bankRequestId,
								bankRequest: response.data.data,
								response: response2 ? true : false
							}
						});
					}
					//}
				}
			});
	};

	const renderDocuments = () => {
		const ownersComponent = [];
		const owners = _.groupBy(clientDocuments, (doc) => doc.owner);
		const ownerKeys = Object.keys(owners);

		ownerKeys.forEach((ownerKey) => {
			const ownerChecked = _.get(checkAll, ownerKey, true);
			const ownerDocuments = _.filter(clientDocuments, (doc) => doc.owner === ownerKey);
			const ownerCheckedDocuments = _.filter(
				clientDocuments,
				(doc) => doc.owner === ownerKey && doc.required === true
			);
			const optionsDocuments = ownerDocuments.map((doc) => {
				return { label: doc.name, value: doc.type };
			});
			const checkedDocuments = ownerCheckedDocuments.map((doc) => {
				return doc.type;
			});

			ownerCheckedDocuments.forEach((doc) => {
				sendDocs.push({
					owner: doc.owner,
					type: doc.type
				});
			});

			ownersComponent.push(
				<Col xs={12} md={12} sm={12} lg={8} xl={8} xxl={8}>
					<Card className="gcard">
						<Checkbox
							onChange={(e) => {
								checkAll[ownerKey] = !ownerChecked;
								setCheckAll(checkAll);
								allDocumentChecked(ownerKey, !ownerChecked);
							}}
							checked={ownerChecked}
							value={ownerKey}>
							<Text className="gb-text-600">{renderTittle(ownerKey)}</Text>
						</Checkbox>
						<Checkbox.Group
							className="gb-checkbox"
							style={{ display: 'flex', flexDirection: 'column' }}
							onChange={(checkedValue) => {
								onDocumentChecked(ownerKey, checkedValue);
							}}
							value={checkedDocuments}
							options={optionsDocuments}></Checkbox.Group>
					</Card>
				</Col>
			);
		});

		return ownersComponent;
	};

	return (
		<>
			<Card className="gb-card-desktop">
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
						Selección de documentación para enviar al banco
					</Text>
					<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400, marginTop: 8 }}>
						Selecciona los archivos necesarios según el perfil.
					</Text>
				</div>
				<Row gutter={[24, 24]} style={{ marginTop: 24 }}>
					{renderDocuments()}
				</Row>

				<Row style={{ justifyContent: 'center', marginTop: 20 }}>
					<Button
						style={{ height: 32, width: 96, fontSize: 14 }}
						className="button-secundary-gibobs"
						onClick={() => navigate(`/operations/mortgages/${mortgageId}/form-send-bank`)}
						type="dashed">
						Cancelar
					</Button>

					<Button
						style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
						type="primary"
						onClick={() => sendAllDocuments()}
						className="button-primari-gibobs">
						Siguiente
					</Button>
				</Row>
			</Card>
		</>
	);
};
export default ViewDocumentList;

import React, { useState } from 'react';
import { Button, Modal, Spin, Upload } from 'antd';
import axios from 'axios';
import authClient from '../../../../../Auth/Auth';
import env from '../../../../../environment';
import 'braft-editor/dist/index.css';
import { UploadOutlined } from '@ant-design/icons';

export const MortgageNoteFromImage = ({
	setImageString,
	mortgageId,
	onSuccess,
	visible,
	onCancel,
	setShowMortgageNoteFromImageModal,
	setShowMortgageNoteModal
}) => {
	const [fileName, setFileName] = useState('');
	const [loading, setLoading] = useState(false);

	const uploadProps = {
		beforeUpload: (file) => {
			setFileName(file.name);
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					saveFileData(base64File, file.name);
				};
			});
		}
	};

	const saveFileData = (file, name) => {
		setLoading(true);
		axios
			.post(
				`${env.api.url}/andromeda/image-to-text/chat-gpt`,
				{
					image: file
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setLoading(false);
				setImageString(response.data.data);
				setShowMortgageNoteFromImageModal(false)
				setShowMortgageNoteModal(true)
			});
	};

	return (
		<Modal
			open={visible}
			title="Crear una nueva nota desde una imagen"
			okText="Crear"
			onCancel={() => {
				onCancel();
			}}
			okButtonProps={{  style: { display: 'none'}}}
			cancelButtonProps={{  style: { display: 'none'}}}
			width="35%"
			className="gb-modal-create-note-image">
			{loading ? (
				<div style={{ width: '100%', textAlign: "center",  marginTop: 100 }}>
					<Spin />
				</div>
			) : (
				<div style={{ width: '100%', textAlign: "center",  marginTop: 100 }}>
					<Upload {...uploadProps}>
						<Button type="primary" icon={<UploadOutlined />}>
							SUBIR imagen
						</Button>
						<small>{fileName}</small>
					</Upload>
				</div>
			)}
		</Modal>
	);
};

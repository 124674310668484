import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import {
	Row,
	Col,
	Input,
	Form,
	Typography,
	Card,
	Progress,
	Spin,
	message,
	Button,
	InputNumber
} from 'antd';
import {
	ArrowUpOutlined,
	ArrowDownOutlined,
	PlusOutlined,
	PauseOutlined,
	StopOutlined
} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import ComponentData from './ComponentData';
import TypeOfInterest from './TypeOfInterest';
import TypeIndicesViability from './typeIndicesViability';
import { renderComponent } from '../data/renderComponent';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const NewPageFinancial = ({ mortgageId, maxBudget, mortgage, mortgageData }) => {
	const [loading, setLoading] = useState(true);
	const [loading2, setLoading2] = useState(true);
	const [viability, setViability] = useState([]);
	const mortgageUpda = useSelector((state) => state.mortgages.updateMortgages);
	const [dataTotalIncomesParticipants, setDataTotalIncomesParticipants] = useState(0);
	const [dataTotalObligationsPaymentsParticipants, setDataTotalObligationsPaymentsParticipants] =
		useState(0);

	const ownerList = [];
	let totalIncomes = 0;
	let totalObligationsPayments = 0;
	let navigate = useNavigate();
	const [result, setResult] = useState(undefined);

	useEffect(() => {
		if (mortgageData && mortgageData.mortgage) {
			for (let i = 1; i <= Number(mortgageData.mortgage.participants); i++) {
				ownerList.push(`owner${i}`);
			}

			for (let i = 0; i < Number(mortgageData.mortgage.guarantors); i++) {
				ownerList.push(`guarantor${i + 1}`);
			}
		}

		ownerList &&
			ownerList.forEach((data) => {
				if (data === 'owner1' || data === 'owner2' || data === 'owner3' || data === 'owner4') {
					if (mortgageData[data] && mortgageData[data].netIncomes) {
						totalIncomes += mortgageData[data].netIncomes;
					}
					if (mortgageData[data] && mortgageData[data].rentIncomes) {
						totalIncomes += mortgageData[data].rentIncomes;
					}
				}
			});

		setDataTotalIncomesParticipants(totalIncomes);

		ownerList &&
			ownerList.forEach((data) => {
				if (data === 'owner1' || data === 'owner2' || data === 'owner3' || data === 'owner4') {
					if (mortgageData[data] && mortgageData[data].loanPayment) {
						totalObligationsPayments += mortgageData[data].loanPayment;
					}
					if (mortgageData[data] && mortgageData[data].mortgagePayment) {
						totalObligationsPayments += mortgageData[data].mortgagePayment;
					}
					if (mortgageData[data] && mortgageData[data].pensionPayment) {
						totalObligationsPayments += mortgageData[data].pensionPayment;
					}
				}
			});

		setDataTotalObligationsPaymentsParticipants(totalObligationsPayments);
		sendDataToFinancing(mortgageData);
	}, [mortgageData]);

	useEffect(() => {
		if (
			mortgage &&
			mortgage !== undefined &&
			mortgage.subtype !== 'subrogation' &&
			mortgage.subtype !== 'green-subrogation'
		) {
			getViability();
		}
		if (
			mortgage !== undefined &&
			(mortgage.subtype === 'subrogation' || mortgage.subtype === 'green-subrogation')
		) {
			setViability('error');
			setLoading(false);
			setLoading2(false);
		}
	}, [mortgageUpda, mortgageId, mortgage]);

	const sendDataToFinancing = (mortgageData) => {
		if (mortgageData?.mortgage?.propertyType) {
			const objValues = {
				propertyType: mortgageData.mortgage.propertyType,
				houseType: mortgageData.mortgage.houseType,
				age: mortgageData.owner1.age,
				savings: mortgageData.mortgage.savings,
				professionalActivity: mortgageData.owner1.activity,
				numerousFamily: false,
				province: mortgageData.mortgage.province,
				seniority: parseInt(mortgageData.owner1.seniority),
				owner1: {
					activity: mortgageData.owner1.activity,
					seniority: parseInt(mortgageData.owner1.seniority),
					netIncomes: mortgageData.owner1.netIncomes,
					loanPayment: mortgageData.owner1.loanPayment,
					age: mortgageData.owner1.age,
					cityzenship: mortgageData.owner1.nationality
				}
			};

			axios
				.post(`${env.api.url}/mortgages/calculations/calculate-ceiling-bonilla`, objValues, {
					headers: {
						Authorization: `Bearer ${authClient.getToken()}`
					}
				})
				.then((response) => {
					setResult(response.data.data);
				})
				.catch((err) => console.log(err));
		}
	};

	const getViability = async () => {
		await axios
			.post(`${env.api.url}/mortgages/conventional-viability-mortgage/get-viability/${mortgageId}`,{}, {
				headers: {
					Authorization: `Bearer ${authClient.getToken()}`
				}
			})
			.then((response) => {
				if (response.data.success) {
					setViability(response.data.data);
					setLoading(false);
					setLoading2(false);
				} else {
					setViability('error');
					setLoading(false);
					setLoading2(false);
				}
			})
			.catch((err) => {
				setViability('error');
				setLoading(false);
				setLoading2(false);
			});
	};

	const changeValueFinacial = (value, name) => {
		setLoading2(true);
		openMessage('cargando');
		const overrides = {
			maxBudget: viability.operation.property,
			savings: viability.operation.savings,
			quotes: viability.operation.quotes,
			fixed: viability.operation.types.fixed.spread,
			spread: viability.operation.types.variable.spread,
		}
		
		if (!maxBudget) {
			overrides.euribor = 0;
			overrides.debts = viability.operation.types.fixed.debtPercentage;
			overrides.ltv = viability.ltv;
			overrides.spread = viability.operation.types.variable.spread;
			overrides.maxBudget = 0;
		}

		overrides[value] = Number(name);

		const objValuesModificated = {
			overrides,
			saveAsDocument: true
		};

		axios
			.post(`${env.api.url}/mortgages/conventional-viability-mortgage/calculate-viability/${mortgageId}`, objValuesModificated, {
				headers: {
					Authorization: `Bearer ${authClient.getToken()}`
				}
			})
			.then((response) => {
				openMessage('cargado');
				setViability(response.data.data);
				setLoading(false);
				setLoading2(false);
			})
			.catch((err) => console.log(err));
	};

	const typeHouseType = (a) => {
		if (a === 'main') {
			return 'Vivienda principal';
		} else if (a === 'second') {
			return 'Segunda vivienda';
		} else if (a === 'investor') {
			return 'Inversión';
		}
	};
	const typePropertyType = (a) => {
		if (a === 'newConstruction' || a === 'Obra Nueva') {
			return 'Obra nueva';
		} else if (a === 'secondHand' || a === 'Segunda Mano') {
			return 'Segunda mano';
		}
	};

	let textViabilitytitle;
	let content;
	const textViabilitytitleFunction = (viabilityValue) => {
		if (viabilityValue < 2) {
			return 'Viabilidad complicada';
		} else if (viabilityValue < 3 && viabilityValue >= 2) {
			return 'Viabilidad moderada';
		} else if (viabilityValue < 4 && viabilityValue >= 3) {
			return 'Viabilidad muy saludable';
		} else if (viabilityValue >= 4) {
			return 'Viabilidad excelente';
		}
	};

	const key = 'updatable';
	const calculatedAge = (age) => {
		if (moment(age).format('DD-MM-YYYY') !== 'Fecha inválida') {
			return moment(moment().format('YYYY-MM-DD')).diff(
				moment(moment(age).format('YYYY-MM-DD')),
				'years'
			);
		}
		if (moment(age).format('DD-MM-YYYY') === 'Fecha inválida') {
			return moment(moment().format('YYYY-MM-DD')).diff(
				moment(moment(age.split('-').reverse().join('-')).format('YYYY-MM-DD')),
				'years'
			);
		}
	};

	const openMessage = (change) => {
		if (change === 'cargando') {
			message.loading({ content: 'Calculando nueva viabilidad...', key });
		}
		if (change === 'cargado') {
			message.success({ content: 'Calculada!', key, duration: 2 });
		}
	};

	const handleChangeTab = () => {
		navigate(`/operations/mortgages/${mortgageId}/?tab=2`);
		window.location.reload();
	};

	if (
		mortgageData &&
		mortgageData.mortgage &&
		mortgageData.mortgage.maxBudget &&
		viability !== undefined &&
		Object.keys(viability).length > 0
	) {
		content = (
			<Row gutter={[24, 24]}>
				<Col lg={24} xs={24}>
					<TypeOfInterest
						typeInterest={'Plazo (años)'}
						name1={'Plazo (años)'}
						icon={'/images/clock.svg'}
						dataTypes={
							viability.operation && viability.operation.quotes && viability.operation.quotes / 12
						}
						changeValueFinacial={(value, name) => changeValueFinacial(value, name)}
						variable={'quotes'}
						loading={() => setLoading(true)}
					/>
				</Col>
				<Col lg={12} xs={24}>
					<TypeOfInterest
						typeInterest={'Tipo fijo'}
						name1={'TIN'}
						name2={'Cuota mensual'}
						name3={'Ratio de endeudamiento'}
						icon={'/images/iconFixed.svg'}
						dataTypes={
							viability !== undefined &&
							viability.operation &&
							viability.operation.types &&
							viability.operation.types.fixed
						}
						totalExpenses={mortgageData.owner1 && mortgageData.owner1.loanPayment}
						totalIncomes={mortgageData.owner1 && mortgageData.owner1.netIncomes}
						changeValueFinacial={(value, name) => changeValueFinacial(value, name)}
						variable={'fixed'}
						loading={() => setLoading(true)}
					/>
				</Col>
				<Col lg={12} xs={24}>
					<TypeOfInterest
						typeInterest={'Tipo variable'}
						name1={'Diferencial'}
						name2={'Cuota mensual'}
						name3={'Ratio de endeudamiento'}
						icon={'/images/iconVariable.svg'}
						dataTypes={
							viability !== undefined &&
							viability.operation &&
							viability.operation.types &&
							viability.operation.types.variable
						}
						totalExpenses={mortgageData.owner1 && mortgageData.owner1.loanPayment}
						totalIncomes={mortgageData.owner1 && mortgageData.owner1.netIncomes}
						changeValueFinacial={(value, name) => changeValueFinacial(value, name)}
						variable={'spread'}
						loading={() => setLoading(true)}
					/>
				</Col>
			</Row>
		);
	}

	const renderDataParticipants = (mortgageData, index) => {
		const tmpValue = [];

		if (
			mortgageData[`owner` + index] !== undefined &&
			(mortgageData[`owner` + index].netIncomes === undefined ||
				mortgageData[`owner` + index].numberOfPayments === undefined ||
				mortgageData[`owner` + index].bonus === undefined ||
				mortgageData[`owner` + index].rentIncomes === undefined ||
				mortgageData[`owner` + index].otherIncomes === undefined ||
				mortgageData[`owner` + index].mortgagePayment === undefined ||
				mortgageData[`owner` + index].loanPayment === undefined ||
				mortgageData[`owner` + index].cityzenship === undefined ||
				mortgageData[`owner` + index].activity === undefined ||
				mortgageData[`owner` + index].seniority === undefined ||
				mortgageData[`owner` + index].age === undefined)
		) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD', marginTop: 4 }}>
					Datos del titular {index}
				</Text>
			);
		}
		if (mortgageData[`owner` + index].netIncomes === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Ingresos
				</Text>
			);
		}
		if (mortgageData[`owner` + index].numberOfPayments === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Numero de pagas
				</Text>
			);
		}
		if (mortgageData[`owner` + index].bonus === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>Bonus</Text>
			);
		}
		if (mortgageData[`owner` + index].rentIncomes === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Rentas
				</Text>
			);
		}
		if (mortgageData[`owner` + index].otherIncomes === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Otros ingresos
				</Text>
			);
		}
		if (mortgageData[`owner` + index].mortgagePayment === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Pagos de hipoteca
				</Text>
			);
		}
		if (mortgageData[`owner` + index].loanPayment === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Prestamos
				</Text>
			);
		}
		if (mortgageData[`owner` + index].cityzenship === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Ciudad donde vive
				</Text>
			);
		}
		if (mortgageData[`owner` + index].activity === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Profesion
				</Text>
			);
		}
		if (mortgageData[`owner` + index].seniority === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Antiguedad
				</Text>
			);
		}
		if (mortgageData[`owner` + index].age === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>Años</Text>
			);
		}
		return tmpValue;
	};

	const renderDataGuarantors = (mortgageData, index) => {
		const tmpValue = [];

		if (
			mortgageData[`guarantor` + index] !== undefined &&
			(mortgageData[`guarantor` + index].netIncomes === undefined ||
				mortgageData[`guarantor` + index].numberOfPayments === undefined ||
				mortgageData[`guarantor` + index].bonus === undefined ||
				mortgageData[`guarantor` + index].rentIncomes === undefined ||
				mortgageData[`guarantor` + index].otherIncomes === undefined ||
				mortgageData[`guarantor` + index].mortgagePayment === undefined ||
				mortgageData[`guarantor` + index].loanPayment === undefined ||
				mortgageData[`guarantor` + index].pensionPayment === undefined ||
				mortgageData[`guarantor` + index].savings === undefined ||
				mortgageData[`guarantor` + index].cityzenship === undefined ||
				mortgageData[`guarantor` + index].activity === undefined ||
				mortgageData[`guarantor` + index].seniority === undefined ||
				mortgageData[`guarantor` + index].age === undefined)
		) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD', marginTop: 4 }}>
					Datos del avalista {index}
				</Text>
			);
		}
		if (mortgageData[`guarantor` + index].netIncomes === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Ingresos
				</Text>
			);
		}
		if (mortgageData[`guarantor` + index].numberOfPayments === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Numero de pagas
				</Text>
			);
		}
		if (mortgageData[`guarantor` + index].bonus === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>Bonus</Text>
			);
		}
		if (mortgageData[`guarantor` + index].rentIncomes === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Rentas
				</Text>
			);
		}
		if (mortgageData[`guarantor` + index].otherIncomes === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Otros ingresos
				</Text>
			);
		}
		if (mortgageData[`guarantor` + index].mortgagePayment === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Pagos de hipoteca
				</Text>
			);
		}
		if (mortgageData[`guarantor` + index].loanPayment === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Prestamos
				</Text>
			);
		}
		if (mortgageData[`guarantor` + index].cityzenship === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Ciudad donde vive
				</Text>
			);
		}
		if (mortgageData[`guarantor` + index].activity === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Profesion
				</Text>
			);
		}
		if (mortgageData[`guarantor` + index].seniority === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
					Antiguedad
				</Text>
			);
		}
		if (mortgageData[`guarantor` + index].age === undefined) {
			tmpValue.push(
				<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>Años</Text>
			);
		}

		return tmpValue;
	};

	const renderBarProgress = () => {
		let percentOne = (result[1]?.maxBudget * 100) / (result[2].maxBudget + 10000);
		percentOne = parseInt(percentOne);

		let percentSecond = (result[2].maxBudget * 100) / (result[2].maxBudget + 10000);
		percentSecond = parseInt(percentSecond - percentOne);

		const tmp = percentOne + percentSecond;

		const total = 100 - parseInt(tmp);

		return (
			<>
				<div
					style={{
						width: 300,
						display: 'flex',
						marginTop: 24,
						marginBottom: 10
					}}>
					<div
						style={{
							width: '85%' //`${percentOne}%`,
						}}></div>
					<Text
						style={{
							color: '#748EA0',
							fontSize: 12,
							fontWeight: 400,
							width: '50%',
							marginLeft: result[2].maxBudget === result[1].maxBudget ? -50 : undefined
						}}>
						{numeral(result[1].maxBudget).format('0,0 $')}
					</Text>
					<div
						style={{
							width: '25%' //`${percentSecond}%`,
						}}></div>
					{result[2].maxBudget !== result[1].maxBudget ? (
						<Text
							style={{
								color: '#748EA0',
								fontSize: 12,
								fontWeight: 400,
								width: '50%'
							}}>
							{numeral(result[2].maxBudget).format('0,0 $')}
						</Text>
					) : undefined}
					<div
						style={{
							width: '25%' //`${total}%`,
						}}></div>
				</div>

				<div style={{ width: 300, display: 'flex' }}>
					<div
						style={{
							width: '50%', //`${percentOne}%`,
							background: '#95DE64',
							height: '5px',
							borderRadius: '8px 0px 0px 8px'
						}}></div>
					<Text style={{ color: '#748EA0', marginTop: -10, marginRight: -1 }}>|</Text>

					<div
						style={{
							width: '25%', //`${percentSecond}%`,
							background: result[2].maxBudget === result[1].maxBudget ? '#FF4D4F' : '#FAAD14',
							height: '5px'
						}}></div>
					{result[2].maxBudget !== result[1].maxBudget ? (
						<Text style={{ color: '#748EA0', marginTop: -10, marginRight: -1 }}>|</Text>
					) : undefined}
					<div
						style={{
							width: '25%', //`${total}%`,
							background: '#FF4D4F',
							height: '5px',
							borderRadius: '0px 8px 8px 0px'
						}}></div>
				</div>
			</>
		);
	};

	if (
		mortgageData &&
		mortgageData.operation &&
		(mortgageData.mortgage.maxBudget === undefined ||
			mortgageData.mortgage.province === undefined ||
			mortgageData.mortgage.propertyType === undefined ||
			mortgageData.owner1.bonus === undefined ||
			mortgageData.owner1.numberOfPayments === undefined)
	) {
		if (
			mortgageData &&
			mortgageData.operation &&
			mortgageData.operation.subtype &&
			(mortgageData.operation.subtype === 'subrogation' ||
				mortgageData.operation.subtype === 'green-subrogation')
		) {
			return (
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
						marginTop: 100,
						height: '40vh'
					}}>
					<StopOutlined style={{ fontSize: 48, color: '#02C3CD' }} />
					<div style={{ marginTop: 32 }}>
						<Text style={{ color: '#02C3CD', fontSize: 20, fontWeight: 400, marginRight: 3 }}>
							Lo sentimos,
						</Text>
						<Text style={{ color: '#02C3CD', fontSize: 20, fontWeight: 700 }}>
							por los momentos la pestaña de financiero no se encuentra disponible para
							subrogaciones.
						</Text>
					</div>
				</div>
			);
		} else if (
			mortgageData &&
			mortgageData.operation &&
			mortgageData.operation.subtype &&
			(mortgageData.operation.subtype !== 'subrogation' ||
				mortgageData.operation.subtype !== 'green-subrogation') &&
			(mortgageData.mortgage.maxBudget === undefined ||
				mortgageData.mortgage.province === undefined ||
				mortgageData.mortgage.propertyType === undefined ||
				mortgageData.owner1.birthdate === undefined)
		) {
			if (loading2) {
				return (
					<div style={{ textAlign: 'center', height: '100vh' }}>
						<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
					</div>
				);
			} else {
				return (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
							Datos incompletos
						</Text>

						<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858', marginTop: 8 }}>
							Los datos suministrados no son suficientes para obtener viabilidad. Los datos por
							completar son los que se muestran a continuación:
						</Text>

						<Row>
							{mortgageData &&
								mortgageData.operation &&
								(mortgageData.operation.type === 'green-mortgage' ||
									mortgageData.operation.type === 'mortgage') &&
								(mortgageData.mortgage.maxBudget === undefined ||
									mortgageData.mortgage.province === undefined ||
									mortgageData.mortgage.propertyType === undefined) && (
									<Col span={6}>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<Text
												style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD', marginTop: 16 }}>
												Datos de la operación
											</Text>

											{mortgageData.mortgage.maxBudget === undefined && (
												<Text
													style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 8 }}>
													Importe de compra
												</Text>
											)}
											{mortgageData.mortgage.province === undefined && (
												<Text
													style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
													Provincia de compra
												</Text>
											)}

											{mortgageData.mortgage.propertyType === undefined && (
												<Text
													style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginTop: 4 }}>
													Tipo de propiedad
												</Text>
											)}
										</div>
									</Col>
								)}
							{mortgageData &&
								mortgageData.operation &&
								(mortgageData.operation.subtype === 'green-mortgage' ||
									mortgageData.operation.subtype === 'mortgage') &&
								Array(mortgageData).map((array) => {
									const tempOwners = [];
									for (let index = 1; index <= mortgageData.mortgage.participants; index++) {
										if (array[`owner` + index] !== undefined) {
											tempOwners.push(
												<Col span={6} style={{ marginTop: 16 }}>
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														{renderDataParticipants(mortgageData, index)}
													</div>
												</Col>
											);
										}
										if (array[`owner` + index] === undefined) {
											tempOwners.push(
												<Col span={6} style={{ marginTop: 16 }}>
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														<Text
															style={{
																fontSize: 12,
																fontWeight: 600,
																color: '#02C3CD',
																marginTop: 4
															}}>
															Datos del titular {index} (No tienen ningun dato)
														</Text>
													</div>
												</Col>
											);
										}
									}
									return tempOwners;
								})}
							{mortgageData &&
								mortgageData.operation &&
								(mortgageData.operation.subtype === 'green-mortgage' ||
									mortgageData.operation.subtype === 'mortgage') &&
								Array(mortgageData).map((array) => {
									const tempOwners = [];
									for (let index = 1; index <= mortgageData.mortgage.guarantors; index++) {
										if (array[`guarantor` + index] !== undefined) {
											tempOwners.push(
												<Col span={6} style={{ marginTop: 16 }}>
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														{renderDataGuarantors(mortgageData, index)}
													</div>
												</Col>
											);
										}
										if (array[`guarantor` + index] === undefined) {
											tempOwners.push(
												<Col span={6} style={{ marginTop: 16 }}>
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														<Text
															style={{
																fontSize: 12,
																fontWeight: 600,
																color: '#02C3CD',
																marginTop: 4
															}}>
															Datos del avalista {index} (No tienen ningun dato)
														</Text>
													</div>
												</Col>
											);
										}
									}
									return tempOwners;
								})}
						</Row>
						<Row style={{ marginTop: 40 }}>
							<Button
								type="primary"
								className="button-primari-gibobs"
								style={{ height: 32, fontSize: 14 }}
								onClick={() => handleChangeTab()}>
								Completar
							</Button>
						</Row>
					</div>
				);
			}
		} else {
			if (loading2) {
				return (
					<div style={{ textAlign: 'center', height: '100vh' }}>
						<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
					</div>
				);
			}
		}
	} else {
		if (loading2) {
			return (
				<div style={{ textAlign: 'center', height: '100vh' }}>
					<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
				</div>
			);
		} else {
			return (
				<Fragment>
					<Row gutter={[24, 24]} style={{ rowGap: 16 }}>
						<Col lg={24}>
							<div style={{ marginTop: '8px' }}>
								<Text
									style={{
										fontSize: '14px',
										fontWeight: '600',
										color: '#2F4858'
									}}>
									Viabilidad
								</Text>
							</div>
						</Col>
						<Col lg={24}>
							<Card style={{ border: '1px solid #C0DAEE', borderRadius: '4px' }}>
								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										justifyContent: 'space-between'
									}}>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '600',
												color: '#52C41A',
												width: '95px',
												marginRight: '16px'
											}}>
											{textViabilitytitleFunction(
												viability && viability.viability && viability.viability
											)}
										</Text>
										<Progress
											type="dashboard"
											strokeColor={'#52C41A'}
											trailColor={'#F1F7F8'}
											percent={((viability ? viability.viability : null) * 100) / 5}
											format={() => (
												<div>
													<Text
														strong
														style={{
															fontSize: '14px',
															color: '#2F4858',
															marginBottom: '4px',
															display: 'grid'
														}}>
														{viability && viability.viability && viability.viability.toFixed(2)}
													</Text>
												</div>
											)}
											width={43}
											className="EstimatedViabilityProgress"
											style={{
												textAlign: 'center',
												marginRight: 0
											}}
										/>
									</div>
									<div style={{ alignSelf: 'center' }}>
										<PauseOutlined
											style={{
												color: '#02C3CD',
												fontSize: '16px',
												transform: 'rotate(90deg)'
											}}
										/>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<TypeIndicesViability
											title={'Viabilidad de partida'}
											propertyData={
												viability && viability.initialViability && viability.initialViability
											}
											propertyColor={'#02C3CD'}
										/>
									</div>
									<div style={{ alignSelf: 'center' }}>
										<PlusOutlined
											style={{
												color: '#02C3CD',
												fontSize: '16px'
											}}
										/>
									</div>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<TypeIndicesViability
											title={'Ratio de financiación'}
											propertyColor={
												viability &&
												viability.penalties &&
												viability.penalties.financingCeiling === true
													? '#02C3CD'
													: '#FF4D4F'
											}
											propertyData={
												viability && viability.penaltyForFinancingCeiling !== undefined
													? viability.penaltyForFinancingCeiling.toFixed(2)
													: '-'
											}
											percent={
												viability && viability.ltv
													? `(${numeral(viability.ltv).format('0%')})`
													: '-'
											}
										/>
										<TypeIndicesViability
											title={'Ratio de endeudamiento'}
											propertyColor={
												viability &&
												viability.penalties &&
												viability.penalties.indebtedness === true
													? '#02C3CD'
													: '#FF4D4F'
											}
											propertyData={
												viability &&
												viability.penaltyForIndebtedness !== undefined &&
												viability.penaltyForIndebtedness !== null
													? viability.penaltyForIndebtedness.toFixed(2)
													: '-'
											}
											percent={
												viability && viability.debtPercentage
													? `(${numeral(viability.debtPercentage).format('0%')})`
													: '-'
											}
										/>
									</div>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<TypeIndicesViability
											title={'Estabilidad laboral'}
											propertyColor={
												viability && viability.penalties && viability.penalties.seniority === true
													? '#02C3CD'
													: '#FF4D4F'
											}
											propertyData={
												viability &&
												viability.penaltyForSeniority !== null &&
												viability.penaltyForSeniority !== undefined &&
												viability.activityPenalty !== null &&
												viability.activityPenalty !== undefined
													? (viability.activityPenalty + viability.penaltyForSeniority).toFixed(2)
													: '-'
											}
										/>
										<TypeIndicesViability
											title={'Volumen ingresos'}
											propertyColor={
												viability && viability.penalties && viability.penalties.incomes === true
													? '#02C3CD'
													: '#FF4D4F'
											}
											propertyData={
												viability &&
												viability.bonusIncomes !== undefined &&
												viability.bonusIncomes !== null
													? viability.bonusIncomes.toFixed(2)
													: '-'
											}
										/>
									</div>

									<TypeIndicesViability
										title={
											viability && viability.penalties && viability.penalties.noresident === true
												? 'Residencia en España'
												: 'No residente en España'
										}
										propertyData={
											viability && viability.penalties && viability.penalties.noresident ? (
												<ArrowUpOutlined style={{ color: '#72D00A', fontSize: '16px' }} />
											) : (
												<ArrowDownOutlined style={{ color: '#FF4D4F', fontSize: '16px' }} />
											)
										}
									/>
								</div>
							</Card>
						</Col>
						<Col lg={24} xs={24}>
							<div style={{ marginTop: '8px' }}>
								<Text
									style={{
										fontSize: '14px',
										fontWeight: '600',
										color: '#2F4858'
									}}>
									Cálculo de techos
								</Text>
							</div>
						</Col>
						<Col lg={24} xs={24}>
							<Card
								style={{
									border: '1px solid #C0DAEE',
									borderRadius: '4px',
									textAlign: 'center'
								}}>
								<div>
									<Text
										style={{
											color: '#2F4858',
											fontWeight: 600,
											fontSize: 16
										}}>
										{'La casa que se puede comprar (PVP)'}
									</Text>
								</div>
								<div style={{ marginTop: 24, textAlign: '-webkit-center' }}>
									{result && result[0] !== undefined && renderBarProgress()}
								</div>
							</Card>
						</Col>
						<Col lg={24}>
							<div style={{ marginTop: '8px' }}>
								<Text
									style={{
										fontSize: '14px',
										fontWeight: '600',
										color: '#2F4858'
									}}>
									Datos de la operación
								</Text>
							</div>
						</Col>

						<Col lg={24}>
							<Row gutter={[24, 24]}>
								<Col lg={8} xs={24}>
									<ComponentData
										image={'/images/userFinancial.svg'}
										title={'Datos personales Titular 1'}
										property1={
											mortgageData?.owner1?.age
												? mortgageData.owner1.age
												: mortgageData?.owner1?.birthdate
												? calculatedAge(mortgageData.owner1.birthdate)
												: '-'
										}
										property2={
											mortgageData && mortgageData.owner1 && mortgageData.owner1.activity
												? renderComponent('activity').options.map((activities) => {
														if (activities.value === mortgageData.owner1.activity) {
															return (
																<Text
																	strong
																	style={{
																		fontSize: '12px',
																		color: '#748EA0',
																		fontWeight: '500'
																	}}
																	key={activities.label}>
																	{activities.label}
																</Text>
															);
														}
												  })
												: 'Situación laboral'
										}
										property3={
											mortgageData && mortgageData.owner1 && mortgageData.owner1.cityzenship
												? `Residente en España`
												: '-'
										}
										property4={
											mortgageData && mortgageData.owner1 && mortgageData.owner1.seniority
												? `${mortgageData.owner1.seniority} años`
												: '-'
										}
									/>
								</Col>
								<Col lg={8} xs={24}>
									<ComponentData
										image={'/images/homeFinancial.svg'}
										title={'Datos de la vivienda'}
										property1={
											mortgageData && mortgageData.mortgage && mortgageData.mortgage.houseType
												? typeHouseType(mortgageData.mortgage.houseType)
												: '-'
										}
										property2={
											mortgageData && mortgageData.mortgage && mortgageData.mortgage.province
												? mortgageData.mortgage.province
												: '-'
										}
										property3={
											mortgageData && mortgageData.mortgage && mortgageData.mortgage.propertyType
												? typePropertyType(mortgageData.mortgage.propertyType)
												: '-'
										}
									/>
								</Col>
								<Col lg={8} xs={24}>
									<ComponentData
										image={'/images/euroFinancial.svg'}
										title={'Datos económicos'}
										property1={numeral(dataTotalIncomesParticipants).format('0 $')}
										property2={numeral(dataTotalObligationsPaymentsParticipants).format('0 $')}
										property4Label={'Ingresos netos: '}
										property5Label={'Gastos netos: '}
									/>
								</Col>
							</Row>
							<Card
								style={{
									border: '1px solid #C0DAEE',
									borderRadius: '4px',
									marginTop: 24
								}}>
								{viability !== undefined && Object.keys(viability).length > 0 && (
									<Form
										name="basic"
										layout="vertical"
										className="gb-form-type"
										initialValues={
											viability !== undefined
												? {
														maxBudget: numeral(viability?.operation?.types?.fixed?.limit).format(
															'0,0 $'
														),
														quotes: viability?.operation?.quotes / 12,
														taxes: numeral(viability?.operation?.taxes).format('0,0 $'),
														standardIndebtedness: numeral(viability?.ltv).format('0 %'),
														mortgageAmount: numeral(viability?.operation?.mortgageAmount).format(
															'0,0 $'
														),
														ltv: numeral(viability?.standardFinancingCeiling).format('0 %'),
														maxBudget1: numeral(viability?.limitSavings).format('0,0 $'),
														mortgagePurchaseSalesExpenses: numeral(
															viability?.operation?.mortgagePurchaseSalesExpenses
														).format('0,0 $')
												  }
												: {}
										}
										autoComplete="off">
										{viability !== undefined && Object.keys(viability).length > 0 && !maxBudget && (
											<div style={{ marginBottom: '16px' }}>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: '600',
														color: '#2F4858'
													}}>
													Cálculo de techo limitado por ahorros
												</Text>
											</div>
										)}
										<Row gutter={24}>
											<Col lg={4} xs={24}>
												{maxBudget ? (
													<Form.Item label="Importe de la vivienda" name="maxBudget">
														<InputNumber
															step={100}
															style={{
																borderColor: '#c0daee',
																width: '100%',
																borderRadius: '4px'
															}}
															defaultValue={mortgageData?.mortgage?.maxBudget}
															placeholder="Importe de la vivienda"
															onBlur={(e) => {
																{
																	changeValueFinacial('maxBudget', numeral(e.target.value).value());
																}
																setLoading(true);
															}}
														/>
													</Form.Item>
												) : (
													<Form.Item label="LTV Máximo" name="ltv">
														<Input
															onBlur={(e) => {
																changeValueFinacial('ltv', e.target.value.replace('%', '') / 100);
																setLoading(true);
															}}
														/>
													</Form.Item>
												)}
											</Col>
											<Col lg={4} xs={24}>
												<Form.Item label="Ahorros disponibles" name="savings">
													<InputNumber
														step={100}
														style={{
															borderColor: '#c0daee',
															width: '100%',
															borderRadius: '4px'
														}}
														defaultValue={
															viability.operation &&
															viability.operation.savings &&
															viability.operation.savings
														}
														placeholder="Ahorros disponibles"
														onBlur={(e) => {
															{
																changeValueFinacial('savings', numeral(e.target.value).value());
															}
															setLoading(true);
														}}
													/>
												</Form.Item>
											</Col>
											{!maxBudget && (
												<Col lg={5} xs={24}>
													<Form.Item label="Importe máximo hipoteca" name="mortgageAmount">
														<Input disabled={true} style={{ border: 'none' }} />
													</Form.Item>
												</Col>
											)}

											<Col lg={4} xs={24}>
												<Form.Item label="Gastos estimados" name={'mortgagePurchaseSalesExpenses'}>
													<Input disabled={true} style={{ border: !maxBudget && 'none' }} />
												</Form.Item>
											</Col>
											<Col lg={4} xs={24}>
												<Form.Item label="Impuestos estimados" name="taxes">
													<Input disabled={true} style={{ border: !maxBudget && 'none' }} />
												</Form.Item>
											</Col>
											{maxBudget && (
												<Col lg={4} xs={24}>
													<Form.Item label="Importe de la hipoteca" name="mortgageAmount">
														<Input disabled={true} style={{ border: !maxBudget && 'none' }} />
													</Form.Item>
												</Col>
											)}
											{maxBudget ? (
												<Col lg={4} xs={24}>
													<Form.Item label="Ratio de financiación" name="standardIndebtedness">
														<Input
															readOnly={true}
															style={{
																border: 'none',
																background: '#EFFCFC',
																color: '#02C3CD',
																fontWeight: 600,
																fontSize: 16
															}}
														/>
													</Form.Item>
												</Col>
											) : (
												<Col lg={5} xs={24}>
													<Form.Item label="Precio máximo de compra" name="maxBudget1">
														<Input
															readOnly={true}
															style={{
																border: 'none',
																background: '#EFFCFC',
																color: '#02C3CD',
																fontWeight: 600
															}}
														/>
													</Form.Item>
												</Col>
											)}
										</Row>
									</Form>
								)}
								{!maxBudget && viability !== undefined && Object.keys(viability).length > 0 ? (
									<Form
										name="basic"
										layout="vertical"
										className="gb-form-type"
										initialValues={
											viability !== undefined
												? {
														maxBudget:
															viability.operation &&
															viability.operation.types &&
															viability.operation.types.fixed &&
															numeral(
																viability.operation.types.fixed.limit >
																	viability.operation.types.variable.limit
																	? viability.operation.types.fixed.limit
																	: viability.operation.types.variable.limit
															).format('0,0 $'),
														savings:
															viability.operation &&
															viability.operation.savings &&
															numeral(viability.operation.savings).format('0,0 $'),
														quotes:
															viability.operation &&
															viability.operation.quotes &&
															viability.operation.quotes / 12,
														quote:
															viability.operation &&
															viability.operation.types &&
															viability.operation.types.fixed &&
															viability.operation.types.fixed.quote &&
															`${numeral(
																viability.operation.types.fixed.limit >
																	viability.operation.types.variable.limit
																	? viability.operation.types.fixed.quote
																	: viability.operation.types.variable.quote
															).format('0,0 $')}/mes`,
														spread:
															viability.operation &&
															viability.operation.types &&
															viability.operation.types.fixed &&
															viability.operation.types.fixed.spread &&
															`${(
																(viability.operation.types.fixed.limit >
																viability.operation.types.variable.limit
																	? viability.operation.types.fixed.spread
																	: viability.operation.types.variable.spread) * 100
															).toFixed(2)} %`,
														mortgageAmount:
															viability.operation &&
															viability.operation.types &&
															viability.operation.types.fixed &&
															viability.operation.types.fixed.mortgageAmount &&
															numeral(
																viability.operation.types.fixed.limit >
																	viability.operation.types.variable.limit
																	? viability.operation.types.fixed.mortgageAmount
																	: viability.operation.types.variable.mortgageAmount
															).format('0,0 $'),
														taxes:
															viability &&
															viability.operation &&
															viability.operation.taxes &&
															numeral(viability.operation.taxes).format('0,0 $'),
														standardIndebtedness:
															viability &&
															viability.standardIndebtedness &&
															numeral(viability.standardIndebtedness).format('0 %'),
														ltv: viability && viability.ltv && numeral(viability.ltv).format('0 %'),
														standardFinancingCeiling: numeral(
															viability && viability.standardIndebtedness
														).format('0 %')
												  }
												: {}
										}
										autoComplete="off">
										{viability !== undefined && Object.keys(viability).length > 0 && (
											<div style={{ marginBottom: '16px' }}>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: '600',
														color: '#2F4858'
													}}>
													Cálculo de techo limitado por endeudamiento
												</Text>
											</div>
										)}
										<Row gutter={24}>
											<Col lg={10} xs={24}>
												<Row gutter={24}>
													<Col lg={8} xs={24}>
														<Form.Item label="Endeudamiento máximo" name="standardFinancingCeiling">
															<Input
																onBlur={(e) => {
																	changeValueFinacial(
																		'debts',
																		e.target.value.replace('%', '') / 100
																	);
																	setLoading(true);
																}}
															/>
														</Form.Item>
													</Col>
													<Col lg={8} xs={24}>
														<Form.Item label="Tipo de interés" name="spread">
															<Input
																onBlur={(e) => {
																	changeValueFinacial(
																		'fixed',
																		e.target.value.replace('%', '') / 100
																	);
																	setLoading(true);
																}}
															/>
														</Form.Item>
													</Col>
													<Col lg={8} xs={24}>
														<Form.Item label="Plazo (años)" name="quotes">
															<Input
																onBlur={(e) => {
																	changeValueFinacial('quotes', e.target.value * 12);
																	setLoading(true);
																}}
															/>
														</Form.Item>
													</Col>
												</Row>
											</Col>
											<Col lg={10} xs={24}>
												<Row gutter={24}>
													<Col lg={8} xs={24}>
														<Form.Item label="Cuota mensual" name="quote">
															<Input disabled={true} style={{ border: 'none' }} />
														</Form.Item>
													</Col>
													<Col
														lg={8}
														xs={24}
														style={{
															paddingRight: '10px',
															paddingLeft: '10px'
														}}>
														<Form.Item label="Importe máximo hipoteca" name="mortgageAmount">
															<Input disabled={true} style={{ border: 'none' }} />
														</Form.Item>
													</Col>
													<Col lg={8} xs={24}>
														<Form.Item label="Impuestos + gastos" name="taxes">
															<Input disabled={true} style={{ border: 'none' }} />
														</Form.Item>
													</Col>
												</Row>
											</Col>
											<Col lg={4} xs={24}>
												<Form.Item label="Precio máximo compra" name="maxBudget">
													<Input
														readOnly={true}
														style={{
															border: 'none',
															background: '#EFFCFC',
															color: '#02C3CD',
															fontWeight: 600
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Form>
								) : null}
								{content}
							</Card>
						</Col>
						<Col lg={24} xs={24}>
							<div style={{ marginTop: '8px' }}>
								<Text
									style={{
										fontSize: '14px',
										fontWeight: '600',
										color: '#2F4858'
									}}>
									Bancos
								</Text>
							</div>
						</Col>
						<Col lg={24} xs={24}>
							<Card
								style={{
									border: '1px solid #C0DAEE',
									borderRadius: '4px',
									textAlign: 'center'
								}}>
								<img src={'/images/comingSoon.jpg'} height={'100px'} style={{ borderRadius: '10px' }} />
							</Card>
						</Col>
					</Row>
				</Fragment>
			);
		}
	}
};

export default NewPageFinancial;
